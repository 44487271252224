import styled from '@emotion/styled';
import { GetUserScheduleDeskReservations as Reservation } from '../../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SidebarIconByline } from '../../IconByLine';
import { getReservationInfo } from '../../../../../utilities/Reservation';

type Props = {
  reservation: Reservation;
};

export const CheckInRequirements = ({
  reservation,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('weekSchedule');

  const {
    hasCheckIn,
    hasLocalCheckIn,
    checkInStatus,
    hasHealthCheckpoint,
    healthCheckpointStatus,
  } = getReservationInfo(reservation);
  const { checkInComplete, checkInRequiredFuture, checkInRequiredNow } =
    checkInStatus;
  const {
    healthCheckpointComplete,
    healthCheckpointFailed,
    healthCheckpointPending,
  } = healthCheckpointStatus;

  if (!hasCheckIn && !hasHealthCheckpoint) return null;

  const { icon, copy } = (() => {
    // LOCAL CHECK IN
    if (hasLocalCheckIn) {
      return {
        icon: 'WARN' as const,
        copy: t('officeDay.visitCard.check_in.local'),
      };
    }
    // Health Check and Check In
    if (hasHealthCheckpoint && hasCheckIn) {
      // Health check failed
      if (healthCheckpointFailed) {
        return {
          icon: 'FAIL' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.healthCheck_failed'
          ),
        };
      }
      // check in and/or HC not complete
      if (healthCheckpointPending) {
        return {
          icon: 'WARN' as const,
          copy: checkInRequiredFuture
            ? t(
                'officeDay.visitCard.check_in_requirements.healthCheck_check_in_required_future',
                {
                  startTime: moment(
                    reservation.confirmation?.confirmationWindowStart
                  ).format('hh:mm A'),
                }
              )
            : checkInRequiredNow
            ? t(
                'officeDay.visitCard.check_in_requirements.healthCheck_check_in_required_now',
                {
                  endTime: moment(
                    reservation.confirmation?.confirmationWindowEnd
                  ).format('hh:mm A'),
                }
              )
            : checkInComplete
            ? t(
                'officeDay.visitCard.check_in_requirements.healthCheck_check_in_required'
              )
            : '',
        };
      }
      // both complete
      if (healthCheckpointComplete && checkInComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.healthCheck_check_in_complete'
          ),
        };
      }
    }
    // Just check in
    if (hasCheckIn) {
      if (checkInComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.check_in_complete'
          ),
        };
      }
      if (checkInRequiredNow) {
        return {
          icon: 'WARN' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.check_in_required_by',
            {
              endTime: moment(
                reservation.confirmation?.confirmationWindowEnd
              ).format('hh:mm A'),
            }
          ),
        };
      }
      if (checkInRequiredFuture) {
        return {
          icon: 'WARN' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.check_in_starts_at',
            {
              startTime: moment(
                reservation.confirmation?.confirmationWindowStart
              ).format('hh:mm A'),
            }
          ),
        };
      }
    }

    // just Health checkpoint
    if (hasHealthCheckpoint) {
      if (healthCheckpointFailed) {
        return {
          icon: 'FAIL' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.healthCheck_failed'
          ),
        };
      }
      if (healthCheckpointPending) {
        return {
          icon: 'WARN' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.healthCheck_required'
          ),
        };
      }
      if (healthCheckpointComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: t(
            'officeDay.visitCard.check_in_requirements.healthCheck_complete'
          ),
        };
      }
    }
    return {
      icon: 'SUCCESS' as const,
      copy: '',
    };
  })();
  return (
    <Container>
      <SidebarIconByline type={icon} copy={copy} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 4px;
`;

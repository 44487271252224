import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LayerFeatures from '../LayerFeatures';
import FeatureState from '../FeatureState';
import {withMap} from '../Map';

export class AtlasZoneLabels extends Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    /**
     * If false, hides zone labels on the Map.
     */
    showLabels: PropTypes.bool.isRequired,
    /**
     * There are cases where we want to override the default behavior for showing or hiding
     * zone labels, so we can pass in an object which tells us which zones need to be overridden
     * and manually set to be hidden or shown regardless of the state of `showLabels`.
     *
     * {
     *  show: ZoneID[], --- any zone id corresponding to a zone in a selected work area
     *  hide: ZoneID[] --- any zone id corresponding to a zone in a work area
     * }
     */
    overrides: PropTypes.object
  };

  static defaultProps = {
    showLabels: true
  };

  render() {
    const specifier = 'zone-labels';

    return (
      <LayerFeatures key={specifier} specifier={[specifier]}>
        {({features}) => {
          return features.map((feature) => {
            const zoneId = feature.properties.ownerId;

            const zoneIdIsInShowOverrides =
              this.props.overrides &&
              this.props.overrides.show &&
              this.props.overrides.show.includes(zoneId);

            const zoneIdIsInHideOverrides =
              this.props.overrides &&
              this.props.overrides.hide &&
              this.props.overrides.hide.includes(zoneId);

            const shouldShowLabel = zoneIdIsInShowOverrides
              ? true
              : zoneIdIsInHideOverrides
              ? false
              : this.props.showLabels;

            return (
              <FeatureState
                key={feature.id}
                feature={feature}
                state={{
                  'show-label': shouldShowLabel
                }}
              />
            );
          });
        }}
      </LayerFeatures>
    );
  }
}

export default withMap(AtlasZoneLabels);

enum MapEventType {
  RESIZE = 'resize',
  REMOVE = 'remove',
  MOUSEDOWN = 'mousedown',
  MOUSEUP = 'mouseup',
  MOUSEOVER = 'mouseover',
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
  DBLCLICK = 'dblclick',
  MOUSEENTER = 'mouseenter',
  MOUSELEAVE = 'mouseleave',
  MOUSEOUT = 'mouseout',
  CONTEXTMENU = 'contextmenu',
  WHEEL = 'wheel',
  TOUCHSTART = 'touchstart',
  TOUCHEND = 'touchend',
  TOUCHMOVE = 'touchmove',
  TOUCHCANCEL = 'touchcancel',
  MOVESTART = 'movestart',
  MOVE = 'move',
  MOVEEND = 'moveend',
  DRAGSTART = 'dragstart',
  DRAG = 'drag',
  DRAGEND = 'dragend',
  ZOOMSTART = 'zoomstart',
  ZOOM = 'zoom',
  ZOOMEND = 'zoomend',
  ROTATESTART = 'rotatestart',
  ROTATE = 'rotate',
  ROTATEEND = 'rotateend',
  PITCHSTART = 'pitchstart',
  PITCH = 'pitch',
  PITCHEND = 'pitchend',
  BOXZOOMSTART = 'boxzoomstart',
  BOXZOOMEND = 'boxzoomend',
  BOXZOOMCANCEL = 'boxzoomcancel',
  WEBGLCONTEXTLOST = 'webglcontextlost',
  WEBGLCONTEXTRESTORED = 'webglcontextrestored',
  LOAD = 'load',
  RENDER = 'render',
  IDLE = 'idle',
  ERROR = 'error',
  DATA = 'data',
  STYLEDATA = 'styledata',
  SOURCEDATA = 'sourcedata',
  DATALOADING = 'dataloading',
  STYLEDATALOADING = 'styledataloading',
  SOURCEDATALOADING = 'sourcedataloading'
}

const MapEventTypeList = Object.values(MapEventType);

export default MapEventType;

export {MapEventTypeList};

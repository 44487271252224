import styled from '@emotion/styled';
import {
  Body07,
  Colors,
  Column,
  Heading07,
  Img,
  Row,
  Shadow03,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
const AvatarsRow = require('./Images/AvatarsRow.png');

export const EventPreview = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation('sidebar');
  return (
    <Column
      className={className}
      backgroundColor={Colors.White0}
      border={`1px solid ${Colors.Tan30}`}
      boxShadow={Shadow03}
      borderRadius="8px"
    >
      <Heading07>{t('brainstorm')}</Heading07>
      <Row width="100%" mt="4px" alignItems="center" ml="4px">
        <Img
          src={AvatarsRow}
          alt={t('avatar_row_img_alt')}
          width="40%"
          height="auto"
        />
        <Byline>{t('office_count_blyine')}</Byline>
      </Row>
    </Column>
  );
};

const Byline = styled(Body07)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

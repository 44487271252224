import { gql, QueryResult, useQuery } from '@apollo/client';
import { useApolloContext } from '../..';
import { GetAllSpaceBookingFeatureFlagsQuery } from './__generated__/useGetAllFeatureFlags.generated';

export const GET_ALL_FEATURE_FLAGS = gql`
  query GetAllSpaceBookingFeatureFlags {
    suggestedSpacesFeatureFlagEnabled: isFeatureFlagEnabledForOrgOrMe(
      flagType: SUGGESTED_SPACES
    )
    teamsSpaceBookingFeatureFlagEnabled: isFeatureFlagEnabledForOrgOrMe(
      flagType: MS_TEAMS_SPACE_BOOKING
    )
  }
`;

export function useFeatureFlagQuery(): QueryResult<GetAllSpaceBookingFeatureFlagsQuery> {
  const { tenantId } = useApolloContext();
  return useQuery(GET_ALL_FEATURE_FLAGS, {
    skip: !tenantId,
  });
}

import { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Flex, Body, Colors } from '@robinpowered/design-system';
import { GroupSolid, VideoCallSolid, PhoneSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../Tooltip';
import { MapSpaceStateForLevelSpaces } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';

type SpaceFeaturesBylineProps = {
  capacity: MapSpaceStateForLevelSpaces['capacity'];
  amenities: MapSpaceStateForLevelSpaces['amenities'];
};

const PHONE = 'Phone';
const VIDEO_CONFERENCING = 'Video Conferencing';

export const SpaceFeaturesByline: FC<SpaceFeaturesBylineProps> = ({
  capacity,
  amenities,
}) => {
  const { t } = useTranslation('spaceBooking');

  const amenityGroups = useMemo(() => {
    return amenities.reduce(
      (
        acc: {
          video: boolean;
          phone: boolean;
          others: string[];
        },
        amenity
      ) => {
        switch (amenity.name) {
          case VIDEO_CONFERENCING:
            acc.video = true;
            break;
          case PHONE:
            acc.phone = true;
            break;
          default:
            acc.others.push(amenity.name);
        }
        return acc;
      },
      { video: false, phone: false, others: [] }
    );
  }, [amenities]);
  return (
    <Flex display="flex" flexDirection="row">
      {capacity && (
        <Flex display="flex" alignItems="center" mr="8px">
          <GroupSolid size={16} color={Colors.Gray60} />
          <Body.Small color={Colors.Gray80} style={{ marginLeft: '4px' }}>
            {capacity}
          </Body.Small>
        </Flex>
      )}
      <Flex display="flex" alignItems="center">
        {amenityGroups.video && (
          <VideoCallSolid
            size={16}
            color={Colors.Gray60}
            style={{ marginRight: '8px' }}
          />
        )}
        {amenityGroups.phone && (
          <PhoneSolid
            size={16}
            color={Colors.Gray60}
            style={{ marginRight: '8px' }}
          />
        )}
        {amenityGroups.others.length > 0 && (
          <MoreHover>
            <MoreAmenities>
              {t('space_list.amenities_more', {
                count: amenityGroups.others.length,
              })}
            </MoreAmenities>
            <TooltipContainer>
              <TooltipText>{amenityGroups.others.join(', ')}</TooltipText>
            </TooltipContainer>
          </MoreHover>
        )}
      </Flex>
    </Flex>
  );
};

const MoreAmenities = styled(Body.XSmall)`
  text-decoration: underline;
  text-decoration-color: ${Colors.Gray80};
  text-decoration-style: dotted;
  text-underline-offset: 5px;
  color: ${Colors.Gray80};
`;

const MoreHover = styled.div`
  position: relative;
  @media (hover: hover) {
    &:hover {
      div {
        display: flex;
      }
    }
  }
`;

const TooltipContainer = styled(Tooltip)`
  bottom: calc(100% - 10px);
  left: 50%;
  word-wrap: break-word;
  padding: 8px 12px;
  text-align: initial;
`;

const TooltipText = styled(Body.XSmall)`
  color: ${Colors.White0};
`;

import { Text } from '@fluentui/react-northstar';
import { Colors } from '@robinpowered/design-system';
import MinorPinSolid from '@robinpowered/icons/MinorPinSolid';
import DeskChairIcon from '@robinpowered/icons/v2/DeskChair';
import { OrgLocation } from '../../../contexts';
import { GetUserScheduleDeskReservations as Reservation } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { OverflowMenu } from '../OverflowMenu';
import styled from '@emotion/styled';
import { isBeforeToday } from '../../../utilities';
import moment from 'moment';
import {
  CheckInRequirementsText as getCheckInRequirementsText,
  CheckInRequirementsIcon,
} from './CheckInRequirements';

type OfficeStatusInfoProps = {
  reservation?: Reservation;
  disabled: boolean;
  inOfficeLocation: OrgLocation | undefined;
  loading: boolean;
  setIsSubmitting: (val: boolean) => void;
  cancelReservation: (reservationId: string) => Promise<void>;
};

export const OfficeStatusInfo = ({
  reservation,
  disabled,
  inOfficeLocation,
  loading,
  setIsSubmitting,
  cancelReservation,
}: OfficeStatusInfoProps) => {
  //use reservation location if there is one, else use in office location
  const locationText = reservation
    ? reservation.seat.location.name
    : inOfficeLocation?.name;
  const deskText = reservation ? reservation.seat.name : 'No Desk Booked';
  const podText = reservation?.seat?.zone
    ? `${reservation.seat.zone.name}`
    : '';
  const levelText = reservation?.seat?.level ? reservation.seat.level.name : '';
  const secondRowText = reservation
    ? `${locationText}, ${podText}, ${levelText}`
    : locationText;

  return (
    <OfficeStatusInfoWrapper>
      <OfficeStatusRow>
        <BoxForIcon>
          {reservation ? (
            <DeskChairIcon
              size={20}
              color={disabled ? '#C7C7C7' : Colors.Gray60}
            />
          ) : (
            <MinorPinSolid
              size={20}
              color={disabled ? '#C7C7C7' : Colors.Gray60}
            />
          )}
        </BoxForIcon>
        <TopRowText disabled={disabled} weight="semibold">
          {deskText}
        </TopRowText>
        {reservation && !disabled ? (
          <OverflowWrapper>
            <OverflowMenu
              cancelReservation={cancelReservation}
              loading={loading}
              setIsLoading={setIsSubmitting}
              isDisabled={disabled}
              reservationId={reservation.id}
            />
          </OverflowWrapper>
        ) : (
          <div />
        )}
      </OfficeStatusRow>
      <OfficeStatusRow>
        <BoxForIcon />
        <BottomRowText disabled={disabled}>{secondRowText}</BottomRowText>
        <div />
      </OfficeStatusRow>
      {reservation && !isBeforeToday(moment(reservation?.startTime)) && (
        <OfficeStatusRow>
          <BoxForIcon>
            <CheckInRequirementsIcon reservation={reservation} />
          </BoxForIcon>
          <CheckInReqTextBox>
            {getCheckInRequirementsText(reservation)}
          </CheckInReqTextBox>
          <div />
        </OfficeStatusRow>
      )}
    </OfficeStatusInfoWrapper>
  );
};

// Row element consisting of a left middle and right child
// using to keep elements aligned with each other
const OfficeStatusRow = (props: { children: JSX.Element[] }) => {
  return (
    <RowWrapper>
      <LeftPanel>{props.children[0] ?? props.children[0]}</LeftPanel>
      <MiddlePanel>{props.children[1] ?? props.children[1]}</MiddlePanel>
      <RightPanel>{props.children[2] ?? props.children[2]}</RightPanel>
    </RowWrapper>
  );
};

const OfficeStatusInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding-bottom: 10px;
`;
const OverflowWrapper = styled.div`
  padding-top: 5px;
`;

const RowWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;

const BoxForIcon = styled.div`
  width: 20px;
`;

const LeftPanel = styled.div`
  align-items: center;
  display: flex;
  width: 10%;
  margin-left: 5px;
`;

const MiddlePanel = styled.div`
  display: flex;
  flex: 1 1 100%;
  width: 0;
`;

const RightPanel = styled.div`
  width: 20%;
`;

const TopRowText = styled(Text)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? '#C8C6C4' : '#242424')};
  padding-bottom: 5px;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
`;

const BottomRowText = styled(Text)<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? '#C8C6C4' : '#616161')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  margin-bottom: 10px;
`;

const CheckInReqTextBox = styled(Text)`
  font-size: 12px;
  flex: 1 1 100%;
  width: 0;
  overflow-wrap: break-word;
`;

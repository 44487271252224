import { FC, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Body,
  Colors,
  Flex,
  Heading,
  Img,
  ButtonV4,
  Skeleton,
  UtilityButton,
} from '@robinpowered/design-system';
import { useGetSpaceById } from '../../contexts/SpaceBooking/hooks/useGetSpaceById';
import InfoSolid from '@robinpowered/icons/InfoSolid';
import SparkleSolid from '@robinpowered/icons/SparkleSolid';
import TeamSolid from '@robinpowered/icons/TeamSolid';
import CircleCheckmarkSolid from '@robinpowered/icons/CircleCheckmarkSolid';
import { ChevronLeftOutline } from '@robinpowered/icons';
import { useGetLocationById } from '../../contexts/SpaceBooking/hooks/useGetLocationById';
import { MeetingSummarySection } from './MeetingSummarySection';
import { useTranslation } from 'react-i18next';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { SpaceReservationPolicies } from './SpaceReservationPolicies';
import {
  Availability,
  SpaceScheduleConfiguration,
  UnbookableReasonType,
} from '../../__generated__/types';
import { MapSpaceStateForLevelSpaces } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';
import { GetSpaceInfoByIdGetSpaceById } from '../../contexts/SpaceBooking/hooks/__generated__/useGetSpaceById.generated';
import { robinLogger } from '../../utilities/RobinLogger';
import { teamsTheme } from '@fluentui/react-northstar';

type Props = {
  selectedSpaceId?: string;
  selectedLevelId?: string;
};

type ExtraReason =
  | 'on_demand' // long ago we could detect presence in the room - not anymore
  | 'request_only_and_available'; // when *unavailable* we'll get CONFLICT_WITH_ANOTHER_MEETING

const getUnbookableReasons = (
  selectedSpaceStateData: MapSpaceStateForLevelSpaces | undefined,
  spaceInfo: GetSpaceInfoByIdGetSpaceById | null | undefined,
  spaceBookedForEvent: boolean
): (UnbookableReasonType | ExtraReason)[] => {
  const firstWindowedAvailability =
    selectedSpaceStateData?.windowedAvailability[0];

  const rawUnbookableReasons =
    firstWindowedAvailability?.unbookableReasons.filter(
      (r): r is UnbookableReasonType => !!r
    ) || [];

  const extraReasons: ExtraReason[] = [];
  if (
    spaceInfo?.scheduleConfiguration === SpaceScheduleConfiguration.ON_DEMAND
  ) {
    // nothing can book an on-demand space
    // maybe that means backend should be listing it in unbookableReasons
    extraReasons.push('on_demand');
  } else if (
    spaceInfo?.scheduleConfiguration ===
      SpaceScheduleConfiguration.REQUEST_ONLY &&
    firstWindowedAvailability?.status === Availability.AVAILABLE
  ) {
    // this UI doesn't support booking request-only spaces :/
    extraReasons.push('request_only_and_available');
  }

  // if the conflict is with our own event, don't warn about that in UI
  const reasonsToIgnore: UnbookableReasonType[] = spaceBookedForEvent
    ? [UnbookableReasonType.CONFLICT_WITH_ANOTHER_MEETING]
    : [];

  return [
    ...rawUnbookableReasons.filter((r) => !reasonsToIgnore.includes(r)),
    ...extraReasons,
  ];
};

const useReasonDescription = () => {
  const { t } = useTranslation('spaceBooking', {
    keyPrefix: 'unbookable_reasons',
  });
  const reasonDescription = (reason: UnbookableReasonType | ExtraReason) =>
    // https://stackoverflow.com/a/71647145
    t(reason.toLowerCase() as Lowercase<typeof reason>);

  return {
    reasonDescription,
  };
};

export const SpaceDetailsView: FC<Props> = ({
  selectedSpaceId,
  selectedLevelId,
}) => {
  const logger = robinLogger();
  const { t } = useTranslation('spaceBooking', { keyPrefix: 'space_details' });
  const { t: tNoPrefix } = useTranslation('spaceBooking');
  const { reasonDescription } = useReasonDescription();
  const { data: spaceData } = useGetSpaceById(selectedSpaceId);
  const { data: locationData, loading: loadingLocation } = useGetLocationById(
    spaceData?.getSpaceById?.locationId.toString()
  );
  const {
    spaceIdCanBeBooked,
    addSpace,
    addSpaceResult,
    event,
    suggestedSpaceId,
    spaceStateData,
    goBack,
    isBookedSpace,
  } = useSpaceBookingContext();

  const spaceInfo = spaceData?.getSpaceById;

  const locationName = locationData?.getLocationById?.name;

  const isSuggestion = !!spaceInfo?.id && spaceInfo.id === suggestedSpaceId;

  const levelName = useMemo(() => {
    return locationData?.getLocationById?.levels.find(
      (level) => level.id === selectedLevelId
    )?.name;
  }, [locationData, selectedLevelId]);

  const addSelectedSpace = useCallback(() => {
    if (!selectedSpaceId) {
      return;
    }
    logger.reportEvent('clicked-book-space', {
      space: selectedSpaceId,
      event: event?.id,
      buttonLocation: 'add space modal details view',
      spaceWasSuggested: isSuggestion,
    });
    addSpace(selectedSpaceId, isSuggestion);
  }, [selectedSpaceId, event?.id, addSpace, isSuggestion, logger]);

  const {
    loading: addSpaceLoading,
    data: addSpaceData,
    error: addSpaceError,
  } = addSpaceResult;

  const selectedSpaceStateData = spaceStateData?.getLevelById?.spaces.find(
    (s) => s.id === selectedSpaceId
  );

  const spaceBookedForEvent = !!event?.spaces.find(
    (space) => space.id === selectedSpaceId
  );

  const unbookableReasons = getUnbookableReasons(
    selectedSpaceStateData,
    spaceInfo,
    spaceBookedForEvent
  );

  return (
    <Flex display="flex" flexDirection="column" style={{ height: '100%' }}>
      <SpaceDetailContainer>
        {!isBookedSpace && (
          <Flex
            display="flex"
            flexDirection="row"
            alignItems="center"
            pt="4px"
            pb="4px"
            mb="8px"
          >
            <StyledChevronButton
              icon={ChevronLeftOutline}
              aria-label={tNoPrefix('aria.go_back')}
              onClick={goBack}
              autoFocus
            />

            <Heading.Regular>{t('title')}</Heading.Regular>
          </Flex>
        )}
        <SpaceImage display="flex" style={{ backgroundColor: Colors.Gray10 }}>
          <Img src={spaceInfo?.image} style={{ width: '100%' }} />
        </SpaceImage>

        {(isSuggestion ||
          spaceBookedForEvent ||
          unbookableReasons.length > 0) && (
          <Flex
            display="flex"
            flexDirection="column"
            alignItems="left"
            mt="4px"
            mb="12px"
          >
            {unbookableReasons.map((reason, index) => {
              return (
                <Flex key={index} display="flex" alignItems="center">
                  <InfoSolid
                    color={Colors.Gray60}
                    size={16}
                    style={{ marginRight: '4px', flexShrink: 0 }}
                  />
                  <Heading.XSmall style={{ color: Colors.Gray60 }}>
                    {reasonDescription(reason)}
                  </Heading.XSmall>
                </Flex>
              );
            })}
            {isSuggestion && (
              <Flex
                display="flex"
                alignItems="center"
                mt={unbookableReasons?.length ? '12px' : 0}
              >
                <SparkleSolid
                  color={teamsTheme.siteVariables.colorScheme.brand.background}
                  size={16}
                  style={{ marginRight: '4px', flexShrink: 0 }}
                />
                <Heading.XSmall
                  style={{
                    color:
                      teamsTheme.siteVariables.colorScheme.brand.background,
                  }}
                >
                  {t('good_fit_for_meeting')}
                </Heading.XSmall>
              </Flex>
            )}
            {spaceBookedForEvent && (
              <Flex
                display="flex"
                alignItems="center"
                mt={unbookableReasons?.length ? '12px' : 0}
              >
                <CircleCheckmarkSolid
                  color={Colors.GreenProduct}
                  size={16}
                  style={{ marginRight: '4px', flexShrink: 0 }}
                />
                <Heading.XSmall style={{ color: Colors.Gray110 }}>
                  {t('booked')}
                </Heading.XSmall>
              </Flex>
            )}
          </Flex>
        )}

        <Flex display="flex" flexDirection="column" marginBottom="12px">
          <Body.Large style={{ wordBreak: 'break-all' }}>
            {spaceInfo?.name}
          </Body.Large>
          {loadingLocation ? (
            <Skeleton width="232px" height="10px" mt="4px" />
          ) : (
            <Body.Regular style={{ color: Colors.Gray80 }}>
              {locationName} {levelName}
            </Body.Regular>
          )}
        </Flex>
        {spaceInfo?.capacity && (
          <Flex display="flex" alignItems={'center'} marginBottom="12px">
            <TeamSolid
              size={15}
              color={Colors.Gray60}
              style={{ marginRight: '4px' }}
            />
            <Body.XSmall>{spaceInfo?.capacity}</Body.XSmall>
          </Flex>
        )}
        {!!spaceInfo?.amenities.length && (
          <Flex marginBottom="12px">
            <Heading.Regular style={{ marginBottom: '8px' }}>
              {t('amenities.title')}
            </Heading.Regular>

            <Flex>
              {spaceInfo?.amenities.map((amenity, index) => {
                return (
                  <Amenity key={index}>
                    <Body.XSmall>{amenity.name}</Body.XSmall>
                  </Amenity>
                );
              })}
            </Flex>
          </Flex>
        )}
        {spaceInfo?.scheduleConfiguration !==
          SpaceScheduleConfiguration.ON_DEMAND &&
          spaceInfo?.reservationPolicies && (
            <SpaceReservationPolicies
              policies={spaceInfo.reservationPolicies}
              scheduleConfiguration={spaceInfo.scheduleConfiguration}
            />
          )}
      </SpaceDetailContainer>
      {event && <MeetingSummarySection event={event} />}
      {spaceIdCanBeBooked(selectedSpaceId) && (
        <Flex display="flex">
          <BookButton
            variant="primary"
            size="large"
            onClick={addSelectedSpace}
            disabled={!selectedSpaceId || addSpaceLoading}
            label={
              addSpaceLoading
                ? t('booking')
                : addSpaceError
                ? t('error_booking')
                : addSpaceData
                ? t('booked')
                : tNoPrefix('actions.book')
            }
          />
        </Flex>
      )}
    </Flex>
  );
};

const StyledChevronButton = styled(UtilityButton)`
  margin-right: 8px;
  border: none !important;
  padding: 0 !important;
  svg {
    height: 20px !important;
    width: 20px !important;
  }
`;

const SpaceDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${Colors.White0};
  padding: 8px 16px;
  overflow-y: auto;
`;

const SpaceImage = styled(Flex)`
  width: 100%;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 12px;
`;

const Amenity = styled.div`
  background-color: ${Colors.Tan10};
  padding: 2px 4px;
  gap: 4px;

  width: 136px;
  height: 24px;

  border-radius: 4px;
  &:not(:first-of-type) {
    margin: 2px;
  }
  display: inline-block;
  width: auto;
  cursor: pointer;
`;

const BookButton = styled(ButtonV4)`
  width: 100%;
  margin: 20px;

  color: ${Colors.White0};
  background-color: ${teamsTheme.siteVariables.colorScheme.brand.background};
  border: 4px;

  &:hover {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand.backgroundHover};
    color: ${Colors.White0};
  }

  &:focus,
  &:focus-visible,
  &:active {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand.backgroundHover};
    color: ${Colors.White0};
`;

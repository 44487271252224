import styled from '@emotion/styled';
import { Body, Colors } from '@robinpowered/design-system';
import SparkleSolid from '@robinpowered/icons/SparkleSolid';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettingsContext } from '../../../contexts';
import { teamsTheme } from '@fluentui/react-northstar';

export const ReservationsEventsWithoutSpaces = ({
  numWithoutSpaces,
}: {
  numWithoutSpaces: number;
}) => {
  const { t } = useTranslation('weekSchedule');
  const { suggestedSpacesEnabled } = useSettingsContext();

  const numWithoutSpacesText = useMemo(() => {
    return numWithoutSpaces > 1
      ? t('officeDay.reservationsCard.without_space_plural', {
          total: numWithoutSpaces,
        })
      : t('officeDay.reservationsCard.without_space_singular');
  }, [numWithoutSpaces, t]);

  return (
    <EventsWithoutSpacesContainer
      isHighlighted={suggestedSpacesEnabled || false}
    >
      {suggestedSpacesEnabled && (
        <SparkleSolid
          color={teamsTheme.siteVariables.colorScheme.brand.background}
          size={16}
        />
      )}
      <Text>{numWithoutSpacesText}</Text>
    </EventsWithoutSpacesContainer>
  );
};

const EventsWithoutSpacesContainer = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;

  background-color: ${teamsTheme.siteVariables.colorScheme.default.background1};

  color: ${({ isHighlighted }) =>
    isHighlighted
      ? teamsTheme.siteVariables.colorScheme.brand.background
      : 'black'};
`;

const Text = styled(Body.XSmall)`
  padding-left: 4px;
`;

import { gql, useQuery } from '@apollo/client';
import {
  UseUserNotifcationSettingsDataGetUserNotificationSettings,
  UseUserNotifcationSettingsDataQuery,
  UseUserNotifcationSettingsDataQueryVariables,
} from './__generated__/useUserNotificationSettingsData.generated';

export type UserNotificationSetting =
  UseUserNotifcationSettingsDataGetUserNotificationSettings;

export const GET_USER_NOTIFICATION_SETTINGS = gql`
  query UseUserNotifcationSettingsData($userId: ID!) {
    getUserNotificationSettings(userId: $userId) {
      ownerId
      name
      values
    }
  }
`;

export const useUserNotificationSettingsData = (userId: string) => {
  const { data, loading } = useQuery<
    UseUserNotifcationSettingsDataQuery,
    UseUserNotifcationSettingsDataQueryVariables
  >(GET_USER_NOTIFICATION_SETTINGS, {
    variables: {
      userId: userId,
    },
    fetchPolicy: 'cache-and-network',
  });

  return {
    loading,
    data: data?.getUserNotificationSettings ?? [],
  };
};

import { gql, QueryResult, useQuery } from '@apollo/client';
import { UserSortBy, UserSortOrder } from '../../../__generated__/types';
import {
  GetUsersByIdsQuery,
  GetUsersByIdsQueryVariables,
} from './__generated__/useUsers.generated';

export const PAGE_SIZE = 50;

export const usersQueryParams = {
  limit: PAGE_SIZE,
  offset: 0,
  sortByField: [UserSortBy.FAVORITE, UserSortBy.NAME],
  sortOrder: UserSortOrder.ASCENDING,
};

export const GET_USERS = gql`
  query getUsersByIds(
    $ids: [ID!]!
    $limit: Int
    $offset: Int
    $sortByField: [UserSortBy]
    $sortOrder: UserSortOrder
    $filterBy: String
  ) {
    getUsersByIds(
      ids: $ids
      limit: $limit
      offset: $offset
      sortByField: $sortByField
      sortOrder: $sortOrder
      filterBy: $filterBy
    ) {
      id
      name
      avatar
      primaryEmail {
        email
      }
    }
  }
`;

type Params = {
  limit?: number;
  offset?: number;
  sortByField?: UserSortBy[];
  sortOder?: UserSortOrder;
  filterBy?: string;
};

// TODO: office day list uses the same call. We should merge these two
export function useUsers(
  userIds?: string[],
  params: Params = {}
): QueryResult<GetUsersByIdsQuery, GetUsersByIdsQueryVariables> {
  return useQuery<GetUsersByIdsQuery, GetUsersByIdsQueryVariables>(GET_USERS, {
    fetchPolicy: 'cache-first',
    variables: { ids: userIds as string[], ...params },
    skip: !userIds || userIds.length === 0,
  });
}

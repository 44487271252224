import { env } from 'process';

declare global {
  interface Window {
    NODE_ENV: string;
    REACT_APP_GQL_URL: string;
    REACT_APP_NAME: string;
    REACT_APP_VERSION: string;
    REACT_APP_START_LOGIN_PAGE_URL: string;
    REACT_APP_ROBIN_OAUTH_URL: string;
    REACT_APP_ROBIN_BOT_SERVICE_URL: string;
    REACT_APP_ROBIN_API_URL: string;
    REACT_APP_ATLAS_SERVICE_URL: string;
    REACT_APP_ROBIN_AMPLITUDE_KEY: string;
    REACT_APP_ROBIN_SENTRY_DSN: string;
    REACT_APP_DD_APPLICATION_ID: string;
    REACT_APP_DD_CLIENT_TOKEN: string;
    REACT_APP_DD_SITE: string | undefined;
    REACT_APP_FEATURE_FLAGS_BASE_URL: string;
    REACT_APP_APPLICATION_ID: string;
    REACT_APP_DASHBOARD_URL: string;
  }
}

type Config = {
  env: string;
  gqlUrl: string;
  appName: string;
  appVersion: string;
  startLoginPageUrl: string;
  oauthUrl: string;
  teamsBotServiceUrl: string;
  robinApiUrl: string;
  atlasServiceUrl: string;
  amplitudeKey: string;
  sentryDsn: string;
  ddApplicationId: string;
  ddClientToken: string;
  ddSite: string;
  featureFlagsUrl: string;
  appId: string;
  dashboardUrl: string;
};

export const config: Config = {
  env: window.NODE_ENV,
  gqlUrl: window.REACT_APP_GQL_URL,
  appName: window.REACT_APP_NAME,
  appVersion: window.REACT_APP_VERSION,
  startLoginPageUrl: window.REACT_APP_START_LOGIN_PAGE_URL,
  oauthUrl: window.REACT_APP_ROBIN_OAUTH_URL,
  teamsBotServiceUrl: window.REACT_APP_ROBIN_BOT_SERVICE_URL,
  robinApiUrl: window.REACT_APP_ROBIN_API_URL,
  atlasServiceUrl: window.REACT_APP_ATLAS_SERVICE_URL,
  amplitudeKey: window.REACT_APP_ROBIN_AMPLITUDE_KEY,
  sentryDsn: window.REACT_APP_ROBIN_SENTRY_DSN,
  ddApplicationId: window.REACT_APP_DD_APPLICATION_ID,
  ddClientToken: window.REACT_APP_DD_CLIENT_TOKEN,
  ddSite: window.REACT_APP_DD_SITE || 'datadoghq.com',
  featureFlagsUrl: window.REACT_APP_FEATURE_FLAGS_BASE_URL,
  appId: window.REACT_APP_APPLICATION_ID,
  dashboardUrl: window.REACT_APP_DASHBOARD_URL,
};

const requiredEnvars: string[] = [
  'REACT_APP_GQL_URL',
  'REACT_APP_START_LOGIN_PAGE_URL',
  'REACT_APP_NAME',
  'REACT_APP_VERSION',
  'REACT_APP_ROBIN_OAUTH_URL',
  'NODE_ENV',
  'REACT_APP_ROBIN_BOT_SERVICE_URL',
  'REACT_APP_ROBIN_API_URL',
  'REACT_APP_ATLAS_SERVICE_URL',
  'REACT_APP_ROBIN_AMPLITUDE_KEY',
  'REACT_APP_ROBIN_SENTRY_DSN',
  'REACT_APP_DD_APPLICATION_ID',
  'REACT_APP_DD_CLIENT_TOKEN',
  'REACT_APP_FEATURE_FLAGS_BASE_URL',
  'REACT_APP_APPLICATION_ID',
  'REACT_APP_DASHBOARD_URL',
];
let missingEnvars: string[] = [];

for (const envVar of requiredEnvars) {
  if (!(envVar in window)) {
    missingEnvars.push(envVar);
  }
}

if (missingEnvars.length > 0) {
  throw new Error(`Missing envars: ${missingEnvars}`);
}

if (config.env === 'development') {
  console.groupCollapsed('Started app with config');
  console.table(config);
  console.groupEnd();
}

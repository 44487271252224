import { Location } from '../../../../../contexts/Location/hooks/useOrgLocations';
import styled from '@emotion/styled';
import { GetUserScheduleDeskReservations as Reservation } from '../../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { SidebarIconByline } from '../../IconByLine';
import moment from 'moment';
import { BrownFontStack, Colors } from '@robinpowered/design-system';
import { useLocalizationContext } from '../../../../../contexts/Localization/LocalizationContext';
import { useTranslation } from 'react-i18next';

type Props = {
  inOfficeLocation: Location;
  reservation?: Reservation;
  hasVisit?: boolean;
  disabled?: boolean;
};

export function InOfficeLocationInfo({
  inOfficeLocation,
  reservation,
  hasVisit,
  disabled,
}: Props): JSX.Element {
  const { t } = useTranslation('weekSchedule');
  const deskName = reservation?.seat.name;
  const locationName = reservation?.seat.location.name;
  const zoneName = reservation?.seat.zone?.name;
  const levelName = reservation?.seat.level?.name;
  const deskLocationCopy = `${locationName}, ${
    zoneName && zoneName + ', '
  }${levelName}`;
  const { timeFormat } = useLocalizationContext();

  function getTimeString(reservation?: Reservation): string | undefined {
    if (!reservation) {
      return undefined;
    }

    const startTime = moment(reservation.startTime);
    if (!startTime.isValid()) {
      return undefined;
    }

    const formattedStartTime = startTime.format(timeFormat);

    if (reservation.endTime) {
      const endTime = moment(reservation.endTime);
      if (!endTime.isValid()) {
        return undefined;
      }

      const formattedEndTime = endTime.format(timeFormat);
      return `${formattedStartTime} - ${formattedEndTime}`;
    } else {
      return formattedStartTime;
    }
  }

  const locationDetailsBottom = () => {
    if ((hasVisit && reservation) || reservation) {
      // show reservation info
      return deskLocationCopy;
    }
    if (hasVisit && !reservation) {
      // show 'no desk booked' copy and visit location
      return inOfficeLocation.name;
    }
  };

  return (
    <SidebarInOfficeLocationInfoContainer>
      {getTimeString(reservation) && (
        <SidebarTimeInfo>{getTimeString(reservation)}</SidebarTimeInfo>
      )}
      {reservation ? (
        disabled ? (
          <SidebarIconByline type={'LOCATION'} copy={deskName} />
        ) : (
          <SidebarIconByline type={'LOCATION'} copy={deskName} />
        )
      ) : (
        <SidebarIconByline
          type={'LOCATION'}
          copy={t('officeDay.visitCard.no_desk') as string | undefined}
        />
      )}
      <SidebarLocationBottom>{locationDetailsBottom()}</SidebarLocationBottom>
    </SidebarInOfficeLocationInfoContainer>
  );
}

const SidebarTimeInfo = styled.div`
  padding-bottom: 8px;
  color: ${Colors.Gray80};
`;

const SidebarInOfficeLocationInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const SidebarLocationBottom = styled.div`
  margin-left: 20px;
  margin-top: 8px;
  width: calc(100% - 25px);
  font-family: ${BrownFontStack};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { Checkbox } from '@robinpowered/design-system';

import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { MapSpaceStateForLevelAmenities } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';

type Props = {
  amenities: MapSpaceStateForLevelAmenities[];
  onChange: (selectedAmenities: MapSpaceStateForLevelAmenities[]) => void;
};

export const AmenitiesFilter: FC<Props> = ({ amenities, onChange }) => {
  const { filters } = useSpaceBookingContext();

  const toggleAmenity = useCallback(
    (amenity: MapSpaceStateForLevelAmenities) => {
      const withoutAmenity = filters.amenities.filter(
        (item) => item !== amenity
      );
      if (withoutAmenity.length !== filters.amenities.length) {
        onChange(withoutAmenity);
      } else {
        onChange([...filters.amenities, amenity]);
      }
    },
    [filters.amenities, onChange]
  );

  return (
    <Container>
      {amenities.map((amenity) => (
        <CheckboxWrapper>
          <Checkbox
            key={amenity.id}
            label={amenity.name}
            checked={filters.amenities.includes(amenity)}
            onChange={() => toggleAmenity(amenity)}
          />
        </CheckboxWrapper>
      ))}
    </Container>
  );
};

const Container = styled.div`
  max-height: 220px;
  overflow: auto;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 8px;
`;

import { MapContext } from '@robinpowered/atlas-web';
import { MapboxFeature } from '@robinpowered/atlas-common';
import maplibregl from 'maplibre-gl';
import { default as turfCentroid } from '@turf/centroid';
import { polygon, LineString } from '@turf/helpers';
import { Feature, GeoJsonProperties, Polygon, Point, Position } from 'geojson';
import { WorkArea } from '../../../contexts/SpaceBooking/hooks/useWorkAreas';
// @ts-ignore implicit any
import { centroid } from 'geojson-utils';

export const WORK_AREA_BOUNDING_BOX_SOURCE =
  'selected-work-area-bounding-box-source';
export const WORK_AREA_BOUNDING_BOX_LAYER =
  'selected-work-area-bounding-box-layer';

export type Degree = number;

export type LngLat = {
  readonly lng: number;
  readonly lat: number;
};

/**
 * Represents the different between two LngLat coordinates.
 */
export type LngLatDelta = {
  readonly lngDelta: number;
  readonly latDelta: number;
};

const ShrinkingDelta = 10;

function shrinkCoordinate(coordinate: Position): [number, number] {
  return [coordinate[0] / ShrinkingDelta, coordinate[1] / ShrinkingDelta];
}

function restoreCoordinate(coordinate: Position): [number, number] {
  return [coordinate[0] * ShrinkingDelta, coordinate[1] * ShrinkingDelta];
}

type CoordinateTransformerType = {
  standardToMercator: (coord: Position) => Position;
  mercatorToStandard: (coord: Position) => Position;
};

export const CoordinateTransformer: CoordinateTransformerType = {
  /**
   * Transforms coordinates from EPSG:4326 to EPSG:3857.
   * Transforms from a "flat" world coordinates to a spherical mercator projection.
   * @see {@link https://epsg.io/4326|EPSG:4326}
   * @see {@link https://epsg.io/3857|EPSG:3857}
   *
   * @param {Array<number>} coord The coordinate to transform.
   * @return {Array<number>} The transformed coordinate.
   */
  standardToMercator: (coord: Position): Position => {
    const value: Position = [
      coord[0],
      ((2 * Math.atan(Math.exp((coord[1] * Math.PI) / 180)) - Math.PI / 2) *
        180) /
        Math.PI,
    ];

    return value;
  },

  /**
   * Transforms back from EPSG:3857 to EPSG:4326.
   * Transforms from spherical mercator coordinates world coordinates to "flat" world coordinates.
   * @see {@link https://epsg.io/4326|EPSG:4326}
   * @see {@link https://epsg.io/3857|EPSG:3857}
   *
   * @param {Array<number>} coord The coordinate to transform.
   * @return {Array<number>} The transformed coordinate.
   */
  mercatorToStandard: (coord: Position): Position => {
    const value: Position = [
      coord[0],
      (Math.log(Math.tan(((coord[1] * Math.PI) / 180 + Math.PI / 2) / 2)) /
        Math.PI) *
        180,
    ];

    return value;
  },
};

/**
 * @TODO: Figure out the underlying cause for this and find a proper fix.
 *
 * Given a collection of features, get the bounding box feature that contains them all.
 *
 * @NOTE
 * During this process, we shrink the coordinates by some delta and restore them later.
 * This is done because it _is_ possible for our coordinates to be out of bounds. This
 * happens when the features are outside of the actual floorplan. Instead of trying to be
 * clever and stop the user from doing this, we currently workaround this by reducing all
 * the coordinates into the bounds, then restoring them later after we use mapbox to compute
 * the bounds for us.
 *
 * @param {Feature<*>[]} features Some features.
 * @returns {Feature<Polygon>} The bounding box.
 */
export function getBBoxOfFeatures(
  features: Feature<Polygon, GeoJsonProperties>[],
  padding = 0
): Feature<Polygon, GeoJsonProperties> {
  const shrunkenCoordinates = features
    .map((feature) => feature.geometry.coordinates)
    .flat(2)
    .map((coords) => shrinkCoordinate(coords));

  const shrunkenBounds = shrunkenCoordinates.reduce((bounds, coord) => {
    return bounds.extend(coord);
  }, new maplibregl.LngLatBounds(shrunkenCoordinates[0], shrunkenCoordinates[0]));

  const ne = [
    shrunkenBounds.getNorthEast().lng + padding,
    shrunkenBounds.getNorthEast().lat + padding,
  ];
  const sw = [
    shrunkenBounds.getSouthWest().lng - padding,
    shrunkenBounds.getSouthWest().lat - padding,
  ];
  const nw = [sw[0], ne[1]];
  const se = [ne[0], sw[1]];

  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [[nw, ne, se, sw, nw].map(restoreCoordinate)],
    },
  };
}

export function getCentroidOfGeometry(
  geometry: Polygon | Point | LineString
): Position {
  switch (geometry.type) {
    case 'Point':
      return geometry.coordinates;
    case 'LineString':
      if (geometry.coordinates.length === 1) {
        return geometry.coordinates[0];
      }
      return [
        (geometry.coordinates[0][0] + geometry.coordinates[1][0]) / 2,
        (geometry.coordinates[0][1] + geometry.coordinates[1][1]) / 2,
      ];
    case 'Polygon':
    default:
      return centroid(geometry).coordinates;
  }
}

export function getFeaturesCentroid(
  features: Feature<Polygon | Point | LineString>[]
): LngLat {
  if (features.length === 0) {
    return {
      lng: 0,
      lat: 0,
    };
  }

  const centroids = features.map(
    ({ geometry }): Position => getCentroidOfGeometry(geometry)
  );

  if (centroids.length === 1) {
    // Calculate the centroid of a single point (trivially the centroid).
    return {
      lng: centroids[0][0],
      lat: centroids[0][1],
    };
  } else if (centroids.length === 2) {
    // Calculate the centroid of a line (midpoint of the two coordinates).
    const c1 = centroids[0];
    const c2 = centroids[1];
    return {
      lng: (c1[0] + c2[0]) / 2,
      lat: (c1[1] + c2[1]) / 2,
    };
  } else {
    // Calculate the centroid of a polygon.
    const itemCoordinates = [...centroids, centroids[0]];

    const itemsPolygon = polygon([itemCoordinates]);

    // @TODO nickzuber
    // There's a bug with `geojson-utils` centroid where it seems to calculate extremely
    // incorrect centroids from certain polygons.
    // While @turf/centroid is slightly off, it doesn't have this bug so its still useable.
    const rotationCenterPoint = turfCentroid(itemsPolygon);
    return {
      lng: rotationCenterPoint.geometry.coordinates[0],
      lat: rotationCenterPoint.geometry.coordinates[1],
    };
  }
}

export const getBoundsOfFeatures = (
  features: Feature<Polygon, GeoJsonProperties>[]
): maplibregl.LngLatBoundsLike => {
  const featureBBox = getBBoxOfFeatures(features);
  const featureBBoxCoords = featureBBox.geometry.coordinates[0];
  const se = featureBBoxCoords[2];
  const nw = featureBBoxCoords[0];

  return [se, nw] as maplibregl.LngLatBoundsLike;
};

export const addWorkAreaSources = (map: MapContext): void => {
  const sourceDoesNotExist = !map.api.getSource(WORK_AREA_BOUNDING_BOX_SOURCE);
  if (sourceDoesNotExist) {
    map.api.addSource(WORK_AREA_BOUNDING_BOX_SOURCE, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    });
  }
};

export const addWorkAreaLayers = (map: MapContext): void => {
  map.api.addLayer({
    id: WORK_AREA_BOUNDING_BOX_LAYER,
    type: 'line',
    source: WORK_AREA_BOUNDING_BOX_SOURCE,
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#116BCE',
      'line-width': 2,
      'line-opacity': 1,
    },
  });
};

export const getWorkAreaBoundingBox = (
  map: MapContext,
  workArea: WorkArea
): Feature<Polygon, GeoJsonProperties> => {
  const features = map.api.querySourceFeatures('seats') as Feature<
    Polygon,
    GeoJsonProperties
  >[];
  const featuresInWorkarea = features.filter((feature) =>
    workArea.desks.find((desk) => desk.id === feature?.properties?.ownerId)
  );

  return getBBoxOfFeatures(featuresInWorkarea, 0.1);
};

export type WorkAreasFeaturesBuckets = Record<string, MapboxFeature[]>;
export type WorkAreasCentroidsBuckets = Record<string, Position>;
export type WorkAreasBBoxBuckets = Record<
  string,
  Feature<Polygon, GeoJsonProperties>
>;

export const createWorkAreasFeaturesBuckets = (
  workAreas: WorkArea[],
  seatFeatures: MapboxFeature[]
): WorkAreasFeaturesBuckets => {
  if (!workAreas || !seatFeatures) {
    return {};
  }
  return workAreas.reduce<WorkAreasFeaturesBuckets>(
    (memo, workArea): WorkAreasFeaturesBuckets => {
      seatFeatures.forEach((feature) => {
        const isFeatureInWorkArea = !!workArea.desks.find(
          (desk) => Number(desk.id) === feature.properties?.ownerId
        );

        if (isFeatureInWorkArea) {
          const workAreaBucket = memo[workArea.id];

          if (workAreaBucket) {
            memo[workArea.id] = [...memo[workArea.id], feature];
          } else {
            memo[workArea.id] = [feature];
          }
        }
      });

      return memo;
    },
    {}
  );
};

export const createWorkAreasCentroidsBuckets = (
  workAreasToFeatures: WorkAreasFeaturesBuckets
): WorkAreasCentroidsBuckets => {
  return Object.keys(workAreasToFeatures).reduce<Record<string, Position>>(
    (memo, workAreaId) => {
      const features = workAreasToFeatures[workAreaId];

      if (!features) {
        memo[workAreaId] = [0, 0];
        return memo;
      }

      const centroid = getFeaturesCentroid(
        features as Feature<Polygon, GeoJsonProperties>[]
      );

      const coords = CoordinateTransformer.standardToMercator([
        centroid.lng,
        centroid.lat,
      ]);

      memo[workAreaId] = coords;
      return memo;
    },
    {}
  );
};

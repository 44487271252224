"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Convert the object representation of a LngLatLike item to the array representation.
 *
 * @param {LngLatObject} coordinate An object representation of a LngLatLike item.
 * @returns {LngLatTuple} The array representation of a LngLatLike item.
 */
function arrayOfLngLatLike(coordinate) {
    return [coordinate.lon, coordinate.lat];
}
/**
 * Compare the coordinate values of two LngLatLike items to see if those values are equal.
 *
 * @param {LngLatLike} a The LngLatLike item to compare.
 * @param {LngLatLike} b The LngLatLike item to compare.
 * @returns {boolean} Whether or not the coordinate values are equal.
 */
function compareLngLatLike(a, b) {
    var _a = Array.isArray(a) ? a : arrayOfLngLatLike(a), aLng = _a[0], aLat = _a[1];
    var _b = Array.isArray(b) ? b : arrayOfLngLatLike(b), bLng = _b[0], bLat = _b[1];
    return aLng === bLng && aLat === bLat;
}
exports.compareLngLatLike = compareLngLatLike;
/**
 * Compare the coordinate values of two LngLatBoundsLike items to see if those values are equal.
 *
 * @param {LngLatBoundsLike} a The LngLatBoundsLike item to compare.
 * @param {LngLatBoundsLike} b The LngLatBoundsLike item to compare.
 * @returns {boolean} Whether or not the coordinate values are equal.
 */
function compareLngLatBoundsLike(a, b) {
    return compareLngLatLike(a[0], b[0]) && compareLngLatLike(a[1], b[1]);
}
exports.compareLngLatBoundsLike = compareLngLatBoundsLike;

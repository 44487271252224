import { FC, useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Colors, Heading } from '@robinpowered/design-system';
import MapIcon from '@robinpowered/icons/v2/FloorplanMapAlt';
import ListIcon from '@robinpowered/icons/v2/ListBullets';
import { useTranslation } from 'react-i18next';
import { SpaceBookingSidebar } from './SpaceBookingSidebar';
import { useUserContext } from '../../contexts/User/UserContext';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { SpaceMapAvailabilityStates } from '../../contexts/SpaceBooking/hooks/useSpaceStateQuery';
import { SpaceOfficeMap } from './Map/SpaceOfficeMap';
import { ChevronStartIcon, Button } from '@fluentui/react-northstar';
import { useMedia } from 'react-use';

export const SpaceBooking: FC = () => {
  const {
    selectedSpaceId,
    cannotBookAnythingElse,
    spaceStateData,
    mapIsAvailable,
    openSpaceDetails,
    isSpaceBookingMapOpen,
    event,
    closeModal: onClose,
    availabilityStates,
    selectedLevelId,
  } = useSpaceBookingContext();
  const { currentUser } = useUserContext();
  const { t } = useTranslation('spaceBooking');
  const isMobileScreen = useMedia('(max-width: 850px)');
  const [isLeftPanelOpenOnMobile, setIsLeftPanelOpenOnMobile] =
    useState<boolean>(false);

  const isLeftPanelVisible = useMemo(() => {
    return isMobileScreen ? isLeftPanelOpenOnMobile : true;
  }, [isLeftPanelOpenOnMobile, isMobileScreen]);

  const toggleLeftPanelVisibilityOnMobile = useCallback(() => {
    setIsLeftPanelOpenOnMobile((isOpen) => !isOpen);
  }, []);

  const onSelectSpace = (spaceId: string) => {
    openSpaceDetails(spaceId);
    setIsLeftPanelOpenOnMobile(true);
  };

  // we want our map to only highlight available (green), everything else should be reduced (gray)
  const greenOnly: SpaceMapAvailabilityStates = useMemo(
    () => ({
      ...availabilityStates,
      reducedIds: Array.from(
        new Set(
          [
            availabilityStates.reducedIds,
            availabilityStates.restrictedIds,
            availabilityStates.bookedIds,
            availabilityStates.inUseIds,
          ].flat()
        )
      ),
      // "reduced" takes precedence over most (all?) other states, no need to clear them
    }),
    [availabilityStates]
  );

  // check state before loading, can we do this better?
  if (!isSpaceBookingMapOpen || !event) {
    return <>Invalid state to load space booking modal</>;
  }

  return (
    <>
      {/* Overlay style is redefined here as design system library isn't up-to-date with the current design */}

      <Container>
        <Header>
          <HeaderLeft>
            <Button
              icon={<ChevronStartIcon />}
              onClick={onClose}
              iconOnly
              text
            />
            <Heading.Large>{t('booking_form.title')}</Heading.Large>
          </HeaderLeft>
          <HeaderRight>
            <Button
              content={
                isLeftPanelVisible ? (
                  <MapIcon color={Colors.Gray100} size={18} />
                ) : (
                  <ListIcon color={Colors.Gray100} size={18} />
                )
              }
              onClick={toggleLeftPanelVisibilityOnMobile}
              iconOnly
            />
          </HeaderRight>
        </Header>
        <Body>
          {isLeftPanelVisible && (
            <BodyLeft style={{ position: 'relative' }}>
              <SpaceBookingSidebar
                event={event}
                spaces={spaceStateData?.getLevelById?.spaces}
                selectedLevelId={selectedLevelId}
              />
            </BodyLeft>
          )}
          <BodyRight>
            {selectedLevelId && currentUser?.id && (
              <SpaceOfficeMap
                levelId={selectedLevelId}
                userId={currentUser.id}
                spaceAvailabilityStates={greenOnly}
                onSelectSpace={
                  !cannotBookAnythingElse ? onSelectSpace : undefined
                }
                selectedSpaceId={selectedSpaceId}
                showMap={mapIsAvailable}
                visible
              />
            )}
          </BodyRight>
        </Body>
      </Container>
    </>
  );
};

const Body = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  border-bottom: 1px solid ${Colors.Gray10};
  padding: 0.625rem 0.25rem;
  background-color: white;
  justify-content: space-between;
`;

const BodyLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100%;
  @media (max-width: 850px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

const BodyRight = styled.div`
  display: flex;
  height: 100%;
  flex-grow: 1;
`;

// right panel displays map button for mobile only
const HeaderRight = styled.div`
  display: flex;
  flex-shrink: 1;
  @media (min-width: 850px) {
    display: none;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

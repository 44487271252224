import React from 'react';
import PropTypes from 'prop-types';
import MapEventType from '../../constants/mapEventType';
import AtlasSeatLabels from '../AtlasSeatLabels';
import AtlasZoneLabels from '../AtlasZoneLabels';
import EventReactor from '../EventReactor';
import {isObjectEmpty, shallowEqualObjects} from '@robinpowered/atlas-common';

/**
 * This component manages the seat labels and maintains own state to avoid unnecessary updates
 * in the main `<AtlasSeats />` component, which can get quite expensive when there's a decent amount (>500)
 * desks involved.
 */
export default class SeatLabels extends React.Component {
  static propTypes = {
    /**
     * Mapping of seat IDs to seat labels.
     */
    seatsToLabels: PropTypes.object,
    map: PropTypes.object.isRequired,
    zoneLabelOverrides: PropTypes.object
  };

  state = {
    showSeatLabels: false,
    hasSeatLabels: false
  };

  componentDidMount() {
    this.toggleSeatLabels();
    this.updateHasSeatLabels();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.showSeatLabels !== nextState.showSeatLabels ||
      this.state.hasSeatLabels !== nextState.hasSeatLabels ||
      !shallowEqualObjects(this.props.seatsToLabels, nextProps.seatsToLabels) ||
      !shallowEqualObjects(
        this.props.zoneLabelOverrides,
        nextProps.zoneLabelOverrides
      )
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !shallowEqualObjects(this.props.seatsToLabels, prevProps.seatsToLabels)
    ) {
      this.updateHasSeatLabels();
    }

    // Turn off the seat labels if we don't have any to show and we're already try to show them.
    if (
      !this.state.hasSeatLabels &&
      prevState.hasSeatLabels &&
      this.state.showSeatLabels
    ) {
      this.setState({showSeatLabels: false});
    }
  }

  toggleSeatLabels = () => {
    const hasSeatLabels =
      this.props.seatsToLabels && !isObjectEmpty(this.props.seatsToLabels);
    const zoomLevelForSeats = this.props.map.componentInstance.getMetadata(
      'seat-label-zoom'
    );

    if (!hasSeatLabels || !zoomLevelForSeats) {
      return;
    }

    const zoom = this.props.map.api.getZoom();

    if (zoom >= zoomLevelForSeats && !this.state.showSeatLabels) {
      // Show the seat labels and hide the zone labels.
      this.setState({showSeatLabels: true});
    } else if (zoom < zoomLevelForSeats && this.state.showSeatLabels) {
      // Show the seat labels and hide the zone labels.
      this.setState({showSeatLabels: false});
    }
  };

  /**
   * Updates `hasSeatLabels` to avoid a potentially expensive computation during the render phase.
   */
  updateHasSeatLabels() {
    const hasSeatLabels =
      this.props.seatsToLabels && !isObjectEmpty(this.props.seatsToLabels);

    if (this.state.hasSeatLabels !== hasSeatLabels) {
      this.setState({hasSeatLabels});
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Adds the logic for toggling between the zone labels and the seat labels.
         * If there are no seat labels, we don't want to try to toggle off zone labels. */}
        {this.state.hasSeatLabels && (
          <EventReactor
            event={MapEventType.ZOOM}
            callback={this.toggleSeatLabels}
          />
        )}

        {/* Adds the labels on the seats. */}
        <AtlasSeatLabels
          showLabels={this.state.showSeatLabels}
          seatsToLabels={this.props.seatsToLabels}
        />

        {/* Controls the zone labels. */}
        <AtlasZoneLabels
          showLabels={!this.state.showSeatLabels}
          overrides={this.props.zoneLabelOverrides}
        />
      </React.Fragment>
    );
  }
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import LayerFeatures from '../LayerFeatures';
import FeatureState from '../FeatureState';
import {withMap} from '../Map';
import {isObjectEmpty, shallowEqualObjects} from '@robinpowered/atlas-common';

const SEAT_LABEL_SOURCE_ID = 'seat_labels';
const SEAT_LABEL_LAYER_ID = 'seat-labels';

export class AtlasSeatLabels extends Component {
  static propTypes = {
    map: PropTypes.object.isRequired,
    /**
     * Mapping of seat IDs to label string.
     */
    seatsToLabels: PropTypes.objectOf(PropTypes.string),
    /**
     * If true, shows seat labels on the Map.
     */
    showLabels: PropTypes.bool.isRequired
  };

  static defaultProps = {
    seatsToLabels: {},
    showLabels: false
  };

  componentDidMount() {
    if (this.props.seatsToLabels && !isObjectEmpty(this.props.seatsToLabels)) {
      this.syncSourceData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !shallowEqualObjects(prevProps.seatsToLabels, this.props.seatsToLabels)
    ) {
      this.syncSourceData();
    }
  }

  syncSourceData = () => {
    const labelSource = this.props.map.api.getSource(SEAT_LABEL_SOURCE_ID);

    if (!labelSource) {
      // For backwards compatibility, if the style spec doesn't contain
      // the sources for seat labels we just want to do nothing.
      return;
    }

    const rules = labelSource._data.features.map((feature) => {
      const seatId = feature.properties.ownerId;
      const label = this.props.seatsToLabels[seatId];

      return [['==', ['get', 'ownerId'], seatId], label || ''];
    });

    this.props.map.api.setLayoutProperty(SEAT_LABEL_LAYER_ID, 'text-field', [
      'case',
      ...rules.reduce((acc, rule) => {
        acc.push(...rule);
        return acc;
      }, []),
      ''
    ]);
  };

  render() {
    return (
      <LayerFeatures
        key={SEAT_LABEL_LAYER_ID}
        specifier={[SEAT_LABEL_LAYER_ID]}
      >
        {({features}) =>
          features.map((feature) => (
            <FeatureState
              key={feature.id}
              feature={feature}
              state={{
                'show-label': this.props.showLabels
              }}
            />
          ))
        }
      </LayerFeatures>
    );
  }
}

export default withMap(AtlasSeatLabels);

import styled from '@emotion/styled';
import {
  Body07,
  Button,
  Colors,
  Column,
  Heading05,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { LayeredPreview } from './LayeredPreview';
import { teamsTheme } from '@fluentui/react-northstar';
import { useCallback } from 'react';
import { config } from '../../../config';

export const ConnectCalendarCallToAction = (): JSX.Element => {
  const { t } = useTranslation('sidebar');

  const onClickConnect = useCallback((): void => {
    window
      .open(`${config.dashboardUrl}/account/integrations`, '_blank')
      ?.focus();
  }, []);

  return (
    <Column width="100%" alignItems="center" mt="3vh">
      <LayeredPreview />
      <Heading05>{t('events.integrate_calendar')}</Heading05>
      <Byline
        mt="8px"
        width="80%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {t('events.better_plan')}
      </Byline>
      <ConnectButton mt="20px" variant="primary" onClick={onClickConnect}>
        {t('events.connect')}
      </ConnectButton>
    </Column>
  );
};

const Byline = styled(Body07)`
  text-align: center;
`;

const ConnectButton = styled(Button)`
  color: ${Colors.White0};
  background-color: ${teamsTheme.siteVariables.colorScheme.brand.background};
  border: 4px;

  &:hover {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
    color: ${Colors.White0};
  }

  &:focus,
  &:focus-visible,
  &:active {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
    color: ${Colors.White0};
  }
`;

import { useCallback, useEffect } from 'react';

export const KeyboardKeys = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  TAB: 'Tab',
};

// Accessibility hook to allow keyboard users to close out of modals
// and sidebars with the escape key
export const useEscape = (
  onkeydown: () => void,
  ref?: React.RefObject<HTMLDivElement>
) => {
  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyboardKeys.ESCAPE) {
        onkeydown();
      }
      event.stopPropagation();
    },
    [onkeydown]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress);
    };
  }, [handleEscapePress, ref]);
};

export const useEscapeForRef = (
  ref: React.RefObject<HTMLDivElement>,
  onkeydown: () => void
) => {
  const handleEscapePress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyboardKeys.ESCAPE) {
        onkeydown();
      }
      event.stopPropagation();
    },
    [onkeydown]
  );

  useEffect(() => {
    const refObjectCurrent = ref.current;
    refObjectCurrent &&
      refObjectCurrent.addEventListener('keydown', handleEscapePress);

    return () => {
      refObjectCurrent &&
        refObjectCurrent.removeEventListener('keydown', handleEscapePress);
    };
  }, [ref, handleEscapePress]);
};

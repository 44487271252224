import { gql } from '@apollo/client';

export const scheduleEmployeeVisitMutation = gql`
  mutation ScheduleEmployeeVisitMutation(
    $employeeId: ID!
    $locationId: ID!
    $start: ISODateTime!
    $end: ISODateTime!
  ) {
    scheduleEmployeeVisitAtLocation(
      employeeId: $employeeId
      locationId: $locationId
      startTime: $start
      endTime: $end
    )
  }
`;

import {
  Button,
  ChevronStartIcon,
  Divider,
  Flex,
  Skeleton,
  Text,
} from '@fluentui/react-northstar';
import { DisabledOutline, ClockSolid } from '@robinpowered/icons';

//using deprecated icons to match dashboard
import DeskChairIcon from '@robinpowered/icons/v2/DeskChair';
import AmenityIcon from '@robinpowered/icons/v2/Amenity';
import CalendarIcon from '@robinpowered/icons/v2/CalendarBlank';

import { Moment } from 'moment';
import { translateUnbookableReason } from '../../../utilities/DeskBooking';
import { VisibilityPicker } from '../VisibilityPicker';
import { DetailsRow } from './DetailsRow';
import { Colors } from '@robinpowered/design-system';
import type { Dispatch, SetStateAction } from 'react';

import { DeskReservee } from './DeskReservee';
import { DeskType } from './utility';
import {
  DeskReservationVisibilityType,
  DeskDetails,
  DeskReservation,
} from '../../../types';

export type DeskInfoProps = {
  visibility: DeskReservationVisibilityType;
  isReserving: boolean;
  deskVisibilityPolicyEnabled: boolean;
  loading: boolean;
  deskType: DeskType;
  start: Moment;
  end: Moment;
  deskDetails?: DeskDetails;
  reservation?: DeskReservation;
  amenityList?: string;
  unbookableReasonsNoAccess?: boolean;
  isAvailable?: boolean;
  isUnbookable?: boolean;
  setVisibility: Dispatch<SetStateAction<DeskReservationVisibilityType>>;
  handleReserveDesk: () => void;
  setSelectedDeskId: (deskId: string | undefined) => void;
};

export const DeskInfo = ({
  amenityList,
  start,
  end,
  loading,
  visibility,
  deskType,
  setVisibility,
  handleReserveDesk,
  setSelectedDeskId,
  isReserving,
  deskVisibilityPolicyEnabled,
  unbookableReasonsNoAccess,
  isUnbookable,
  isAvailable,
  deskDetails,
  reservation,
}: DeskInfoProps) => {
  const createBookableState = () => {
    // Assigned desks cannot be booked (displays as information)
    if (deskDetails?.type === 'ASSIGNED') {
      return <Text>This desk must be assigned by an administrator</Text>;
    }
    // Desks that are not bookable because the user lacks access (displays as information)
    if (unbookableReasonsNoAccess && !isAvailable && deskDetails) {
      return (
        <Text>
          {
            translateUnbookableReason(
              deskDetails.availability.unbookableReasons,
              deskDetails.level?.name
            )[0]
          }
        </Text>
      );
    }

    // Desks that are not bookable for any other reason (displays as an error)
    if (isUnbookable && deskDetails?.availability) {
      return (
        <Flex vAlign="center" gap="gap.small">
          <DisabledOutline size={24} color={'#8E192E'} />
          <Text style={{ color: '#8E192E' }}>
            {
              translateUnbookableReason(
                deskDetails.availability.unbookableReasons,
                deskDetails.level?.name
              )[0]
            }
          </Text>
        </Flex>
      );
    }

    // Bookable desks display visibility and reserve actions
    return (
      <Flex column gap="gap.small" style={{ alignItems: 'flex-start' }}>
        {deskVisibilityPolicyEnabled && (
          <VisibilityPicker
            visibility={visibility}
            setVisibility={setVisibility}
            isDisabled={isReserving}
          />
        )}
        <Button
          content="Reserve"
          disabled={isReserving}
          onClick={() => {
            handleReserveDesk();
          }}
          primary
        />
      </Flex>
    );
  };

  return (
    <Flex column style={{ width: '100%', padding: '12px' }} gap="gap.small">
      {/* Title with back nav */}
      <Flex vAlign="center">
        <Button
          icon={<ChevronStartIcon />}
          onClick={() => setSelectedDeskId(undefined)}
          iconOnly
          text
        />
        <Text size="large" weight="bold">
          Reserve this desk
        </Text>
      </Flex>

      {/* Skeleton loader */}
      {loading && (
        <Flex vAlign="start" hAlign="center" style={{ width: '100%' }}>
          <Skeleton animation="pulse">
            <Flex column gap="gap.small">
              <Skeleton.Line />
              <Skeleton.Line />
              <Skeleton.Line />
            </Flex>
          </Skeleton>
        </Flex>
      )}

      {!loading && (
        // Details about the desk (name, pod, reservee) and potential reservation (date, time, etc)
        <Flex column gap="gap.small">
          {isAvailable || !reservation ? (
            <DetailsRow
              Icon={<DeskChairIcon size={24} color={Colors.Gray70} />}
              title={deskDetails?.name}
              body={`${deskDetails?.zone?.name}, ${deskDetails?.level?.name}`}
            />
          ) : (
            <DeskReservee
              reservation={reservation}
              deskName={deskDetails?.name}
              levelName={deskDetails?.level?.name}
              deskType={deskType}
            />
          )}

          <DetailsRow
            Icon={<AmenityIcon size={24} color={Colors.Gray70} />}
            body={amenityList || 'None'}
          />
          <DetailsRow
            Icon={<CalendarIcon size={24} color={Colors.Gray70} />}
            body={start.format('ddd, MMMM DD')}
          />
          <DetailsRow
            Icon={<ClockSolid size={24} color={Colors.Gray70} />}
            body={`${start.format('LT')} - ${end.format('LT')}`}
          />

          <Divider />

          {/* Info/error messages and actions the user can take */}
          {!reservation && createBookableState()}
        </Flex>
      )}
    </Flex>
  );
};

import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withMap} from '../Map';
import FeatureEvents from '../FeatureEvents';
import LayerFeatures from '../LayerFeatures';

const SEAT_MARKER_LAYER_ID = 'seat_markers';

export function createMarkerRules(map, seatsToMarkers) {
  const markerSource = map.api.getSource(SEAT_MARKER_LAYER_ID);

  if (!markerSource) {
    return {iconImage: '', textField: ''};
  }

  const rules = markerSource._data.features.map((feature) => {
    const seatId = feature.properties.ownerId;
    const label = seatsToMarkers[seatId];
    const formattedText = ['format'];
    if (label && label.title) {
      formattedText.push(label.title, {});
      if (label.subtitle) {
        formattedText.push('\n', {}, label.subtitle, {
          'font-scale': 0.8
        });
      }
    } else {
      formattedText.push('', {});
    }

    return {
      iconImage: [
        ['==', ['get', 'ownerId'], seatId],
        typeof label !== 'undefined' ? 'seat_marker' : ''
      ],
      textField: [['==', ['get', 'ownerId'], seatId], formattedText]
    };
  });

  const ruleSet = rules.reduce(
    (acc, rule) => {
      acc.iconImage.push(...rule.iconImage);
      acc.textField.push(...rule.textField);
      return acc;
    },
    {iconImage: [], textField: []}
  );

  return ruleSet;
}

export function AtlasSeatMarkers({map, seatsToMarkers}) {
  useEffect(() => {
    const {iconImage, textField} = createMarkerRules(map, seatsToMarkers);

    map.api.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'icon-image', [
      'case',
      ...iconImage,
      ''
    ]);

    map.api.setLayoutProperty(SEAT_MARKER_LAYER_ID, 'text-field', [
      'case',
      ...textField,
      ''
    ]);
  }, [seatsToMarkers]);

  return (
    <LayerFeatures specifier={[SEAT_MARKER_LAYER_ID]}>
      {({features}) => {
        return features.map((feature) => {
          return <FeatureEvents key={feature.id} feature={feature} />;
        });
      }}
    </LayerFeatures>
  );
}

AtlasSeatMarkers.propTypes = {
  map: PropTypes.object.isRequired,
  seatsToMarkers: PropTypes.object.isRequired
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.seatsToMarkers === nextProps.seatsToMarkers;
}

export default memo(compose(withMap)(AtlasSeatMarkers), arePropsEqual);

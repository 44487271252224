import { ApolloError, ApolloQueryResult, gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { useMemo } from 'react';
import {
  GetLocationsByOrgIdLocations,
  GetLocationsByOrgIdOfficeAccess,
  GetLocationsByOrgIdQuery,
  GetLocationsByOrgIdTimeFrames,
  GetLocationsByOrgIdVariables,
  GetLocationsByOrgIdWorkingHours,
} from './__generated__/useOrgLocations.generated';
import { Exact } from '../../../__generated__/types';

export type Location = OmitRecursively<
  GetLocationsByOrgIdLocations,
  '__typename'
>;
export type WorkingHours = GetLocationsByOrgIdWorkingHours;
export type TimeFrames = GetLocationsByOrgIdTimeFrames;
export type OfficeAccess = GetLocationsByOrgIdOfficeAccess;

const GET_LOCATIONS = gql`
  query getLocationsByOrgId($id: ID!, $userId: ID!, $date: Date) {
    getOrganizationById(id: $id) {
      id
      name
      locations {
        id
        name
        timezone
        totalSpaces
        latitude
        longitude
        campus {
          id
          name
        }
        workingHours {
          day
          timeFrames {
            start
            end
          }
        }
        customWorkingHours(startFromDateTime: $date) {
          id
          date
          timeFrames {
            start
            end
          }
        }
        levels {
          id
          name
          mapIsAvailable
          zones {
            id
            name
          }
        }
        officeAccess(userId: $userId) {
          id
          schedule
        }
      }
    }
  }
`;

type OrgLocations = {
  loading: boolean;
  called: boolean;
  error: ApolloError | undefined;
  locations: Location[];
  sortedLocations: Location[];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            id: string;
            userId: string;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            date?: any;
          }>
        >
      | undefined
  ) => Promise<ApolloQueryResult<GetLocationsByOrgIdQuery>>;
};

export const useOrgLocations = (
  currentUserId: string | undefined,
  tenantId: string | undefined,
  isOrgLoading: boolean
): OrgLocations => {
  const now = moment().format('YYYY-MM-DD HH:mm');

  const { loading, data, error, called, refetch } = useQuery<
    GetLocationsByOrgIdQuery,
    GetLocationsByOrgIdVariables
  >(GET_LOCATIONS, {
    skip: isOrgLoading || !tenantId || !currentUserId,
    errorPolicy: 'all',
    // Need to cast the id to string since TS not
    // smart enough to know it will skip the query if undefined
    variables: {
      id: tenantId as string,
      userId: currentUserId as string,
      date: now,
    },
  });

  const locations = useMemo(
    () => data?.getOrganizationById?.locations ?? [],
    [data]
  );

  const sortedLocations = useMemo(() => {
    return locations.slice().sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    });
  }, [locations]);

  return {
    loading,
    locations,
    sortedLocations,
    called,
    error,
    refetch,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import CursorStyle from '../CursorStyle';
import {withMap} from '../Map';
import {
  withLayers,
  shallowEqualArrays,
  shallowEqualObjects
} from '@robinpowered/atlas-common';
import LayerEventType from '../../constants/layerEventType';
import LayerEvents from '../LayerEvents';
import {useLayerFeatureStates} from '../../hooks';
import getEventToCallbackFromProps from '../../utils/getEventToCallbackFromProps';
import SeatLabels from './SeatLabels';
import SeatAvatars from './SeatAvatars';

// Single empty array reference to avoid default props from
// updating their value refs whenever AtlasSeats re-renders.
const emptyArray = [];
const emptyObject = {};

export function AtlasSeats({
  map,
  layers,
  seatsToLabels = emptyObject,
  seatsToAvatars = emptyObject,
  availableIds, // Intentionally has no default.
  busyIds = emptyArray,
  selectedIds = emptyArray,
  restrictedIds = emptyArray,
  reducedIds = emptyArray,
  disabledIds = emptyArray,
  forbiddenIds = emptyArray,
  dimmedIds = emptyArray,
  reservationTypes = emptyObject,
  zoneLabelOverrides = emptyObject,
  ...restOfProps
}) {
  const [hovered, setHovered] = React.useState(false);

  const eventsToCallbacks = React.useMemo(() => {
    const eventsToCallbacks = getEventToCallbackFromProps(restOfProps);
    return {
      ...eventsToCallbacks,
      [LayerEventType.MOUSEENTER]: (...args) => {
        setHovered(true);
        if (eventsToCallbacks[LayerEventType.MOUSEENTER]) {
          return eventsToCallbacks[LayerEventType.MOUSEENTER](...args);
        }
      },
      [LayerEventType.MOUSELEAVE]: (...args) => {
        setHovered(false);
        if (eventsToCallbacks[LayerEventType.MOUSELEAVE]) {
          return eventsToCallbacks[LayerEventType.MOUSELEAVE](...args);
        }
      }
    };
  }, Object.values(restOfProps));

  function getStateFromFeature(feature) {
    const id = feature.properties.ownerId;
    const states = {
      busy: busyIds.includes(id),
      selected: selectedIds.includes(id),
      restricted: restrictedIds.includes(id),
      reduced: reducedIds.includes(id),
      available: Array.isArray(availableIds) ? availableIds.includes(id) : true,
      disabled: disabledIds.includes(id),
      forbidden: forbiddenIds.includes(id),
      dimmed: dimmedIds.includes(id),
      reservationTypes: reservationTypes[id]
    };
    return states;
  }

  const reloadFeatureStates = useLayerFeatureStates({
    map,
    layers,
    getStateFromFeature
  });

  React.useEffect(() => reloadFeatureStates(), [
    availableIds,
    busyIds,
    selectedIds,
    restrictedIds,
    reducedIds,
    disabledIds,
    forbiddenIds,
    reservationTypes
  ]);

  const layersWithoutLabels = React.useMemo(
    () => excludeSeatLabelsLayer(layers),
    [layers]
  );

  return (
    <React.Fragment>
      <SeatAvatars map={map} seatsToAvatars={seatsToAvatars} />
      <SeatLabels
        map={map}
        seatsToLabels={seatsToLabels}
        zoneLabelOverrides={zoneLabelOverrides}
      />
      <LayerEvents
        map={map}
        layers={layersWithoutLabels}
        eventsToCallbacks={eventsToCallbacks}
      />
      {hovered && eventsToCallbacks[LayerEventType.CLICK] && (
        <CursorStyle cursor="pointer" />
      )}
    </React.Fragment>
  );
}

/** @TODO
 * We don't want to attach events to the seat labels, so we exlude that layer here.
 * Knowing this layer association should be supported by the style spec.
 */
function excludeSeatLabelsLayer(layers) {
  return layers.filter((layer) => layer !== 'seat-labels');
}

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.onClick === nextProps.onClick &&
    prevProps.onMouseover === nextProps.onMouseover &&
    prevProps.onMousedown === nextProps.onMousedown &&
    prevProps.onMouseup === nextProps.onMouseup &&
    prevProps.onMousemove === nextProps.onMousemove &&
    prevProps.onDblclick === nextProps.onDblclick &&
    prevProps.onMouseenter === nextProps.onMouseenter &&
    prevProps.onMouseleave === nextProps.onMouseleave &&
    prevProps.onMouseout === nextProps.onMouseout &&
    prevProps.onContextmenu === nextProps.onContextmenu &&
    prevProps.onWheel === nextProps.onWheel &&
    shallowEqualArrays(prevProps.busyIds, nextProps.busyIds) &&
    shallowEqualArrays(prevProps.selectedIds, nextProps.selectedIds) &&
    shallowEqualArrays(prevProps.restrictedIds, nextProps.restrictedIds) &&
    shallowEqualArrays(prevProps.reducedIds, nextProps.reducedIds) &&
    shallowEqualArrays(prevProps.availableIds, nextProps.availableIds) &&
    shallowEqualArrays(prevProps.disabledIds, nextProps.disabledIds) &&
    shallowEqualArrays(prevProps.forbiddenIds, nextProps.forbiddenIds) &&
    shallowEqualArrays(prevProps.dimmedIds, nextProps.dimmedIds) &&
    shallowEqualObjects(prevProps.seatsToLabels, nextProps.seatsToLabels) &&
    shallowEqualObjects(prevProps.seatsToAvatars, nextProps.seatsToAvatars) &&
    shallowEqualObjects(
      prevProps.reservationTypes,
      nextProps.reservationTypes
    ) &&
    shallowEqualObjects(
      prevProps.zoneLabelOverrides,
      nextProps.zoneLabelOverrides
    )
  );
}

AtlasSeats.propTypes = {
  /**
   * Seat IDs which should display as busy.
   */
  busyIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as selected.
   */
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as restricted.
   */
  restrictedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display in reduced state (e.g. filtered out).
   */
  reducedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as available.
   */
  availableIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as disabled.
   */
  disabledIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as forbidden.
   */
  forbiddenIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Seat IDs which should display as dimmed.
   */
  dimmedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Object indexed by seat ID to array of reservation types.
   */
  reservationTypes: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  /**
   * Mapping of seat IDs to seat labels.
   */
  seatsToLabels: PropTypes.object,
  /**
   * Mapping of seat IDs to seat avatars.
   */
  seatsToAvatars: PropTypes.object,
  /**
   * Callback called when a seat is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Callback called when a seat is hovered.
   */
  onMouseover: PropTypes.func,
  /**
   * Callback for mousedown event.
   */
  onMousedown: PropTypes.func,
  /**
   * Callback for mouseup event.
   */
  onMouseup: PropTypes.func,
  /**
   * Callback for mousemove event.
   */
  onMousemove: PropTypes.func,
  /**
   * Callback for dblclick event.
   */
  onDblclick: PropTypes.func,
  /**
   * Callback for mouseenter event.
   */
  onMouseenter: PropTypes.func,
  /**
   * Callback for mouseleave event.
   */
  onMouseleave: PropTypes.func,
  /**
   * Callback for mouseout event.
   */
  onMouseout: PropTypes.func,
  /**
   * Callback for contextmenu event.
   */
  onContextmenu: PropTypes.func,
  /**
   * Callback for wheel event.
   */
  onWheel: PropTypes.func,
  map: PropTypes.object.isRequired,
  layers: PropTypes.arrayOf(PropTypes.string).isRequired,
  zoneLabelOverrides: PropTypes.object
};

const connect = compose(withMap, withLayers('seats'));
export default React.memo(connect(AtlasSeats), arePropsEqual);

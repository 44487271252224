import React, {Component} from 'react';
import PropTypes from 'prop-types';
import xhr from 'xhr';
import {
  createRequestTransformer,
  ensureUniqueFeatures,
  getDisplayName,
  getMetadata,
  PitchPropType,
  BearingPropType,
  MapDebugPropType,
  shallowEqualArrays,
  shallowEqualObjects
} from '@robinpowered/atlas-common';
import warning from 'warning';
import maplibregl from 'maplibre-gl';
import MapEventType from '../../constants/mapEventType';
import {MapKeyProvider} from '../MapKeyContext';
import evaluateExpressionWithValue from '../../utils/evaluateExpressionWithValue';

if (
  !process ||
  !process.env ||
  ![true, 'true'].includes(process.env.ATLAS_UI_DISABLE_DEFAULT_STYLES)
) {
  // Load Mapbox CSS by default, unless the bundling application prevents it.
  // This is done for compatibility and to make sure that we don't ever need to load this twice (i.e. if
  // loaded from CDN).
  require('maplibre-gl/dist/maplibre-gl.css');
}

// Support bundling this for static sites (such as gatsby).
if (typeof window !== 'undefined' && window.document) {
  // Load RTL plugin from our CDN on demand.
  maplibregl.setRTLTextPlugin(
    'https://static.robinpowered.com/atlas/ui/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.min.js',
    null, // No need for callback.
    true // Only load this on demand.
  );
}

// Create context for providing the map APIs to child elements.
const Context = React.createContext({});

const {Consumer, Provider} = Context;

/**
 * Determines if given style spec is a valid style spec.
 *
 * @param  {*} style The style spec to check.
 * @return {boolean} True if style spec is valid.
 */
const isValidStyleSpec = (style) =>
  typeof style === 'object' &&
  Array.isArray(style.layers) &&
  typeof style.sources === 'object';

/**
 * Determines if given source is a remote GeoJSON source.
 *
 * @param  {Object} source The source to check.
 * @return {boolean} True if source is a remote GeoJSON source.
 */
const isRemoteGeoJsonSource = (source) =>
  source &&
  `${source.type}`.toLowerCase() === 'geojson' &&
  typeof source.data === 'string';

/**
 * Sets source for the style.
 *
 * @param {Object} style The style to set.
 * @param {string} sourceId The source ID to set.
 * @param {Object} source The source with style set.
 */
const setSourceForStyle = (style, sourceId, source) => ({
  ...style,
  sources: {
    ...style.sources,
    [sourceId]: source
  }
});

/**
 * Separates the sources into the ones to fetch and ones to just set.
 *
 * @param  {Object} styleSpec The style spec to fetch.
 * @return {Object} Object with `toFetch` and `toSet`.
 */
const getSourcesToSetAndFetch = (styleSpec) => {
  return Object.entries(styleSpec.sources).reduce(
    (map, [sourceId, source]) => {
      if (isRemoteGeoJsonSource(source)) {
        // Fetch geojson sources which are URLs.
        map.toFetch[sourceId] = source;
      } else {
        // Source is something else - just set it.
        map.toSet[sourceId] = source;
      }

      return map;
    },
    {toFetch: {}, toSet: {}}
  );
};

/**
 * Default bounds which Robin maps are locked to.
 * @type {Array<Array<number>>}
 */
const DEFAULT_BOUNDS = [
  -179.9999999999999, // Maplibre v2 doesn't like 180˚, even though that's what we're looking for.
  -66.51326044311186,
  179.9999999999999, // Maplibre v2 doesn't like 180˚, even though that's what we're looking for.
  66.51326044311186
];

export {Consumer as MapConsumer, Context as MapContext};

/**
 * A HoC to provide map context to given component.
 *
 * @param {Object} WrappedComponent The component to wrap & provide the map for.
 * @return {Object} The wapped component.
 */
export const withMap = (WrappedComponent) => {
  const WithMap = (props) => (
    <Consumer>{(map) => <WrappedComponent map={map} {...props} />}</Consumer>
  );
  WithMap.displayName = `WithMap(${getDisplayName(WrappedComponent)})`;
  return React.memo(WithMap);
};

/**
 * Returns the first value that's defined (not undefined and not null).
 * @param  {*} args Any N args in which to find first non-undefined & non-null value.
 * @return {*} First non-undefined value, or undefined if not found.
 */
const getFirstOrderedDefinedValue = (...args) =>
  args.find((value) => value !== undefined && value !== null);

/**
 * The main map component, which renders the mapbox map.
 * It manages the initial boot-up phase of the map, as well as some lifecycle events, alongside
 * detecting if the current platform supports this particular map implementation.
 */
export default class Map extends Component {
  static propTypes = {
    /**
     * The initial map zoom.
     * Changing this prop after does not have an effect.
     */
    zoom: PropTypes.number,
    /**
     * The style URL to load.
     */
    styleURL: PropTypes.string.isRequired,
    /**
     * Forwarded style to the `<div />` container which hosts the map.
     */
    style: PropTypes.object,
    /**
     * Forwarded class name to the `<div />` container which hosts the map.
     */
    className: PropTypes.string,
    /**
     * The atlas server URL for which the token will be added to the requests.
     */
    serverUrl: PropTypes.string.isRequired,
    /**
     * The token to add to the atlas server requests
     */
    serverToken: PropTypes.string.isRequired,
    /**
     * Request transformer function.
     */
    transformRequest: PropTypes.func,
    /**
     * Children to render for this component.
     */
    children: PropTypes.node,
    /**
     * Loader component to show while map is loading.
     */
    loader: PropTypes.node,
    /**
     * Component to show if current platform does not support WebGL.
     */
    unsupportedHostPlaceholder: PropTypes.node,
    /**
     * The initial center coordinate for the map.
     */
    center: PropTypes.arrayOf(PropTypes.number),
    /**
     * If map should be interactive or not. Overrides meta flag set by style spec.
     */
    interactive: PropTypes.bool,
    /**
     * Prefix which namespaces all related metadata.
     */
    metadataPrefix: PropTypes.string,
    /**
     * Min zoom available to the map.
     */
    minZoom: PropTypes.number,
    /**
     * Max zoom available to the map.
     */
    maxZoom: PropTypes.number,
    /**
     * Max bounds to which the map should fit.
     */
    maxBounds: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    /**
     * Allows/disables rotation of the map.
     */
    rotateEnabled: PropTypes.bool,
    /**
     * Controls pitch gesture support.
     */
    pitchEnabled: PropTypes.bool,
    /**
     * Callback called when Mapbox reports an error.
     */
    onError: PropTypes.func,
    /**
     * Flag indicating if the resulting image should use anti-aliasing.
     */
    antialias: PropTypes.bool,
    /**
     * Flag indicating if the map's canvas can be exported to a PNG.
     */
    preserveDrawingBuffer: PropTypes.bool,
    /**
     * Sets initial map's bearing.
     */
    bearing: BearingPropType,
    /**
     * Sets initial map's pitch.
     */
    pitch: PitchPropType,
    /**
     * Enters debug mode for the map.
     */
    debug: MapDebugPropType
  };

  static defaultProps = {
    /**
     * Prefix which namespaces all related metadata.
     * @type {String}
     */
    metadataPrefix: 'robin:',
    /**
     * Determines if antialias should be turned on or off.
     * @type {boolean}
     */
    antialias: true,
    /**
     * Determines if the map's canvas can be exported to PNG.
     * False by default as a performance optimization.
     * @type {boolean}
     */
    preserveDrawingBuffer: false,
    /**
     * Determines whether or not to remove the tabindex on the Map's canvas element.
     * True by default, as we likely never want the Map to be tabbable.
     * @type {boolean}
     */
    disableTabIndexOnCanvas: true
  };

  state = {
    // Immediately determine if platform supports Mapbox & WebGL.
    supported: maplibregl.supported(),
    loaded: false,
    // Ensures that we'll not try to load child components too early.
    specLoaded: false,
    style: {}
  };

  fetchWithTransform(toFetch, transformRequest) {
    const {url, headers, credentials} = transformRequest(toFetch);

    return new Promise((resolve, reject) => {
      xhr.get(
        {
          url,
          headers,
          withCredentials: credentials,
          json: true
        },
        (err, response) => {
          if (err) {
            reject(err);
            return;
          }

          resolve(response.body);
        }
      );
    });
  }

  fetchMap(styleSpecUrl, transformRequest) {
    return this.fetchWithTransform(styleSpecUrl, transformRequest)
      .then((style) => {
        if (!isValidStyleSpec(style)) {
          throw new Error('Unable to load style spec.');
        }

        const {toFetch, toSet} = getSourcesToSetAndFetch(style);

        this.setState({
          style: {
            ...style,
            sources: toSet
          }
        });

        return toFetch;
      })
      .then((sourcesToFetch) => {
        // Get all sources to fetch, if any.
        return Promise.all(
          Object.entries(sourcesToFetch).map(([sourceId, source]) => {
            return this.fetchWithTransform(source.data, transformRequest)
              .then((sourceData) => {
                if (typeof sourceData !== 'object') {
                  throw new Error(`Unable to load source ${sourceId}.`);
                }

                // Resolves when state with the new style was set.
                // Allows loading/showing the styles asynchronously.
                return new Promise((resolve) => {
                  this.setState(
                    (state) => ({
                      ...state,
                      style: setSourceForStyle(state.style, sourceId, {
                        ...source,
                        data: sourceData
                      })
                    }),
                    () => resolve()
                  );
                });
              })
              .catch((error) => {
                // Mapbox doesn't fail if loading a source has failed,
                // so this implementation should not as well.
                // We just "gracefully" handle the error here, and let it proceed.
                // eslint-disable-next-line no-console
                console.error && console.error(error);
              });
          })
        );
      })
      .then(() => {
        this.setState({specLoaded: true});
      })
      .catch((error) => {
        this.setState({specLoaded: true}, () => {
          // Let React handle the error.
          this.setState(() => {
            throw error;
          });
        });
      });
  }

  // Style stack keeps the cursor styles in an ordered manner.
  cursorStyleStack = [];

  interceptedEventCallbacks = {};
  interceptedEventCallbacksWithSpecifiers = {};

  handleInterceptedEvent = (event, ...rest) => {
    if (!Array.isArray(this.interceptedEventCallbacks[event.type])) {
      return;
    }

    this.interceptedEventCallbacks[event.type].forEach((callback) =>
      callback(event, ...rest)
    );
  };

  onInterceptedHandler = (...args) => {
    let event, specifier, callback;

    if (args.length === 2) {
      [event, callback] = args;
    } else {
      [event, specifier, callback] = args;
    }

    if (!specifier) {
      if (!this.interceptedEventCallbacks[event]) {
        this.interceptedEventCallbacks[event] = [];
        this.map.on(event, this.handleInterceptedEvent);
      }

      this.interceptedEventCallbacks[event].push(callback);
    } else {
      if (!this.interceptedEventCallbacksWithSpecifiers[event]) {
        this.interceptedEventCallbacksWithSpecifiers[event] = {};
      }

      if (!this.interceptedEventCallbacksWithSpecifiers[event][specifier]) {
        const handler = (...rest) => {
          if (
            !this.interceptedEventCallbacksWithSpecifiers[event] ||
            !this.interceptedEventCallbacksWithSpecifiers[event][specifier]
          ) {
            return;
          }

          this.interceptedEventCallbacksWithSpecifiers[event][
            specifier
          ].callbacks.forEach((callback) => callback(...rest));
        };
        this.interceptedEventCallbacksWithSpecifiers[event][specifier] = {
          callbacks: [],
          handler: handler
        };
        this.map.on(event, specifier, handler);
      }

      this.interceptedEventCallbacksWithSpecifiers[event][
        specifier
      ].callbacks.push(callback);
    }
  };

  shouldInterceptEvent = (event) => {
    return ['mousemove', 'mouseenter', 'mouseleave', 'mouseout'].includes(
      event
    );
  };

  offInterceptedHandler = (...args) => {
    let event, specifier, callback;

    if (args.length === 2) {
      [event, callback] = args;
    } else {
      [event, specifier, callback] = args;
    }

    if (!specifier) {
      if (!this.interceptedEventCallbacks[event]) {
        return;
      }

      this.interceptedEventCallbacks[event] = this.interceptedEventCallbacks[
        event
      ].filter((potentialCb) => callback !== potentialCb);

      if (this.interceptedEventCallbacks[event].length === 0) {
        this.map.off(event, this.handleInterceptedEvent);
        delete this.interceptedEventCallbacks[event];
      }
    } else {
      if (
        !this.interceptedEventCallbacksWithSpecifiers[event] ||
        !this.interceptedEventCallbacksWithSpecifiers[event][specifier]
      ) {
        return;
      }

      this.interceptedEventCallbacksWithSpecifiers[event][
        specifier
      ].callbacks = this.interceptedEventCallbacksWithSpecifiers[event][
        specifier
      ].callbacks.filter((potentialCb) => callback !== potentialCb);

      if (
        this.interceptedEventCallbacksWithSpecifiers[event][specifier].callbacks
          .length === 0
      ) {
        this.map.off(
          event,
          specifier,
          this.interceptedEventCallbacksWithSpecifiers[event][specifier].handler
        );
        delete this.interceptedEventCallbacksWithSpecifiers[event][specifier];

        if (
          Object.keys(this.interceptedEventCallbacksWithSpecifiers[event])
            .length === 0
        ) {
          delete this.interceptedEventCallbacksWithSpecifiers[event];
        }
      }
    }
  };

  projectCoordinate = ([lng, lat]) => {
    const project = this.getMetadata('project');

    if (!project) {
      throw new Error(
        `The map isn't loaded or doesn't have projection metadata.`
      );
    }

    const {lng: projectLng, lat: projectLat} = project;

    return [
      evaluateExpressionWithValue('coordinate', lng, projectLng),
      evaluateExpressionWithValue('coordinate', lat, projectLat)
    ];
  };

  unprojectCoordinate = ([lng, lat]) => {
    const unproject = this.getMetadata('unproject');

    if (!unproject) {
      throw new Error(
        `The map isn't loaded or doesn't have projection metadata.`
      );
    }

    const {lng: unprojectLng, lat: unprojectLat} = unproject;

    return [
      evaluateExpressionWithValue('coordinate', lng, unprojectLng),
      evaluateExpressionWithValue('coordinate', lat, unprojectLat)
    ];
  };

  renderMap = (ref) => {
    if (ref) {
      this.map = new maplibregl.Map({
        container: ref,
        renderWorldCopies: false,
        style: this.state.style,
        antialias: this.props.antialias,
        preserveDrawingBuffer: this.props.preserveDrawingBuffer,
        // Add access token to Atlas server's requests.
        transformRequest:
          this.props.transformRequest ||
          createRequestTransformer(
            this.props.serverUrl,
            this.props.serverToken
          ),
        // Default to prop bounds, or use a const.
        maxBounds: this.props.maxBounds || DEFAULT_BOUNDS,
        minZoom: this.props.minZoom,
        maxZoom: this.props.maxZoom,
        // Since style spec might return own center & zoom,
        // by default omit these props from options completely.
        ...(this.props.center ? {center: this.props.center} : null),
        ...(this.props.zoom !== undefined ? {zoom: this.props.zoom} : null),
        // Passing any prop for `interactive` will trigger the map to check for it,
        // even if `undefined` is passed.
        ...(this.props.interactive !== undefined
          ? {interactive: this.props.interactive}
          : null),
        ...(this.props.bearing !== undefined
          ? {bearing: this.props.bearing}
          : null),
        ...(this.props.pitch !== undefined ? {pitch: this.props.pitch} : null)
      });

      this.handleMapDebugMode();

      if (this.props.disableTabIndexOnCanvas) {
        // Occasionally, setting the Map's canvas as the activeElement via tabbing
        // can cause UI issues.
        const canvasEl = this.map.getCanvas();
        canvasEl.removeAttribute('tabindex');
      }

      this.fetchMap(
        this.props.styleURL,
        this.props.transformRequest ||
          this.map._requestManager.transformRequest.bind(
            this.map._requestManager
          )
      );

      this.map.on(MapEventType.ERROR, this.handleError);
      this.map.on(MapEventType.LOAD, this.handleLoad);
      const imagesInProgress = {};

      this.map.on('styleimagemissing', (e) => {
        const prefix = this.getMetadata('avatar-renderer:prefix');
        const id = e.id;
        if (id.indexOf(prefix) !== 0) {
          return;
        }

        if (imagesInProgress[id]) {
          return;
        }

        imagesInProgress[id] = true;

        const url = this.getMetadata('avatar-renderer:url');
        const {image, fallback} = JSON.parse(id.replace(prefix, ''));
        this.map.loadImage(
          url.replace('{srcset}', image).replace('{fallback}', fallback),
          (error, image) => {
            imagesInProgress[id] = false;
            if (error) throw error;
            this.map.addImage(id, image);
          }
        );
      });

      this.mapApi = {
        // The Map component's instance.
        componentInstance: this,
        // This api is needed for the popups and other things.
        // Should not be used directly, if possible.
        api: this.map,
        // Re-binding the mapbox API, which makes it 1-1 with native.
        addControl: (...args) => this.map.addControl(...args),
        removeControl: (...args) => this.map.removeControl(...args),
        on: (...args) => {
          if (this.shouldInterceptEvent(...args)) {
            return this.onInterceptedHandler(...args);
          }

          return this.map.on(...args);
        },
        off: (...args) => {
          if (this.shouldInterceptEvent(...args)) {
            return this.offInterceptedHandler(...args);
          }

          return this.map.off(...args);
        },
        getStyle: (...args) => this.map.getStyle(...args),
        removeFeatureState: (...args) => this.map.removeFeatureState(...args),
        getFeatureState: (...args) => this.map.getFeatureState(...args),
        setFeatureState: (...args) => this.map.setFeatureState(...args),
        getLayer: (...args) => this.map.getLayer(...args),
        getSource: (...args) => this.map.getSource(...args),
        getZoom: (...args) => this.map.getZoom(...args),
        getMinZoom: (...args) => this.map.getMinZoom(...args),
        getMaxZoom: (...args) => this.map.getMaxZoom(...args),
        zoomIn: (...args) => this.map.zoomIn(...args),
        zoomOut: (...args) => this.map.zoomOut(...args),
        project: (...args) => this.map.project(...args),
        fitBounds: (...args) => this.map.fitBounds(...args),
        easeTo: (...args) => this.map.easeTo(...args)
      };

      // No state to set, we just need to start showing loader.
      // With this version of mapbox, we determine loading state based on calls to map's API
      // to query if it's loaded.
      this.forceUpdate();
    }
  };

  componentWillUnmount() {
    if (this.map) {
      this.map.off(MapEventType.LOAD, this.handleLoad);
      this.map.remove();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.zoom !== nextProps.zoom ||
      this.props.styleURL !== nextProps.styleURL ||
      this.props.className !== nextProps.className ||
      this.props.serverUrl !== nextProps.serverUrl ||
      this.props.serverToken !== nextProps.serverToken ||
      this.props.loader !== nextProps.loader ||
      this.props.unsupportedHostPlaceholder !==
        nextProps.unsupportedHostPlaceholder ||
      this.props.interactive !== nextProps.interactive ||
      this.props.metadataPrefix !== nextProps.metadataPrefix ||
      this.props.minZoom !== nextProps.minZoom ||
      this.props.maxZoom !== nextProps.maxZoom ||
      this.props.pitch !== nextProps.pitch ||
      this.props.transformRequest !== nextProps.transformRequest ||
      this.props.bearing !== nextProps.bearing ||
      this.props.rotateEnabled !== nextProps.rotateEnabled ||
      this.props.pitchEnabled !== nextProps.pitchEnabled ||
      this.props.onError !== nextProps.onError ||
      this.props.children !== nextProps.children ||
      !shallowEqualObjects(this.props.style, nextProps.style) ||
      !shallowEqualArrays(this.props.center, nextProps.center) ||
      !shallowEqualArrays(this.props.maxBounds, nextProps.maxBounds) ||
      this.props.debug !== nextProps.debug ||
      this.state.supported !== nextState.supported ||
      this.state.loaded !== nextState.loaded ||
      this.state.style !== nextState.style ||
      this.state.specLoaded !== nextState.specLoaded
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.map) {
      if (prevState.style !== this.state.style) {
        const sourceIds = Object.keys(this.state.style.sources);
        const layers = this.state.style.layers.filter(
          ({source}) => !source || sourceIds.includes(source)
        );

        this.map.setStyle({
          ...this.state.style,
          layers
        });
      }

      if (prevProps.debug !== this.props.debug) {
        this.handleMapDebugMode();
      }

      if (prevProps.zoom !== this.props.zoom) {
        this.map.setZoom(this.props.zoom);
      }

      if (prevProps.interactive !== this.props.interactive) {
        this.setMapInteractive(this.props.interactive);
      }

      if (prevProps.minZoom !== this.props.minZoom) {
        this.map.setMinZoom(this.props.minZoom);
      }

      if (prevProps.pitch !== this.props.pitch) {
        this.map.setPitch(this.props.pitch);
      }

      if (prevProps.bearing !== this.props.bearing) {
        this.map.setBearing(this.props.bearing);
      }

      if (prevProps.maxZoom !== this.props.maxZoom) {
        this.map.setMaxZoom(this.props.maxZoom);
      }

      if (prevProps.maxBounds !== this.props.maxBounds) {
        this.setMaxBounds();
      }

      if (prevProps.pitchEnabled !== this.props.pitchEnabled) {
        this.setPitchEnabled();
      }

      if (prevProps.rotateEnabled !== this.props.rotateEnabled) {
        this.setRotateEnabled();
      }

      if (prevProps.transformRequest !== this.props.transformRequest) {
        this.map.setTransformRequest(
          this.props.transformRequest ||
            createRequestTransformer(
              this.props.serverUrl,
              this.props.serverToken
            )
        );
      }
    }

    // @TODO: detecting support changes depends on unrelated factors updating the component.
    if (this.state.supported !== maplibregl.supported()) {
      this.setState({supported: maplibregl.supported()});
    }
  }

  handleMapDebugMode = () => {
    if (!this.map) {
      return;
    }

    const showCollisionBoxes =
      this.props.debug === true ||
      (this.props.debug &&
        this.props.debug.mapbox &&
        this.props.debug.mapbox.showCollisionBoxes);

    if (showCollisionBoxes !== this.map.showCollisionBoxes) {
      this.map.showCollisionBoxes = showCollisionBoxes;
    }

    const showPadding =
      this.props.debug === true ||
      (this.props.debug &&
        this.props.debug.mapbox &&
        this.props.debug.mapbox.showPadding);

    if (showPadding !== this.map.showPadding) {
      this.map.showPadding = showPadding;
    }

    const showTileBoundaries =
      this.props.debug === true ||
      (this.props.debug &&
        this.props.debug.mapbox &&
        this.props.debug.mapbox.showTileBoundaries);

    if (showTileBoundaries !== this.map.showTileBoundaries) {
      this.map.showTileBoundaries = showTileBoundaries;
    }

    const repaint =
      this.props.debug === true ||
      (this.props.debug &&
        this.props.debug.mapbox &&
        this.props.debug.mapbox.repaint);

    if (repaint !== this.map.repaint) {
      this.map.repaint = repaint;
    }
  };

  getMinZoomForMaxBounds = () => {
    const bounds = this.map.getMaxBounds();
    const camera = this.map.cameraForBounds(bounds);
    return camera.zoom;
  };

  getZoomForFloorplan = () => {
    const bounds = this.getMetadata('bounds');

    if (bounds !== null) {
      const camera = this.map.cameraForBounds(bounds);
      return camera.zoom;
    }

    return null;
  };

  getFeatures = (specifier) => {
    // Get all sources to get the features from.
    const sources = specifier.reduce((sourceList, layerName) => {
      const layer = this.map.getLayer(layerName);

      if (layer && !sourceList.includes(layer.source)) {
        // If source wasn't added yet, add the source to list of features to fetch.
        sourceList.push(layer.source);
      }

      return sourceList;
    }, []);
    // Grab the features from given sources.
    const features = sources.reduce((featureList, sourceName) => {
      const source = this.map.getSource(sourceName);

      if (!source) {
        // If there's no such source - skip the loop.
        return featureList;
      }

      // By serializing the source, we get access to underlying GeoJSON data,
      // which also gives us all the features.
      const serialized = source.serialize();

      if (
        // Make sure it's still what we expect, as sources can be modified at run-time.
        serialized &&
        serialized.data &&
        Array.isArray(serialized.data.features)
      ) {
        featureList.push(
          ...serialized.data.features.map((feature) => {
            // Assign the source name on the feature, as `queryRenderedFeatures` would.
            // This is required for convenience of passing in the features as props.
            // We're technically modifying the original feature, but that should
            // not matter, and for performance reasons, cloning it isn't possible.
            feature.source = sourceName;
            return feature;
          })
        );
      }

      return featureList;
    }, []);

    return ensureUniqueFeatures(features);
  };

  getMetadata(key) {
    return getMetadata(this.map.getStyle(), key);
  }

  setMapInteractive(interactive) {
    const method = interactive ? 'enable' : 'disable';

    // Enable or disable all methods of interacting with the map.
    this.map.scrollZoom[method]();
    this.map.boxZoom[method]();
    this.map.dragPan[method]();
    this.map.keyboard[method]();
    this.map.doubleClickZoom[method]();
    // We have to set rotation separately.
    this.setRotateEnabled();
  }

  setMaxBounds = () => {
    // If style spec contains bounds, and max bounds are set to defaults, use bounds from style spec.
    this.map.setMaxBounds(
      getFirstOrderedDefinedValue(
        // Prop takes precedence.
        this.props.maxBounds,
        // Use metadata field.
        this.getMetadata('max-bounds'),
        // Set default bounds otherwise.
        DEFAULT_BOUNDS
      )
    );
  };

  setPitchEnabled = () => {
    // We have to use private APIs to enable pitching with rotate after the map was already loaded.
    this.map.dragRotate._pitchWithRotate = getFirstOrderedDefinedValue(
      // Prop takes precedence.
      this.props.pitchEnabled,
      // Use metadata field.
      this.getMetadata('pitch-enabled'),
      // Disable pitch by default.
      false
    );
  };

  /**
   * Computes the cursor style that the map should be set to.
   */
  computeCursorStyle = () => {
    const canvas = this.map.getCanvasContainer();

    // Inherit the cursor by default.
    let cursor = 'inherit';

    // Iterate the stack from top -> bottom.
    for (let i = this.cursorStyleStack.length - 1; i > -1; i -= 1) {
      if (typeof this.cursorStyleStack[i] === 'string') {
        // If style exists and it's not undefined (i.e.) removed,
        // set this as the current cursor style.
        cursor = this.cursorStyleStack[i];
        break;
      }
    }

    canvas.style.cursor = cursor;
  };

  /**
   * Pushes a new cursor style onto a stack.
   * @param  {string} style The cursor style to push onto a stack.
   * @return {number} The cursor style ID.
   */
  pushCursorStyle = (style) => {
    const id = this.cursorStyleStack.push(style) - 1;
    // Rebuild the style.
    this.computeCursorStyle();

    return id;
  };

  /**
   * Removes the cursor style by ID.
   * @param  {number} id The style ID to remove.
   */
  removeCursorStyle = (id) => {
    this.cursorStyleStack[id] = undefined;
    // Rebuild the style.
    this.computeCursorStyle();
  };

  setRotateEnabled = () => {
    const isInteractive = getFirstOrderedDefinedValue(
      this.props.interactive,
      this.getMetadata('interactive')
    );

    const rotateEnabled = getFirstOrderedDefinedValue(
      // Prop takes precedence.
      this.props.rotateEnabled,
      // Use metadata field.
      this.getMetadata('rotate-enabled'),
      // Disable rotation by default.
      false
    );
    if (
      // Don't enable dragRotate if map is not interactive already.
      isInteractive !== false ||
      rotateEnabled !== true
    ) {
      this.map.dragRotate[rotateEnabled ? 'enable' : 'disable']();
      this.map.touchZoomRotate[
        rotateEnabled ? 'enableRotation' : 'disableRotation'
      ]();
    }
  };

  handleError = (error) =>
    this.props.onError ? this.props.onError(error) : null;

  handleLoad = () => {
    this.setState({loaded: true});

    const isInteractive = this.getMetadata('interactive');

    if (
      isInteractive !== null &&
      // Only set interactive when prop isn't set.
      this.props.interactive === undefined
    ) {
      this.setMapInteractive(isInteractive);
    }

    const minZoom = this.getMetadata('min-zoom');
    const maxZoom = this.getMetadata('max-zoom');

    // Only set the zoom levels if they're not already controlled via props.
    if (typeof minZoom === 'number' && typeof this.props.minZoom !== 'number') {
      this.map.setMinZoom(minZoom);
    }

    if (typeof maxZoom === 'number' && typeof this.props.maxZoom !== 'number') {
      this.map.setMaxZoom(maxZoom);
    }

    // Set the zoom level to fit the floorplan if there's no zoom prop set.
    if (typeof this.props.zoom !== 'number') {
      const defaultZoom = this.getZoomForFloorplan();
      if (defaultZoom !== null) {
        this.map.setZoom(defaultZoom);
      }
    }

    this.setMaxBounds();
    this.setPitchEnabled();
    this.setRotateEnabled();
  };

  render() {
    const {children, unsupportedHostPlaceholder} = this.props;
    const {supported, loaded, specLoaded} = this.state;

    warning(
      !(!supported && !unsupportedHostPlaceholder),
      "Current host does not support WebGL, and `unsupportedHostPlaceholder` prop wasn't specified."
    );

    if (!supported) {
      return unsupportedHostPlaceholder || null;
    }

    return (
      <div
        style={this.props.style}
        className={this.props.className}
        ref={this.renderMap}
      >
        {loaded && specLoaded ? (
          <Provider value={this.mapApi}>
            <MapKeyProvider value={this.getMetadata('key')}>
              {children}
            </MapKeyProvider>
          </Provider>
        ) : (
          this.props.loader
        )}
      </div>
    );
  }
}

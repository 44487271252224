import styled from '@emotion/styled';
import MinorDesk from '@robinpowered/icons/MinorDesk';
import { Body } from '@robinpowered/design-system';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserReservation } from '../../../contexts/UserSchedule/hooks';

export const ReservationsDeskInfo = ({
  reservation,
}: {
  reservation?: UserReservation;
}) => {
  const { t } = useTranslation('weekSchedule');

  const [deskAndZoneName, levelAndLocationName] = useMemo(() => {
    const deskName = reservation?.seat.name;
    const locationName = reservation?.seat.location.name;
    const zoneName = reservation?.seat.zone?.name;
    const levelName = reservation?.seat.level?.name;

    const deskAndZoneName = `${deskName}, ${zoneName}`;
    const levelAndLocationName = `${levelName}, ${locationName}`;

    return [deskAndZoneName, levelAndLocationName];
  }, [
    reservation?.seat.level?.name,
    reservation?.seat.location.name,
    reservation?.seat.name,
    reservation?.seat.zone?.name,
  ]);

  return (
    <>
      {reservation && (
        <ReservationDeskInfoContainer>
          <DeskIcon size={16} />
          <DeskInfo>
            <Body.XSmall>{deskAndZoneName}</Body.XSmall>
            <Body.XSmall>{levelAndLocationName}</Body.XSmall>
          </DeskInfo>
        </ReservationDeskInfoContainer>
      )}

      {!reservation && (
        <Body.XSmall>{t('officeDay.reservationsCard.no_desk')}</Body.XSmall>
      )}
    </>
  );
};

const ReservationDeskInfoContainer = styled.div`
  display: flex;
  padding-left: 8px;
`;

const DeskIcon = styled(MinorDesk)`
  margin-top: 2px;
`;

const DeskInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 4px;
`;

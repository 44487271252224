import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Heading07, Row } from '@robinpowered/design-system';
import { GoogleLogo, MicrosoftLogo } from '../../Icons';
import {
  EventPreview,
  OfficeCardPreview,
  WeekViewPreview,
} from '../../CallToActionPreviews';

export const LayeredPreview = (): JSX.Element => {
  const { t } = useTranslation('sidebar');
  return (
    <Container>
      <FloatingWeekViewPreview />
      <FloatingOfficeCard>
        <Heading07>{t('navigation.whos_in')}</Heading07>
      </FloatingOfficeCard>
      <FloatingEventCard />
      <FloatingSVGs alignItems="center" ml="3px">
        <GoogleLogo />
        <Box ml="5px">
          <MicrosoftLogo />
        </Box>
      </FloatingSVGs>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 125px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const FloatingWeekViewPreview = styled(WeekViewPreview)`
  width: 70%;
  height: 150px;
`;

const FloatingOfficeCard = styled(OfficeCardPreview)`
  position: absolute;
  right: 30px;
  top: 45px;
  width: 140px;
  height: auto;
  padding: 14px 14px 0px 14px;
`;

const FloatingEventCard = styled(EventPreview)`
  position: absolute;
  padding: 10px;
  width: 180px;
  top: 145px;
  left: 30px;
  height: auto;
`;

const FloatingSVGs = styled(Row)`
  top: 225px;
  left: 30px;
  position: absolute;
`;

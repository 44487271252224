import styled from '@emotion/styled';
import { ButtonV4, Colors, Flex } from '@robinpowered/design-system';
import SpaceSolid from '@robinpowered/icons/SpaceSolid';
import { SpaceWithNameAndLocation } from './graphql/useSpaces';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';
import { useCallback, useMemo } from 'react';
import {
  useEventsListContext,
  useSpaceBookingContext,
  useToast,
} from '../../contexts';
import { robinLogger } from '../../utilities/RobinLogger';
import { useTranslation } from 'react-i18next';
import { useRemoveSpaceFromEvent } from '../../graphql/mutations';
import { TFunction } from 'i18next';
import { RemoveSpaceFromEventErrorReasons } from '../../__generated__/types';
import moment from 'moment';

export const ExistingSpace = ({
  space,
  event,
  eventInFuture,
}: {
  space: SpaceWithNameAndLocation;
  event: Event;
  eventInFuture: boolean;
}) => {
  const logger = robinLogger();
  const { t } = useTranslation('sidebar');
  const { openModal } = useSpaceBookingContext();
  const toast = useToast();

  const [removeSpaceFromEvent, { loading }] = useRemoveSpaceFromEvent();
  const { dispatchUpdateSpaceBookingState } = useEventsListContext();

  const allowSpaceRemoval = useMemo(
    () => event.endTime && moment().isBefore(event.endTime),
    [event.endTime]
  );

  const handleOpenSpaceDetails = useCallback(() => {
    logger.reportEvent('clicked-view-booked-space');

    openModal(
      {
        event,
        spaceId: space.id,
        selectedLocationId: String(space.locationId),
        selectedFloorId: space.levelId ?? undefined,
      },
      true
    );
  }, [logger, openModal, event, space.id, space.locationId, space.levelId]);

  const handleRemoveSpaceFromEvent = useCallback(async () => {
    logger.reportEvent('clicked-remove-space-from-event', {
      event: event.id,
      space: space.id,
    });

    try {
      const result = await removeSpaceFromEvent({
        variables: { input: { eventId: event.id, spaceId: space.id } },
      });

      if (!result.data?.removeSpaceFromEvent.eventUpdated) {
        toast.error(
          removalErrorReason(t, result.data?.removeSpaceFromEvent.error?.reason)
        );
        return;
      }

      toast.success(t('events.remove_space.successful'));
      dispatchUpdateSpaceBookingState({
        type: 'SPACE_REMOVED',
        payload: { eventId: event.id },
      });
    } catch (e) {
      toast.error(t('events.remove_space.errors.unable_to_remove'));
    }
  }, [
    dispatchUpdateSpaceBookingState,
    event.id,
    logger,
    removeSpaceFromEvent,
    space.id,
    t,
    toast,
  ]);

  return (
    <Container>
      <Flex display="flex" alignItems="center" opacity={!eventInFuture && 0.7}>
        <IconWrapper>
          <SpaceSolid color={Colors.Gray60} size={16} />
        </IconWrapper>

        <ExistingSpaceButton onClick={handleOpenSpaceDetails}>
          {space.name}
        </ExistingSpaceButton>
      </Flex>

      {allowSpaceRemoval && (
        <ButtonV4
          label={t('events.remove_space.command')}
          onClick={handleRemoveSpaceFromEvent}
          size="small"
          variant="secondary"
          isLoading={loading}
        />
      )}
    </Container>
  );
};

const removalErrorReason = (
  t: TFunction<'spaceBooking', 'booked_space_actions'>,
  errorReason?: RemoveSpaceFromEventErrorReasons | null
) => {
  switch (errorReason) {
    case RemoveSpaceFromEventErrorReasons.LACKING_EVENT_UPDATE_PERMISSIONS:
      return t('events.remove_space.errors.no_permission');

    case RemoveSpaceFromEventErrorReasons.UNKNOWN:
    default:
      return t('events.remove_space.errors.unable_to_remove');
  }
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: ${Colors.Gray5};
  border-radius: 8px;
`;

const ExistingSpaceButton = styled.a`
  padding: 0 8px;
  height: unset;
  white-space: nowrap;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

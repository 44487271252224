import { RefObject, useEffect, useState } from 'react';

export const useIsHover = <T extends HTMLElement = HTMLElement>(
  elementRef: RefObject<T>
): boolean => {
  const [isHover, setIsHover] = useState<boolean>(false);
  useEffect(() => {
    const handleMouseEnter = () => setIsHover(true);
    const handleMouseLeave = () => setIsHover(false);
    elementRef.current?.addEventListener('mouseenter', handleMouseEnter);
    elementRef.current?.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      elementRef.current?.removeEventListener('mouseenter', handleMouseEnter);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this next time file is edited
      elementRef.current?.removeEventListener('mouseleave', handleMouseEnter);
    };
  }, [elementRef]);
  return isHover;
};

import React from 'react';

function LayerEvents({map, layers, eventsToCallbacks}) {
  React.useEffect(() => {
    const eventsToCallbacksWithFeature = Object.entries(
      eventsToCallbacks
    ).reduce((eventsToCallback, [eventName, cb]) => {
      eventsToCallback[eventName] = (event) => {
        // If the even has features attached to it, then we want to pass in
        // the first feature we find in that collection. Otherwise, just return
        // the event.
        const argument = event.features
          ? event.features[event.features.length - 1]
          : event;

        return cb(argument);
      };
      return eventsToCallback;
    }, {});

    layers.forEach((layer) =>
      Object.entries(eventsToCallbacksWithFeature).forEach(([eventName, cb]) =>
        map.on(eventName, layer, cb)
      )
    );
    return () => {
      layers.forEach((layer) =>
        Object.entries(
          eventsToCallbacksWithFeature
        ).forEach(([eventName, cb]) => map.off(eventName, layer, cb))
      );
    };
  }, [eventsToCallbacks, layers]);

  return null;
}

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.map === nextProps.map,
    prevProps.layers === nextProps.layers,
    prevProps.eventsToCallbacks === nextProps.eventsToCallbacks
  );
}

export default React.memo(LayerEvents, arePropsEqual);

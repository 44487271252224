import {
  Dropdown,
  DropdownProps,
  EyeIcon,
  EyeSlashIcon,
  Flex,
} from '@fluentui/react-northstar';
import { DeskReservationVisibilityType } from '../../../types';

type VisibilityPickerProps = {
  visibility: DeskReservationVisibilityType;
  setVisibility: React.Dispatch<
    React.SetStateAction<DeskReservationVisibilityType>
  >;
  isDisabled: boolean;
};

type VisibilityDropdownItemValue = {
  key: DeskReservationVisibilityType;
  header: string;
};

export const VisibilityPicker = ({
  visibility,
  setVisibility,
  isDisabled,
}: VisibilityPickerProps) => {
  const visibilityOptions = [
    {
      key: 'EVERYONE',
      header: 'Visible',
    },
    {
      key: 'JUST_ME',
      header: 'Hidden',
    },
  ];

  const handleChange = (
    event: React.MouseEvent | React.KeyboardEvent | null,
    data: DropdownProps
  ) => {
    const value = data.value as VisibilityDropdownItemValue;

    setVisibility(value.key);
  };

  return (
    <Flex
      vAlign="center"
      gap="gap.small"
      style={{
        boxShadow: '0px 2px 4px 0px #00000024',
        paddingLeft: '8px',
        borderRadius: '4px',
        display: 'flex',
      }}
    >
      {visibility === 'EVERYONE' ? <EyeIcon /> : <EyeSlashIcon />}
      <Dropdown
        highlightedIndex={visibilityOptions.findIndex(
          (f) => f.key === visibility
        )}
        fluid
        inverted
        disabled={isDisabled}
        items={visibilityOptions}
        value={visibilityOptions.find((opt) => opt.key === visibility)}
        onChange={handleChange}
      />
    </Flex>
  );
};

import { GetUserScheduleDeskReservations as Reservation } from '../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import {
  BookingPolicyType,
  DeskConfirmationStatus,
  HealthCheckpointStatus,
  UnconfirmableReasonType,
} from '../__generated__/types';

type ReservationInfo = {
  isAssigned: boolean;
  isAssignedNotConfirmed: boolean;
  isFlex: boolean;
  hasCheckIn: boolean;
  hasLocalCheckIn: boolean;
  checkInStatus: {
    checkInComplete: boolean;
    checkInRequiredFuture: boolean;
    checkInRequiredNow: boolean;
  };
  hasHealthCheckpoint: boolean;
  healthCheckpointStatus: {
    healthCheckpointComplete: boolean;
    healthCheckpointFailed: boolean;
    healthCheckpointPending: boolean;
  };
};

//copy pasted from dashboard apps
export const getReservationInfo = (
  reservation?: Reservation
): ReservationInfo => {
  const isAssigned = reservation?.type === BookingPolicyType.ASSIGNED;
  const isFlex = reservation?.type !== BookingPolicyType.ASSIGNED;
  const hasCheckIn = !reservation?.confirmation?.unconfirmableReasons.some(
    ({ reason }) => reason === UnconfirmableReasonType.CHECKIN_DISABLED
  );

  const hasLocalCheckIn =
    !!reservation?.seat.reservationPolicies?.checkinPolicies
      ?.enforceLocalCheckinOnly;

  const checkInComplete =
    reservation?.confirmation?.status === DeskConfirmationStatus.CONFIRMED;
  const checkInRequiredNow =
    reservation?.confirmation?.status === DeskConfirmationStatus.UNCONFIRMED;
  const checkInRequiredFuture =
    reservation?.confirmation?.status ===
      DeskConfirmationStatus.CANNOT_CONFIRM &&
    reservation?.confirmation.unconfirmableReasons.some(
      ({ reason }) =>
        reason === UnconfirmableReasonType.OUTSIDE_OF_CHECK_IN_WINDOW
    );

  const hasHealthCheckpoint = !(
    !reservation?.healthCheckpoint ||
    reservation?.healthCheckpoint.status === HealthCheckpointStatus.DISABLED
  );

  const healthCheckpointComplete =
    reservation?.healthCheckpoint?.status === HealthCheckpointStatus.PASSED;
  const healthCheckpointFailed =
    reservation?.healthCheckpoint?.status === HealthCheckpointStatus.FAILED;
  const healthCheckpointPending =
    reservation?.healthCheckpoint?.status === HealthCheckpointStatus.INCOMPLETE;
  const healthCheckpointFuture =
    reservation?.healthCheckpoint?.status ===
    HealthCheckpointStatus.NOT_AVAILABLE;

  const isAssignedNotConfirmed =
    isAssigned && (checkInRequiredNow || checkInRequiredFuture);

  return {
    isAssigned,
    isAssignedNotConfirmed,
    isFlex,
    hasCheckIn: hasCheckIn,
    hasLocalCheckIn: hasLocalCheckIn,
    checkInStatus: {
      checkInComplete: checkInComplete,
      checkInRequiredNow: checkInRequiredNow,
      checkInRequiredFuture: checkInRequiredFuture,
    },
    hasHealthCheckpoint: hasHealthCheckpoint,
    healthCheckpointStatus: {
      healthCheckpointComplete: healthCheckpointComplete,
      healthCheckpointFailed: healthCheckpointFailed,
      healthCheckpointPending:
        healthCheckpointPending || healthCheckpointFuture,
    },
  };
};

export const getReservationTypeForBooking = (
  policyType: BookingPolicyType | undefined
): BookingPolicyType => {
  switch (policyType) {
    case BookingPolicyType.HOT:
    case BookingPolicyType.HOTELED:
      return policyType;

    case BookingPolicyType.ASSIGNED:
    case BookingPolicyType.SHARED:
      return BookingPolicyType.REVERSE_HOTEL;

    default:
      return BookingPolicyType.HOT;
  }
};

import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useReducer,
  useEffect,
} from 'react';
import styled from '@emotion/styled';

import { useTranslation } from 'react-i18next';

import { Colors, Flex } from '@robinpowered/design-system';
import { ChevronRightOutline, BuildingSolid } from '@robinpowered/icons';
import { compact, groupBy, uniqBy } from 'lodash';
import { DropdownPicker, Item, DropdownStateChange } from './DropdownPicker';
import { OrgLocation, Levels } from '../../contexts/Location';

const NO_CAMPUS = 'no-campus';

const naturalSort = (items: Item[]) =>
  items.sort((a, b) =>
    a.name.localeCompare(b.name, undefined, { numeric: true })
  );

type DropdownState = 'campus' | 'location' | 'level' | 'closed';

const LocationPickerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${Colors.Tan5};
  padding: 4px 6px;
  border-radius: 8px;
`;

const NoMapByline = styled.div`
  color: ${Colors.Gray80};
  margin-left: auto;
`;

type Props = {
  locations: OrgLocation[];
  selected: { locationId?: string; levelId?: string };
  onSelect: (selection: Levels) => void;
};

type PickerState = {
  selectedCampus?: Item | null;
  selectedLocation?: Item | null;
  selectedLevel?: Item | null;
  dropdownState: DropdownState;
};

type Action =
  | { type: 'SELECT_CAMPUS'; payload: DropdownStateChange }
  | { type: 'SELECT_LOCATION'; payload: DropdownStateChange }
  | { type: 'SELECT_LEVEL'; payload: DropdownStateChange }
  | { type: 'SET_DROPDOWN'; payload: DropdownState };

const locationPickerReducer = (
  state: PickerState,
  action: Action
): PickerState => {
  switch (action.type) {
    case 'SELECT_CAMPUS':
      return {
        ...state,
        selectedCampus: action.payload.selectedItem,
        selectedLocation: null,
        dropdownState: 'location',
      };
    case 'SELECT_LOCATION':
      return {
        ...state,
        selectedLocation: action.payload.selectedItem,
        selectedLevel: null,
        dropdownState: 'level',
      };
    case 'SELECT_LEVEL':
      return {
        ...state,
        selectedLevel: action.payload.selectedItem,
        dropdownState: 'closed',
      };
    case 'SET_DROPDOWN':
      return {
        ...state,
        dropdownState: action.payload,
      };
    default:
      return state;
  }
};

export const LocationPicker: FC<Props> = ({
  locations,
  selected,
  onSelect,
}) => {
  const { t } = useTranslation('spaceBooking');

  const levelsById = useMemo(() => {
    return locations.reduce((levelsById: Record<string, Levels>, location) => {
      location.levels.forEach((level) => (levelsById[level.id] = level));
      return levelsById;
    }, {});
  }, [locations]);

  const locationsById = useMemo(() => {
    return locations.reduce(
      (locationsById: Record<string, OrgLocation>, location) => {
        locationsById[location.id] = location;
        return locationsById;
      },
      {}
    );
  }, [locations]);

  const locationsByCampus = useMemo(
    () =>
      groupBy(locations, (location) => {
        if (location.campus && location.campus.id) {
          return location.campus.id;
        } else {
          return NO_CAMPUS;
        }
      }),
    [locations]
  );

  const campuses = useMemo(() => {
    return uniqBy(compact(locations.map((location) => location.campus)), 'id');
  }, [locations]);

  const hasCampuses = campuses && campuses.length > 0;

  const initialState = useMemo<PickerState>(() => {
    const initialLocation = selected.locationId
      ? locationsById[selected.locationId]
      : null;
    let initialLevel = null;

    // Ensure that the passed in selected level is within the selected location.
    if (
      selected.levelId &&
      initialLocation?.levels.some(({ id }) => id === selected.levelId)
    ) {
      initialLevel = levelsById[selected.levelId];
    }

    return {
      selectedCampus: initialLocation?.campus ?? null,
      selectedLocation: initialLocation,
      selectedLevel: initialLevel,
      dropdownState: 'closed',
    };
  }, [selected, locationsById, levelsById]);

  const [locationPickerState, dispatchStateUpdate] = useReducer(
    locationPickerReducer,
    initialState
  );

  useEffect(() => {
    const levelId = locationPickerState.selectedLevel?.id;
    if (levelId) {
      onSelect(levelsById[levelId]);
    }
  }, [locationPickerState.selectedLevel, levelsById, onSelect]);

  const locationList = useMemo(() => {
    let locationList = locations;

    if (hasCampuses) {
      if (locationPickerState.selectedCampus) {
        locationList = locationsByCampus[locationPickerState.selectedCampus.id];
      } else if (locationsByCampus[NO_CAMPUS]) {
        locationList = locationsByCampus[NO_CAMPUS];
      }
    }

    return naturalSort(locationList);
  }, [
    locations,
    locationsByCampus,
    locationPickerState.selectedCampus,
    hasCampuses,
  ]);

  const levelList = useMemo(() => {
    if (!locationPickerState.selectedLocation?.id) {
      return [];
    }
    const selectedLocation =
      locationsById[locationPickerState?.selectedLocation?.id];
    return selectedLocation?.levels && selectedLocation.levels.length > 0
      ? naturalSort([...selectedLocation.levels])
      : [];
  }, [locationPickerState.selectedLocation, locationsById]);

  const campusList = useMemo(() => {
    let campusList: Item[] = [];
    if (hasCampuses) {
      if (
        locationsByCampus[NO_CAMPUS] &&
        locationsByCampus[NO_CAMPUS].length > 0
      ) {
        // Add a fake "No campus" to the list to "orphan" locations.
        // This is just as it works on the office tab.
        campusList = campuses.concat([
          {
            name: t('location_picker.no_campus'),
            id: NO_CAMPUS,
          },
        ]);
      } else {
        campusList = campuses;
      }
    }
    return naturalSort(campusList);
  }, [locationsByCampus, campuses, hasCampuses, t]);

  const handleItemSelect = useCallback(
    (actionType: 'SELECT_CAMPUS' | 'SELECT_LOCATION' | 'SELECT_LEVEL') =>
      (changes: DropdownStateChange) => {
        dispatchStateUpdate({
          type: actionType,
          payload: changes,
        });
      },
    []
  );

  const handleToggleClick = useCallback(
    (dropdown: DropdownState) => (changes: DropdownStateChange) =>
      dispatchStateUpdate({
        type: 'SET_DROPDOWN',
        payload: changes.isOpen ? dropdown : 'closed',
      }),
    []
  );

  return (
    <LocationPickerWrapper>
      <Flex>
        <BuildingSolid size={16} color={Colors.Gray60} />
      </Flex>
      {hasCampuses && (
        <Fragment>
          <DropdownPicker
            items={campusList}
            displayByline={locationPickerState.selectedCampus?.name}
            selectedItem={locationPickerState.selectedCampus}
            onSelect={handleItemSelect('SELECT_CAMPUS')}
            isOpen={locationPickerState.dropdownState === 'campus'}
            onToggleClick={handleToggleClick('campus')}
          />
          <ChevronRightOutline size={10} />
        </Fragment>
      )}
      <DropdownPicker
        items={locationList}
        displayByline={locationPickerState.selectedLocation?.name}
        selectedItem={locationPickerState.selectedLocation}
        onSelect={handleItemSelect('SELECT_LOCATION')}
        isOpen={locationPickerState.dropdownState === 'location'}
        onToggleClick={handleToggleClick('location')}
      />
      <ChevronRightOutline size={10} />
      <DropdownPicker
        items={levelList}
        displayByline={
          locationPickerState.selectedLevel?.name ||
          t('location_picker.select_floor')
        }
        selectedItem={locationPickerState.selectedLevel}
        onSelect={handleItemSelect('SELECT_LEVEL')}
        isOpen={locationPickerState.dropdownState === 'level'}
        onToggleClick={handleToggleClick('level')}
        getItemByline={(item) => {
          if (!levelsById[item.id]?.mapIsAvailable) {
            return <NoMapByline>{t('location_picker.no_map')}</NoMapByline>;
          } else {
            return '';
          }
        }}
      />
    </LocationPickerWrapper>
  );
};

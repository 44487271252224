import { ApolloError, gql, useQuery } from '@apollo/client';
import { useApolloContext } from '../..';
import {
  GetZoneAvailabilityWithReservationQuery,
  GetZoneAvailabilityWithReservationQueryVariables,
} from './__generated__/useGetZoneAvailabilitiesWithReservation.generated';
import { ZoneDeskDetails } from '../../../types';

const GET_ZONE_AVAILABILITY_WITH_RESERVATION = gql`
  query GetZoneAvailabilityWithReservation(
    $getZoneByIdId: ID!
    $amenityIds: [ID!]!
    $startTime: Date!
    $endTime: Date!
    $userId: Int
  ) {
    getZoneById(id: $getZoneByIdId) {
      id
      filteredDesksByAmenityIds(ids: $amenityIds) {
        id
        type
        name
        isDisabled
        isReservable
        reservationPolicies {
          seatReservationVisibilityEnabled
        }
        level {
          id
          name
        }
        location {
          name
        }
        state(startTime: $startTime, endTime: $endTime, userId: $userId) {
          availability {
            status
          }
          reservations {
            id
            startTime
            endTime
            recurrence
            visibility
            seat {
              id
              name
              isDisabled
              isReservable
              type
              zone {
                id
                name
              }
            }
            reservee {
              user {
                id
                name
                avatar
                slug
              }
            }
          }
        }
      }
    }
  }
`;

type ReturnType = {
  zoneDeskDetails?: ZoneDeskDetails[];
  loading: boolean;
  error?: ApolloError;
};

export const useGetZoneAvailabilitiesWithReservation = (
  getZoneByIdId: string,
  startTime: moment.Moment,
  endTime: moment.Moment,
  userId: number
): ReturnType => {
  const { tenantId } = useApolloContext();
  const { data, loading } = useQuery<
    GetZoneAvailabilityWithReservationQuery,
    GetZoneAvailabilityWithReservationQueryVariables
  >(GET_ZONE_AVAILABILITY_WITH_RESERVATION, {
    variables: {
      getZoneByIdId,
      startTime,
      endTime,
      amenityIds: [],
      userId,
    },
    skip: !getZoneByIdId || !tenantId,
    errorPolicy: 'all',
  });

  return {
    zoneDeskDetails: mapQueryResultToZoneDeskDetails(data),
    loading,
  };
};

const mapQueryResultToZoneDeskDetails = (
  reservationDetails: GetZoneAvailabilityWithReservationQuery | undefined
): ZoneDeskDetails[] | undefined => {
  if (reservationDetails && reservationDetails.getZoneById) {
    const zone = reservationDetails.getZoneById;
    const desks = zone.filteredDesksByAmenityIds.map(
      (desk) =>
        ({
          id: desk.id,
          type: desk.type,
          name: desk.name,
          level: desk.level,
          seatReservationVisibilityEnabled:
            desk.reservationPolicies?.seatReservationVisibilityEnabled,
          availability: {
            status: desk.state.availability.status,
            unbookableReasons: [],
          },
          reservations: desk.state.reservations,
        } as ZoneDeskDetails)
    );
    return desks;
  }
  return undefined;
};

import { gql, useMutation } from '@apollo/client';
import { GET_USER_NOTIFICATION_SETTINGS } from './useUserNotificationSettingsData';

const SET_USER_NOTIFICATION_SETTING = gql`
  mutation SetUserNotificationSetting(
    $input: SetUserNotificationSettingInput!
  ) {
    setUserNotificationSetting(input: $input)
  }
`;

export const useSetUserNotificationSetting = () => {
  const [setUserNotificationSetting] = useMutation(
    SET_USER_NOTIFICATION_SETTING,
    {
      onError: (e: Error) => console.log(e),
      refetchQueries: [GET_USER_NOTIFICATION_SETTINGS],
    }
  );

  return setUserNotificationSetting;
};

import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from '../..';
import { IsCalendarConnectedQuery } from './__generated__/useIsCalendarConnected.generated';

const query = gql`
  query IsCalendarConnected {
    doIHaveACalendar
  }
`;

export function useIsCalendarConnected() {
  const { tenantId } = useApolloContext();
  return useQuery<IsCalendarConnectedQuery>(query, {
    skip: !tenantId,
  });
}

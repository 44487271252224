import styled from '@emotion/styled';
import {
  Button,
  Flex,
  Text,
  ChevronStartIcon,
} from '@fluentui/react-northstar';
import { ArrowRight } from '@robinpowered/icons';
import CalendarIcon from '@robinpowered/icons/v2/CalendarBlank';
import moment, { Moment } from 'moment';
import { useMemo } from 'react';
import { useUserContext, useDeskBookingContext } from '../../../contexts';
import { DeskTime } from '../../../contexts/DeskBooking/hooks/useDeskSearch';
import { LevelPicker } from '../LevelPicker';
import { TimePicker } from '../TimePicker';
import MapIcon from '@robinpowered/icons/v2/FloorplanMapAlt';
import ListIcon from '@robinpowered/icons/v2/ListBullets';
import { Colors } from '@robinpowered/design-system/dist/Color';

type MapControlProps = {
  initialTime: DeskTime;
  deskDateTime: DeskTime;
  isLeftPanelVisible: boolean;
  changeStartTime: (time: Moment) => void;
  changeEndTime: (time: Moment) => void;
  toggleLeftPanelVisibilityOnMobile: () => void;
};

export const MapControls = ({
  initialTime,
  deskDateTime,
  isLeftPanelVisible,
  changeStartTime,
  changeEndTime,
  toggleLeftPanelVisibilityOnMobile,
}: MapControlProps) => {
  const { currentUser } = useUserContext();
  const {
    closeMap,
    currentLocation,
    currentDate,
    currentLevel,
    setCurrentLevelId,
    levelData,
  } = useDeskBookingContext();

  const timeZone = useMemo(
    () =>
      currentLocation?.timezone || currentUser?.timeZone || moment.tz.guess(),
    [currentLocation, currentUser]
  );

  const startTimePickerMin = useMemo(() => {
    return moment.tz(timeZone).isAfter(deskDateTime.start)
      ? deskDateTime.start
      : moment.tz(timeZone).isBefore(initialTime.start)
      ? initialTime.start
      : moment.tz(timeZone);
  }, [deskDateTime, initialTime, timeZone]);

  return (
    <>
      <Flex
        vAlign="center"
        style={{ backgroundColor: 'white', padding: '0.625rem 0.25rem' }}
      >
        <Button icon={<ChevronStartIcon />} onClick={closeMap} iconOnly text />

        <Text weight="bold" size="large">
          Book a desk at {currentLocation?.name}
        </Text>
      </Flex>

      <Flex
        padding="padding.medium"
        style={{
          backgroundColor: 'white',
          borderTop: '1px solid #E0E0E0',
          borderBottom: '1px solid #E0E0E0',
        }}
      >
        <Container>
          <Flex vAlign="center" gap="gap.small">
            <CalendarIcon size={24} />
            <Text weight="bold">{currentDate?.format('ddd, MMM D')}</Text>
          </Flex>

          <Flex vAlign="center" gap="gap.medium" space="between">
            <TimePicker
              onTimeChange={changeStartTime}
              selectedTime={deskDateTime.start}
              min={startTimePickerMin}
              max={initialTime.end}
              type={'start'}
            />

            <ArrowRight size={24} />

            <TimePicker
              onTimeChange={changeEndTime}
              selectedTime={deskDateTime.end}
              min={deskDateTime.start}
              max={initialTime.end}
              type={'end'}
            />
          </Flex>

          <Flex space="between">
            <LevelPicker
              levelData={levelData}
              selectedLevelId={currentLevel?.id}
              onLevelChange={setCurrentLevelId}
            />

            <ShowZoneListButtonWrapper>
              <Button
                content={
                  isLeftPanelVisible ? (
                    <MapIcon color={Colors.Gray100} size={18} />
                  ) : (
                    <ListIcon color={Colors.Gray100} size={18} />
                  )
                }
                onClick={toggleLeftPanelVisibilityOnMobile}
                iconOnly
              />
            </ShowZoneListButtonWrapper>
          </Flex>
        </Container>
      </Flex>
    </>
  );
};

const Container = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const ShowZoneListButtonWrapper = styled.div`
  @media (min-width: 850px) {
    display: none;
  }
`;

import React, { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { Avatar as RobinAvatar } from '@robinpowered/design-system';
import { teamsTheme } from '@fluentui/react-northstar';
// import { config } from "config";

type Props = {
  name: string;
  src?: string;
  size: number;
  icon?: JSX.Element | null;
  iconStyles?: CSSProperties;
};

export const Avatar = ({
  name,
  src,
  icon,
  size,
  iconStyles,
}: Props): JSX.Element => {
  return (
    <AvatarWrapper>
      <RobinAvatar name={name} src={src} size={size} disableTitle={true} />
      {icon && <IconWrapper style={iconStyles}>{icon}</IconWrapper>}
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.span`
  background-color: ${teamsTheme.siteVariables.colorScheme.default.background2};
  position: absolute;
  right: 0;
  top: 18px;
  left: 20px;
  border-radius: 100px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

import { Button } from '@fluentui/react-northstar';
import { BuildingSolid } from '@robinpowered/icons';
import { Colors } from '@robinpowered/design-system';
import { useLocationsContext } from '../../../contexts';

export const LocationPickerModalButton = () => {
  const { selectedLocation, setIsLocationModalOpen } = useLocationsContext();
  const selectedLocationName = selectedLocation
    ? selectedLocation.name
    : 'No Locations';
  return (
    <Button
      onClick={() => setIsLocationModalOpen(true)}
      icon={<BuildingSolid size={24} color={Colors.Gray40} />}
      content={selectedLocationName}
      iconPosition="before"
      flat
    />
  );
};

import {Component} from 'react';
import PropTypes from 'prop-types';
import {shallowEqualArrays} from '@robinpowered/atlas-common';
import {withMap} from '../Map';

export class LayerFeatures extends Component {
  static propTypes = {
    /**
     * The layer specifier for the event listener. Will only listen for events for a given feature which happened
     * on layers of this specifier. If given multiple, it will create listeners for all of them.
     */
    specifier: PropTypes.arrayOf(PropTypes.string),
    /**
     * The render func for which to provide the features.
     */
    children: PropTypes.func.isRequired,
    /**
     * The feature for which's event to listen.
     */
    map: PropTypes.object.isRequired
  };

  state = {
    features: [],
    specifier: []
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.children !== nextProps.children ||
      !shallowEqualArrays(this.props.children, nextProps.children)
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (!shallowEqualArrays(props.specifier, state.specifier)) {
      return {
        ...state,
        specifier: props.specifier,
        features: props.map.componentInstance.getFeatures(props.specifier)
      };
    }

    return state;
  }

  render() {
    const {children} = this.props;
    const {specifier, features} = this.state;
    return children({
      features,
      specifier
    });
  }
}

const EnchancedLayerFeatures = withMap(LayerFeatures);

export default EnchancedLayerFeatures;

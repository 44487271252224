import { MutationResult } from '@apollo/client';
import moment from 'moment';
import {
  FC,
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';

import { SpaceType } from '../../__generated__/types';
import { Levels, OrgLocation, useLocationsContext } from '../Location';
import { useToast } from '../ToastContext';
import {
  SpaceMapAvailabilityStateById,
  SpaceMapAvailabilityStates,
  useSpaceStateQuery,
} from './hooks/useSpaceStateQuery';

import { useAddSpaceToEvent } from '../../graphql/mutations/addSpaceToEvent';
import { AddSpaceToEventMutation } from './hooks/__generated__/AddSpaceToEvent.generated';
import {
  MapSpaceStateForLevelAmenities,
  MapSpaceStateForLevelQuery,
  MapSpaceStateForLevelSpaces,
} from './hooks/__generated__/useSpaceStateQuery.generated';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './hooks/__generated__/useMyEvents.generated';
import { useSuggestedSpacesForEvents } from '../../components/EventsList/graphql/useSuggestedSpacesForEvents';

export type SpaceTypeWithID = {
  spaceType: SpaceType | null;
  id: number;
};

type SpaceBookingParams = {
  event: Event;
  selectedLocationId: string;
  selectedFloorId?: string;
  spaceId?: string;
};

type SidebarMode = 'LIST_VIEW' | 'DETAILS_VIEW' | 'FILTERS';

export type CapacityType = 'any' | '1-2' | '3-4' | '5-6' | '7-8' | '9+';

type SpaceFilters = {
  amenities: MapSpaceStateForLevelAmenities[];
  spaceTypes: SpaceTypeWithID[];
  capacity: CapacityType;
};

type ModalState = {
  isSpaceBookingMapOpen: boolean;
  mapIsAvailable: boolean;
  selectedLevelId?: string;
  selectedSpaceId?: string;
  event?: SpaceBookingParams['event'];
  sidebarMode: SidebarMode;
  filters: SpaceFilters;
  availabilityStates: SpaceMapAvailabilityStates;
  isBookedSpace: boolean;
};

const initialModalState: ModalState = {
  isSpaceBookingMapOpen: false,
  mapIsAvailable: false,
  selectedLevelId: undefined,
  selectedSpaceId: undefined,
  event: undefined,
  sidebarMode: 'LIST_VIEW',
  filters: {
    amenities: [],
    spaceTypes: [],
    capacity: 'any',
  },
  availabilityStates: {
    bookedIds: [],
    inUseIds: [],
    restrictedIds: [],
    warningIds: [],
    disabledIds: [],
    focusedIds: [],
    availableIds: [],
    selectedIds: [],
    reducedIds: [],
  },
  isBookedSpace: false,
};

type Action =
  | {
      type: 'OPEN_MODAL';
      payload: {
        params: SpaceBookingParams;
        mapIsAvailable: boolean;
        isBookedSpace: boolean;
      };
    }
  | { type: 'SELECT_LEVEL'; payload: { level: Levels } }
  | { type: 'SELECT_SPACE'; payload: { spaceId: string } }
  | { type: 'SET_FILTERS'; payload: { filters: SpaceFilters } }
  | {
      type: 'UPDATE_AMENITY_FILTER';
      payload: { amenities: SpaceFilters['amenities'] };
    }
  | {
      type: 'UPDATE_SPACE_TYPE_FILTER';
      payload: { spaceTypes: SpaceTypeWithID[] };
    }
  | { type: 'UPDATE_CAPACITY_FILTER'; payload: { capacity: CapacityType } }
  | { type: 'OPEN_FILTERS' }
  | { type: 'GO_BACK' }
  | { type: 'CLOSE_MODAL' };

const modalStateReducer = (state: ModalState, action: Action): ModalState => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      const sidebarMode = action.payload.params.spaceId
        ? 'DETAILS_VIEW'
        : 'LIST_VIEW';
      return {
        ...state,
        isSpaceBookingMapOpen: true,
        selectedLevelId: action.payload.params.selectedFloorId,
        selectedSpaceId: action.payload.params.spaceId,
        event: action.payload.params.event,
        mapIsAvailable: action.payload.mapIsAvailable,
        sidebarMode,
        isBookedSpace: action.payload.isBookedSpace,
      };
    }
    case 'CLOSE_MODAL': {
      return initialModalState;
    }
    case 'SELECT_LEVEL':
      return {
        ...state,
        selectedLevelId: action.payload.level.id,
        mapIsAvailable: action.payload.level.mapIsAvailable ?? false,
        filters: initialModalState.filters, // Reset filters when switching floors.
      };
    case 'SELECT_SPACE':
      return {
        ...state,
        selectedSpaceId: action.payload.spaceId,
        sidebarMode: 'DETAILS_VIEW',
      };
    case 'OPEN_FILTERS':
      return {
        ...state,
        sidebarMode: 'FILTERS',
      };
    case 'SET_FILTERS':
      return {
        ...state,
        filters: action.payload.filters,
      };
    case 'UPDATE_AMENITY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          amenities: action.payload.amenities,
        },
      };
    case 'UPDATE_SPACE_TYPE_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          spaceTypes: action.payload.spaceTypes,
        },
      };
    case 'UPDATE_CAPACITY_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          capacity: action.payload.capacity,
        },
      };
    case 'GO_BACK': {
      return {
        ...state,
        // Reset space selection if returing from details view.
        selectedSpaceId:
          state.sidebarMode === 'DETAILS_VIEW'
            ? undefined
            : state.selectedSpaceId,
        sidebarMode: 'LIST_VIEW',
      };
    }
    default:
      return state;
  }
};

type SpaceBookingFormContextType = {
  setSelectedLevel: (level: Levels) => void;
  suggestedSpaceId?: string;
  openModal: (params: SpaceBookingParams, isBookedSpace?: boolean) => void;
  closeModal: () => void;
  hasDismissedSuggestion: (eventId: string) => boolean;
  dismissSuggestion: (eventId: string, spaceId: string) => void;
  spaceStateData: MapSpaceStateForLevelQuery | undefined;
  addSpace: (spaceId: string, spaceWasSuggested: boolean) => void;
  addSpaceResult: MutationResult<AddSpaceToEventMutation>;
  spaceIdCanBeBooked: (spaceId?: string) => boolean;
  openSpaceDetails: (spaceId: string) => void;
  openFilters: () => void;
  goBack: () => void;
  resetFilters: () => void;
  updateAmenityFilter: (amenities: SpaceFilters['amenities']) => void;
  updateSpaceTypeFilter: (spaceTypes: SpaceFilters['spaceTypes']) => void;
  updateCapacityFilter: (spaceTypes: SpaceFilters['capacity']) => void;
  availabilityStateById: SpaceMapAvailabilityStateById;
  cannotBookAnythingElse: boolean;
  loadingSpaceStateData: boolean;
} & ModalState;

export const SpaceBookingFormContext =
  createContext<SpaceBookingFormContextType>({
    ...initialModalState,
    setSelectedLevel: () => undefined,
    suggestedSpaceId: undefined,
    openModal: () => null,
    closeModal: () => null,
    hasDismissedSuggestion: () => false,
    dismissSuggestion: () => null,
    spaceStateData: undefined,
    addSpace: () => undefined,
    // not worth mocking - only used if provider is missing
    addSpaceResult:
      undefined as unknown as MutationResult<AddSpaceToEventMutation>,
    spaceIdCanBeBooked: () => false,
    openSpaceDetails: () => null,
    openFilters: () => null,
    goBack: () => null,
    resetFilters: () => null,
    updateAmenityFilter: () => null,
    updateSpaceTypeFilter: () => null,
    updateCapacityFilter: () => null,
    availabilityStateById: {},
    cannotBookAnythingElse: false,
    loadingSpaceStateData: false,
  });

export const SpaceBookingFormProvider: FC = ({ children }) => {
  const toast = useToast();
  const { locations } = useLocationsContext();
  const { t } = useTranslation('sidebar'); // TODO: "sidebar" is probably not the right place for this stuff

  const [
    {
      isSpaceBookingMapOpen: isOpen,
      selectedLevelId,
      selectedSpaceId,
      mapIsAvailable,
      event,
      sidebarMode,
      filters,
      isBookedSpace,
    },
    dispatchModalStateUpdate,
  ] = useReducer(modalStateReducer, initialModalState);

  // Store dismissed suggested spaces by event.
  const [dismissedSuggestions, setDismissedSuggestions] = useState<
    Record<string, string>
  >({});

  const getMapIsAvailableForLevelIdInLocation = useCallback(
    (levelId: string, locationId: string) => {
      return (
        locations
          .find(({ id }) => locationId === id)
          ?.levels.find((level) => level.id === levelId)?.mapIsAvailable ??
        false
      );
    },
    [locations]
  );

  const hasDismissedSuggestion = useCallback(
    (eventId: string) => {
      return !!dismissedSuggestions[eventId];
    },
    [dismissedSuggestions]
  );

  const setSelectedLevel = useCallback(
    (level: Levels) => {
      if (selectedLevelId !== level.id) {
        dispatchModalStateUpdate({ type: 'SELECT_LEVEL', payload: { level } });
      }
    },
    [selectedLevelId]
  );

  const dismissSuggestion = (eventId: string, spaceId: string) => {
    setDismissedSuggestions((state) => ({ ...state, [eventId]: spaceId }));
  };

  const {
    suggestedSpacesByEventId,
    // TODO: Implement loading state for sidebar.
    // loading: loadingSuggestion,
    // silently fail, lack of suggestions is not the end of the world
    // error: suggestionsError,
  } = useSuggestedSpacesForEvents({ events: event ? [event] : [] });

  const suggestedSpaceId = useMemo(
    () => event?.id && suggestedSpacesByEventId?.[event.id]?.id,
    [event?.id, suggestedSpacesByEventId]
  );

  const selectedAmenityIds = useMemo(
    () => new Set(filters.amenities.map(({ amenityId }) => amenityId)),
    [filters.amenities]
  );

  const spaceMeetsFilterCriteria = useCallback(
    (space: MapSpaceStateForLevelSpaces): boolean => {
      // Would be nice to pre-compute this set, but it depends on the results of the hook.
      const spaceAmenityIds = new Set(
        space.amenities.map((amenity) => amenity.amenityId)
      );

      const hasAllAmenities = Array.from(selectedAmenityIds).every((x) =>
        spaceAmenityIds.has(x)
      );

      const hasSpaceType =
        filters.spaceTypes.length !== 0
          ? space.type !== null &&
            filters.spaceTypes.find(
              (spaceTypeWithId) => space.type === spaceTypeWithId.spaceType
            ) !== undefined
          : true;

      const withinCapacityRange = (filterCapacity: CapacityType) => {
        if (!space.capacity && filterCapacity === 'any') {
          return true;
        } else if (!space.capacity) return false;
        if (filterCapacity[1] === '+') {
          return space.capacity >= Number(filterCapacity[0]);
        } else if (filterCapacity === 'any') {
          return true;
        } else {
          return (
            space.capacity <= Number(filterCapacity[0]) + 1 &&
            space.capacity >= Number(filterCapacity[0])
          );
        }
      };

      return (
        hasAllAmenities && hasSpaceType && withinCapacityRange(filters.capacity)
      );
    },
    [selectedAmenityIds, filters]
  );

  const {
    availabilityStates,
    availabilityStateById,
    data: spaceStateData,
    loading: loadingSpaceStateData,
  } = useSpaceStateQuery({
    levelId: selectedLevelId,
    startTime: moment(event?.startTime),
    endTime: moment(event?.endTime),
    spaceFilter: spaceMeetsFilterCriteria,
    skip: !event || !isOpen || isBookedSpace,
  });

  // until we support multiple-spaces/editing/removal, prevent further action after selected space is booked
  const cannotBookAnythingElse = !!(
    selectedSpaceId && event?.spaces.find((s) => s.id === selectedSpaceId)
  );

  // TODO: would be nice to compute ahead of time and pass down next to other space data
  const spaceIdCanBeBooked = useCallback(
    (spaceId?: string) => {
      const state = !spaceId ? undefined : availabilityStateById[spaceId];
      if (!state) {
        return false;
      }
      if (cannotBookAnythingElse) {
        return false;
      }
      return (
        state.available &&
        !state.disabled &&
        !state.reduced &&
        !state.restricted
      );
    },
    [availabilityStateById, cannotBookAnythingElse]
  );

  const [addSpaceMutation, addSpaceResult] = useAddSpaceToEvent();

  const closeModal = useCallback(() => {
    dispatchModalStateUpdate({ type: 'CLOSE_MODAL' });
    addSpaceResult.reset();
  }, [addSpaceResult]);

  const openSpaceDetails = useCallback(
    (spaceId) => {
      if (cannotBookAnythingElse) return;
      dispatchModalStateUpdate({ type: 'SELECT_SPACE', payload: { spaceId } });
    },
    [cannotBookAnythingElse]
  );

  const openFilters = useCallback(() => {
    dispatchModalStateUpdate({ type: 'OPEN_FILTERS' });
  }, []);

  const goBack = useCallback(() => {
    dispatchModalStateUpdate({ type: 'GO_BACK' });
  }, []);

  const resetFilters = useCallback(() => {
    dispatchModalStateUpdate({
      type: 'SET_FILTERS',
      payload: {
        filters: initialModalState.filters,
      },
    });
  }, []);

  const updateAmenityFilter = useCallback(
    (amenities: SpaceFilters['amenities']) => {
      dispatchModalStateUpdate({
        type: 'UPDATE_AMENITY_FILTER',
        payload: { amenities },
      });
    },
    []
  );

  const updateSpaceTypeFilter = useCallback(
    (spaceTypes: SpaceFilters['spaceTypes']) => {
      dispatchModalStateUpdate({
        type: 'UPDATE_SPACE_TYPE_FILTER',
        payload: { spaceTypes },
      });
    },
    []
  );

  const updateCapacityFilter = useCallback(
    (capacity: SpaceFilters['capacity']) => {
      dispatchModalStateUpdate({
        type: 'UPDATE_CAPACITY_FILTER',
        payload: { capacity },
      });
    },
    []
  );

  const addSpace = useCallback(
    async (spaceId: string, spaceWasSuggested: boolean) => {
      if (!event || !spaceIdCanBeBooked(spaceId)) {
        return;
      }

      let result;
      try {
        result = await addSpaceMutation({
          // TODO: when suggestion is highlighted in UI, we should pass true here if space was suggested
          // https://robinpowered.atlassian.net/browse/PHO-216
          spaceWasSuggested,
          variables: {
            request: {
              spaceId,
              eventId: event.id,
              startTime: event.startTime,
              endTime: event.endTime,
            },
          },
        });
      } catch (e) {
        toast.error(t('events.basic_error'));
        return;
      }

      // Chi ~ TODO: Put this toast back in (related to space suggestions)
      // if (!result.data?.addSpaceToEvent.eventUpdated) {
      //   toast.error(
      //     unBookableReason(t, result.data?.addSpaceToEvent.error?.reason)
      //   );
      //   return;
      // }

      closeModal();
      toast.success(t('events.space_booked'));
    },
    [event, spaceIdCanBeBooked, addSpaceMutation, closeModal, toast, t]
  );

  const getDefaultLevel = (
    locations: OrgLocation[],
    selectedLocationId: string
  ) => {
    // First, try to find the selected location
    const selectedLocation = locations.find(
      (location) => location.id === selectedLocationId
    );

    // Check if the selected location has levels and a map
    if (
      selectedLocation &&
      selectedLocation.levels &&
      selectedLocation.levels.some((level) => level.mapIsAvailable)
    ) {
      // Return the first level with a map
      return selectedLocation.levels.find((level) => level.mapIsAvailable);
    } else {
      // If the selected location doesn't have levels with a map,
      // find the first location that does
      const fallbackLocation = locations.find(
        (location) =>
          location.levels &&
          location.levels.some((level) => level.mapIsAvailable)
      );

      // If a fallback location is found, return its first level with a map
      return fallbackLocation
        ? fallbackLocation.levels.find((level) => level.mapIsAvailable)
        : undefined;
    }
  };

  const handleOpenModal = useCallback(
    (params: SpaceBookingParams, isBookedSpace?: boolean) => {
      const levelId = params.spaceId
        ? params.selectedFloorId
        : params.selectedFloorId ||
          getDefaultLevel(locations, params.selectedLocationId)?.id;
      const mapIsAvailable =
        levelId && params.selectedLocationId
          ? getMapIsAvailableForLevelIdInLocation(
              levelId,
              params.selectedLocationId
            )
          : false;
      dispatchModalStateUpdate({
        type: 'OPEN_MODAL',
        payload: {
          params: {
            ...params,
            selectedFloorId: levelId,
          },
          mapIsAvailable,
          isBookedSpace: isBookedSpace ?? false,
        },
      });
    },
    [locations, getMapIsAvailableForLevelIdInLocation]
  );

  const api = useMemo<SpaceBookingFormContextType>(
    () => ({
      isSpaceBookingMapOpen: isOpen,
      mapIsAvailable,
      selectedLevelId,
      selectedSpaceId,
      setSelectedLevel,
      suggestedSpaceId,
      openModal: handleOpenModal,
      closeModal,
      hasDismissedSuggestion,
      dismissSuggestion,
      spaceStateData,
      addSpace,
      addSpaceResult,
      spaceIdCanBeBooked,
      event,
      sidebarMode,
      openSpaceDetails,
      openFilters,
      goBack,
      resetFilters,
      updateSpaceTypeFilter,
      updateCapacityFilter,
      updateAmenityFilter,
      availabilityStateById,
      filters,
      cannotBookAnythingElse,
      loadingSpaceStateData,
      availabilityStates,
      isBookedSpace,
    }),
    [
      isOpen,
      hasDismissedSuggestion,
      mapIsAvailable,
      selectedLevelId,
      selectedSpaceId,
      setSelectedLevel,
      suggestedSpaceId,
      closeModal,
      spaceStateData,
      addSpace,
      addSpaceResult,
      spaceIdCanBeBooked,
      event,
      sidebarMode,
      openSpaceDetails,
      openFilters,
      goBack,
      handleOpenModal,
      resetFilters,
      updateSpaceTypeFilter,
      updateCapacityFilter,
      updateAmenityFilter,
      availabilityStateById,
      filters,
      cannotBookAnythingElse,
      loadingSpaceStateData,
      availabilityStates,
      isBookedSpace,
    ]
  );

  return (
    <SpaceBookingFormContext.Provider value={api}>
      {children}
    </SpaceBookingFormContext.Provider>
  );
};

export const useSpaceBookingContext = (): SpaceBookingFormContextType => {
  return useContext(SpaceBookingFormContext);
};

import { Body02, Body04, Flex } from '@robinpowered/design-system';

type PropTypes = {
  error: Error;
};

export const ErrorMessage = ({ error }: PropTypes): JSX.Element => {
  const { message, name } = error;
  return (
    <Flex
      display="flex"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        style={{ transform: 'translateY(-200px)' }}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Body02 mb={message ? 2 : 0}>{name}</Body02>
        {message && <Body04>{message}</Body04>}
      </Flex>
    </Flex>
  );
};

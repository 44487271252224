import { useMutation } from '@apollo/client';
import styled from '@emotion/styled';
import {
  Button as FluentButton,
  Flex,
  Popup,
  MoreIcon,
} from '@fluentui/react-northstar';
import { useToast, useUserScheduleContext } from '../../../contexts';
import { endOrCancelReservationMutation } from '../../../graphql/mutations';
import { robinLogger } from '../../../utilities/RobinLogger';

type OverflowMenuProps = {
  isDisabled: boolean;
  reservationId?: string;
  loading: boolean;
  setIsLoading: (val: boolean) => void;
  cancelReservation: (reservationId: string) => Promise<void>;
};

export const OverflowMenu = ({
  isDisabled,
  reservationId,
  loading,
  setIsLoading,
}: OverflowMenuProps) => {
  const { success, error } = useToast();
  const { refetch: refetchUserSchedule } = useUserScheduleContext();

  const [cancelReservation] = useMutation(endOrCancelReservationMutation, {
    onCompleted: () => success('Status updated.'),
    onError: () => error('Error. Please try again'),
  });

  const handleCancelDesk = async () => {
    if (reservationId) {
      setIsLoading(true);

      await cancelReservation({
        variables: { reservationId: reservationId },
      });

      if (refetchUserSchedule) {
        await refetchUserSchedule();
      }
      robinLogger().reportEvent('cancel_desk_reservation');
      setIsLoading(false);
    }
  };

  return (
    <Popup
      position="below"
      trigger={
        <FluentButton icon={<MoreIcon />} iconOnly text disabled={isDisabled} />
      }
      content={
        <Flex column hAlign="start">
          {reservationId && (
            <>
              <Button onClick={handleCancelDesk} disabled={loading}>
                Cancel desk
              </Button>
            </>
          )}
        </Flex>
      }
    />
  );
};

const Button = styled.button`
  appearance: none;
  border: none;
  background-color: unset;
  padding: 4px 0;
  cursor: pointer;

  &:hover {
    color: #5b5fc7;
  }
`;

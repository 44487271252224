import styled from '@emotion/styled';

type InfoPanelProps = {
  children: React.ReactNode;
};

export const InfoPanel = ({ children }: InfoPanelProps) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  background-color: white;

  @media (max-width: 850px) {
    width: 100%;
    flex: 0 0 auto;
  }
`;

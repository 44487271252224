import { gql } from '@apollo/client';

export const confirmDeskMutation = gql`
  mutation ConfirmDeskReservation(
    $reservationId: ID!
    $type: DeskConfirmationType!
  ) {
    confirmDeskReservation(reservationId: $reservationId, type: $type)
  }
`;

import { gql, useQuery } from '@apollo/client';
import { Moment } from 'moment';
import {
  UseZoneListItemGetZoneById,
  UseZoneListItemQuery,
  UseZoneListItemQueryVariables,
} from './__generated__/useZoneListItemData.generated';

export type ZoneListItemData = UseZoneListItemGetZoneById;

const GET_ZONE_DESK_AVAILABILITY = gql`
  query UseZoneListItem(
    $zoneId: ID!
    $startTime: Date!
    $endTime: Date!
    $userId: Int!
    $amenityIds: [ID!]!
  ) {
    getZoneById(id: $zoneId) {
      id
      filteredDesksByAmenityIds(ids: $amenityIds) {
        id
        state(startTime: $startTime, endTime: $endTime, userId: $userId) {
          availability {
            status
          }
        }
        type
      }
    }
  }
`;

export const useZoneListItemData = (
  zoneId: string,
  currentUserId: string | undefined,
  start: Moment,
  end: Moment
): {
  loading: boolean;
  data: ZoneListItemData | undefined | null;
} => {
  const { data, loading } = useQuery<
    UseZoneListItemQuery,
    UseZoneListItemQueryVariables
  >(GET_ZONE_DESK_AVAILABILITY, {
    variables: {
      zoneId: zoneId,
      amenityIds: [],
      userId: Number(currentUserId),
      startTime: start.format(),
      endTime: end.format(),
    },
    skip: !currentUserId,
  });

  return {
    loading,
    data: data?.getZoneById,
  };
};

import { gql } from '@apollo/client';

//TODO: Do we need to rename these queries for sidebar? They exist in the weekschedule components as well
export const scheduleEmployeeVisitMutation = gql`
  mutation ScheduleEmployeeVisitMutation(
    $employeeId: ID!
    $locationId: ID!
    $start: ISODateTime!
    $end: ISODateTime!
  ) {
    scheduleEmployeeVisitAtLocation(
      employeeId: $employeeId
      locationId: $locationId
      startTime: $start
      endTime: $end
    )
  }
`;

export const cancelEmployeeVisitMutation = gql`
  mutation CancelEmployeeVisitMutation($employeeVisitId: ID!) {
    cancelEmployeeVisit(employeeVisitId: $employeeVisitId)
  }
`;

export const endOrCancelReservationMutation = gql`
  mutation EndOrCancelReservationMutation($reservationId: ID!) {
    endOrCancelReservation(reservationId: $reservationId) {
      reservationId
    }
  }
`;

export const confirmDeskMutation = gql`
  mutation ConfirmDeskReservation(
    $reservationId: ID!
    $type: DeskConfirmationType!
  ) {
    confirmDeskReservation(reservationId: $reservationId, type: $type)
  }
`;

import { Moment } from 'moment';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  GetLevelsByIdsQuery,
  GetLevelsByIdsGetLevelsByIds as Level,
} from './hooks/__generated__/useGetLevels.generated';

import { OrgLocation as Location, useLocationsContext } from '../Location';
import { useGetLevels } from './hooks';

type DeskBookingContextProviderProps = {
  children: React.ReactNode;
};

type DeskBookingContextType = {
  isMapOpen: boolean;
  currentLocation: Location | undefined;
  currentDate: Moment | undefined;
  currentLevel: Level | undefined;
  selectedDeskId: string | undefined;
  levelData: GetLevelsByIdsQuery | undefined;
  openMap: (location: Location | undefined, date: Moment) => void;
  closeMap: () => void;
  setCurrentDate: React.Dispatch<React.SetStateAction<Moment | undefined>>;
  setCurrentLevelId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedDeskId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const DeskBookingContext = createContext<DeskBookingContextType>({
  isMapOpen: false,
  currentLocation: undefined,
  currentDate: undefined,
  currentLevel: undefined,
  selectedDeskId: undefined,
  levelData: undefined,
  openMap: (location: Location | undefined, date: Moment) => null,
  closeMap: () => null,
  setCurrentDate: () => null,
  setCurrentLevelId: () => null,
  setSelectedDeskId: () => null,
});

export const DeskBookingContextProvider = ({
  children,
}: DeskBookingContextProviderProps) => {
  const { preferredLocation } = useLocationsContext();

  const [isMapOpen, setIsMapOpen] = useState<boolean>(false);
  const [currentDate, setCurrentDate] = useState<Moment | undefined>(undefined);
  const [currentLocation, setCurrentLocation] = useState<Location | undefined>(
    preferredLocation
  );
  const getDefaultLevel = (location: Location | undefined) =>
    location?.levels && location.levels.length > 0
      ? location.levels[0].id
      : undefined;

  const [currentLevelId, setCurrentLevelId] = useState(
    getDefaultLevel(currentLocation)
  );
  // All of the level ids from the current location
  const levelIds = useMemo(() => {
    if (currentLocation) {
      return currentLocation?.levels.map(({ id }) => id);
    }

    return [];
  }, [currentLocation]);

  const { data: levelData } = useGetLevels(levelIds);

  const currentLevel = useMemo(() => {
    return levelData?.getLevelsByIds.find((l) => l.id === currentLevelId);
  }, [currentLevelId, levelData?.getLevelsByIds]);

  const [selectedDeskId, setSelectedDeskId] = useState<string | undefined>(
    undefined
  );

  // Open a map for a given location, level, and date
  const openMap = useCallback(
    (location: Location | undefined, date: Moment) => {
      setCurrentLocation(location);
      setCurrentDate(date);
      setCurrentLevelId(getDefaultLevel(location));
      setIsMapOpen(true);
    },
    []
  );

  const closeMap = useCallback(() => {
    setIsMapOpen(false);
    setSelectedDeskId(undefined);
  }, []);

  return (
    <DeskBookingContext.Provider
      value={{
        isMapOpen,
        currentLocation,
        currentDate,
        currentLevel,
        selectedDeskId,
        levelData,
        openMap,
        closeMap,
        setCurrentDate,
        setCurrentLevelId,
        setSelectedDeskId,
      }}
    >
      {children}
    </DeskBookingContext.Provider>
  );
};

export const useDeskBookingContext = (): DeskBookingContextType =>
  useContext(DeskBookingContext);

import { createContext, useContext } from 'react';
import { useIsCalendarConnected } from './hooks/useIsCalendarConnected';

type CalendarContextType = {
  loading: boolean;
  isCalendarEnabled: boolean;
};

const CalendarContext = createContext<CalendarContextType>({
  loading: false,
  isCalendarEnabled: false,
});

export const CalendarProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const { loading, data } = useIsCalendarConnected();

  return (
    <CalendarContext.Provider
      value={{
        loading,
        isCalendarEnabled: data?.doIHaveACalendar || false,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = (): CalendarContextType => {
  return useContext(CalendarContext);
};

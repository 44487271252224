import { useMemo } from 'react';
import { Body07, Heading06 } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import MinorStarFavoriteSolid from '@robinpowered/icons/v2/MinorStarFavoriteSolid';
import { AnonymousAvatar, Avatar } from '../Avatar';
import { Tooltip } from '../../Tooltip';
import { teamsTheme } from '@fluentui/react-northstar';
import { AccountUser } from '../../../types';

type Props = {
  users: AccountUser[];
  anonymousUserCount: number;
  favorites: AccountUser[];
};

export const AvatarList = ({
  users,
  anonymousUserCount = 0,
  favorites,
}: Props): JSX.Element | null => {
  let displayedUsers: AccountUser[] = users as [];

  const publicUserCount = useMemo(() => {
    return displayedUsers?.length || 0;
  }, [displayedUsers]);

  if (!displayedUsers && anonymousUserCount === 0) {
    return null;
  }

  let extraUsers = 0;
  if (publicUserCount + anonymousUserCount > 5) {
    extraUsers = publicUserCount + anonymousUserCount - 5;
    displayedUsers = displayedUsers.slice(0, 5);
  }

  const showExtraUsers = extraUsers > 0;

  const showAnonymousUsers =
    !!anonymousUserCount &&
    anonymousUserCount > 0 &&
    displayedUsers.length < 5 &&
    !showExtraUsers;

  /*
   * Total hack to get our avatar component to display a two digit number. Instead of refactoring this component
   * to a full avatar stack we are using our avatar component which is used for displaying initials. Example, if
   * there are 25 extra users, it will currently display 2 because thats the first "initial" to a single word name.
   * Right now the avatar component interprets "25" as a name so instead we split this into "2 5" so its
   * first name / last name and cap it at 99 so we never have to deal with 3 terms.
   */
  function numberToStringWithSpace(number: number): string {
    if (number > 99) {
      number = 99;
    }

    if (number < 10) {
      return number.toString();
    } else {
      const numberString = number.toString();
      const characters = numberString.split('');
      const stringWithSpace = characters.join(' ');
      return stringWithSpace;
    }
  }

  const isFavorite = (userId: string): boolean => {
    return favorites?.find((fav) => fav.id === userId) !== undefined;
  };

  return (
    <AvatarListWrapper>
      {displayedUsers.map((user) => {
        return user.name ? (
          <AvatarWrapper key={user.id}>
            <AvatarHover>
              <Avatar
                name={user.name}
                src={user.avatar || undefined}
                size={40}
                iconStyles={{
                  right: 0,
                  top: '22px',
                  left: '22px',
                  background:
                    teamsTheme.siteVariables.colorScheme.yellow.background2,
                }}
                icon={
                  isFavorite(user.id) ? (
                    <MinorStarFavoriteSolid
                      width={15}
                      height={15}
                      color={
                        teamsTheme.siteVariables.colorScheme.default.foreground
                      }
                    />
                  ) : null
                }
              />
              <TooltipContainer>
                <Heading06
                  color={
                    teamsTheme.siteVariables.colorScheme.default.background
                  }
                >
                  {user.name}
                </Heading06>
              </TooltipContainer>
            </AvatarHover>
            <AvatarName maxWidth="40px">{user.name?.split(' ')[0]}</AvatarName>
          </AvatarWrapper>
        ) : null;
      })}
      {showAnonymousUsers && (
        <AvatarWrapper>
          <AnonymousAvatar avatarSize={40} iconSize={22} />
          <AvatarName maxWidth="60px">{`Private`}</AvatarName>
        </AvatarWrapper>
      )}
      {showExtraUsers && (
        <AvatarWrapper>
          <Avatar
            name={numberToStringWithSpace(extraUsers)}
            src={undefined}
            size={40}
            iconStyles={{
              right: 0,
              top: '22px',
              left: '22px',
              background:
                teamsTheme.siteVariables.colorScheme.yellow.background1,
            }}
          />
        </AvatarWrapper>
      )}
    </AvatarListWrapper>
  );
};

const AvatarListWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 8px;
  gap: 25px;
  width: 186px;
  height: 142px;
  margin-bottom: 8px;
  margin-top: 4px;
  margin: auto;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AvatarHover = styled.div`
  position: relative;
  @media (hover: hover) {
    &:hover {
      div {
        display: flex;
      }
    }
  }
`;

const TooltipContainer = styled(Tooltip)`
  bottom: calc(100% - 10px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  white-space: nowrap;
`;

const AvatarName = styled(Body07)`
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin-top: 4px;
`;

import React from 'react';
import { Sidebar } from './Sidebar';
import { EventsListContainer } from '../EventsList/EventsListContainer';
import { useSidebarContext } from '../../contexts/Sidebar/SidebarContext';

export const DaySidebar = (): JSX.Element => {
  const { closeSidebar: closeDaysideBar } = useSidebarContext();

  const closeSidebar = () => {
    closeDaysideBar();
  };

  return (
    <Sidebar onClose={closeSidebar} onBack={undefined} title={'Schedule'}>
      <EventsListContainer />
    </Sidebar>
  );
};

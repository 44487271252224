import { gql, useQuery } from '@apollo/client';
import {
  GetLocationByIdQuery,
  GetLocationByIdQueryVariables,
} from './__generated__/useGetLocationById.generated';


const GET_LOCATION_BY_ID = gql`
  query GetLocationById($getLocationByIdId: ID!) {
    getLocationById(id: $getLocationByIdId) {
      id
      name
      address
      levels {
        name
        id
      }
    }
  }
`;

export const useGetLocationById = (locationId?: string) => {
  return useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(
    GET_LOCATION_BY_ID,
    {
      variables: {
        getLocationByIdId: `${locationId}`,
      },
      skip: !locationId,
    }
  );
};

(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("@babel/runtime/helpers/defineProperty"), require("@robinpowered/atlas-common"), require("@babel/runtime/helpers/assertThisInitialized"), require("@babel/runtime/helpers/objectSpread"), require("@babel/runtime/helpers/slicedToArray"), require("@babel/runtime/helpers/classCallCheck"), require("@babel/runtime/helpers/createClass"), require("@babel/runtime/helpers/possibleConstructorReturn"), require("@babel/runtime/helpers/getPrototypeOf"), require("@babel/runtime/helpers/inherits"), require("@babel/runtime/helpers/objectWithoutProperties"), require("@babel/runtime/helpers/toConsumableArray"), require("recompose"), require("maplibre-gl"), require("@babel/runtime/helpers/extends"), require("@babel/runtime/helpers/typeof"), require("react-dom"), require("@babel/runtime/helpers/taggedTemplateLiteral"), require("@emotion/core"), require("xhr"), require("warning"), require("@turf/centroid"), require("maplibre-gl/dist/maplibre-gl.css"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "@babel/runtime/helpers/defineProperty", "@robinpowered/atlas-common", "@babel/runtime/helpers/assertThisInitialized", "@babel/runtime/helpers/objectSpread", "@babel/runtime/helpers/slicedToArray", "@babel/runtime/helpers/classCallCheck", "@babel/runtime/helpers/createClass", "@babel/runtime/helpers/possibleConstructorReturn", "@babel/runtime/helpers/getPrototypeOf", "@babel/runtime/helpers/inherits", "@babel/runtime/helpers/objectWithoutProperties", "@babel/runtime/helpers/toConsumableArray", "recompose", "maplibre-gl", "@babel/runtime/helpers/extends", "@babel/runtime/helpers/typeof", "react-dom", "@babel/runtime/helpers/taggedTemplateLiteral", "@emotion/core", "xhr", "warning", "@turf/centroid", "maplibre-gl/dist/maplibre-gl.css"], factory);
	else if(typeof exports === 'object')
		exports["@robinpowered/atlas-web"] = factory(require("prop-types"), require("react"), require("@babel/runtime/helpers/defineProperty"), require("@robinpowered/atlas-common"), require("@babel/runtime/helpers/assertThisInitialized"), require("@babel/runtime/helpers/objectSpread"), require("@babel/runtime/helpers/slicedToArray"), require("@babel/runtime/helpers/classCallCheck"), require("@babel/runtime/helpers/createClass"), require("@babel/runtime/helpers/possibleConstructorReturn"), require("@babel/runtime/helpers/getPrototypeOf"), require("@babel/runtime/helpers/inherits"), require("@babel/runtime/helpers/objectWithoutProperties"), require("@babel/runtime/helpers/toConsumableArray"), require("recompose"), require("maplibre-gl"), require("@babel/runtime/helpers/extends"), require("@babel/runtime/helpers/typeof"), require("react-dom"), require("@babel/runtime/helpers/taggedTemplateLiteral"), require("@emotion/core"), require("xhr"), require("warning"), require("@turf/centroid"), require("maplibre-gl/dist/maplibre-gl.css"));
	else
		root["@robinpowered/atlas-web"] = factory(root["prop-types"], root["react"], root["@babel/runtime/helpers/defineProperty"], root["@robinpowered/atlas-common"], root["@babel/runtime/helpers/assertThisInitialized"], root["@babel/runtime/helpers/objectSpread"], root["@babel/runtime/helpers/slicedToArray"], root["@babel/runtime/helpers/classCallCheck"], root["@babel/runtime/helpers/createClass"], root["@babel/runtime/helpers/possibleConstructorReturn"], root["@babel/runtime/helpers/getPrototypeOf"], root["@babel/runtime/helpers/inherits"], root["@babel/runtime/helpers/objectWithoutProperties"], root["@babel/runtime/helpers/toConsumableArray"], root["recompose"], root["maplibre-gl"], root["@babel/runtime/helpers/extends"], root["@babel/runtime/helpers/typeof"], root["react-dom"], root["@babel/runtime/helpers/taggedTemplateLiteral"], root["@emotion/core"], root["xhr"], root["warning"], root["@turf/centroid"], root["maplibre-gl/dist/maplibre-gl.css"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__22__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__42__, __WEBPACK_EXTERNAL_MODULE__45__, __WEBPACK_EXTERNAL_MODULE__46__, __WEBPACK_EXTERNAL_MODULE__47__, __WEBPACK_EXTERNAL_MODULE__50__) {
return 
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Moment } from 'moment';
import { Column } from '@robinpowered/design-system';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { InOfficeInfo } from './components/InOfficeInfo';
import { HealthCheckpointButton } from './components/HealthCheckpoint';
import { CheckInButton } from './components/CheckIn';
import { UserContextQueryUser as User } from '../../contexts/User/__generated__/UserContext.generated';
import { getReservationInfo } from '../../utilities/Reservation';
import { isUserInOffice } from '../OfficeDayList/utlity';
import { Location } from '../../contexts/Location/hooks/useOrgLocations';

type Props = {
  currentUser: User | null;
  inOfficeLocation?: Location;
  date: Moment;
  handleCancelDesk: () => void;
  handleCheckIn: () => void;
  isCardDisabled: boolean;
  reservation?: Reservation;
  visit?: Visit;
  isLoading?: boolean;
};

export const SidebarInOfficeStatusCard = ({
  inOfficeLocation,
  date,
  handleCancelDesk,
  handleCheckIn,
  isCardDisabled,
  reservation,
  visit,
  isLoading,
}: Props): JSX.Element => {
  //TODO: This was the old hack that needs to be replaced with preferred location
  //const { setSelectedLocation } = useDeskBookingForm();

  const { hasHealthCheckpoint, healthCheckpointStatus, hasLocalCheckIn } =
    getReservationInfo(reservation);
  const { healthCheckpointComplete, healthCheckpointPending } =
    healthCheckpointStatus;

  const inOffice: boolean = useMemo(
    () => isUserInOffice(visit, reservation),
    [reservation, visit]
  );
  const showHealthCheckpoint = useMemo(
    () => inOffice && healthCheckpointPending,
    [healthCheckpointPending, inOffice]
  );
  const showCheckInButton = useMemo(
    () =>
      inOffice &&
      (healthCheckpointComplete || !hasHealthCheckpoint) &&
      !hasLocalCheckIn,
    [hasHealthCheckpoint, hasLocalCheckIn, healthCheckpointComplete, inOffice]
  );
  return (
    <>
      <InOfficeStatusCardWrapper>
        {inOffice && (
          <InOfficeInfo
            disabled={isCardDisabled}
            inOfficeLocation={inOfficeLocation}
            reservation={reservation}
            date={date}
            handleCancelDesk={handleCancelDesk}
          />
        )}
        {showHealthCheckpoint ? (
          <HealthCheckpointButton reservation={reservation} />
        ) : showCheckInButton ? (
          <CheckInButton
            handleCheckIn={handleCheckIn}
            isLoading={isLoading}
            isCardDisabled={isCardDisabled}
            reservation={reservation}
          />
        ) : null}
      </InOfficeStatusCardWrapper>
    </>
  );
};

const InOfficeStatusCardWrapper = styled(Column)`
  width: 100%;
  gap: 8px;
`;

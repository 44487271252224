import { Flex, Text } from '@fluentui/react-northstar';

export const DetailsRow = ({
  Icon,
  title,
  body,
}: {
  Icon: JSX.Element;
  title?: string;
  body: string;
}) => {
  return (
    <Flex gap="gap.small" vAlign="center">
      {Icon}
      <Flex column>
        {title && <Text weight="bold">{title}</Text>}
        <Text>{body}</Text>
      </Flex>
    </Flex>
  );
};

import {Component} from 'react';
import PropTypes from 'prop-types';
import {
  featureShape,
  isSameFeature,
  shallowEqualObjects
} from '@robinpowered/atlas-common';
import {withMap} from '../Map';

export class FeatureState extends Component {
  static propTypes = {
    /**
     * The feature to set the state for.
     * @see {@link https://docs.mapbox.com/mapbox-gl-js/api/#map#setfeaturestate}
     */
    feature: featureShape.isRequired,
    /**
     * The state to set for the feature.
     */
    state: PropTypes.object.isRequired,
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps) {
    return (
      !shallowEqualObjects(this.props.state, nextProps.state) ||
      !isSameFeature(this.props.feature, nextProps.feature)
    );
  }

  removeFeatureStates(feature, featureStates) {
    // We check for the existence of the map, in case it gets un-set, or is unloading,
    // in which case we'll also be unmounting this component. The `getStyle` check is the
    // only method that reliably tells us if map is loaded or not.
    if (this.props.map && this.props.map.api.loaded()) {
      featureStates.forEach((key) =>
        this.props.map.removeFeatureState(feature, key)
      );
    }
  }

  componentWillUnmount() {
    // When component is about to unmount, remove any feature states this component set.
    this.removeFeatureStates(this.props.feature, Object.keys(this.props.state));
  }

  componentDidUpdate(prevProps) {
    const previousState = this.props.map.getFeatureState(prevProps.feature);
    // Calculate the differences between Mapbox's feature states (source of truth),
    // and the component's feature states, and remove the ones that have become unset or changed.
    const statesToRemove = Object.keys(previousState).reduce(
      (toRemove, key) => {
        if (
          // Ensure that the state existed in the previous props
          // and that it's what the internal datastore has for the prop.
          prevProps.state[key] !== undefined &&
          prevProps.state[key] === previousState[key] && // If feature is the same, but the state prop different in the new props,
          // it should be removed.
          ((prevProps.feature.id === this.props.feature.id &&
            prevProps.feature.source === this.props.feature.source &&
            this.props.state[key] !== previousState[key]) ||
            // If a different feature is given, the feature has to change.
            (prevProps.feature.id !== this.props.feature.id &&
              prevProps.feature.source !== this.props.feature.source))
        ) {
          toRemove.push(key);
        }
        return toRemove;
      },
      []
    );

    // First remove the feature states that we declared previously.
    this.removeFeatureStates(prevProps.feature, statesToRemove);

    this.setFeatureState(this.props.map);
  }

  componentDidMount() {
    this.setFeatureState(this.props.map);
  }

  setFeatureState(map) {
    const {state, feature} = this.props;
    map.setFeatureState(feature, state);
  }

  render() {
    return null;
  }
}

export default withMap(FeatureState);

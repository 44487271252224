import styled from '@emotion/styled';
import { Flex } from '@fluentui/react-northstar';
import { LocationPickerModalButton } from './LocationPickerModal';
import { OrganizationDropdown } from './OrganizationDropdown';
import { WeekPicker } from './WeekPicker';

export const Controls = () => {
  return (
    <ControlWrapper>
      <OrganizationAndLocationRow>
        <OrganizationDropdown />
        <LocationPickerModalButton />
      </OrganizationAndLocationRow>
      <WeekPickerRow>
        <WeekPicker />
      </WeekPickerRow>
    </ControlWrapper>
  );
};

const OrganizationAndLocationRow = styled.div`
  display: flex;
  width: 50%;
  gap: 10%;
  flex: 1 0 auto;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const WeekPickerRow = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-wrap: no-wrap;
  align-items: center;
  @media (max-width: 480px) {
    margin-top: 10px;
    width: 100%;
  }
`;

const ControlWrapper = styled(Flex)`
  flex-direction: row;
  @media (max-width: 480px) {
    flex-direction: column;
    flex-wrap: wrap;
  }
  margin-top: 10px;
  margin-left: 10px;
  gap: 5%;
`;

import { FC } from 'react';
import { Flex, Heading, Body, Colors } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from '@emotion/styled';
import { SpaceScheduleConfiguration } from '../../__generated__/types';
import {
  GetSpaceInfoByIdGetSpaceById,
  GetSpaceInfoByIdReservationPolicies,
} from '../../contexts/SpaceBooking/hooks/__generated__/useGetSpaceById.generated';

type Props = {
  policies: GetSpaceInfoByIdReservationPolicies;
  scheduleConfiguration: GetSpaceInfoByIdGetSpaceById['scheduleConfiguration'];
};

export const SpaceReservationPolicies: FC<Props> = ({
  policies,
  scheduleConfiguration,
}) => {
  const { t } = useTranslation('spaceBooking', {
    keyPrefix: 'space_details.policies',
  });

  const hasPolicies =
    scheduleConfiguration === SpaceScheduleConfiguration.REQUEST_ONLY ||
    policies.abandonedMeetingProtection ||
    policies.strikesForRecurringEvents ||
    policies.maxReservationLength ||
    policies.advanceBookingThreshold;

  if (!hasPolicies) {
    return null;
  }

  // There are more policies like allowRecurringEvents and restrictBookingToWorkingHours,
  // but not mentioning them for parity with dashboard/mobile.
  return (
    <Flex>
      <Heading.Regular style={{ marginBottom: '8px' }}>
        {t('title')}
      </Heading.Regular>

      {scheduleConfiguration === SpaceScheduleConfiguration.REQUEST_ONLY && (
        <>
          <SmallGray80>{t('requires_approval')}</SmallGray80>
          <UnorderedList>
            <SmallGray80 as="li">{t('create_request_to_book')}</SmallGray80>
          </UnorderedList>
        </>
      )}

      {policies.abandonedMeetingProtection && (
        <SmallGray80>{t('enforces_checkins')}</SmallGray80>
      )}

      <UnorderedList>
        {policies.abandonedMeetingProtection && (
          <SmallGray80 as="li">
            {t('abandoned_threshold', {
              duration: moment.duration(policies.abandonedThreshold).humanize(),
            })}
          </SmallGray80>
        )}
        {policies.strikesForRecurringEvents && (
          <SmallGray80 as="li">
            {t('strikes_for_recurring_events', {
              count: policies.strikesForRecurringEvents,
            })}
          </SmallGray80>
        )}

        {policies.maxReservationLength && (
          <SmallGray80 as="li">
            {t('max_reservation_length', {
              duration: moment
                .duration(policies.maxReservationLength)
                .humanize(),
            })}
          </SmallGray80>
        )}

        {policies.advanceBookingThreshold && (
          <SmallGray80 as="li">
            {t('advance_booking_theshold', {
              duration: moment
                .duration(policies.advanceBookingThreshold)
                .humanize(),
            })}
          </SmallGray80>
        )}
      </UnorderedList>
    </Flex>
  );
};

const SmallGray80 = styled(Body.Small)`
  color: ${Colors.Gray80};
`;

const UnorderedList = styled.ul`
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 24px;
  list-style: disc;
`;

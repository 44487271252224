import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import moment from 'moment';
import { useMemo } from 'react';
import {
  DeskConfirmationStatus,
  GetSuggestedSpacesForEventsAlgorithm,
} from '../../../__generated__/types';

import { useEventUpdatePermissionsForEvents } from './useEventUpdatePermissions';
import {
  useSettingsContext,
  useLocationsContext,
  useUserScheduleContext,
} from '../../../contexts';
import {
  GetSuggestedSpacesForEventsQuery,
  GetSuggestedSpacesForEventsQueryVariables,
  GetSuggestedSpacesForEventsSpace,
  GetSuggestedSpacesForEventsSuggestedSpaces,
} from './__generated__/useSuggestedSpacesForEvents.generated';
import { GetMyEventsInTheRangeGetMyEventsInTheRange } from './__generated__/useMyEvents.generated';

type Event = GetMyEventsInTheRangeGetMyEventsInTheRange;

export const GET_SUGGESTED_SPACES_FOR_EVENTS = gql`
  query GetSuggestedSpacesForEvents($input: GetSuggestedSpacesForEventsInput!) {
    getSuggestedSpacesForEvents(input: $input) {
      algorithm
      suggestedSpaces {
        eventId
        space {
          id
          name
          locationId
          levelId
        }
      }
    }
  }
`;

export type SuggestedSpace = GetSuggestedSpacesForEventsSpace;

export type SuggestedSpacesByEventId = Record<string, SuggestedSpace | null>;

export const useSuggestedSpacesForEvents = (
  {
    events,
    algorithm,
  }: {
    events: Event[];
    algorithm?: GetSuggestedSpacesForEventsAlgorithm;
  },
  options: Omit<
    QueryHookOptions<
      GetSuggestedSpacesForEventsQuery,
      GetSuggestedSpacesForEventsQueryVariables
    >,
    'variables'
  > = {}
): QueryResult<
  GetSuggestedSpacesForEventsQuery,
  GetSuggestedSpacesForEventsQueryVariables
> & {
  suggestedSpacesByEventId: null | SuggestedSpacesByEventId;
  suggestedSpaces: GetSuggestedSpacesForEventsSuggestedSpaces[] | null;
  eventsToGetSuggestionsFor: Event[];
} => {
  const { selectedLocation } = useLocationsContext();
  const { data, loading } = useUserScheduleContext();
  const { suggestedSpacesEnabled } = useSettingsContext();

  // TODO: Use space booking context once its created
  // const { hasDismissedSuggestion } = useSpaceBookingForm();
  const hasDismissedSuggestion = (eventId: string) => false;

  const locationIdForSuggestions = selectedLocation?.id || null;
  const doesLocationHaveSpaces = (selectedLocation?.totalSpaces || 0) > 0;

  // In the future, we will want to exclude only those events with spaces in the location
  // we're looking at.  However, since we cannot yet add multiple spaces to events we should
  // skip events with *any* spaces.

  // skip events that already have a space, already ended, are all day, missing times
  const eventsToCheckPermissionsFor = useMemo(
    () =>
      events.filter((event) => {
        if (!event.startTime || !event.endTime) {
          return false;
        }
        const momentEnd = moment(event.endTime);
        const durationInMinutes = momentEnd.diff(
          moment(event.startTime),
          'minutes'
        );
        return (
          event.spaces.length < 1 &&
          // Exclude:
          // * All day events
          // * Events over 12 hours long
          // * Events less than 10 minutes long
          // * Events with no invitees
          // * Events with too many invitees (this is handled by the above since the list gets hidden when too large)
          !event.isAllDay &&
          durationInMinutes < 720 &&
          durationInMinutes > 10 &&
          event.invitees.length >= 2 &&
          momentEnd >= moment()
        );
      }),
    [events]
  );

  const skipPermissionCheck =
    options.skip ||
    !suggestedSpacesEnabled ||
    // no events or they all have a space in the location
    eventsToCheckPermissionsFor.length < 1 ||
    // the following is probably checked transitively by the above condition, but just to be safe...
    !locationIdForSuggestions || // don't have enough context for meaningful suggestions
    !doesLocationHaveSpaces; // impossible to suggest any spaces if they don't exist

  const { isPermittedByEventId } = useEventUpdatePermissionsForEvents(
    { events: eventsToCheckPermissionsFor },
    { skip: skipPermissionCheck }
  );

  const eventsToGetSuggestionsFor = eventsToCheckPermissionsFor.filter(
    (event) => isPermittedByEventId?.[event.id]
  );

  const startTime = useMemo(() => {
    const epochs = eventsToGetSuggestionsFor.flatMap((e) =>
      e.startTime ? [moment(e.startTime).toDate().getTime()] : []
    );
    return epochs.length > 0 ? moment(Math.min(...epochs)) : null;
  }, [eventsToGetSuggestionsFor]);

  const { getUserInOfficeDataByDay } = data || {};
  const levelIds = useMemo(
    () =>
      getUserInOfficeDataByDay
        ?.find((officeData) =>
          moment(officeData.date).isSame(startTime, 'date')
        )
        ?.userInOffice?.deskReservations.filter(
          (reservation) =>
            locationIdForSuggestions === reservation.seat.location.id &&
            reservation.confirmation?.status !== DeskConfirmationStatus.DECLINED
        )
        .map((reservation) => reservation.seat.level?.id)
        .filter((id): id is string => !!id),
    [getUserInOfficeDataByDay, startTime, locationIdForSuggestions]
  );

  const skip =
    skipPermissionCheck ||
    // permission check still loading
    !isPermittedByEventId ||
    // no events with permission to update
    eventsToGetSuggestionsFor.length < 1 ||
    // while this is loading we don't know which level ID to prefer
    loading;

  const eventsToGetSuggestionsForInput = useMemo(() => {
    return eventsToGetSuggestionsFor.map(
      ({ id, startTime, endTime, whosInData, invitees }) => {
        const whosInUserIdsSet = new Set(
          whosInData.whosIn?.flatMap((data) =>
            data.userId ? [data.userId] : []
          )
        );
        const preferredSpaceCapacity = invitees.reduce((sum, invitee) => {
          if (
            invitee.user?.id &&
            whosInUserIdsSet.has(invitee.user.id) &&
            invitee.responseStatus !== 'DECLINED'
          ) {
            return sum + 1;
          }
          return sum;
        }, 0);

        return {
          id,
          preferredSpaceCapacity: preferredSpaceCapacity || null,
          startTime,
          endTime,
        };
      }
    );
  }, [eventsToGetSuggestionsFor]);

  // note that apollo client has a special typepolicy for this query in ApolloContext
  const results = useQuery<
    GetSuggestedSpacesForEventsQuery,
    GetSuggestedSpacesForEventsQueryVariables
  >(GET_SUGGESTED_SPACES_FOR_EVENTS, {
    ...options,
    skip,
    variables: {
      input: {
        spaceFilters: {
          locationIds: locationIdForSuggestions
            ? [locationIdForSuggestions]
            : [],
        },
        spacePreferences: {
          levelIds: levelIds && levelIds.length > 0 ? levelIds : null,
        },
        algorithm,
        events: eventsToGetSuggestionsForInput,
      },
    },
  });

  const suggestedSpaces = useMemo(() => {
    return (
      results.data?.getSuggestedSpacesForEvents.suggestedSpaces.filter(
        (suggestedSpace) =>
          suggestedSpace.space !== null &&
          !hasDismissedSuggestion(suggestedSpace.eventId)
      ) ?? null
    );
  }, [results, hasDismissedSuggestion]);

  const suggestedSpacesByEventId = useMemo(
    () =>
      suggestedSpaces?.reduce<SuggestedSpacesByEventId>((acc, suggestion) => {
        acc[suggestion.eventId] = suggestion.space;
        return acc;
      }, {}) ?? null,
    [suggestedSpaces]
  );

  return {
    ...results,
    suggestedSpaces,
    suggestedSpacesByEventId,
    eventsToGetSuggestionsFor,
  };
};

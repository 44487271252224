import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Flex,
  Column,
  Colors,
  SpinnerLoader,
  Heading,
  Banner,
} from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useMyEvents } from './graphql/useMyEvents';
import {
  useCalendarContext,
  useEventsListContext,
  useLocationsContext,
  useUserContext,
  useUserScheduleContext,
} from '../../contexts';
import { uniq } from 'lodash';
import { useSpaces } from './graphql/useSpaces';
import { EventsListLoader } from './EventsListLoader';
import { SidebarError } from '../Sidebar';
import { EventListSummaryContainer } from './EventListSummaryContainer';
import { useSuggestedSpacesForEvents } from './graphql/useSuggestedSpacesForEvents';
import { useEventUpdatePermissionsForEvents } from './graphql/useEventUpdatePermissions';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';
import {
  GetUserScheduleDeskReservations as DeskReservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { DeskConfirmationStatus } from '../../__generated__/types';
import { isUserInOffice } from '../OfficeDayList/utlity';
import { SidebarInOfficeStatusCardContainer } from '../InOfficeStatusCardContainer';
import { isBeforeToday } from '../../utilities';
import { useSidebarContext } from '../../contexts/Sidebar';
import { teamsTheme } from '@fluentui/react-northstar';
import { SidebarEmptyState } from '../Sidebar/SidebarEmptyState';
import { ConnectCalendarCallToAction } from './ConnectCalendarCallToAction';
import { WarningSolid } from '@robinpowered/icons';
import { keyframes } from '@emotion/core';

const TOAST_TIMER = 4;

const CurrentTimeLine: React.FC = () => {
  return (
    <CurrentTimeLineContainer>
      <RedBall></RedBall>
      <RedLine></RedLine>
    </CurrentTimeLineContainer>
  );
};

export const EventsListContainer = (): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { currentUser } = useUserContext();
  const { data: userScheduleData } = useUserScheduleContext();
  const {
    totalSpacesBookedSuccessfully,
    totalBookAllSpacesUnsuccessful,
    dispatchUpdateSpaceBookingState,
    bookSuggestedSpace,
    spaceBookingState,
  } = useEventsListContext();

  const { selectedLocation } = useLocationsContext();
  const [deskReservations, setDeskReservations] = useState<
    DeskReservation[] | undefined
  >(undefined);
  const [userVisits, setUserVisits] = useState<Visit[] | null>(null);

  const { daySidebarDate: date } = useSidebarContext();
  const { data, loading: loadingEvents, error: eventsError } = useMyEvents(100);

  const [showingErrorBanner, setShowErrorBanner] = useState(false);

  useEffect(() => {
    if (
      spaceBookingState.bookingAllSuggestedSpaces ||
      totalBookAllSpacesUnsuccessful < 1
    )
      return;
    setShowErrorBanner(true);
    const timer = setTimeout(() => {
      setShowErrorBanner(false);
    }, 1000 * TOAST_TIMER);

    return () => {
      clearTimeout(timer);
    };
  }, [spaceBookingState, totalBookAllSpacesUnsuccessful]);

  useEffect(() => {
    const userDay = userScheduleData?.getUserInOfficeDataByDay?.find((day) => {
      return moment(date).isSame(day.date, 'day');
    });

    const deskReservations = userDay?.userInOffice?.deskReservations?.filter(
      (reservation) =>
        selectedLocation?.id === reservation.seat.location.id &&
        reservation.confirmation?.status !== DeskConfirmationStatus.DECLINED &&
        moment().isBefore(reservation.endTime)
    );

    setUserVisits(
      userDay?.userInOffice ? userDay.userInOffice.employeeVisits : null
    );

    setDeskReservations(deskReservations);
  }, [date, selectedLocation, userScheduleData?.getUserInOfficeDataByDay]);

  // Add amplitude tracking
  //const { trackEvent } = useAmplitude();

  const events: Event[] | undefined = useMemo(() => {
    return data?.getMyEventsInTheRange.filter((event) => {
      return moment(event.endTime).isSame(date, 'd');
    });
  }, [data, date]);

  const { isPermittedByEventId } = useEventUpdatePermissionsForEvents({
    events: events || [],
  });

  const {
    suggestedSpacesByEventId,
    suggestedSpaces,
    // silently fail, lack of suggestions is not the end of the world
  } = useSuggestedSpacesForEvents(
    { events: events || [] },
    // important: this comes *after* larger permission check above so subset of events hits cache
    { skip: !isPermittedByEventId }
  );

  const { loading: calendarLoading, isCalendarEnabled } = useCalendarContext();

  const loading = loadingEvents || calendarLoading;
  const error = eventsError;

  const eventSpaceIds = useMemo(() => {
    const arr =
      events?.flatMap((event) => event.spaces.map(({ id }) => id)) ?? [];
    return uniq(arr);
  }, [events]);

  const { spacesById } = useSpaces(eventSpaceIds);

  const amIInOffice = useMemo(() => {
    return !!events?.find((event) => {
      const me = event.whosInData.whosIn?.find(
        (who) => who.userId === currentUser?.id
      );
      return me ? true : false;
    });
  }, [currentUser?.id, events]);

  const showBookAllButton = useMemo(() => {
    return (
      suggestedSpaces &&
      suggestedSpaces?.length > 0 &&
      totalSpacesBookedSuccessfully < suggestedSpaces?.length
    );
  }, [suggestedSpaces, totalSpacesBookedSuccessfully]);

  const bookAllSuggestedSpaces = useCallback(async () => {
    if (!events || !suggestedSpacesByEventId) return;
    //trackEvent(AmplitudeEvents.CLICKED_BOOK_ALL_SUGGESTED_SPACES);
    dispatchUpdateSpaceBookingState({
      type: 'SET_BOOKING_STATUS',
      payload: true,
    });
    await Promise.all(
      events.map((event) => {
        if (suggestedSpacesByEventId[event.id]) {
          return bookSuggestedSpace(
            event,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            suggestedSpacesByEventId[event.id]!.id,
            t
          );
        }
        return null;
      })
    );
    dispatchUpdateSpaceBookingState({
      type: 'SET_BOOKING_STATUS',
      payload: false,
    });
  }, [
    events,
    suggestedSpacesByEventId,
    dispatchUpdateSpaceBookingState,
    bookSuggestedSpace,
    t,
  ]);

  const shouldShowRedLine = useMemo(() => {
    if (events)
      return (
        events[0]?.startTime && moment(events[0]?.startTime).isBefore(moment())
      );
  }, [events]);

  const redLineEvent = useMemo(() => {
    return events?.find((event) => {
      if (event.isAllDay || moment(event.endTime).isBefore(moment()))
        return false;
      return moment().isBefore(event.endTime);
    });
  }, [events]);

  const isDisabled = useMemo(() => {
    return isBeforeToday(moment(date));
  }, [date]);

  const showDesk = useCallback(
    (reservation: DeskReservation) => {
      return isUserInOffice(
        userVisits ? userVisits[0] : undefined,
        reservation
      );
    },
    [userVisits]
  );

  const showDesks = useMemo(() => {
    if (!deskReservations) return false;
    return deskReservations.some((reservation) => showDesk(reservation));
  }, [showDesk, deskReservations]);

  return (
    <Flex width="100%" height="100%" overflowY="auto" overflowX="hidden">
      {loading ? (
        <>
          <EventsListLoader />
        </>
      ) : error ? (
        <SidebarError />
      ) : isCalendarEnabled ? (
        events && events.length > 0 ? (
          <>
            {showingErrorBanner && (
              <SideBarToast totalSpaceErrors={totalBookAllSpacesUnsuccessful} />
            )}
            {showDesks && (
              <>
                <SectionHeader>{t('events.desks')}</SectionHeader>
                {deskReservations?.map((reservation) => (
                  <>
                    {showDesk(reservation) && (
                      <Card key={reservation.id}>
                        <SidebarInOfficeStatusCardContainer
                          reservation={reservation}
                          visit={userVisits ? userVisits?.[0] : undefined}
                          date={moment(date)}
                          disabled={isDisabled}
                        />
                      </Card>
                    )}
                  </>
                ))}
              </>
            )}
            <SectionHeader>{t('events.spaces')}</SectionHeader>
            <EventsContainer>
              {events.map((event) => {
                const spaceId = event.spaces[0]?.id;
                const showRedLine = redLineEvent === event && shouldShowRedLine;
                return (
                  <div key={event.id}>
                    {showRedLine && <CurrentTimeLine />}
                    <EventListSummaryContainer
                      key={event.id}
                      event={event}
                      existingSpace={spacesById?.[spaceId]}
                      loadingSuggestions={false}
                      suggestedSpace={
                        suggestedSpacesByEventId?.[event.id] ?? null
                      }
                      permittedToUpdateEvent={
                        isPermittedByEventId?.[event.id] ?? null
                      }
                      amIInOffice={amIInOffice}
                    />
                  </div>
                );
              })}
            </EventsContainer>
            {showBookAllButton && (
              <BookAllContainer
                width="100%"
                p={'16px'}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  transition: 'opacity 0.7s',
                }}
              >
                {
                  <BookAllButton
                    iconLeft={() => (
                      <SpinnerLoader
                        style={{
                          color: Colors.White0,
                          marginRight: '4px',
                        }}
                      />
                    )}
                    variant="secondary"
                    disabled={spaceBookingState.bookingAllSuggestedSpaces}
                    onClick={bookAllSuggestedSpaces}
                  >
                    {t('events.book_all_suggested_spaces')}
                  </BookAllButton>
                }
              </BookAllContainer>
            )}
          </>
        ) : (
          <Column mt="30px" alignItems="center" width="100%">
            <SidebarEmptyState byLine={t('events.no_events')} />
          </Column>
        )
      ) : (
        <ConnectCalendarCallToAction />
      )}
    </Flex>
  );
};

type ErrorToastProps = {
  totalSpaceErrors: number;
  toastType?: string | null;
};

//TODO: Bring back toast
const SideBarToast: FC<ErrorToastProps> = ({ totalSpaceErrors }) => {
  const { t } = useTranslation('sidebar');
  const message =
    totalSpaceErrors > 1
      ? t('events.space_booking_error.banner_plural', {
          totalErrors: totalSpaceErrors,
        })
      : t('events.space_booking_error.banner_singular', {
          totalErrors: totalSpaceErrors,
        });

  if (totalSpaceErrors < 1) return null;

  return (
    <Flex
      width="100%"
      p={'16px 10px 0px 10px'}
      justifyContent="center"
      alignItems={'center'}
      style={{ position: 'absolute' }}
    >
      {/* Add toast back */}
      <ErrorToast
        message={message}
        icon={WarningSolid}
        center={true}
        style={{ backgroundColor: Colors.Yellow100 }}
      />
    </Flex>
  );
};

const fadeDown = keyframes`
  from {
    opacity: 0;
    top: -100px;
  }
  to {
    opacity: 1;
    top: 1px;
  }
`;

const ErrorToast = styled(Banner)`
  position: relative;
  width: 100%;
  opacity: 0;
  animation: ${fadeDown};
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.65, -0.25, 0.38, 1.19);
  animation-fill-mode: forwards;
  z-index: 1;
`;

const CurrentTimeLineContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-bottom: 6px;
  margin-top: -6px;
  margin: 0 16px;
`;

const RedBall = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: ${Colors.RedProduct};
`;
const RedLine = styled.div`
  height: 2px;
  flex: 1px;
  background-color: ${Colors.RedProduct};
  margin-right: 1px;
`;

const EventsContainer = styled.div`
  margin-bottom: 100px;
`;

const BookAllContainer = styled(Flex)`
  margin-top: 30px;
  border-top: 1px solid ${Colors.Gray10};
  background-color: ${Colors.White0};
  transition: opacity 0.7s;
  position: absolute;
  bottom: 0px;
`;

const BookAllButton = styled(Button)`
  width: 100%;
  color: ${Colors.White0};
  background-color: ${teamsTheme.siteVariables.colorScheme.brand.background};
  border: 4px;

  &:hover {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
    color: ${Colors.White0};
  }

  &:focus,
  &:focus-visible,
  &:active {
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
    color: ${Colors.White0};
  }
`;

const SectionHeader = styled(Heading.Small)`
  width: calc(100% - 20px);
  margin: 12px;
`;

const Card = styled.div`
  border: 1px solid ${Colors.Gray30};
  border-radius: 8px;
  padding: 6px;
  background-color: white;
  margin: 12px;
`;

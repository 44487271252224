import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import {withMap} from '../Map';

export class Container extends Component {
  static propTypes = {
    /**
     * Anchor point coordinates, at which the container will be attached to.
     */
    anchor: PropTypes.arrayOf(PropTypes.number).isRequired,
    /**
     * Children to show in the container.
     */
    children: PropTypes.node,
    /**
     * The position of the anchor.
     */
    position: PropTypes.string.isRequired,
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  state = {
    x: null,
    y: null
  };

  componentWillUnmount() {
    this.props.map.off('zoom', this.zoomListener);
    this.props.map.off('move', this.zoomListener);
  }

  componentDidMount() {
    // Listen for changes in potential map position as well as zoom level.
    // Both of these events require us to recalculate the position of the attached group.
    this.props.map.on('zoom', this.zoomListener);
    this.props.map.on('move', this.zoomListener);
  }

  componentDidUpdate() {
    this.updateElementPosition();
  }

  getAnchorPositionCoordinates() {
    const rect = this.element.getBoundingClientRect();
    const {x, y} = this.props.map.project(this.props.anchor);
    const width = rect.width;
    const height = rect.height;

    switch (this.props.position) {
      case 'center':
        return {
          x: x - width / 2,
          y: y - height / 2
        };
      case 'bottom-left':
        return {
          x: x - width,
          y
        };
      case 'bottom-right':
        return {
          x,
          y
        };
      case 'top-left':
        return {
          x: x - width,
          y: y - height
        };
      case 'top-right':
        return {
          x,
          y: y - height
        };
      case 'right':
        return {
          x,
          y: y - height / 2
        };
      case 'left':
        return {
          x: x - width,
          y: y - height / 2
        };
      case 'top':
        return {
          x: x - width / 2,
          y: y - height
        };
      case 'bottom':
      default:
        return {
          x: x - width / 2,
          y
        };
    }
  }

  updateElementPosition() {
    const {x, y} = this.getAnchorPositionCoordinates();

    if (this.state.x !== x || this.state.y !== y) {
      this.setState({x, y});
    }
  }

  zoomListener = () => {
    this.updateElementPosition();
  };

  setupRef = (ref) => {
    if (ref) {
      this.element = ref;
      this.updateElementPosition();
    }
  };

  render() {
    // We're creating a React portal, which "portals" into the mapbox's control container.
    // This enables us to "natively" attach arbitrary DOM content anywhere we want.
    return createPortal(
      React.cloneElement(this.props.children, {
        // Override the style prop of the component. This allows us to tell it where to position itself
        // in regards to the mapbox's container. Since we're using `style` prop,
        // it allows us to pass this prop down until it hits the first actual DOM node that will be attached.
        // While it's possible for this to wrap the given chilren in our own DOM node,
        // That would lead to us creating more DOM nodes + harder to fully control the actual element shown on the
        // map.
        style:
          typeof this.state.x === 'number' && typeof this.state.y === 'number'
            ? {
                ...(this.props.children.props
                  ? this.props.children.props.style
                  : null),
                // Overrite the styles.
                position: 'absolute',
                // @TODO: we should consider looking into child's style too, and if we find these
                // props in there too, calculate the total value. This would allow the child to
                // use CSS styles to position itself (i.e. slight offset from default.)
                left: this.state.x,
                top: this.state.y
              }
            : this.props.children.style,
        // Getting the ref allows us to get the container's dimensions,
        // Thus adjusting the CSS styles.
        // This also proxies the ref to the children itself if they specified the prop.
        ref: (node) => {
          this.setupRef(node);
          const {ref} = this.props.children;
          if (typeof ref === 'function') {
            ref(node);
          }
        }
      }),
      this.props.map.api._controlContainer
    );
  }
}

export default withMap(Container);

import { SpaceStateWithoutMouseFeaturesType } from './spaceStateType';
import { Availability } from '../../../__generated__/types';
import { MapSpaceStateForLevelSpaces } from './__generated__/useSpaceStateQuery.generated';

/**
 * Function maps space state to map state type
 * @param space Space availability
 * @returns Map state
 */
export function mapSpaceStateToMapType(
  space: MapSpaceStateForLevelSpaces | null | undefined,
  meetsFilterCriteria: boolean
): SpaceStateWithoutMouseFeaturesType {
  const result: SpaceStateWithoutMouseFeaturesType = {
    restricted: false,
    reduced: false,
    available: false,
    disabled: false,
    booked: false,
    inUse: false,
    warning: false,
  };

  if (!space) {
    return result;
  }

  if (space.isDisabled) {
    result.disabled = true;
  }

  // space is either on-demand (must book from tablet) or requires admin approval
  if (!space.calendar?.calendarId || space.isManaged || !meetsFilterCriteria) {
    result.restricted = true;
  }

  const firstAvailability = space.windowedAvailability[0];

  switch (firstAvailability?.status) {
    case Availability.AVAILABLE:
      result.available = true;
      break;
    case Availability.BOOKED:
      result.booked = true;
      break;
    case Availability.UNAVAILABLE:
      result.inUse = true;
      break;
    case Availability.UNKNOWN:
      result.disabled = true;
      break;
    default:
      break;
  }
  return result;
}

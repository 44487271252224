import { Moment } from 'moment';
import { OrgLocation, useSettingsContext } from '../../contexts';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { JoinOffice } from './JoinOffice/JoinOffice';
import { BookDeskButton } from './BookDeskButton';
import { OfficeStatusInfo } from './OfficeStatus/OfficeStatusInfo';
import { useMemo } from 'react';
import { isUserInOffice } from './utlity';
import { getReservationInfo } from '../../utilities/Reservation';
import { HealthCheckpointButton } from './HealthCheckPoint';
import { CheckInButton } from './CheckIn';

type InOfficeCardProps = {
  inOfficeLocation: OrgLocation | undefined;
  date: Moment;
  visit: Visit | undefined;
  reservation: Reservation | undefined;
  disabled: boolean;
  joinOffice: (location: OrgLocation) => Promise<void>;
  cancelReservation: (reservationId: string) => Promise<void>;
  openMap: (loc: OrgLocation, date: Moment) => void;
  handleCheckIn: () => Promise<void>;
  isSubmitting: boolean;
  setIsSubmitting: (val: boolean) => void;
};

export const InOfficeCard = ({
  inOfficeLocation,
  date,
  visit,
  reservation,
  disabled,
  openMap,
  joinOffice,
  cancelReservation,
  handleCheckIn,
  isSubmitting,
  setIsSubmitting,
}: InOfficeCardProps) => {
  const { teamsSpaceBookingEnabled } = useSettingsContext();

  const { healthCheckpointStatus, hasHealthCheckpoint, hasLocalCheckIn } =
    getReservationInfo(reservation);

  const userInOffice = useMemo(
    () => isUserInOffice(visit, reservation),
    [visit, reservation]
  );

  const showBookDeskButton = useMemo(
    () => reservation === undefined,
    [reservation]
  );

  const showHealthCheckpoint = useMemo(() => {
    return (
      inOfficeLocation !== undefined &&
      healthCheckpointStatus.healthCheckpointPending &&
      !showBookDeskButton
    );
  }, [
    inOfficeLocation,
    healthCheckpointStatus.healthCheckpointPending,
    showBookDeskButton,
  ]);

  const showCheckInButton = useMemo(() => {
    return (
      inOfficeLocation !== undefined &&
      (healthCheckpointStatus.healthCheckpointComplete ||
        !hasHealthCheckpoint) &&
      !hasLocalCheckIn &&
      !showHealthCheckpoint
    );
  }, [
    inOfficeLocation,
    healthCheckpointStatus.healthCheckpointComplete,
    hasHealthCheckpoint,
    hasLocalCheckIn,
    showHealthCheckpoint,
  ]);

  return (
    <>
      <JoinOffice
        date={date}
        loading={isSubmitting}
        visit={visit}
        reservation={reservation}
        disabled={disabled}
        setIsSubmitting={setIsSubmitting}
        inOfficeLocation={inOfficeLocation}
        userInOffice={userInOffice}
        joinOffice={joinOffice}
        cancelReservation={cancelReservation}
      />

      {userInOffice && !teamsSpaceBookingEnabled && (
        <OfficeStatusInfo
          loading={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          inOfficeLocation={inOfficeLocation}
          reservation={reservation}
          disabled={disabled}
          cancelReservation={cancelReservation}
        />
      )}

      {showBookDeskButton && (
        <BookDeskButton
          disabled={disabled}
          visit={visit}
          date={date}
          onBookDeskButtonClick={openMap}
        />
      )}

      {reservation && showHealthCheckpoint && !teamsSpaceBookingEnabled && (
        <HealthCheckpointButton reservation={reservation} />
      )}

      {reservation && showCheckInButton && !teamsSpaceBookingEnabled && (
        <CheckInButton
          reservation={reservation}
          disabled={disabled}
          isLoading={isSubmitting}
          handleCheckIn={handleCheckIn}
        />
      )}
    </>
  );
};

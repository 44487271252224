import React, {Fragment, memo, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withLayers} from '@robinpowered/atlas-common';
import LayerEventType from '../../constants/layerEventType';
import getEventToCallbackFromProps from '../../utils/getEventToCallbackFromProps';
import CursorStyle from '../CursorStyle';
import LayerEvents from '../LayerEvents';
import {useLayerFeatureStates} from '../../hooks';
import {withMap} from '../Map';

export function AtlasStatusBoards({map, layers, ...restOfProps}) {
  const [hoveredFeatureId, setHoveredFeatureId] = useState(null);

  const eventsToCallbacks = useMemo(() => {
    const eventsToCallbacks = getEventToCallbackFromProps(restOfProps);
    return {
      ...eventsToCallbacks,
      [LayerEventType.MOUSEMOVE]: (feature) => setHoveredFeatureId(feature.id),
      [LayerEventType.MOUSEOUT]: () => setHoveredFeatureId(null)
    };
  }, Object.values(restOfProps));

  function getStateFromFeature(feature) {
    const states = {
      'show-label': feature.id === hoveredFeatureId
    };
    return states;
  }

  const reloadFeatureStates = useLayerFeatureStates({
    map,
    layers,
    getStateFromFeature
  });

  useEffect(() => reloadFeatureStates(), [hoveredFeatureId]);

  return (
    <Fragment>
      <LayerEvents
        map={map}
        layers={layers}
        eventsToCallbacks={eventsToCallbacks}
      />
      {hoveredFeatureId && restOfProps.onClick ? (
        <CursorStyle cursor="pointer" />
      ) : null}
    </Fragment>
  );
}

function arePropsEqual(prevProps, nextProps) {
  return prevProps.onClick === nextProps.onClick;
}

AtlasStatusBoards.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.string),
  map: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default memo(
  compose(withMap, withLayers('status_boards'))(AtlasStatusBoards),
  arePropsEqual
);

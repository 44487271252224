import { useCallback } from 'react';
import { Moment } from 'moment';
import { useMutation } from '@apollo/client';
import { endOrCancelReservationMutation, confirmDeskMutation } from './queries';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { useTranslation } from 'react-i18next';
import { SidebarInOfficeStatusCard } from './SidebarInOfficeStatusCard';
import {
  useLocationsContext,
  useToast,
  useUserContext,
  useUserScheduleContext,
  useWhosInContext,
} from '../../contexts';
import { useMyEvents } from '../../contexts/SpaceBooking/hooks/useMyEvents';
import { DeskConfirmationType } from '../../__generated__/types';

type Props = {
  date: Moment;
  visit?: Visit;
  reservation?: Reservation;
  disabled?: boolean;
};

export const SidebarInOfficeStatusCardContainer = ({
  date,
  visit,
  reservation,
  disabled,
}: Props): JSX.Element => {
  const { t } = useTranslation(['errorMessage', 'weekSchedule']);
  const { success, error } = useToast();

  //const { trackEvent } = useAmplitude();

  const { currentUser } = useUserContext();
  const { locations } = useLocationsContext();

  const inOfficeLocation = locations[0];

  const { refetch: refetchUserSchedule } = useUserScheduleContext();
  const { refetch: refetchWhosIn } = useWhosInContext();

  //TODO: Should this be brought into a different context? Currently using space booking context
  const { refetch: refetchEvents } = useMyEvents(100);

  const handleError = () => error(t('errorMessage:try_again'));
  const [cancelReservation] = useMutation(endOrCancelReservationMutation, {
    onError: handleError,
    onCompleted: () =>
      success(t('weekSchedule:officeDay.visitCard.check_in.status_updated')),
  });
  const [checkIn] = useMutation(confirmDeskMutation, {
    onError: handleError,
  });

  const handleCancelDesk = useCallback(async () => {
    if (reservation && refetchUserSchedule && refetchWhosIn) {
      await cancelReservation({
        variables: { reservationId: reservation.id },
      });

      await refetchUserSchedule();
      await refetchWhosIn();
      await refetchEvents();
    }
  }, [
    cancelReservation,
    refetchEvents,
    refetchUserSchedule,
    refetchWhosIn,
    reservation,
  ]);

  const handleCheckIn = useCallback(async () => {
    if (reservation && refetchUserSchedule) {
      await checkIn({
        variables: {
          reservationId: reservation.id,
          type: DeskConfirmationType.TEAMS,
        },
      });
      await refetchUserSchedule();
      // trackEvent(AmplitudeEvents.CHECK_INTO_RESERVATION, {
      //   daysAhead: date.clone().diff(today, 'days'),
      //   checkInType: 'remote',
      //   date: moment(),
      //   localCheckInType: null,
      //   originScreen: 'schedule_week',
      //   reservationId: reservation.id,
      //   reservationType: reservation.type,
      //   scenario: 'successful checkin',
      //   seatId: reservation.seat.id,
      // });
    }
  }, [checkIn, refetchUserSchedule, reservation]);

  return (
    <SidebarInOfficeStatusCard
      reservation={reservation}
      visit={visit}
      inOfficeLocation={inOfficeLocation}
      currentUser={currentUser}
      date={date}
      handleCancelDesk={handleCancelDesk}
      handleCheckIn={handleCheckIn}
      isCardDisabled={!!disabled}
    />
  );
};

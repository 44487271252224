import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  Map,
  AtlasSeats,
  AtlasSpaces,
  LayerFeatures,
  withMap,
} from '@robinpowered/atlas-web/';
import { withLayers } from '@robinpowered/atlas-common';
import { MapRef } from '@robinpowered/atlas-web';
import { MapboxFeature } from '@robinpowered/atlas-common';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Body05, Heading03, SpinnerLoader } from '@robinpowered/design-system';
import type { SpaceMapAvailabilityStates } from '../../../contexts/SpaceBooking/hooks/useSpaceStateQuery';
import { useTranslation } from 'react-i18next';
import { config } from '../../../config';
import { WorkAreasProvider } from '../WorkAreas/WorkAreasContext';
import { WorkAreas } from '../WorkAreas/WorkAreas';
import { useApolloContext } from '../../../contexts/ApolloContext';
import {
  DeskSearchParams,
  useDeskAvailability,
} from '../../../contexts/DeskBooking/hooks';

type Props = {
  style?: React.CSSProperties;
  levelId: string;
  userId: string;
  visible?: boolean;
  interactive?: boolean;
  theme?: string;
  zoom?: number;
  deskSearch?: DeskSearchParams;
  showMap?: boolean | null;
  selectedDeskId?: string;
  selectedSpaceId?: string;
  spaceAvailabilityStates?: SpaceMapAvailabilityStates;
  onSelectDesk?: (deskId: string) => void;
  onSelectSpace?: (spaceId: string) => void;
};
type RequestTransformer = (url: string) => {
  url: string;
  headers?: { Authorization: string };
};

const useMapLoadingPerf = (mapRef: React.RefObject<MapRef>) => {
  const loadingStart = useRef(Date.now());
  const [mapLoading, setMapLoading] = useState(true);

  useEffect(() => {
    if (!mapRef || !mapRef?.current) {
      return;
    }
    const mapApi = mapRef?.current?.map;
    const handleMapLoad = () => setMapLoading(false);

    mapApi.on('load', handleMapLoad);
    return () => {
      mapApi.off('load', handleMapLoad);
    };
  }, [mapRef]);

  useEffect(() => {
    if (mapLoading) {
      // eslint-disable-next-line no-console
      console.time('Map loaded');
    } else {
      // eslint-disable-next-line no-console
      console.timeEnd('Map loaded');
    }
  }, [loadingStart, mapLoading]);
};

const SpaceDisabler = withMap(
  withLayers('spaces')((sources) => (
    <LayerFeatures specifier={sources.layers}>
      {({ features }: { features: MapboxFeature<{ ownerId: number }>[] }) => (
        <AtlasSpaces
          disabledIds={features.map((feature) => feature.properties.ownerId)}
        />
      )}
    </LayerFeatures>
  ))
);

export const SpaceOfficeMap: React.FC<Props> = ({
  style,
  userId,
  levelId,
  visible = true,
  interactive = true,
  theme = 'light',
  zoom = 3,
  showMap = true,
  deskSearch,
  selectedDeskId,
  selectedSpaceId,
  spaceAvailabilityStates,
  onSelectDesk,
  onSelectSpace,
}) => {
  const mapRef = useRef<MapRef>(null);
  const { t } = useTranslation('spaceBooking');
  const { jwt } = useApolloContext();
  const { atlasServiceUrl } = config;
  // Triggering the map resizing event forces the map to redraw and correctly fill the container.
  useEffect(() => {
    if (visible) {
      mapRef?.current?.api?.resize();
    }
  }, [visible, mapRef]);

  // Logging map load time to benchmark optimization changes.
  if (config.env === 'development') {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMapLoadingPerf(mapRef);
  }

  // TODO: move to DeskBookingModal so this doesn't get called in SpaceBookingModal
  const {
    availabilityStates: deskAvailabilityStates,
    seatsToAvatars,
    loading,
  } = useDeskAvailability(userId, levelId, {
    params: deskSearch,
    visible,
  });

  const handlePressDesk = useCallback(
    (deskFeature) => {
      onSelectDesk && onSelectDesk(String(deskFeature?.properties.ownerId));
    },
    [onSelectDesk]
  );

  const handlePressSpace = useCallback(
    (spaceFeature) => {
      onSelectSpace && onSelectSpace(String(spaceFeature?.properties.ownerId));
    },
    [onSelectSpace]
  );

  const selectedDeskIds = useMemo(
    () =>
      typeof selectedDeskId !== 'undefined' ? [Number(selectedDeskId)] : [],
    [selectedDeskId]
  );

  const selectedSpaceIds = useMemo(
    () =>
      typeof selectedSpaceId !== 'undefined' ? [Number(selectedSpaceId)] : [],
    [selectedSpaceId]
  );

  if (!showMap) {
    return (
      <NoMapWrapper>
        <Heading03>{t('no_map')}</Heading03>
      </NoMapWrapper>
    );
  }

  const createRequestTransformer = (
    atlasServiceUrl: string,
    bearerToken: string | undefined
  ): RequestTransformer => {
    return (url) => {
      if (url.indexOf(atlasServiceUrl) !== 0) {
        // If the URL isn't Atlas service as given exactly - skip.
        return { url };
      }

      return {
        url,
        headers: {
          // Add Auth headers.
          Authorization: `Bearer ${bearerToken}`,
        },
      };
    };
  };

  return (
    <WorkAreasProvider levelId={levelId}>
      <MapWrapper
        key={levelId}
        ref={mapRef}
        zoom={zoom}
        interactive={interactive}
        transformRequest={createRequestTransformer(atlasServiceUrl, jwt)}
        styleURL={`${atlasServiceUrl}/v1.2/styles/levels/${levelId}/${theme}/style.json`}
        serverUrl={atlasServiceUrl}
        serverToken={jwt || ''}
        style={{
          width: '100%',
          height: '100%',
          ...style,
        }}
        loader={<NoMapWrapper></NoMapWrapper>}
      >
        {loading && (
          <MapLoading>
            <SpinnerLoader />
            <Body05>{t('map_loading')}</Body05>
          </MapLoading>
        )}
        {deskAvailabilityStates && (
          <AtlasSeats
            seatsToAvatars={seatsToAvatars}
            selectedIds={selectedDeskIds}
            availableIds={deskAvailabilityStates.availableIds}
            busyIds={deskAvailabilityStates.busyIds}
            forbiddenIds={deskAvailabilityStates.forbiddenIds}
            dimmedIds={deskAvailabilityStates.dimmedIds}
            // passing prop with `undefined` seems to upset it
            {...{ ...(onSelectDesk ? { onClick: handlePressDesk } : {}) }}
          />
        )}
        <WorkAreas />
        {spaceAvailabilityStates ? (
          <AtlasSpaces
            {...spaceAvailabilityStates}
            selectedIds={selectedSpaceIds}
            // passing prop with `undefined` seems to upset it
            {...{ ...(onSelectSpace ? { onClick: handlePressSpace } : {}) }}
            selectedSpaceIds={selectedSpaceIds}
          />
        ) : (
          <SpaceDisabler />
        )}
      </MapWrapper>
    </WorkAreasProvider>
  );
};

const bounce = keyframes`
  from {top: -10px}
  to {top: 20px}
`;

const MapLoading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 30px;
  padding: 6px 16px;
  gap: 10px;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
  animation: ${bounce} 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
`;

const NoMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #cdd5dc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MapWrapper = styled(Map)`
  .maplibregl-control-container {
    pointer-events: none;

    div {
      pointer-events: all;
    }
  }
`;

import styled from '@emotion/styled';
import { teamsTheme } from '@fluentui/react-northstar';
import { Colors } from '@robinpowered/design-system';

import BuildingSolid from '@robinpowered/icons/BuildingSolid';
import HomeSolid from '@robinpowered/icons/HomeSolid';

type Props = {
  inOffice?: boolean;
};

export const StatusIcon = ({ inOffice }: Props): JSX.Element => {
  return (
    <Container inOffice={inOffice}>
      {inOffice ? (
        <BuildingSolid size={20} color={Colors.White0} />
      ) : (
        <HomeSolid size={24} color={'#B3B0AD'} />
      )}
    </Container>
  );
};

const Container = styled.div<{ inOffice?: boolean }>`
  min-width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ inOffice }) =>
    inOffice
      ? teamsTheme.siteVariables.colorScheme.brand.background
      : '#F3F2F1'};
  border-radius: 4px;
  margin-right: 8px;
`;

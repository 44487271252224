import React, {Component} from 'react';
import PropTypes from 'prop-types';
import maplibre from 'maplibre-gl';
import Container from './Container';

/**
 * Calculates bounds of a given feature in LngLat.
 *
 * @param  {Object} feature The feature to get the bounds for.
 * @return {maplibre.LngLatBounds} The bounds of a given feature.
 */
const getBounds = (feature) => {
  const coordinates = [];

  // Flatten out any geometry into just coordinates.
  switch (feature.geometry.type.toLowerCase()) {
    case 'polygon':
    case 'multilinestring':
      coordinates.push(...feature.geometry.coordinates[0]);
      break;
    case 'linestring':
      coordinates.push(...feature.geometry.coordinates);
      break;
    case 'point':
      coordinates.push(feature.geometry.coordinates);
      break;
    default:
      throw new Error(`Unsupported geometry type: ${feature.geometry.type}`);
  }

  // Get the bounds of the first point. Since we know it's already in the array,
  // it'll for sure be part of the bounds.
  const bounds = new maplibre.LngLatBounds(coordinates[0], coordinates[0]);

  for (
    // We've already added our initial coordinate.
    let i = 1;
    i < coordinates.length;
    i++
  ) {
    // Extend bounds with new points as they come in.
    bounds.extend(coordinates[i]);
  }

  return bounds;
};

export class AttachedControlGroup extends Component {
  static propTypes = {
    /**
     * The feature to attach the control group to.
     * @type {Object}
     */
    feature: PropTypes.object.isRequired,
    /**
     *  The position of the anchor.
     * @type {string}
     */
    anchor: PropTypes.string,
    /**
     * Children to show in the container.
     */
    children: PropTypes.node
  };

  state = {
    anchor: null
  };

  static defaultProps = {
    anchor: 'bottom'
  };

  componentDidMount() {
    this.updateAnchorPoint();
  }

  componentDidUpdate() {
    this.updateAnchorPoint();
  }

  getAnchorPoint() {
    const bounds = getBounds(this.props.feature);
    let nw, ne, sw, se;

    switch (this.props.anchor) {
      case 'center':
        nw = bounds.getNorthWest();
        ne = bounds.getNorthEast();
        sw = bounds.getSouthWest();
        se = bounds.getSouthEast();
        return [(nw.lng + ne.lng) / 2, (nw.lat + sw.lat) / 2];
      case 'bottom-left':
        sw = bounds.getSouthWest();
        return [sw.lng, sw.lat];
      case 'bottom-right':
        se = bounds.getSouthEast();
        return [se.lng, se.lat];
      case 'top-left':
        nw = bounds.getNorthWest();
        return [nw.lng, nw.lat];
      case 'top-right':
        ne = bounds.getNorthEast();
        return [ne.lng, ne.lat];
      case 'right':
        ne = bounds.getNorthEast();
        se = bounds.getSouthEast();
        return [ne.lng, ne.lat + (se.lat - ne.lat) / 2];
      case 'left':
        nw = bounds.getNorthWest();
        sw = bounds.getSouthWest();
        return [nw.lng, nw.lat + (sw.lat - nw.lat) / 2];
      case 'top':
        nw = bounds.getNorthWest();
        ne = bounds.getNorthEast();
        return [nw.lng + (ne.lng - nw.lng) / 2, nw.lat];
      case 'bottom':
      default:
        sw = bounds.getSouthWest();
        se = bounds.getSouthEast();
        return [sw.lng + (se.lng - sw.lng) / 2, sw.lat];
    }
  }

  updateAnchorPoint() {
    const newAnchor = this.getAnchorPoint();

    if (
      !this.state.anchor ||
      this.state.anchor[0] !== newAnchor[0] ||
      this.state.anchor[1] !== newAnchor[1]
    ) {
      // If anchor position is different from the one in the state - update the state.
      this.setState({anchor: newAnchor});
    }
  }

  render() {
    if (!this.state.anchor) {
      return null;
    }

    return (
      <Container position={this.props.anchor} anchor={this.state.anchor}>
        {this.props.children}
      </Container>
    );
  }
}

export default AttachedControlGroup;

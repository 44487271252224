import React, { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useSelect, UseSelectStateChange } from 'downshift';
import { Colors, BrownFontStack } from '@robinpowered/design-system';
import { Size } from './Size';
import { useTranslation } from 'react-i18next';

export type Item = { id: string; name: string };
export type DropdownStateChange = UseSelectStateChange<Item>;

type Props = {
  items: Item[];
  selectedItem?: Item | null;
  onSelect: (changes: DropdownStateChange) => void;
  displayByline: string | undefined | null;
  isOpen: boolean;
  onToggleClick: (changes: DropdownStateChange) => void;
  getItemByline?: (item: Item) => ReactNode;
};

export const DropdownPicker: FC<Props> = ({
  items,
  selectedItem,
  onSelect,
  displayByline,
  isOpen,
  onToggleClick,
  getItemByline,
}) => {
  // TODO: Generalize this translation.
  const { t } = useTranslation('spaceBooking');
  const { getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } =
    useSelect({
      items,
      selectedItem,
      onSelectedItemChange: onSelect,
      isOpen,
      onIsOpenChange: onToggleClick,
    });

  return (
    <div>
      <DropdownTrigger {...getToggleButtonProps()}>
        <DropdownTriggerText title={displayByline || ''}>
          {displayByline || t('location_picker.select')}
        </DropdownTriggerText>
      </DropdownTrigger>
      <DropdownWrapper isOpen={isOpen}>
        <DropdownBodyWrapper {...getMenuProps()} isOpen={isOpen}>
          {isOpen &&
            items.map((item, index) => (
              <ListItem
                key={item.id}
                {...getItemProps({ item, index })}
                highlight={index === highlightedIndex}
                selected={selectedItem && item.id === selectedItem.id}
              >
                <div>{item.name}</div>
                {getItemByline && getItemByline(item)}
              </ListItem>
            ))}
        </DropdownBodyWrapper>
      </DropdownWrapper>
    </div>
  );
};

const DropdownWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 10000;
  border-radius: 4px;
  display: none;
  ${({ isOpen }) =>
    isOpen &&
    css`
      display: flex;
      flex-direction: column;
      border: solid 1px ${Colors.Tan70};
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      width: auto;
    `}
`;

const DropdownBodyWrapper = styled.ul`
  background-color: ${Colors.White0};
  max-height: 300px;
  overflow: auto;
`;

const ListItem = styled.li<{ highlight: boolean; selected: boolean }>`
  list-style: none;
  cursor: pointer;
  font-family: ${BrownFontStack};
  font-size: ${Size.Small}px;
  padding: 6px 16px;
  background: ${({ highlight }) => (highlight ? Colors.Tan10 : Colors.White0)};
  display: flex;
  color: ${({ selected }) => (selected ? Colors.Maroon100 : Colors.Gray80)};
  }
`;

const DropdownTrigger = styled.button`
  color: ${Colors.Gray80};
  background: ${Colors.Tan5};
  border: 2px solid ${Colors.Tan5};
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
  font-family: ${BrownFontStack};
  font-size: ${Size.Small}px;
  max-width: 120px;
  &:hover {
    background: ${Colors.Tan30};
  }
  &:focus-visible {
    border: 2px solid ${Colors.Maroon100};
  }
`;

const DropdownTriggerText = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${BrownFontStack};
  font-weight: 500;
  font-size: ${Size.Small};
  color: ${Colors.Gray100};
`;

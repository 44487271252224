import styled from '@emotion/styled';
import { Flex, Text } from '@fluentui/react-northstar';
import { Moment } from 'moment';
import { useMemo, useState } from 'react';
import {
  OrgLocation,
  useSettingsContext,
  useDeskBookingContext,
  useLocationsContext,
  useToast,
  useUserContext,
  useUserScheduleContext,
  useCalendarContext,
  WhosInByDate,
  WhosInUser,
  useDateContext,
} from '../../../contexts';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import {
  hasAccessOnDay,
  isBeforeToday,
  isOfficeClosed,
  isToday,
} from '../../../utilities';
import { OfficeStatusLoading } from '../OfficeStatus/OfficeStatusLoading';
import { OfficeStatusNoAccess } from '../OfficeStatus/OfficeStatusNoAccess';
import { OfficeStatusClosed } from '../OfficeStatus/OfficeStatusClosed';
import { InOfficeCard } from '../InOfficeCard';
import { useMutation } from '@apollo/client';
import {
  endOrCancelReservationMutation,
  scheduleEmployeeVisitMutation,
} from '../../../graphql/mutations';
import { robinLogger } from '../../../utilities/RobinLogger';
import moment from 'moment';
import { JoinOfficeCTA } from '../JoinOfficeCTA';
import { AvatarListContainer } from '../AvatarList';
import { confirmDeskMutation } from '../../../graphql/mutations/confirmDesk';
import { DeskConfirmationType } from '../../../__generated__/types';
import { ReservationsCard } from '../Reservations';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from '../DayCard/graphql/__generated__/useMyEvents.generated';
import { Colors } from '@robinpowered/design-system';

type DayCardProps = {
  index: number;
  isCardLoading: boolean;
  date: Moment;
  visit: Visit | undefined;
  reservation: Reservation | undefined;
  whosIn: WhosInByDate[] | undefined;
  loadingReservation: boolean;
  events: Event[];
  loadingEvents: boolean;
  lookingForSuggestedSpaces: boolean;
};

export const DayCard = ({
  index,
  isCardLoading,
  date,
  visit,
  reservation,
  loadingReservation,
  whosIn,
  events,
  loadingEvents,
  lookingForSuggestedSpaces,
}: DayCardProps) => {
  //loading variable used to set loading state when calling API from card
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { refetch: refetchUserSchedule } = useUserScheduleContext();
  const { teamsSpaceBookingEnabled } = useSettingsContext();
  const { isCalendarEnabled } = useCalendarContext();
  const { selectedLocation } = useLocationsContext();
  const { hideWeekends } = useDateContext();

  const { openMap } = useDeskBookingContext();
  const logger = robinLogger();
  const { success, error } = useToast();

  const [scheduleEmployeeVisit] = useMutation(scheduleEmployeeVisitMutation, {
    onCompleted: () => success('Status updated.'),
    onError: (e) => {
      logger.reportError(e);
      error('Error. Please try again');
    },
  });

  const [cancelReservation] = useMutation(endOrCancelReservationMutation, {
    onCompleted: () => success('Status updated.'),
    onError: () => error('Error. Please try again'),
  });

  const { currentUser } = useUserContext();
  const handleJoinOffice = async (location: OrgLocation) => {
    await scheduleEmployeeVisit({
      variables: {
        employeeId: currentUser?.id,
        locationId: location.id,
        start: moment()
          .tz(location.timezone || moment.tz.guess())
          .set(date.toObject())
          .toISOString(),
        end: moment()
          .tz(location.timezone || moment.tz.guess())
          .set(date.toObject())
          .endOf('day')
          .toISOString(),
      },
    });
  };

  const [checkIn] = useMutation(confirmDeskMutation, {
    onCompleted: () => success('Status updated.'),
    onError: () => error('Error. Please try again'),
  });

  const handleCheckIn = async () => {
    if (reservation) {
      setIsSubmitting(true);
      await checkIn({
        variables: {
          reservationId: reservation.id,
          type: DeskConfirmationType.TEAMS,
        },
      });
      if (refetchUserSchedule) {
        await refetchUserSchedule();
      }
      logger.reportEvent('check_into_reservation');
      setIsSubmitting(false);
    }
  };

  const handleCancelReservation = async (reservationId: string) => {
    await cancelReservation({
      variables: { reservationId: reservationId },
    });
  };

  const selectedLocationClosed = useMemo(() => {
    return selectedLocation ? isOfficeClosed(date, selectedLocation) : false;
  }, [selectedLocation, date]);

  const inOfficeLocation = selectedLocation;

  const disabled = useMemo(() => isBeforeToday(date), [date]);

  const hasAccess = useMemo(() => {
    // TODO: This recreates an existing bug that is tracked by https://robinpowered.atlassian.net/browse/DMX-2039
    if (selectedLocation) {
      return hasAccessOnDay(date, selectedLocation.officeAccess);
    }

    return false;
  }, [selectedLocation, date]);

  // TODO: Use something like classames package?
  const dayTextColor = useMemo(() => {
    if (disabled) {
      return '#C7C7C7';
    }

    return isToday(date) ? '#5B5FC7' : '#242424';
  }, [disabled, date]);

  const whosInToday: WhosInUser[] =
    whosIn?.find((record) => {
      return date.isSame(record.date, 'date');
    })?.whosIn || [];

  const showJoinOfficeCta = whosInToday.length === 0;

  const showReservationsCard = useMemo(() => {
    return teamsSpaceBookingEnabled && isCalendarEnabled && !!events?.length;
  }, [events?.length, isCalendarEnabled, teamsSpaceBookingEnabled]);

  return (
    <DayCardWrapper showWeekendEnabled={!hideWeekends}>
      <>
        <Flex vAlign="center" space="between" style={{ minHeight: '24px' }}>
          <Text size="medium" weight="bold" style={{ color: dayTextColor }}>
            {date.format('dddd DD')}
          </Text>

          {isToday(date) && (
            <Today>
              <Text>Today</Text>
            </Today>
          )}
        </Flex>
        {isCardLoading ? (
          <OfficeStatusLoading />
        ) : !hasAccess ? (
          <OfficeStatusNoAccess />
        ) : selectedLocationClosed ? (
          <OfficeStatusClosed disabled={disabled} date={date} />
        ) : (
          <>
            <InOfficeCard
              setIsSubmitting={setIsSubmitting}
              isSubmitting={isSubmitting}
              handleCheckIn={handleCheckIn}
              cancelReservation={handleCancelReservation}
              joinOffice={handleJoinOffice}
              inOfficeLocation={inOfficeLocation}
              date={date}
              visit={visit}
              reservation={reservation}
              disabled={disabled}
              openMap={openMap}
            />

            {showReservationsCard && (
              <ReservationsCard
                date={date}
                events={events || []}
                reservation={reservation}
                loading={loadingEvents || loadingReservation}
                lookForSuggestedSpaces={lookingForSuggestedSpaces}
              />
            )}

            <Flex
              style={{
                paddingTop: '15px',
                paddingBottom: '15px',
              }}
            >
              {showJoinOfficeCta ? (
                <Flex
                  style={{
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <JoinOfficeCTA />
                </Flex>
              ) : (
                <AvatarListContainer
                  whosInToday={whosInToday}
                ></AvatarListContainer>
              )}
            </Flex>
          </>
        )}
      </>
    </DayCardWrapper>
  );
};

type DayCardWrapperProps = {
  showWeekendEnabled?: boolean;
};

const DayCardWrapper = styled.div<DayCardWrapperProps>`
  width: ${({ showWeekendEnabled }) =>
    showWeekendEnabled ? '210px' : '256px'};
  border-radius: 8px;
  min-height: 342px;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White0};
  padding: 12px;
  flex: 1;
  gap: 8px;

  @media (max-width: 480px) {
    width: 100%;
    padding: 12px 24px 12px 12px;
  }
  @media (min-width: 481px) {
    min-width: ${({ showWeekendEnabled }) =>
      showWeekendEnabled ? '210px' : '256px'};

    max-width: ${({ showWeekendEnabled }) =>
      showWeekendEnabled ? '210px' : '256px'};
  }
`;

const Today = styled.div`
  padding: 2px 8px;
  background-color: #e8ebfa;
  border-radius: 4px;
  color: #444791;
  font-weight: bold;
  font-size: 12px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import {withMap} from '../Map';
import {withLayers} from '@robinpowered/atlas-common';
import LayerEventType from '../../constants/layerEventType';
import LayerEvents from '../LayerEvents';
import Tooltip from './Tooltip';

export function Tooltips({map, layers, renderContents}) {
  const [hoveredFeature, setHoveredFeature] = React.useState(null);

  const eventsToCallbacks = React.useMemo(() => {
    return {
      [LayerEventType.MOUSEMOVE]: (feature) => {
        setHoveredFeature(feature);
      },
      [LayerEventType.MOUSELEAVE]: () => {
        setHoveredFeature(null);
      }
    };
  }, []);

  return (
    <React.Fragment>
      <LayerEvents
        map={map}
        layers={layers}
        eventsToCallbacks={eventsToCallbacks}
      />
      {hoveredFeature && (
        <Tooltip feature={hoveredFeature}>
          {renderContents(hoveredFeature)}
        </Tooltip>
      )}
    </React.Fragment>
  );
}

Tooltips.propTypes = {
  map: PropTypes.object.isRequired,
  layers: PropTypes.arrayOf(PropTypes.string).isRequired,
  renderContents: PropTypes.func.isRequired
};

const connect = compose(withMap, withLayers('spaces'));
export default connect(Tooltips);

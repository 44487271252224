import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import {
  GetWorkAreasByLevelQueryGetWorkAreasByLevel,
  GetWorkAreasByLevelQueryQuery,
} from './__generated__/useWorkAreas.generated';

export type WorkArea = GetWorkAreasByLevelQueryGetWorkAreasByLevel;

const query = gql`
  query GetWorkAreasByLevelQuery($levelId: ID!) {
    getWorkAreasByLevel(levelId: $levelId) {
      id
      name
      desks {
        id
        name
        zone {
          id
        }
        isDisabled
        amenities {
          id
          name
        }
      }
    }
  }
`;

export function useWorkAreas(levelId?: string): WorkArea[] {
  const { data } = useQuery<GetWorkAreasByLevelQueryQuery>(query, {
    variables: {
      levelId: Number(levelId),
    },
    skip: !levelId,
  });

  return useMemo(() => {
    if (data?.getWorkAreasByLevel) {
      // Returns work areas that are not considered archived or have 0 desks
      return data?.getWorkAreasByLevel.filter(
        (workArea: WorkArea) =>
          workArea.desks.filter((desk) => !desk.isDisabled).length > 0
      );
    }
    return [];
  }, [data]);
}

import { OrgLocation } from '../../contexts';
import {
  GetUserScheduleDeskReservations as Reservation,
  GetUserScheduleEmployeeVisits as Visit,
} from '../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import {
  BookingPolicyType,
  DeskConfirmationStatus,
  HealthCheckpointStatus,
  ParticipationStatus,
} from '../../__generated__/types';

export const getInOfficeLocation = (
  selectedLocations: OrgLocation[],
  visit?: Visit,
  reservation?: Reservation
) => {
  //get inoffice location if available, else use reservation location
  const locationIdToFind = visit
    ? visit.locationId
    : reservation?.seat.location.id;
  return selectedLocations.find((loc) => loc.id === locationIdToFind);
};

const hasAssignedDesk = (reservation: Reservation) => {
  return reservation?.type.toLocaleUpperCase() === BookingPolicyType.ASSIGNED;
};

export const isUserInOffice = (visit?: Visit, reservation?: Reservation) => {
  const userHasConfirmedAssignedDesk =
    reservation &&
    hasAssignedDesk(reservation) &&
    ((reservation.confirmation?.status === DeskConfirmationStatus.CONFIRMED &&
      reservation.accountReservee?.participationStatus ===
        ParticipationStatus.ACCEPTED) ||
      reservation?.healthCheckpoint?.status ===
        HealthCheckpointStatus.COMPLETE);

  const userHasValidInOfficeReservation =
    (reservation?.accountReservee?.participationStatus !==
      ParticipationStatus.DECLINED &&
      reservation?.type.toLocaleUpperCase() === BookingPolicyType.HOT) ||
    reservation?.type.toLocaleUpperCase() === BookingPolicyType.HOTELED ||
    reservation?.type.toLocaleUpperCase() === BookingPolicyType.REVERSE_HOTEL;
  return !!(
    visit ||
    userHasConfirmedAssignedDesk ||
    userHasValidInOfficeReservation
  );
};

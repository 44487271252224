import * as locations from './locations.json';
import * as notifications from './notifications.json';
import * as spaceBooking from './spaceBooking.json';
import * as spaces from './spaces.json';
import * as sidebar from './sidebar.json';
import * as weekSchedule from './weekSchedule.json';

export const en = {
  locations,
  notifications,
  spaces,
  sidebar,
  spaceBooking,
  weekSchedule,
};

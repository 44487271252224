import React from 'react';
import {
  useWorkAreas,
  WorkArea,
} from '../../../contexts/SpaceBooking/hooks/useWorkAreas';

type WorkAreasProviderProps = {
  children: React.ReactNode;
  levelId?: string;
};

// TODO: This context will become more useful if/when we add hover & select states to work areas.
export type WorkAreasContextType = {
  workAreas: WorkArea[];
};

export const WorkAreasContext = React.createContext<WorkAreasContextType>({
  workAreas: [],
});

export const WorkAreasProvider = ({
  children,
  levelId,
}: WorkAreasProviderProps): JSX.Element => {
  const workAreas = useWorkAreas(levelId);

  const workAreasApi = {
    workAreas,
  };

  return (
    <WorkAreasContext.Provider value={workAreasApi}>
      {children}
    </WorkAreasContext.Provider>
  );
};

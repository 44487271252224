import { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { SpaceListView } from './SpaceListView';
import { SpaceDetailsView } from './SpaceDetailsView';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { FiltersView } from './FiltersView';
import { MapSpaceStateForLevelSpaces } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';

type Props = {
  event: Event;
  spaces?: MapSpaceStateForLevelSpaces[];
  selectedLevelId?: string;
};

export const SpaceBookingSidebar: FC<Props> = ({
  spaces,
  selectedLevelId,
  event,
}) => {
  const { selectedSpaceId, sidebarMode, availabilityStateById } =
    useSpaceBookingContext();

  const availableSpaces = useMemo(
    () => spaces?.filter((space) => availabilityStateById[space.id].available),
    [spaces, availabilityStateById]
  );

  const renderSidebarView = () => {
    switch (sidebarMode) {
      case 'LIST_VIEW':
        return (
          <SpaceListView
            event={event}
            spaces={availableSpaces}
            selectedLevelId={selectedLevelId}
          />
        );
      case 'DETAILS_VIEW':
        return (
          <SpaceDetailsView
            selectedSpaceId={selectedSpaceId}
            selectedLevelId={selectedLevelId}
          />
        );
      case 'FILTERS':
        return <FiltersView spaces={spaces} />;
      default:
        return null;
    }
  };

  return (
    <SidebarContainer>
      <SidebarBody>{renderSidebarView()}</SidebarBody>
    </SidebarContainer>
  );
};

const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${Colors.White0};
  border-left: 1px solid ${Colors.Gray10};
  border-bottom-left-radius: 8px;
`;

import styled from '@emotion/styled';
import { Colors, Heading05, Row } from '@robinpowered/design-system';

export const SidebarEmptyState = ({
  byLine,
  width = '190px',
}: {
  byLine: string;
  width?: string;
}): JSX.Element => {
  return (
    <Row width="100%" justifyContent="center" alignItems="center">
      <EmptyStateByline
        width={width}
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={Colors.Gray80}
      >
        {byLine}
      </EmptyStateByline>
    </Row>
  );
};

const EmptyStateByline = styled(Heading05)`
  text-align: center;
`;

import React, {
  FC,
  createContext,
  useContext,
  useReducer,
  useMemo,
  useCallback,
} from 'react';
import { TFunction } from 'i18next';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from '../../components/EventsList/graphql/__generated__/useMyEvents.generated';
import { EventUpdateFailReasons } from '../../__generated__/types';
import { useToast } from '../ToastContext';
import { useAddSpaceToEvent } from './hooks/useAddSpaceToEvent';

export const unBookableReason = (
  t: TFunction,
  errorReason?: EventUpdateFailReasons | null
) => {
  switch (errorReason) {
    case EventUpdateFailReasons.SPACE_UNAVAILABLE:
      return t('events.space_booking_error.unavailable_space');

    case EventUpdateFailReasons.LACKING_EVENT_UPDATE_PERMISSIONS:
      return t('events.space_booking_error.no_permission');

    case EventUpdateFailReasons.SPACE_DECLINED:
    case EventUpdateFailReasons.UNKNOWN:
      return t('events.space_booking_error.unable_to_book');

    default:
      return t('events.basic_error');
  }
};

export type AttemptedSuggestedSpaceBookingType = {
  isLoading: boolean;
  success: boolean;
  errorMessage: string | null;
  withBookAll: boolean;
};

type State = {
  bookingAllSuggestedSpaces: boolean;
  spaceBookingStatus: { [key: string]: AttemptedSuggestedSpaceBookingType };
};

type Action =
  | { type: 'SET_BOOKING_STATUS'; payload: boolean }
  | { type: 'SPACE_BOOKING_SUCCESS'; payload: { eventId: string } }
  | {
      type: 'SPACE_BOOKING_FAILED';
      payload: { eventId: string; errorMessage: string | null };
    }
  | { type: 'BOOKING_SUGGESTED_SPACE'; payload: { eventId: string } }
  | { type: 'SPACE_REMOVED'; payload: { eventId: string } }
  | { type: 'RESET_STATE' };

const initialState: State = {
  bookingAllSuggestedSpaces: false,
  spaceBookingStatus: {},
};

const spaceBookingReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_BOOKING_STATUS':
      return { ...state, bookingAllSuggestedSpaces: action.payload };
    case 'SPACE_BOOKING_SUCCESS':
      return {
        ...state,
        spaceBookingStatus: {
          ...state.spaceBookingStatus,
          [action.payload.eventId]: {
            isLoading: false,
            success: true,
            errorMessage: null,
            withBookAll: state.bookingAllSuggestedSpaces,
          },
        },
      };
    case 'SPACE_BOOKING_FAILED':
      return {
        ...state,
        spaceBookingStatus: {
          ...state.spaceBookingStatus,
          [action.payload.eventId]: {
            isLoading: false,
            success: false,
            errorMessage: action.payload.errorMessage,
            withBookAll: state.bookingAllSuggestedSpaces,
          },
        },
      };
    case 'BOOKING_SUGGESTED_SPACE':
      return {
        ...state,
        spaceBookingStatus: {
          ...state.spaceBookingStatus,
          [action.payload.eventId]: {
            isLoading: true,
            success: false,
            errorMessage: null,
            withBookAll: state.bookingAllSuggestedSpaces,
          },
        },
      };
    case 'SPACE_REMOVED': {
      const spaceBookingStatus = { ...state.spaceBookingStatus };
      delete spaceBookingStatus[action.payload.eventId];
      return {
        ...state,
        spaceBookingStatus,
      };
    }
    case 'RESET_STATE':
      return initialState;
    default:
      return state;
  }
};

type EventsListContextType = {
  totalSpacesBookedSuccessfully: number;
  totalBookAllSpacesUnsuccessful: number;
  spaceBookingState: State;
  dispatchUpdateSpaceBookingState: React.Dispatch<Action>;
  bookSuggestedSpace: (
    event: Event,
    spaceId: string | null,
    t: TFunction
  ) => void;
};

const EventsListContext = createContext<EventsListContextType>({
  totalSpacesBookedSuccessfully: 0,
  totalBookAllSpacesUnsuccessful: 0,
  spaceBookingState: initialState,
  dispatchUpdateSpaceBookingState: () => undefined,
  bookSuggestedSpace: () => undefined,
});

export const EventsListContextProvider: FC = ({ children, ...props }) => {
  const [spaceBookingState, dispatchUpdateSpaceBookingState] = useReducer(
    spaceBookingReducer,
    initialState
  );
  const totalSpacesBookedSuccessfully = useMemo(() => {
    return Object.values(spaceBookingState.spaceBookingStatus).filter(
      (s) => s.success
    ).length;
  }, [spaceBookingState]);

  const totalBookAllSpacesUnsuccessful = useMemo(() => {
    return Object.values(spaceBookingState.spaceBookingStatus).filter(
      (s) => s.errorMessage && s.withBookAll
    ).length;
  }, [spaceBookingState]);

  const toast = useToast();

  const [addSpace] = useAddSpaceToEvent();

  const bookSuggestedSpace = useCallback(
    async (event: Event, spaceId: string | null, t: TFunction) => {
      if (!spaceId) return;
      dispatchUpdateSpaceBookingState({
        type: 'BOOKING_SUGGESTED_SPACE',
        payload: { eventId: event.id },
      });
      let addSpaceData;
      try {
        addSpaceData = await addSpace({
          spaceWasSuggested: true,
          variables: {
            request: {
              eventId: event.id,
              spaceId,
              startTime: event.startTime,
              endTime: event.endTime,
            },
          },
        });
      } catch (err) {
        dispatchUpdateSpaceBookingState({
          type: 'SPACE_BOOKING_FAILED',
          payload: {
            eventId: event.id,
            errorMessage: null,
          },
        });
        toast.error(t('events.basic_error'));
      }
      const data = addSpaceData?.data?.addSpaceToEvent;
      if (data) {
        if (data.eventUpdated) {
          dispatchUpdateSpaceBookingState({
            type: 'SPACE_BOOKING_SUCCESS',
            payload: { eventId: event.id },
          });
        } else if (data.error) {
          dispatchUpdateSpaceBookingState({
            type: 'SPACE_BOOKING_FAILED',
            payload: {
              eventId: event.id,
              errorMessage: unBookableReason(t, data.error.reason),
            },
          });
        }
      }
      return data;
    },
    [addSpace, toast]
  );

  return (
    <EventsListContext.Provider
      value={{
        totalSpacesBookedSuccessfully,
        totalBookAllSpacesUnsuccessful,
        spaceBookingState,
        dispatchUpdateSpaceBookingState,
        bookSuggestedSpace,
      }}
    >
      {children}
    </EventsListContext.Provider>
  );
};

export const useEventsListContext = () => useContext(EventsListContext);

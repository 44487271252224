import { Redirect } from 'react-router-dom';
import { useUserContext } from '../contexts';
import { UserSettings } from '../components/UserSettings';

export const UserSettingsTab = () => {
  const { currentUser } = useUserContext();

  return currentUser ? (
    <UserSettings userId={currentUser.id} />
  ) : (
    <Redirect to="/landing" />
  );
};

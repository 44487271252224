import { Moment } from 'moment';
import { useCallback } from 'react';
import {
  OrgLocation,
  useLocationsContext,
  useToast,
  useUserScheduleContext,
} from '../../../contexts';
import {
  GetUserScheduleEmployeeVisits as Visit,
  GetUserScheduleDeskReservations as Reservation,
} from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { JoinOfficeDropdown } from './JoinOfficeDropdown';
import { Flex } from '@fluentui/react-northstar';
import { StatusIcon } from './StatusIcon';
import { useMutation } from '@apollo/client';
import { cancelEmployeeVisitMutation } from '../../../graphql/mutations';
import { robinLogger } from '../../../utilities/RobinLogger';
import { JoinOfficeLoading } from './JoinOfficeLoading';

type JoinOfficeProps = {
  date: Moment;
  loading: boolean;
  inOfficeLocation: OrgLocation | undefined;
  visit: Visit | undefined;
  reservation: Reservation | undefined;
  disabled: boolean;
  setIsSubmitting: (val: boolean) => void;
  userInOffice: boolean;
  joinOffice: (location: OrgLocation) => Promise<void>;
  cancelReservation: (reservationId: string) => Promise<void>;
};

export const JoinOffice = ({
  date,
  loading,
  visit,
  reservation,
  disabled,
  setIsSubmitting,
  userInOffice,
  joinOffice,
  cancelReservation,
}: JoinOfficeProps) => {
  const logger = robinLogger();
  const { selectedLocation } = useLocationsContext();
  const { refetch: refetchUserSchedule } = useUserScheduleContext();
  const { error, success } = useToast();

  const [cancelEmployeeVisit] = useMutation(cancelEmployeeVisitMutation, {
    onError: (e) => {
      logger.reportError(e);
      error('Error. Please try again');
    },
  });

  const handleBookVisit = useCallback(
    async (location) => {
      setIsSubmitting(true);

      await joinOffice(location);

      logger.reportEvent('scheduled-employee-visit');

      if (refetchUserSchedule) {
        await refetchUserSchedule();
      }

      success('Status updated.');
      setIsSubmitting(false);
    },
    [refetchUserSchedule, logger, setIsSubmitting, success, joinOffice]
  );

  const handleCancelVisitAndDesk = useCallback(async () => {
    if (visit) {
      setIsSubmitting(true);
      //cancels visit and reservation
      await cancelEmployeeVisit({
        variables: {
          employeeVisitId: visit.id,
        },
      });
      logger.reportEvent('cancel-employee-visit');

      if (refetchUserSchedule) {
        await refetchUserSchedule();
      }

      success('Status updated.');
      setIsSubmitting(false);
    }

    //no visit but has reservation, cancel the reservation
    if (!visit && reservation) {
      setIsSubmitting(true);
      await cancelReservation(reservation.id);
      logger.reportEvent('cancel_desk_reservation');

      if (refetchUserSchedule) {
        await refetchUserSchedule();
      }

      success('Status updated.');
      setIsSubmitting(false);
    }
  }, [
    reservation,
    cancelReservation,
    cancelEmployeeVisit,
    refetchUserSchedule,
    visit,
    logger,
    setIsSubmitting,
    success,
  ]);

  if (loading) {
    return <JoinOfficeLoading />;
  }

  return (
    <Flex gap="gap.medium">
      <StatusIcon inOffice={visit !== undefined} />
      <JoinOfficeDropdown
        visit={visit}
        disabled={disabled}
        selectedLocation={selectedLocation}
        handleBookVisit={handleBookVisit}
        handleCancelVisitAndDesk={handleCancelVisitAndDesk}
        userInOffice={userInOffice}
      />
    </Flex>
  );
};

import {Component} from 'react';
import PropTypes from 'prop-types';
import {withMap} from '../Map';

export class CursorStyle extends Component {
  static propTypes = {
    /**
     * The cursor style to set.
     *
     * @type {string}
     */
    cursor: PropTypes.string.isRequired,
    /**
     * The map instance.
     *
     * @type {Object}
     */
    map: PropTypes.object.isRequired
  };

  state = {
    stackId: null
  };

  updateCursor(cursor) {
    const {componentInstance} = this.props.map;
    if (this.state.stackId !== null) {
      componentInstance.removeCursorStyle(this.state.stackId);
    }

    if (cursor) {
      this.setState({
        stackId: componentInstance.pushCursorStyle(cursor)
      });
    }
  }

  componentWillUnmount() {
    this.updateCursor();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cursor !== prevProps.cursor) {
      this.updateCursor(this.props.cursor);
    }
  }

  componentDidMount() {
    this.updateCursor(this.props.cursor);
  }

  render() {
    return null;
  }
}

export default withMap(CursorStyle);

import {
  Button,
  ChevronEndIcon,
  ChevronStartIcon,
  Text,
} from '@fluentui/react-northstar';
import moment from 'moment';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useDateContext } from '../../contexts';
import { useSidebarContext } from '../../contexts/Sidebar';
import { debounce } from 'lodash';

const INTERVAL = 300;

export const WeekPicker = () => {
  const {
    weekStartDate,
    weekScheduleDates: scheduleDates,
    minCalendarDate,
    setWeekIndex,
    weekIndex,
  } = useDateContext();
  const { openDaySidebar } = useSidebarContext();

  const isFirstWeek = useMemo(
    () => weekStartDate.isSame(minCalendarDate),
    [weekStartDate, minCalendarDate]
  );

  const getNextWeekStartDate = (
    currentDateTime: Moment,
    weekIndex: number,
    nextWeekIndex: number,
    schedule: Moment[]
  ) => {
    if (schedule.length === 0) {
      return undefined;
    }
    const isThisWeek = nextWeekIndex === 0;
    const nextWeekDiffIndex = nextWeekIndex - weekIndex;
    const nextWeekStartDate = moment(schedule[0]).add(
      nextWeekDiffIndex,
      'week'
    );
    const nextStartDate = isThisWeek ? currentDateTime : nextWeekStartDate;
    const isInTheFuture = nextStartDate.isSameOrAfter(
      currentDateTime.startOf('day')
    );
    return isInTheFuture ? nextStartDate : undefined;
  };

  const updateDaySidebar = useCallback(
    (index: number) => {
      const nextWeekDayStart = getNextWeekStartDate(
        moment(),
        weekIndex,
        index,
        scheduleDates
      );

      if (nextWeekDayStart) {
        openDaySidebar(nextWeekDayStart);
      }
    },
    [openDaySidebar, scheduleDates, weekIndex]
  );

  const navigateRight = useMemo(() => {
    return debounce(() => {
      setWeekIndex(weekIndex + 1);
      updateDaySidebar(weekIndex + 1);
    }, INTERVAL);
  }, [setWeekIndex, updateDaySidebar, weekIndex]);

  const navigateLeft = useMemo(() => {
    return debounce(() => {
      setWeekIndex(weekIndex - 1);
      updateDaySidebar(weekIndex - 1);
    }, INTERVAL);
  }, [setWeekIndex, updateDaySidebar, weekIndex]);

  const resetPicker = useCallback(() => {
    setWeekIndex(0);
    updateDaySidebar(0);
  }, [setWeekIndex, updateDaySidebar]);

  return (
    <>
      <Button content="Today" onClick={resetPicker} flat />

      <Button
        icon={<ChevronStartIcon />}
        onClick={navigateLeft}
        disabled={isFirstWeek}
        iconOnly
        text
      />
      <Button icon={<ChevronEndIcon />} onClick={navigateRight} iconOnly text />

      <Text weight="bold">{weekStartDate.format('MMMM YYYY')}</Text>
    </>
  );
};

import styled from '@emotion/styled';
import { Avatar } from '@robinpowered/design-system';
import { UserOutline } from '@robinpowered/icons';
import { teamsTheme } from '@fluentui/react-northstar';

type AnonymousAvatarProps = {
  avatarSize?: number;
  iconSize?: number;
  className?: string;
};

export const AnonymousAvatar = ({
  avatarSize,
  iconSize = 18,
  className,
}: AnonymousAvatarProps): JSX.Element => {
  return (
    <AvatarWrapper name={''} size={avatarSize} className={className}>
      <UserOutline
        color={teamsTheme.siteVariables.colorScheme.default.foreground2}
        size={iconSize}
        fill={teamsTheme.siteVariables.colorScheme.default.foreground2}
      />
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled(Avatar)`
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

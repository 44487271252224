import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from '../../../contexts';
import { UserSortBy, UserSortOrder } from '../../../__generated__/types';
import {
  UseEventListSummaryContainerDataGetAccountUsersByIds,
  UseEventListSummaryContainerDataQuery,
  UseEventListSummaryContainerDataQueryVariables,
} from './__generated__/useEventListSummaryContainerData.generated';

type EventListSummaryContainerAccountUser =
  UseEventListSummaryContainerDataGetAccountUsersByIds;

const PAGE_SIZE = 50;

const usersQueryParams = {
  limit: PAGE_SIZE,
  offset: 0,
  sortByField: [UserSortBy.FAVORITE, UserSortBy.NAME],
  sortOrder: UserSortOrder.ASCENDING,
};

export const GET_ACCOUNT_USERS = gql`
  query UseEventListSummaryContainerData(
    $ids: [ID!]!
    $limit: Int
    $offset: Int
    $sortByField: [UserSortBy]
    $sortOrder: UserSortOrder
  ) {
    getAccountUsersByIds(
      ids: $ids
      limit: $limit
      offset: $offset
      sortByField: $sortByField
      sortOrder: $sortOrder
    ) {
      id
      name
      avatar
      slug
      isMyFavorite
    }
  }
`;

export const useEventListSummaryContainerData = (userIds: string[]) => {
  const { tenantId } = useApolloContext();
  const { data, loading } = useQuery<
    UseEventListSummaryContainerDataQuery,
    UseEventListSummaryContainerDataQueryVariables
  >(GET_ACCOUNT_USERS, {
    variables: {
      ids: userIds,
      ...usersQueryParams,
    },
    skip: !userIds || userIds.length === 0 || !tenantId,
  });
  return {
    users: mapQueryResultToUsers(data),
    loading,
  };
};

const mapQueryResultToUsers = (
  userDetails: UseEventListSummaryContainerDataQuery | undefined
): EventListSummaryContainerAccountUser[] => {
  if (userDetails?.getAccountUsersByIds) {
    return userDetails.getAccountUsersByIds.map((user) => {
      return {
        id: user?.id,
        name: user?.name,
        avatar: user?.avatar,
        slug: user?.slug,
        isMyFavorite: user?.isMyFavorite,
      };
    }) as EventListSummaryContainerAccountUser[];
  }
  return [];
};

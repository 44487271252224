import { Controls } from '../components/Controls';
import { DayList } from '../components/OfficeDayList/DayList';
import {
  useUserContext,
  useDeskBookingContext,
  useSpaceBookingContext,
  useSettingsContext,
  useLocationsContext,
} from '../contexts';
import { DeskBooking } from '../components/DeskBooking';
import { Redirect } from 'react-router-dom';
import { DaySidebar } from '../components/Sidebar/DaySidebar';
import { SpaceBooking } from '../components/SpaceBookingModal/SpaceBooking';
import { useSidebarContext } from '../contexts/Sidebar';
import styled from '@emotion/styled';
import { Colors, Row } from '@robinpowered/design-system';
import { Flex } from '@fluentui/react-northstar';
import { LocationPickerModal } from '../components/Controls/LocationPickerModal';

export const WeekSchedule = () => {
  const { currentUser } = useUserContext();
  const { isMapOpen } = useDeskBookingContext();
  const { isSpaceBookingMapOpen } = useSpaceBookingContext();
  const { isLocationModalOpen } = useLocationsContext();
  const deskBookingOpen = isMapOpen && !isSpaceBookingMapOpen;
  const spaceBookingOpen = isSpaceBookingMapOpen && !isMapOpen;
  const weekScheduleOpen = !isSpaceBookingMapOpen && !isMapOpen;

  const { isSidebarOpen } = useSidebarContext();
  const { teamsSpaceBookingEnabled } = useSettingsContext();
  return currentUser ? (
    <ScreenBackground>
      {deskBookingOpen && <DeskBooking />}
      {spaceBookingOpen && <SpaceBooking />}
      {isLocationModalOpen && <LocationPickerModal />}
      {weekScheduleOpen && (
        <Container isSidebarOpen={isSidebarOpen}>
          <MainContainer isSidebarOpen={isSidebarOpen}>
            <Toolbar>
              <ToolbarGroup>
                <Controls />
              </ToolbarGroup>
            </Toolbar>
            <ScrollWrapper>
              <DayList />
            </ScrollWrapper>
          </MainContainer>
          {/* //TODO Implement click handler to open */}
          <SidebarContainer isSidebarOpen={isSidebarOpen}>
            {isSidebarOpen && teamsSpaceBookingEnabled && <DaySidebar />}
          </SidebarContainer>
        </Container>
      )}
      {/* // invalid page state here, need a way route between the 3 pages or use a new shared context */}
      {!deskBookingOpen && !spaceBookingOpen && !weekScheduleOpen && (
        <>Invalid work week state.</>
      )}
    </ScreenBackground>
  ) : (
    <Redirect to="/landing" />
  );
};

export const ScreenBackground = styled(Flex)`
  display: flex;
  background-color: ${Colors.Tan10};
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  flex-flow: column wrap;
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;
`;

// height: 0% needed so that the main container doesnt blow up from the parent containers height in flexbox
const MainContainer = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  min-width: 1120px;
  margin-right: ${({ isSidebarOpen }) => (isSidebarOpen ? '373px' : '0')};
  align-items: start;
  @media (max-width: 480px) {
    min-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '100%' : '100px')};
  }
`;

const SidebarContainer = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0;
  right: 0;
  height: 100%;
  filter: drop-shadow(0px 12px 16px rgba(16, 24, 40, 0.08));
  min-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '373px' : '0%')};
  @media (max-width: 480px) {
    min-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '100%' : '0%')};
  }
`;
const Container = styled.main<{ isSidebarOpen: boolean }>`
  display: flex;
  overflow-x: auto;
  position: fixed;
  height: 100vh;
  background-color: ${Colors.Tan10};
  @media (max-width: 480px) {
    max-width: ${({ isSidebarOpen }) => (isSidebarOpen ? '100%' : '0%')};
    min-width: 100vw;
    overflow-y: ${({ isSidebarOpen }) => (isSidebarOpen ? 'hidden' : 'auto')};
    overflow-x: hidden;
  }
`;
const ToolbarGroup = styled(Row)`
  align-items: center;
  position: relative;
`;

const Toolbar = styled(Row)`
  display: flex;
  flex: 0;
  @media (max-width: 480px) {
    display: flex;
    max-width: 100vw;
    flex-wrap: wrap;
  }
`;

const ScrollWrapper = styled.div``;

import { Flex, Text } from '@fluentui/react-northstar';
import { Moment } from 'moment';

export const OfficeStatusClosed = ({
  disabled: isDisabled,
  date,
}: {
  disabled: boolean;
  date: Moment;
}) => {
  return (
    <Flex
      padding="padding.medium"
      gap="gap.small"
      vAlign="center"
      hAlign="center"
      style={{
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        minHeight: '52px',
      }}
    >
      <Text disabled={isDisabled} weight="semibold">
        Office is closed on {date.format('MMM DD')}
      </Text>
    </Flex>
  );
};

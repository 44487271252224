import { WhosInUser } from '../../../contexts';
import { AvatarList } from './AvatarList';
import { useAvatarListContainerData } from './graphql/useAvatarListContainerData';

type Props = {
  whosInToday: WhosInUser[];
};

export const AvatarListContainer = (props: Props): JSX.Element | null => {
  const { whosInToday } = props;
  const userIds: string[] =
    whosInToday
      .map((user) => {
        return !user.isAnonymous ? user.userId : undefined;
      })
      .filter((id): id is string => id !== null && id !== undefined) || [];

  const users = useAvatarListContainerData(userIds).users || [];

  if (userIds.length === 0) {
    return null;
  }

  const anonymousUserCount =
    whosInToday?.filter((user) => {
      return user.isAnonymous;
    }).length || 0;
  const favorites = users?.filter((user) => user.isMyFavorite).slice(0, 5);

  return (
    <AvatarList
      users={users}
      anonymousUserCount={anonymousUserCount}
      favorites={favorites}
    />
  );
};

import { QueryResult, gql, useQuery } from '@apollo/client';

import { useMemo } from 'react';
import { mapSpaceStateToMapType } from './mapSpaceStateToMapType';

import { SpaceStateWithoutMouseFeaturesType } from './spaceStateType';
import { toISOStringWithoutMilliseconds } from '../../../utilities';
import { useApolloContext } from '../../ApolloContext';
import {
  MapSpaceStateForLevelGetLevelById,
  MapSpaceStateForLevelQuery,
  MapSpaceStateForLevelSpaces,
  MapSpaceStateForLevelVariables,
} from './__generated__/useSpaceStateQuery.generated';

const USE_SPACE_STATE_QUERY = gql`
  query MapSpaceStateForLevel(
    $levelId: ID!
    $intervalInMinutes: Int
    $startTime: Date
    $meetingDurationInMinutes: Int
  ) {
    getLevelById(id: $levelId) {
      spaces {
        id
        name
        type
        capacity
        image
        isDisabled
        isAccessible
        isManaged
        locationId
        amenities {
          id
          name
          amenityId
        }
        calendar {
          calendarId
        }
        windowedAvailability(
          numberOfWindows: 1
          intervalInMinutes: $intervalInMinutes
          startTime: $startTime
          meetingDurationInMinutes: $meetingDurationInMinutes
        ) {
          status
          unbookableReasons
          isConfirmed
          start
          end
        }
      }
    }
  }
`;

export type SpaceMapAvailabilityStates = {
  bookedIds: number[];
  inUseIds: number[];
  restrictedIds: number[];
  warningIds: number[];
  disabledIds: number[];
  focusedIds: number[];
  availableIds: number[];
  selectedIds: number[];
  reducedIds: number[];
};

export type SpaceMapAvailabilityStateById = Record<
  string,
  SpaceStateWithoutMouseFeaturesType
>;

export const useSpaceStateQuery = ({
  levelId,
  startTime,
  endTime,
  spaceFilter,
  skip,
}: {
  levelId: string | undefined;
  startTime: moment.Moment;
  endTime: moment.Moment;
  spaceFilter: (space: MapSpaceStateForLevelSpaces) => boolean;
  skip: boolean;
}): QueryResult<MapSpaceStateForLevelQuery, MapSpaceStateForLevelVariables> & {
  availabilityStates: SpaceMapAvailabilityStates;
  availabilityStateById: SpaceMapAvailabilityStateById;
} => {
  const { tenantId } = useApolloContext();
  const meetingDurationInMinutes = endTime.diff(startTime, 'minutes');
  const result = useQuery<
    MapSpaceStateForLevelQuery,
    MapSpaceStateForLevelVariables
  >(USE_SPACE_STATE_QUERY, {
    skip: skip || !tenantId || !levelId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      levelId: levelId || '',
      startTime: toISOStringWithoutMilliseconds(startTime),
      intervalInMinutes: meetingDurationInMinutes,
      meetingDurationInMinutes,
    },
  });

  const availabilityStateById = useMemo(() => {
    const spaces = result.data?.getLevelById?.spaces || [];
    return spaces.reduce<SpaceMapAvailabilityStateById>((acc, space) => {
      const meetsFilterCriteria = spaceFilter(space);
      acc[space.id] = mapSpaceStateToMapType(space, meetsFilterCriteria);
      return acc;
    }, {});
  }, [result.data?.getLevelById?.spaces, spaceFilter]);

  const availabilityStates = useMemo<SpaceMapAvailabilityStates>(() => {
    return Object.entries(
      availabilityStateById
    ).reduce<SpaceMapAvailabilityStates>(
      (acc, [stringId, state]) => {
        const id = parseInt(stringId, 10);
        Object.entries(state).forEach(([key, value]) => {
          if (value) {
            acc[`${key as keyof typeof state}Ids`].push(id);
          }
        });
        return acc;
      },
      {
        bookedIds: [],
        inUseIds: [],
        restrictedIds: [],
        warningIds: [],
        disabledIds: [],
        focusedIds: [],
        availableIds: [],
        selectedIds: [],
        reducedIds: [],
      }
    );
  }, [availabilityStateById]);

  return {
    ...result,
    availabilityStates,
    availabilityStateById,
  };
};

import moment, { Moment } from 'moment';

// Builds a date range to be an input for the GraphQL query `getWhosInDataByLocationByDay`
export const buildDatesArray = (
  weekStartDate: Moment,
  dayCount = 7,
  timezone = moment.tz.guess(),
  hideWeekends?: boolean
): Moment[] => {
  const today = moment().tz(timezone).startOf('day');

  return [...Array(dayCount)]
    .map((d, i) => {
      // If today is Sunday AND user start date is Monday, hiding past
      // and weekends can result in an empty array
      if (
        hideWeekends &&
        (today.isoWeekday() === 7 || today.isoWeekday() === 6)
      ) {
        return weekStartDate
          .clone()
          .add(dayCount + i, 'day')
          .startOf('day');
      }
      return weekStartDate.clone().add(i, 'day').startOf('day');
    })
    .filter(
      (day) =>
        (hideWeekends && !(day.isoWeekday() === 6 || day.isoWeekday() === 7)) ||
        !hideWeekends
    );
};

export const buildDatesArrayFromRange = (
  start: Moment,
  end: Moment
): Moment[] => {
  const dates = [];
  let current = start;
  while (current.isSameOrBefore(end, 'date')) {
    dates.push(current);
    current = current.clone().add(1, 'day');
  }
  return dates;
};

export const getStartOfUserWeek = (
  userWeekStartDay: number,
  hidePastDates?: boolean,
  hideWeekends?: boolean
) => {
  const userWeekStartDate = moment()
    .isoWeekday(userWeekStartDay)
    .startOf('day');
  // Default set to start of today
  const today = moment().startOf('day');

  // If the user wants to hide past days and their
  // start is in past, then set the new start to today
  const newWeekStart =
    hidePastDates && userWeekStartDate.isBefore(today)
      ? today
      : userWeekStartDate;

  // If the user hides weekends and the new week start is a weekend day, then add
  // days until it is not
  if (hideWeekends) {
    while (newWeekStart.isoWeekday() === 6 || newWeekStart.isoWeekday() === 7) {
      newWeekStart.add(1, 'day');
    }
  }

  return newWeekStart;
};

export const getStartDate = (
  userWeekStartSetting: number,
  hidePastDates?: boolean
) => {
  const currentDate = moment().startOf('day');

  return hidePastDates
    ? currentDate
    : currentDate.isoWeekday() >= userWeekStartSetting
    ? moment().isoWeekday(userWeekStartSetting).startOf('day')
    : moment().startOf('week').isoWeekday(userWeekStartSetting).startOf('day');
};

export const isToday = (date: Moment): boolean => {
  return date.isSame(moment(), 'day');
};

export const isBeforeToday = (date: Moment, timezone?: string): boolean => {
  return date.isBefore(moment(), 'day');
};

export const toISOStringWithoutMilliseconds = (date: Moment): string => {
  return date.toISOString().slice(0, -5) + 'Z';
};

export const isReservationToday = (startTime?: string): boolean => {
  const parsedTime = moment(startTime);
  return parsedTime.isValid() ? parsedTime.isSame(moment(), 'date') : false;
};

import { Dispatch, SetStateAction } from 'react';
// import { useParams } from "react-router-dom";
import { useLocalStorage } from 'react-use';
import { useApolloContext } from '../contexts';

const createStorageKey = (key: string, orgSlug: string) =>
  `robin:schedule:${orgSlug}:${key}`;

// ts 4.7+ will allow referencing lower-order function return type automatically
// `ReturnType<typeof useLocalStorage<T>>`
type UseLocalStorageReturnType<T> = [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
  () => void
];

// TODO: Verify that we will actually have access to local storage in Teams
// If we don't we'll need mutation/query to store this
// Might also be a requirement from MS that these are persisted between devices?
export function useTenantLocalStorage<T>(
  key: string,
  initialValue?: T
): UseLocalStorageReturnType<T> {
  const { tenantId } = useApolloContext();

  return useLocalStorage<T>(
    createStorageKey(key, tenantId as string),
    initialValue
  );
}

import {
  FC,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
  Observable,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { config } from '../config';
import {
  USER_AZURE_ID,
  WEEK_VIEW_SELECTED_TENANT,
} from '../constants/location';
import { onError } from '@apollo/client/link/error';
import { getAccessToken } from '../services/teamsBot';
import { useTenantLocalStorage } from '../utilities';

type ApolloContextValue = {
  tenantId: string | undefined;
  jwt: string | undefined;
  setTenantId: Dispatch<SetStateAction<string | undefined>>;
  setJwt: Dispatch<SetStateAction<string | undefined>>;
};

const httpLink = createHttpLink({
  uri: config.gqlUrl ?? '',
});

function stripQuotes(str: string) {
  const regex = /^['"](.*)['"]$/;
  const strippedString = str.replace(regex, '$1');
  return strippedString;
}

export const ApolloContext = createContext<ApolloContextValue>({
  tenantId: undefined,
  jwt: undefined,
  setTenantId: () => null,
  setJwt: () => null,
});

export const ApolloContextProvider: FC = ({ children }) => {
  const [tenantId, setTenantId] = useTenantLocalStorage<string>(
    WEEK_VIEW_SELECTED_TENANT,
    undefined
  );
  const [jwt, setJwt] = useState<string | undefined>(undefined);

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, operation, forward }) => {
        if (graphQLErrors) {
          for (const error of graphQLErrors) {
            if (
              error.extensions &&
              error.extensions.code === 'UNAUTHENTICATED'
            ) {
              // Token likely expired, Refresh token
              return new Observable((observer) => {
                const tenantId = stripQuotes(
                  localStorage.getItem(
                    `robin:schedule:undefined:${WEEK_VIEW_SELECTED_TENANT}`
                  ) as string
                );

                const azureAdId = stripQuotes(
                  localStorage.getItem(
                    `robin:schedule:${tenantId}:${USER_AZURE_ID}`
                  ) as string
                );

                getAccessToken(azureAdId)
                  .then((response) => {
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        // Switch out old access token for new one
                        authorization:
                          `Bearer ${response.token.access_token}` || null,
                      },
                    }));

                    return response.token.access_token;
                  })
                  .then((token: string) => {
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    // Retry last failed request
                    forward(operation).subscribe(subscriber);

                    // Update the jwt so that all future requests use it
                    setJwt(token);
                  })
                  .catch((error) => {
                    // No refresh or client token available, we force user to login
                    observer.error(error);
                  });
              });
            }
          }
        }
      }),
    []
  );

  const createApolloClient = useCallback(
    (jwt: string | undefined, tenantId?: string) => {
      const authLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          ...(jwt && { authorization: `Bearer ${jwt}` }),
          ...(tenantId && { 'tenant-id': tenantId }),
        },
      }));

      return new ApolloClient({
        name: config.appName ?? 'unknown-react-dashboard-app',
        version: config.appVersion ?? '0.0',
        link: from([authLink, errorLink, httpLink]),
        cache: new InMemoryCache(),
        credentials: 'include',
        resolvers: {},
      });
    },
    [errorLink]
  );

  const apolloClient = useMemo(
    () => createApolloClient(jwt, tenantId),
    [createApolloClient, jwt, tenantId]
  );

  return (
    <ApolloContext.Provider value={{ tenantId, jwt, setTenantId, setJwt }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </ApolloContext.Provider>
  );
};

export const useApolloContext = (): ApolloContextValue => {
  return useContext(ApolloContext);
};

import React, { useEffect } from 'react';
// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  Provider as FluentUIProvider,
  teamsTheme,
} from '@fluentui/react-northstar';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { WeekSchedule, Landing } from './tabs';
import './App.css';
import {
  ApolloContextProvider,
  UserContextProvider,
  DateProvider,
  LocationsProvider,
  UserScheduleProvider,
  DeskBookingContextProvider,
  ToastContextProvider,
  SettingsProvider,
  CalendarProvider,
  SpaceBookingFormProvider,
  EventsListContextProvider,
  WhosInProvider,
} from './contexts';
import { ErrorBoundary } from './components/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';
import { config } from './config';
import { UserSettingsTab } from './tabs/UserSettings';
import { RKTheme } from '@robinpowered/design-system';
import { SidebarProvider } from './contexts/Sidebar/SidebarContext';

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  useEffect(() => {
    datadogRum.init({
      applicationId: config.ddApplicationId,
      clientToken: config.ddClientToken,
      site: config.ddSite,
      service: 'teams-app-ui',
      env: config.env,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100, //Default
      sessionReplaySampleRate: 0, //Default - adding in case we want to start using
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      useCrossSiteSessionCookie: true,
    });

    return;
  }, []);

  return (
    <ErrorBoundary>
      <FluentUIProvider
        theme={teamsTheme}
        styles={{ backgroundColor: '#eeeeee' }}
      >
        {/* RKTheme needed to use some components in space booking modal. Need to fix those components before removing*/}
        <RKTheme>
          <ApolloContextProvider>
            <ToastContextProvider>
              <UserContextProvider>
                <LocationsProvider>
                  <CalendarProvider>
                    <DateProvider>
                      <SettingsProvider>
                        <UserScheduleProvider>
                          <WhosInProvider>
                            <SidebarProvider>
                              <DeskBookingContextProvider>
                                <SpaceBookingFormProvider>
                                  <EventsListContextProvider>
                                    {/* allows handling of ui in path */}
                                    <Router basename="/ui">
                                      <Route exact path="/">
                                        <Redirect to="/schedule" />
                                      </Route>
                                      <Route
                                        path="/schedule"
                                        component={WeekSchedule}
                                      />
                                      <Route
                                        path="/landing"
                                        component={Landing}
                                      />
                                      <Route
                                        path="/settings"
                                        component={UserSettingsTab}
                                      />
                                    </Router>
                                  </EventsListContextProvider>
                                </SpaceBookingFormProvider>
                              </DeskBookingContextProvider>
                            </SidebarProvider>
                          </WhosInProvider>
                        </UserScheduleProvider>
                      </SettingsProvider>
                    </DateProvider>
                  </CalendarProvider>
                </LocationsProvider>
              </UserContextProvider>
            </ToastContextProvider>
          </ApolloContextProvider>
        </RKTheme>
      </FluentUIProvider>
    </ErrorBoundary>
  );
}

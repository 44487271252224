(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("warning"), require("shallow-equal"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "warning", "shallow-equal"], factory);
	else if(typeof exports === 'object')
		exports["@robinpowered/atlas-common"] = factory(require("prop-types"), require("react"), require("warning"), require("shallow-equal"));
	else
		root["@robinpowered/atlas-common"] = factory(root["prop-types"], root["react"], root["warning"], root["shallow-equal"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 
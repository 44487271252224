enum LayerEventType {
  MOUSEDOWN = 'mousedown',
  MOUSEUP = 'mouseup',
  MOUSEOVER = 'mouseover',
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
  DBLCLICK = 'dblclick',
  MOUSEENTER = 'mouseenter',
  MOUSELEAVE = 'mouseleave',
  MOUSEOUT = 'mouseout',
  CONTEXTMENU = 'contextmenu',
  WHEEL = 'wheel'
}

const eventPropToEventName = {
  onMouseDown: LayerEventType.MOUSEDOWN,
  onMousedown: LayerEventType.MOUSEDOWN,
  onMouseUp: LayerEventType.MOUSEUP,
  onMouseup: LayerEventType.MOUSEUP,
  onMouseOver: LayerEventType.MOUSEOVER,
  onMouseover: LayerEventType.MOUSEOVER,
  onMouseMove: LayerEventType.MOUSEMOVE,
  onMousemove: LayerEventType.MOUSEMOVE,
  onClick: LayerEventType.CLICK,
  onDoubleClick: LayerEventType.DBLCLICK,
  onDoubleclick: LayerEventType.DBLCLICK,
  onDblclick: LayerEventType.DBLCLICK,
  onMouseEnter: LayerEventType.MOUSEENTER,
  onMouseenter: LayerEventType.MOUSEENTER,
  onMouseLeave: LayerEventType.MOUSELEAVE,
  onMouseleave: LayerEventType.MOUSELEAVE,
  onMouseOut: LayerEventType.MOUSEOUT,
  onMouseout: LayerEventType.MOUSEOUT,
  onContextMenu: LayerEventType.CONTEXTMENU,
  onContextmenu: LayerEventType.CONTEXTMENU,
  onWheel: LayerEventType.WHEEL
};

const LayerEventTypeList = Object.values(LayerEventType);

export default LayerEventType;

export {LayerEventTypeList, eventPropToEventName};

import { useMemo } from 'react';
import { useUserContext } from '../..';
import { Location } from './useOrgLocations';

function findLocation(passedId: string, locationList: Location[]) {
  return locationList.find((l) => l.id === passedId);
}

/**
 * Returns
 * a. the location from the url
 * b. the default location returned on user settings
 * c. the first location in the list of user locations
 * d. null
 */
export function useBestLocation(locations: Location[]): Location {
  //   const query = useQueryParams();
  const { currentUser } = useUserContext();
  const defaultLocationId = useMemo(
    () => currentUser?.defaultLocation,
    [currentUser]
  );
  const bestLocation = useMemo(() => {
    // Dashboard app first looks to see what slug is in the route
    // We don't have that so first check users default location, then just grab first

    if (defaultLocationId) {
      const defaultLocation = findLocation(defaultLocationId, locations);

      if (defaultLocation) {
        return defaultLocation;
      }
    }

    return locations?.[0];
  }, [locations, defaultLocationId]);

  return bestLocation;
}

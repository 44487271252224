import { Button } from '@fluentui/react-northstar';
import { GetUserScheduleDeskReservations as Reservation } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { DeskConfirmationStatus } from '../../../__generated__/types';

type Props = {
  handleCheckIn: () => void;
  disabled: boolean;
  reservation: Reservation;
  isLoading?: boolean;
};

export const CheckInButton = ({
  handleCheckIn,
  disabled,
  reservation,
  isLoading,
}: Props): JSX.Element | null => {
  if (reservation.confirmation?.status !== DeskConfirmationStatus.UNCONFIRMED) {
    return null;
  }

  return (
    <Button
      primary
      style={{ width: '100%' }}
      disabled={
        disabled ||
        reservation.seat.reservationPolicies?.checkinPolicies
          ?.enforceLocalCheckinOnly
      }
      onClick={handleCheckIn}
      loading={isLoading}
      content="Check in"
    />
  );
};

import { gql, MutationTuple, useMutation } from '@apollo/client';
import { robinLogger } from '../../utilities/RobinLogger';
import {
  AddSpaceToEventMutation,
  AddSpaceToEventVariables,
} from './__generated__/addSpaceToEvent.generated';
// import { Sentry } from 'lib';
// import { AmplitudeEvents } from 'constants/amplitudeEvents';

export const ADD_SPACE_TO_EVENT = gql`
  mutation AddSpaceToEvent($request: AddSpaceToEventRequest) {
    addSpaceToEvent(request: $request) {
      eventUpdated
      updatedEvent {
        id
        startTime
        endTime
        spaces {
          id
          name
          locationId
        }
      }
      error {
        message
        reason
      }
    }
  }
`;

type AddSpaceMutationTuple = MutationTuple<
  AddSpaceToEventMutation,
  AddSpaceToEventVariables
>;

type EnhancedFunction = (
  options: Parameters<AddSpaceMutationTuple[0]>[0] & {
    spaceWasSuggested: boolean;
  }
) => ReturnType<AddSpaceMutationTuple[0]>;

type EnhancedAddSpaceMutationTuple = [
  EnhancedFunction,
  AddSpaceMutationTuple[1]
];

export function useAddSpaceToEvent(): EnhancedAddSpaceMutationTuple {
  const logger = robinLogger();

  const [originalFunction, result] = useMutation<
    AddSpaceToEventMutation,
    AddSpaceToEventVariables
  >(ADD_SPACE_TO_EVENT, { fetchPolicy: 'network-only' });

  const enhancedFunction: EnhancedFunction = async ({
    spaceWasSuggested,
    ...rest
  }) => {
    const { variables } = rest;
    let originalReturnValue;
    try {
      originalReturnValue = await originalFunction(rest);
    } catch (e) {
      //Sentry.captureException(e);
      throw e;
    }
    const error = originalReturnValue.data?.addSpaceToEvent.error;
    if (error) {
      //Sentry.captureException(error.message);
    } else {
      // all successfully booked spaces in robin should track this event
      logger.reportEvent('booked-space', {
        spaceWasSuggested,
        event: variables?.request?.eventId,
        space: variables?.request?.spaceId,
      });
    }
    return originalReturnValue;
  };

  return [enhancedFunction, result];
}

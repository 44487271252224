import { BrownFontStack, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import InfoSolid from '@robinpowered/icons/InfoSolid';
import MinorPinSolid from '@robinpowered/icons/MinorPinSolid';
import SvgCircleXSolid from '@robinpowered/icons/CircleXSolid';
import SvgCircleCheckmarkSolid from '@robinpowered/icons/CircleCheckmarkSolid';

type IconBylineProps = {
  type: IconBylineType;
  copy?: string;
  required?: boolean;
  href?: string;
};

type IconBylineType = 'LOCATION' | 'SUCCESS' | 'INFO' | 'WARN' | 'FAIL';

export const SidebarIconByline = ({
  type,
  copy,
  required,
  href,
}: IconBylineProps): JSX.Element | null => {
  const getIcon = () => {
    switch (type) {
      case 'LOCATION':
        return <MinorPinSolid size={16} color={Colors.Gray60} />;
      case 'SUCCESS':
        return (
          <SvgCircleCheckmarkSolid size={16} color={Colors.GreenProduct} />
        );
      case 'INFO':
      case 'WARN':
        return (
          <InfoSolid
            size={16}
            color={required ? Colors.RedProduct : Colors.Gray60}
          />
        );
      case 'FAIL':
        return <SvgCircleXSolid size={16} color={Colors.RedProduct} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <IconContainer>{getIcon()}</IconContainer>
      {href ? <TextLink href={href}>{copy}</TextLink> : <Byline>{copy}</Byline>}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const IconContainer = styled.div`
  margin-top: 2px;
`;

const Byline = styled.div`
  margin-left: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const TextLink = styled.a`
  font-family: ${BrownFontStack};
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${Colors.Gray100};
  border-radius: 4px;
  border: none;
  margin-left: 4px;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: ${Colors.Gray30};
  &:hover {
    color: ${Colors.Blue100};
    text-decoration-color: ${Colors.Blue100};
  }
  &:focus {
    outline: none;
    text-decoration: none;
    box-shadow: 0 0 0 4px ${Colors.Blue100};
  }
`;

import { FC, useMemo } from 'react';
import { Avatar, Flex, Text } from '@fluentui/react-northstar';
import { DeskReservation } from '../../../types';
import { DeskType } from './utility';

type Props = {
  deskName?: string;
  levelName?: string;
  deskType: DeskType;
  reservation?: DeskReservation;
  isFavorite?: boolean;
};

export const DeskReservee: FC<Props> = ({
  deskName,
  levelName,
  deskType,
  reservation,
}) => {
  const user = useMemo(() => {
    return reservation?.accountReservee?.user;
  }, [reservation]);

  const isAnonymous = useMemo(() => {
    return deskType === 'Anonymous';
  }, [deskType]);

  const userNameText = useMemo(() => {
    return deskType === 'Anonymous' ? `${deskName}, ${levelName}` : user?.name;
  }, [deskName, deskType, levelName, user?.name]);

  const deskTypeText = useMemo(() => {
    if (isAnonymous) {
      return 'Anonymous reservation';
    }

    return deskType;
  }, [deskType, isAnonymous]);

  return (
    <Flex gap="gap.small" vAlign="center">
      {isAnonymous ? (
        <Avatar name="Reserved" size="smallest" />
      ) : (
        <Avatar
          name={user?.name || 'Reserved'}
          image={user?.avatar}
          size="smallest"
        />
      )}

      <Flex column>
        <Text weight="bold">{userNameText}</Text>
        <Text>{deskTypeText}</Text>
      </Flex>
    </Flex>
  );
};

import styled from '@emotion/styled';
import { Button, Flex } from '@fluentui/react-northstar';
import { Redirect } from 'react-router-dom';
import { useUserContext } from '../contexts';
import { config } from '../config';
import { useEffect, useState } from 'react';
import { app, authentication, pages } from '@microsoft/teams-js';

export const Landing = () => {
  const { teamsBotServiceUrl, appId } = config;
  const { currentUser } = useUserContext();

  const [currentTabId, setCurrentTabId] = useState<string>('conversations');

  useEffect(() => {
    app
      .initialize()
      .then(() => app.getContext())
      .then((context) => {
        setCurrentTabId(context.page.id);
      });
  }, []);

  if (currentUser) {
    <Redirect to="/schedule" />;
  }

  return (
    <Flex
      hAlign="center"
      vAlign="center"
      gap="gap.small"
      column
      padding="padding.medium"
    >
      <BoldText>
        Hi! Please login to your Robin account to get started using Robin in
        Teams
      </BoldText>

      <SplashLogo src="https://static.robinpowered.com/reimagine/images/7eefbedab6290c5787d5a1c6c819bb0fe26571a1_1242.png" />

      <Button
        style={{
          color: 'white',
          backgroundColor: '#464EB8',
        }}
        onClick={() => {
          app.initialize().then(() => {
            authentication
              .authenticate({
                url: `${teamsBotServiceUrl}/oauth/kickoff`,
                width: 600,
                height: 535,
              })
              .then(async (result) => {
                console.log('Login succeeded: ' + JSON.stringify(result));
                pages.navigateToApp({
                  appId: appId,
                  pageId: currentTabId,
                });
              })
              .catch(async (reason) => {
                console.log('Login failed: ' + JSON.stringify(reason));
              });
          });
        }}
      >
        Log in to Robin
      </Button>
    </Flex>
  );
};

const BoldText = styled.div`
  font-family: Segoe UI;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  flex: 1;
`;

const SplashLogo = styled.img`
  height: 360px;
`;

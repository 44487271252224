import styled from '@emotion/styled';
import {
  Box,
  Heading,
  Input,
  UtilityButton,
} from '@robinpowered/design-system';
import Close from '@robinpowered/icons/Close';
import { LocationRow } from './LocationRow';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { ChangeEvent, useMemo, useState } from 'react';
import { useLocationsContext } from '../../../contexts';
import { Modal } from './Modal';

const modalStyles = {
  width: '426px',
  padding: '20px 0 0 0',
};

export const LocationPickerModal = (): JSX.Element => {
  const [searchText, setSearchText] = useState('');
  const { locations, setIsLocationModalOpen } = useLocationsContext();

  const filteredLocations = useMemo(() => {
    return locations.filter((location) =>
      location.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [locations, searchText]);

  const closeLocationPickerModal = () => setIsLocationModalOpen(false);
  const handleClose = () => {
    closeLocationPickerModal();
    setSearchText('');
  };

  return (
    <Modal onClose={handleClose} style={modalStyles}>
      <ModalInnerContainer>
        <ModalHeader>
          <Heading.Large>{'Select a building'}</Heading.Large>
          <UtilityButton
            icon={Close}
            onClick={handleClose}
            aria-label={'Close modal'}
            autoFocus
          />
        </ModalHeader>
        {/*
          // eslint-disable-next-line
          // @ts-ignore: Bad type definition in design system */}
        <Input
          icon={<SearchOutline size={20} />}
          placeholder={'Search'}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setSearchText(target.value);
          }}
          mb={4}
          pl={'8px'}
          nonce={''}
        />
        <ModalBody>
          {filteredLocations.map((location) => {
            return (
              <LocationRow
                key={location.id}
                location={location}
                onClick={handleClose}
              />
            );
          })}
        </ModalBody>
      </ModalInnerContainer>
    </Modal>
  );
};

const ModalInnerContainer = styled(Box)`
  padding: 0 20px;
  margin-bottom: 20px;
`;

const ModalHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const ModalBody = styled(Box)`
  height: 100%;
  overflow-y: auto;
  max-height: 380px;
`;

import { gql, useQuery } from '@apollo/client';
import {
  GetSpaceInfoByIdQuery,
  GetSpaceInfoByIdQueryVariables,
} from './__generated__/useGetSpaceById.generated';

const GET_SPACE_BY_ID = gql`
  query GetSpaceInfoById($getSpaceByIdId: ID!) {
    getSpaceById(id: $getSpaceByIdId) {
      amenities {
        name
        quantity
        id
      }
      availableAt
      description
      id
      image
      permissions {
        name
        value
      }
      type
      name
      capacity
      locationId
      reservationPolicies {
        id
        meetingCheckins
        abandonedMeetingProtection
        abandonedThreshold
        strikesForRecurringEvents
        maxReservationLength
        advanceBookingThreshold
        allowRecurringEvents
        restrictBookingToWorkingHours
      }
      scheduleConfiguration
    }
  }
`;

export const useGetSpaceById = (selectedSpaceId?: string) => {
  return useQuery<GetSpaceInfoByIdQuery, GetSpaceInfoByIdQueryVariables>(
    GET_SPACE_BY_ID,
    {
      variables: {
        getSpaceByIdId: selectedSpaceId ?? '',
      },
      skip: !selectedSpaceId,
    }
  );
};

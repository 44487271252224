import { createContext, useContext, useEffect, useState } from 'react';
import {
  useQuery,
  gql,
  ApolloError,
  OperationVariables,
  ApolloQueryResult,
} from '@apollo/client';
import { useApolloContext } from '..';
import { config } from '../../config';
import ky from 'ky-universal';
import { robinLogger } from '../../utilities/RobinLogger';
import { Landing } from '../../tabs';
import { Loader } from '@fluentui/react-northstar';
import { USER_AZURE_ID } from '../../constants/location';
import { getAccessToken } from '../../services/teamsBot';
import {
  UserContextQueryQuery,
  UserContextQueryUser,
} from './__generated__/UserContext.generated';
import { useTenantLocalStorage } from '../../utilities';

export type ApiOrganizations = {
  data: OrganizationData[];
};

export type OrganizationData = {
  id: string;
  is_organization: boolean;
  name: string;
  slug: string;
  avatar: string;
};

const GET_CURRENT_USER_QUERY = gql`
  query UserContextQuery {
    getCurrentUserAndOrgs {
      userId
      user {
        id
        name
        timeZone
        defaultLocation
        avatar
      }
    }
  }
`;

type UserContextValue = {
  loading: boolean;
  error: undefined | ApolloError;
  currentUser: null | UserContextQueryUser;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<UserContextQueryQuery>>;
  currentOrgs: OrganizationData[] | undefined;
  azureAdId: string | undefined;
};

const UserContext = createContext<UserContextValue>({
  loading: true,
  error: undefined,
  currentUser: null,
  refetch: undefined,
  currentOrgs: undefined,
  azureAdId: undefined,
});

export const UserContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [userOrganizations, setUserOrganizations] = useState<
    OrganizationData[] | undefined
  >(undefined);
  const { tenantId, jwt, setTenantId, setJwt } = useApolloContext();
  const [azureAdId, setAzureAdId] = useTenantLocalStorage<string>(
    USER_AZURE_ID,
    undefined
  );
  const [teamsContextloading, setTeamsContextLoading] = useState<boolean>(true);
  const [userContextHasError, setUserContextHasError] =
    useState<boolean>(false);

  const { teamsBotServiceUrl } = config;
  const serviceUrl = `${teamsBotServiceUrl}/token`;

  useEffect(() => {
    if (!serviceUrl) {
      return;
    }

    let simulatedLoadingTimeout: NodeJS.Timeout;

    if (process.env.REACT_APP_IS_OUTSIDE_TEAMS === 'true') {
      simulatedLoadingTimeout = setTimeout(async () => {
        setAzureAdId(process.env.AZURE_ACTIVE_DIR_ID);
        setJwt(process.env.REACT_APP_LOCAL_JWT);

        const { robinApiUrl } = config;
        const api = ky.extend({
          hooks: {
            beforeRequest: [
              (request) => {
                request.headers.set(
                  'Authorization',
                  `Bearer ${process.env.REACT_APP_LOCAL_JWT}`
                );
              },
            ],
          },
        });

        const orgs: ApiOrganizations = await api
          .get(`${robinApiUrl}/v1.0/me/organizations?per_page=50`)
          .json();

        setUserOrganizations(orgs.data);

        if (!tenantId) setTenantId(orgs.data[0].id);

        setTeamsContextLoading(false);
      }, 1000);

      return;
    }

    const fetchTeamsData = async () => {
      const { robinApiUrl } = config;

      const microsoftTeams = await import('@microsoft/teams-js');

      microsoftTeams.initialize(() => {
        microsoftTeams.getContext(async (context) => {
          if (!jwt) {
            try {
              if (!context.userObjectId) {
                throw new Error(
                  'User object ID is undefined on the teams context'
                );
              }

              setAzureAdId(context.userObjectId);
              const res = await getAccessToken(context.userObjectId);

              robinLogger().setUserId(context.userObjectId);

              const bearer: string = res.token.access_token;

              const api = ky.extend({
                hooks: {
                  beforeRequest: [
                    (request) => {
                      request.headers.set('Authorization', `Bearer ${bearer}`);
                    },
                  ],
                },
              });

              const orgs: ApiOrganizations = await api
                .get(`${robinApiUrl}/v1.0/me/organizations?per_page=50`)
                .json();

              setUserOrganizations(orgs.data);

              //Start with first org until a different one is selected
              if (!tenantId) setTenantId(orgs.data[0].id);
              setJwt(bearer);
              setTeamsContextLoading(false);
            } catch (e) {
              setUserContextHasError(true);
              setTeamsContextLoading(false);
            }
          }
        });
      });
    };

    fetchTeamsData();

    return () => {
      clearTimeout(simulatedLoadingTimeout);
    };
  }, [serviceUrl]);

  const {
    data,
    loading: userLoading,
    error: userLoadingError,
    refetch,
  } = useQuery<UserContextQueryQuery>(GET_CURRENT_USER_QUERY, {
    skip: !jwt,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  const loading =
    teamsContextloading ||
    userLoading ||
    (!userLoading && !tenantId && !userContextHasError);

  const currentUser = data?.getCurrentUserAndOrgs?.user;

  const value: UserContextValue = {
    loading,
    error: userLoadingError,
    currentUser: currentUser ?? null,
    refetch,
    currentOrgs: userOrganizations,
    azureAdId,
  };

  // Report session created whenever a new user context is initialized
  if (currentUser?.id) robinLogger().setUserId(currentUser.id);
  robinLogger().reportEvent('session-created');

  return (
    <UserContext.Provider value={value}>
      {loading ? (
        <Loader></Loader>
      ) : !currentUser ? (
        <Landing></Landing>
      ) : (
        // Only load children (the remaining contexts and the actual app) after the user has been resolved
        !loading && currentUser && children
      )}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextValue => {
  return useContext(UserContext);
};

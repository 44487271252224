import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  UseAddSpaceToEventMutation,
  UseAddSpaceToEventMutationVariables,
} from './__generated__/useAddSpaceToEvent.generated';
import { robinLogger } from '../../../utilities/RobinLogger';

export const ADD_SPACE_TO_EVENT = gql`
  mutation useAddSpaceToEvent($request: AddSpaceToEventRequest) {
    addSpaceToEvent(request: $request) {
      eventUpdated
      updatedEvent {
        id
        startTime
        endTime
        spaces {
          id
          name
          locationId
        }
      }
      error {
        message
        reason
      }
    }
  }
`;

type AddSpaceMutationTuple = MutationTuple<
  UseAddSpaceToEventMutation,
  UseAddSpaceToEventMutationVariables
>;

type EnhancedFunction = (
  options: Parameters<AddSpaceMutationTuple[0]>[0] & {
    spaceWasSuggested: boolean;
  }
) => ReturnType<AddSpaceMutationTuple[0]>;

type EnhancedAddSpaceMutationTuple = [
  EnhancedFunction,
  AddSpaceMutationTuple[1]
];

export function useAddSpaceToEvent(): EnhancedAddSpaceMutationTuple {
  const { reportError, reportEvent } = robinLogger();

  const [originalFunction, result] = useMutation<
    UseAddSpaceToEventMutation,
    UseAddSpaceToEventMutationVariables
  >(ADD_SPACE_TO_EVENT, { fetchPolicy: 'network-only' });

  const enhancedFunction: EnhancedFunction = async ({
    spaceWasSuggested,
    ...rest
  }) => {
    const { variables } = rest;
    let originalReturnValue;

    try {
      originalReturnValue = await originalFunction(rest);
    } catch (e) {
      if (e instanceof Error) {
        reportError(e);
      } else {
        reportError(new Error(JSON.stringify(e)));
      }

      throw e;
    }

    const error = originalReturnValue.data?.addSpaceToEvent.error;
    if (error) {
      reportError(new Error(`${error.reason}: ${error.message}`));
    } else {
      // all successfully booked spaces in robin should track this event
      reportEvent('booked-space', {
        spaceWasSuggested,
        event: variables?.request?.eventId,
        space: variables?.request?.spaceId,
      });
    }

    return originalReturnValue;
  };

  return [enhancedFunction, result];
}

import { createContext, useContext } from 'react';
import { useFeatureFlagQuery } from './hooks/useGetAllFeatureFlags';
import { useGetSuggestedSpacesEnabledForOrg } from './hooks/useGetSuggestedSpacesEnabled';

type SettingsContextType = {
  teamsSpaceBookingEnabled?: boolean;
  suggestedSpacesEnabled?: boolean;
};

const SettingsContext = createContext<SettingsContextType>({});

export const SettingsProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const featureFlagRequest = useFeatureFlagQuery();

  const suggestedSpacesFeatureFlagEnabled =
    featureFlagRequest.data?.suggestedSpacesFeatureFlagEnabled;

  const teamsSpaceBookingsFeatureFlagEnabled =
    featureFlagRequest.data?.teamsSpaceBookingFeatureFlagEnabled;

  const settingQuery = useGetSuggestedSpacesEnabledForOrg(true);

  const suggestedSpacesSettingEnabled =
    settingQuery.data?.suggestedSpacesEnabled;

  return (
    <SettingsContext.Provider
      value={{
        teamsSpaceBookingEnabled: teamsSpaceBookingsFeatureFlagEnabled,
        suggestedSpacesEnabled:
          suggestedSpacesFeatureFlagEnabled && suggestedSpacesSettingEnabled,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = (): SettingsContextType => {
  return useContext(SettingsContext);
};

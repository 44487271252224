import { Flex, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { NotificationSettingType } from '../../__generated__/types';
import { UserSetting } from './UserSetting';
import {
  UserNotificationSetting,
  useUserNotificationSettingsData,
} from './graphql/useUserNotificationSettingsData';
import { useSetUserNotificationSetting } from './graphql/useSetUserNotificationSetting';

type UserNotificationSettingsProps = {
  userId: string;
};

// Any settings in this list will get a 'NEW' label applied
const NEW_SETTINGS: UserNotificationSettingsSlugs[] = [
  'notifications-reminder-event',
  'notifications-visitor-management-guest-arrival',
  'notifications-weekly-booking-digest-for-user',
];

export const UserNotificationSettings = ({
  userId,
}: UserNotificationSettingsProps) => {
  const { data: userNotificationSettings } =
    useUserNotificationSettingsData(userId);

  const setUserNotificationSetting = useSetUserNotificationSetting();

  const handleToggleSetting = (setting: UserNotificationSetting) => {
    let updatedSetting = {
      ...setting,
      values: [...setting.values],
    };

    const foundIndex = updatedSetting.values.findIndex(
      (value) => value === NotificationSettingType.TEAMS
    );
    if (foundIndex > -1) updatedSetting.values.splice(foundIndex, 1);
    else updatedSetting.values.push(NotificationSettingType.TEAMS);

    try {
      setUserNotificationSetting({
        variables: {
          input: {
            name: updatedSetting.name,
            ownerId: updatedSetting.ownerId,
            values: updatedSetting.values,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { t } = useTranslation('notifications');

  return (
    <Flex column padding="padding.medium" vAlign="center" gap="gap.small">
      <Text size="large" weight="semibold">
        {t('sectionLabel')}
      </Text>
      {userNotificationSettings.map((setting, index) => {
        const isNewSetting = NEW_SETTINGS.includes(
          setting?.name as UserNotificationSettingsSlugs
        );

        if (setting) {
          return (
            <UserSetting
              key={setting.name}
              setting={setting}
              index={index}
              isNewSetting={isNewSetting}
              onChange={handleToggleSetting}
            />
          );
        }
      })}
    </Flex>
  );
};

import moment, { Moment } from 'moment';
import { RRule } from 'rrule';
import {
  OfficeAccess,
  OrgLocation as Location,
  WorkingHours,
} from '../contexts';

export function isOfficeClosed(date: Moment, location: Location) {
  if (!location.workingHours) {
    return false;
  }
  const day = date.day();
  const timeFrames = getTimeFrames(location.workingHours, day);

  // The office is represented as closed if the custom work hours
  // returns an empty array for the timeframes on a particular day
  const customWorkHoursForDate = location?.customWorkingHours.find(
    (customWorkHour) => moment(customWorkHour.date).isSame(date, 'day')
  );

  return customWorkHoursForDate
    ? customWorkHoursForDate.timeFrames.length === 0
    : !timeFrames || timeFrames.length === 0;
}

export function getTimeFrames(
  workingHours: WorkingHours[],
  selectedDay: number
) {
  return workingHours.find(({ day }) => day === selectedDay)?.timeFrames;
}

// Constructs a list of days to represent office access in the pass UI.
export const getAccessDays = (schedule: string[]): number[] => {
  const rrule = RRule.fromString(schedule.join('\n'));
  const access = rrule.options.byweekday.map((day) => {
    return (day + 1) % 7;
  });

  access.sort();
  return access;
};

export const hasAccessOnDay = (
  selectedDate: Moment,
  officeAccess?: OfficeAccess[]
) => {
  if (officeAccess && officeAccess.length > 0) {
    const accessDays = Array.from(
      new Set(
        officeAccess.reduce<number[]>((acc, offAcc) => {
          return [...acc, ...getAccessDays(offAcc.schedule)];
        }, [])
      )
    );

    return accessDays.indexOf(selectedDate.day()) >= 0;
  } else {
    return false;
  }
};

import React, { useContext } from 'react';
import { MapContext, withMap, LayerFeatures } from '@robinpowered/atlas-web';
import { withLayers, MapboxFeature } from '@robinpowered/atlas-common';
import { WorkAreasContext } from './WorkAreasContext';
import { WorkAreaPopup } from './WorkAreaPopup';
import { useWorkAreaMapInteractions } from '../../../contexts/SpaceBooking/hooks/useWorkAreaMapInteractions';

interface WorkAreaProps {
  map: MapContext;
  seatFeatures: MapboxFeature[];
}
const WorkAreasOverlay = withMap(
  ({ map, seatFeatures }: WorkAreaProps): JSX.Element | null => {
    const { workAreas } = useContext(WorkAreasContext);
    const workAreaMapInteractions = useWorkAreaMapInteractions(
      map,
      seatFeatures,
      workAreas
    );

    return (
      <>
        {workAreas.map((workArea) => (
          <WorkAreaPopup
            key={`${workArea.id}`}
            workArea={workArea}
            centroid={workAreaMapInteractions.getWorkAreaCentroid(workArea)}
          />
        ))}
      </>
    );
  }
);

export const WorkAreas = withMap(
  withLayers('seats')((sources) => (
    <LayerFeatures specifier={sources.layers}>
      {({ features }: { features: MapboxFeature[] }) => (
        <WorkAreasOverlay seatFeatures={features} />
      )}
    </LayerFeatures>
  ))
);

import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
} from '@apollo/client';
import { createContext, useCallback, useContext } from 'react';
import { useUserOfficeSchedule } from './hooks/useUserOfficeSchedule';
import { GetUserScheduleQuery } from './hooks/__generated__/useUserOfficeSchedule.generated';
import { useDateContext } from '../Date/DateContext';
import { Moment } from 'moment';
import moment from 'moment';
import { DeskConfirmationStatus } from '../../__generated__/types';
import { useLocationsContext } from '../Location';
import { isUserInOffice } from '../../components/OfficeDayList/utlity';

type UserScheduleProviderProps = {
  children: React.ReactNode;
};

type UserScheduleContextType = {
  data: GetUserScheduleQuery | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<GetUserScheduleQuery>>;
  isUserInOfficeForScheduleDay: (date: Moment) => unknown;
};

const UserScheduleContext = createContext<UserScheduleContextType>({
  data: undefined,
  error: undefined,
  loading: false,
  refetch: undefined,
  isUserInOfficeForScheduleDay: () => undefined,
});

export const UserScheduleProvider = ({
  children,
}: UserScheduleProviderProps) => {
  const { weekStartDate, weekEndDate } = useDateContext();
  const { selectedLocation } = useLocationsContext();

  const { data, error, loading, refetch } = useUserOfficeSchedule({
    start: weekStartDate,
    end: weekEndDate,
  });

  const isUserInOfficeForScheduleDay = useCallback(
    (date: Moment) => {
      const scheduleDay = data?.getUserInOfficeDataByDay.find((schedule) => {
        return moment(date).isSame(schedule.date, 'date');
      });
      if (!scheduleDay) {
        return false;
      }
      const visit = scheduleDay?.userInOffice?.employeeVisits?.find((visit) => {
        return selectedLocation?.id === visit.locationId;
      });
      const reservation = scheduleDay?.userInOffice?.deskReservations?.find(
        (reservation) => {
          if (selectedLocation?.id !== reservation.seat.location.id) {
            return false;
          }
          if (
            reservation.confirmation?.status === DeskConfirmationStatus.DECLINED
          ) {
            return false;
          }
          return true;
        }
      );
      return isUserInOffice(visit, reservation);
    },
    [data, selectedLocation]
  );

  return (
    <UserScheduleContext.Provider
      value={{
        data,
        error,
        loading,
        refetch,
        isUserInOfficeForScheduleDay,
      }}
    >
      {children}
    </UserScheduleContext.Provider>
  );
};

export const useUserScheduleContext = (): UserScheduleContextType => {
  return useContext(UserScheduleContext);
};

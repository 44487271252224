import React, { useMemo } from 'react';
import {
  CapacityType,
  useSpaceBookingContext,
} from '../../contexts/SpaceBooking/SpaceBookingContext';
import { Flex, RadioGroup } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

type CapacityFilterType = {
  maxCapacity: number;
  onChange: (capacity: CapacityType) => void;
};
export const CapacityFilter: React.FC<CapacityFilterType> = ({
  maxCapacity,
  onChange,
}) => {
  const { t } = useTranslation('spaceBooking', { keyPrefix: 'filters' });
  const { filters } = useSpaceBookingContext();

  const capacityOptions = useMemo(() => {
    const filters = [1, 3, 5, 7, 9];
    const capFilters = filters
      .filter((filter) => filter < maxCapacity)
      .map((mappedFilter) => {
        const capacity =
          mappedFilter >= 9
            ? '9+'
            : (`${mappedFilter}-${mappedFilter + 1}` as CapacityType);
        return {
          label: `${capacity}`,
          value: capacity,
        };
      });

    capFilters.unshift({ value: 'any', label: t('any') });

    return capFilters;
  }, [maxCapacity, t]);

  return (
    <Flex display={'flex'} flexDirection={'column'}>
      <RadioGroup
        value={filters.capacity}
        options={capacityOptions}
        onChange={onChange}
      />
    </Flex>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getCacheTS() {
    try {
        return window.localStorage.getItem('ATLAS_MAP_CACHE_TS');
    }
    catch (e) {
        return null;
    }
}
/**
 * Creates request transformed to use when requesting style spec & resources.
 *
 * @param  {string} urlToIntercept The URL to intercept.
 * @param  {string} accessToken The token to set for requests.
 * @return {Function} The transformer for requests.
 */
var createRequestTransformer = function (atlasServiceUrl, accessToken) {
    return function (url) {
        // Check the localstorage for a cache busting TS.
        // If its set, attach it to every request as a query param
        // such that we bust any locally cached asssets in the browser.
        var cacheTS = getCacheTS();
        if (cacheTS) {
            var hasQueryParams = url.includes('?');
            url = hasQueryParams
                ? url + "&invalidates_local_cache=" + cacheTS
                : url + "?invalidates_local_cache=" + cacheTS;
        }
        if (url.indexOf(atlasServiceUrl) !== 0) {
            // If the URL isn't Atlas service as given exactly - skip.
            return { url: url };
        }
        return {
            url: url,
            headers: {
                // Add Auth headers.
                Authorization: "Access-Token " + accessToken
            }
        };
    };
};
exports.default = createRequestTransformer;

import styled from '@emotion/styled';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  AvatarProps,
  AvatarStack,
  Box,
  Colors,
  Flex,
  Row,
} from '@robinpowered/design-system';
import { useRouteMatch } from 'react-router-dom';
import { useLocalizationContext } from '../../contexts/Localization/LocalizationContext';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';

type props = {
  event?: Event;
  avatars?: AvatarProps[];
  amIInOffice?: boolean;
};

export const EventsListSummary = ({
  event,
  avatars,
  amIInOffice,
}: props): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { url } = useRouteMatch();

  const { timeFormat } = useLocalizationContext();

  const totalNumberOfWhosIn = event?.whosInData.totalNumberOfWhosIn || 0;
  const onlyIAmInOffice = amIInOffice && totalNumberOfWhosIn === 1;

  return (
    <EventSummaryContainer>
      <Flex display="flex" flexDirection="row">
        <div style={{ color: Colors.Gray80 }}>
          {event?.isAllDay
            ? t('events.all_day')
            : t('events.event_time', {
                start: moment(event?.startTime).format(timeFormat),
                end: moment(event?.endTime).format(timeFormat),
              })}
        </div>
      </Flex>
      <Box width="100%" marginTop="4px">
        <Box>
          <EventTextLink href={`${url}/${event?.id}`}>
            {event?.title || ''}
          </EventTextLink>
        </Box>
        {totalNumberOfWhosIn > 0 && !onlyIAmInOffice && (
          <Row alignItems="baseline" mt={'8px'}>
            {
              /*
          // eslint-disable-next-line
          // @ts-ignore: Bad type definition in design system */
              avatars && <AvatarList avatars={avatars} limit={3} />
            }
            <div style={{ alignSelf: 'center', color: Colors.Gray80 }}>
              {t(
                `${
                  totalNumberOfWhosIn !== 1
                    ? 'events.attendees_in_office_plural'
                    : 'events.attendees_in_office'
                }`,
                {
                  count: totalNumberOfWhosIn,
                }
              )}
            </div>
          </Row>
        )}
      </Box>
    </EventSummaryContainer>
  );
};

//TODO: Add Textlink back
const EventTextLink = styled.text`
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;

  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const EventSummaryContainer = styled(Flex)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
`;

const AvatarList = styled(AvatarStack)`
  div {
    width: 24px;
    height: 24px;
  }
  margin-right: 10px;
`;

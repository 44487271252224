import {Component} from 'react';
import {createPortal} from 'react-dom';
import maplibre from 'maplibre-gl';
import PropTypes from 'prop-types';
import {
  shallowEqualArrays,
  shallowEqualObjects
} from '@robinpowered/atlas-common';
import {withMap} from '../Map';

export class Popup extends Component {
  static propTypes = {
    /**
     * The lng/lat of the popup.
     */
    lngLat: PropTypes.arrayOf(PropTypes.number),
    /**
     * Determines if popup should close when clicking outside.
     */
    closeOnClick: PropTypes.bool,
    /**
     * The popup anchor.
     * @see {@link https://docs.mapbox.com/mapbox-gl-js/api/#popup}
     */
    anchor: PropTypes.string,
    /**
     * The popup offset.
     * @see {@link https://docs.mapbox.com/mapbox-gl-js/api/#popup}
     */
    offset: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.shape({
        top: PropTypes.arrayOf(PropTypes.number),
        'top-left': PropTypes.arrayOf(PropTypes.number),
        'top-right': PropTypes.arrayOf(PropTypes.number),
        bottom: PropTypes.arrayOf(PropTypes.number),
        'bottom-left': PropTypes.arrayOf(PropTypes.number),
        'bottom-right': PropTypes.arrayOf(PropTypes.number),
        left: PropTypes.arrayOf(PropTypes.number),
        right: PropTypes.arrayOf(PropTypes.number)
      })
    ]),
    /**
     * The class name for the holding container.
     */
    className: PropTypes.string,
    /**
     * Class name for the container of the tooltip.
     */
    containerClassName: PropTypes.string,
    /**
     * Class name for the tip of the tooltip.
     */
    tipClassName: PropTypes.string,
    /**
     * Determines the parent element type.
     */
    as: PropTypes.oneOf(['div', 'span']),
    /**
     * Callback for when popup is closing.
     */
    onClose: PropTypes.func,
    /**
     * Max width of the popup. If not specified, will default to the default value from Mapbox.
     */
    maxWidth: PropTypes.string,
    /**
     * The control contents.
     */
    children: PropTypes.node.isRequired,
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  static defaultProps = {
    closeOnClick: false,
    containerClassName: null,
    tipClassName: null,
    as: 'div'
  };

  state = {
    // @FIXME: Move popup to property on a component instead of being in a state.
    popup: null
  };

  container = document.createElement(this.props.as);

  componentDidUpdate(prevProps) {
    if (prevProps.lngLat !== this.props.lngLat && this.state.popup) {
      this.state.popup.setLngLat(this.props.lngLat);
    }

    if (prevProps.maxWidth !== this.props.maxWidth && this.state.popup) {
      this.state.popup.setMaxWidth(this.props.maxWidth);
    }

    if (
      (typeof prevProps.offset !== typeof this.props.offset ||
        (Array.isArray(this.props.offset) &&
          !shallowEqualArrays(prevProps.offset, this.props.offset)) ||
        (typeof this.props.offset === 'object' &&
          !shallowEqualObjects(prevProps.offset, this.props.offset)) ||
        // It can only be a number
        prevProps.offset !== this.props.offset) &&
      this.state.popup
    ) {
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.popup.options = {
        ...this.state.popup.options,
        offset: this.props.offset
      };
      // Update the popup, which will pick up the update to the offset.
      this.state.popup._update();
    }

    if (prevProps.containerClassName !== this.props.containerClassName) {
      this.updateContainerStyle(
        this.props.containerClassName,
        prevProps.containerClassName
      );
    }

    if (prevProps.tipClassName !== this.props.tipClassName) {
      this.updateTipStyle(this.props.tipClassName, prevProps.tipClassName);
    }
  }

  componentWillUnmount() {
    this.state.popup && this.state.popup.remove();

    this.props.map && this.props.map.off('click', this.handleCloseOnClick);
  }

  closeHandler = () => {
    if (!this.props.onClose) {
      return;
    }

    this.props.onClose();
  };

  handleCloseOnClick = () => {
    if (!this.props.closeOnClick) {
      return;
    }

    this.closeHandler();
  };

  componentDidMount() {
    const {anchor, offset, className, maxWidth} = this.props;
    const popup = new maplibre.Popup({
      // Popups should implement their own close buttons.
      closeButton: false,
      // Always set this to false.
      // we're handling this event in the component itself.
      closeOnClick: false,
      anchor,
      offset,
      className,
      // Only give it max width when one is specified.
      ...(maxWidth !== undefined ? {maxWidth} : {})
    });

    // Attach the holder container as the DOM node for the popup.
    popup.setDOMContent(this.container);

    // Set initial position for the popup.
    popup.setLngLat(this.props.lngLat);

    // Also inform parent about popup's closure.
    // Since we've updated the handler, the popup won't actually close.
    popup.on('close', this.closeHandler);

    this.props.map.on('click', this.handleCloseOnClick);

    // Set the popup, and make it visible on the screen.
    this.setState({popup}, () => {
      this.state.popup.addTo(this.props.map.api);

      // Update the container style.
      this.updateContainerStyle(this.props.containerClassName);

      // Update the tip style.
      this.updateTipStyle(this.props.tipClassName);
    });
  }

  updateContainerStyle = (classToAdd, classToRemove = null) => {
    const element = this.container.closest('.mapboxgl-popup-content');
    if (!element || !classToAdd) return;

    if (classToRemove) {
      element.classList.remove(classToRemove);
    }

    element.classList.add(classToAdd);
  };

  updateTipStyle = (classToAdd, classToRemove = null) => {
    const element = this.container.closest('.mapboxgl-popup-content')
      .previousSibling;
    if (!element || !classToAdd) return;

    if (classToRemove) {
      element.classList.remove(classToRemove);
    }

    element.classList.add(classToAdd);
  };

  render() {
    const {popup} = this.state;

    if (!popup) {
      return null;
    }

    return createPortal(this.props.children, this.container);
  }
}

export default withMap(Popup);

import * as Sentry from '@sentry/react';
import * as Amplitude from '@amplitude/analytics-browser';
import { config } from '../config';
import { ApolloError } from '@apollo/client';

Sentry.init({
  enabled: config.env !== 'development',
  environment: config.env,
  dsn: config.sentryDsn,
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});
Amplitude.init(config.amplitudeKey);

export const logError = (e: Error | ApolloError) => {
  Sentry.captureException(e);
};

const baseEventProperties = {
  application: 'teams',
  appSource: config.appName,
  appVersion: config.appVersion,
};

export const logEvent = (eventName: RobinTeamsEvent, properties: Object) => {
  Amplitude.track(eventName, { ...baseEventProperties, ...properties });
};

export const robinLogger = () => {
  const setUserId = (userId: string) => {
    Amplitude.setUserId(userId);
  };

  const reportEvent = (event: RobinTeamsEvent, properties = {}) => {
    logEvent(event, properties);
  };

  const reportError = (error: Error | ApolloError) => {
    if (config.env !== 'development') logError(error);
  };

  return {
    setUserId,
    reportEvent,
    reportError,
  };
};

import {eventPropToEventName} from '../constants/layerEventType';

function getEventToCallbackFromProps(props) {
  return Object.entries(props).reduce((map, [prop, value]) => {
    const eventName = eventPropToEventName[prop];
    if (eventName) {
      map[eventName] = value;
    }
    return map;
  }, {});
}

export default getEventToCallbackFromProps;

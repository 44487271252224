import { Event } from '../components/EventsList/graphql/useMyEvents'; // should this closer to this component
import moment, { Moment } from 'moment';

/**
 * Filter events that meet the following criteria:
 * - Not all day
 * - Duration less than 720 minutes (12 hours)
 * - Duration longer than 10 minutes
 * - Has no space
 * - Has 2 or more invitees
 * - End time is after now
 */
export const filterForEventsThatNeedSuggestedSpaces = (
  events: Event[],
  now: Moment
) => {
  return events.filter((event) => {
    const datetimeStart = moment(event.startTime);
    const datetimeEnd = moment(event.endTime);

    const durationInMinutes = datetimeEnd.diff(datetimeStart, 'minutes');

    /* Check for events that have no spaces and are in the future */
    return (
      !event.isAllDay &&
      durationInMinutes < 720 &&
      durationInMinutes > 10 &&
      !event.spaces.length &&
      event.invitees.length >= 2 &&
      moment(event.endTime).isAfter(now)
    );
  });
};

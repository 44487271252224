import React, { useCallback, useMemo } from 'react';
import { GetUserScheduleDeskReservations as Reservation } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { robinLogger } from '../../../utilities/RobinLogger';
import { isToday } from '../../../utilities';
import moment from 'moment';
import { Button } from '@fluentui/react-northstar';

type Props = {
  reservation: Reservation;
};

export const HealthCheckpointButton = ({
  reservation,
}: Props): JSX.Element | null => {
  const { reportEvent } = robinLogger();

  const redirectToHealthCheckpoint = `${reservation.healthCheckpoint?.surveyUrl}${reservation.healthCheckpoint?.surveyId}`;

  const isResToday = useMemo(() => {
    return isToday(moment(reservation?.startTime));
  }, [reservation]);

  const handleClick = useCallback(() => {
    if (reservation) {
      reportEvent('started_health_checkpoint');
    }
  }, [reservation, reportEvent]);

  if (!isResToday) {
    return null;
  }

  return (
    <a
      href={redirectToHealthCheckpoint}
      target={'_blank'}
      rel="noopener noreferrer"
    >
      <Button
        primary
        style={{ width: '100%', textDecoration: 'none' }}
        content="Start health check"
        onClick={handleClick}
      ></Button>
    </a>
  );
};

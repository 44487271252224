import styled from '@emotion/styled';
import { Avatar, Box, Flex, Text } from '@fluentui/react-northstar';
import LockSolid from '@robinpowered/icons/v2/LockSolid';
import moment from 'moment';
import { useMemo } from 'react';
import { useDeskBookingContext } from '../../../contexts';
import {
  BookingPolicyType,
  DeskReservationVisibility,
} from '../../../__generated__/types';
import { Colors } from '@robinpowered/design-system';
import CirclePlus from '@robinpowered/icons/v2/CirclePlus';
import { ZoneDeskReservation } from '../../../types';
import { robinLogger } from '../../../utilities/RobinLogger';

type DeskListItemProps = {
  isAvailable: boolean;
  deskName?: string;
  deskId: string;
  levelName?: string;
  deskType?: string;
  isFavorite?: boolean;
  startTime: moment.Moment;
  endTime: moment.Moment;
  reservation?: ZoneDeskReservation;
  seatReservationVisibilityEnabled?: boolean;
};

export const DeskListItem = ({
  isAvailable,
  deskName,
  deskId,
  levelName,
  deskType,
  reservation,
  seatReservationVisibilityEnabled,
  isFavorite,
  startTime,
  endTime,
}: DeskListItemProps) => {
  const { setSelectedDeskId } = useDeskBookingContext();

  const user = useMemo(() => {
    return reservation?.reservee?.user;
  }, [reservation]);

  const isAnonymous = useMemo(() => {
    if (
      reservation?.visibility === DeskReservationVisibility.JUST_ME &&
      seatReservationVisibilityEnabled
    ) {
      return true;
    } else {
      return false;
    }
  }, [reservation?.visibility, seatReservationVisibilityEnabled]);

  const icon = useMemo(() => {
    if (isAvailable) {
      return (
        <CirclePlus color={Colors.White0} fill={Colors.Maroon100} size={38} />
      );
    }

    return isAnonymous ? (
      <LockSolid color={Colors.Gray40} size={28} />
    ) : (
      <Avatar name={user?.name || 'Reserved'} image={user?.avatar} />
    );
  }, [isAnonymous, isAvailable, user?.avatar, user?.name]);

  const headerText = useMemo((): string => {
    return isAvailable || isAnonymous
      ? `${deskName}, ${levelName}`
      : user?.name || 'Reserved';
  }, [deskName, isAnonymous, isAvailable, levelName, user?.name]);

  const subHeaderText = useMemo((): string => {
    if (isAvailable) {
      // Display the booking date and time
      const dateNow = moment();
      const day =
        dateNow.isAfter(startTime) && dateNow.isBefore(endTime)
          ? 'Today'
          : startTime.format('dddd');

      const timeFormat = 'hh:mm a';

      return `${day}, ${startTime.format(timeFormat)} - ${endTime.format(
        timeFormat
      )}`;
    }

    if (isAnonymous) {
      return 'Anonymous reservation';
    }

    if (deskType === BookingPolicyType.ASSIGNED) {
      return 'Assigned';
    }

    return 'Flexible';
  }, [deskType, endTime, isAnonymous, isAvailable, startTime]);

  return (
    <DeskButton
      onClick={() => {
        robinLogger().reportEvent('select_desk_list');
        setSelectedDeskId(deskId);
      }}
    >
      <Flex vAlign="center" gap="gap.small">
        <Box style={{ width: '42px' }}>{icon}</Box>

        <Flex
          column
          hAlign="start"
          style={{ textAlign: 'left', padding: '20px 0' }}
        >
          <Text weight="bold">{headerText}</Text>
          <Text>{subHeaderText}</Text>
        </Flex>
      </Flex>
    </DeskButton>
  );
};

const DeskButton = styled.button`
  cursor: pointer;
  background-color: white;
  appearance: none;
  border: 1px solid #d1d1d1;
  padding: 0 12px;
  margin: 0 12px;
`;

import React from 'react';
import PropTypes from 'prop-types';
import {compose} from 'recompose';
import CursorStyle from '../CursorStyle';
import Tooltips from '../Tooltips';
import {withMap} from '../Map';
import {withLayers, shallowEqualArrays} from '@robinpowered/atlas-common';
import LayerEventType from '../../constants/layerEventType';
import LayerEvents from '../LayerEvents';
import {useLayerFeatureStates} from '../../hooks';
import getEventToCallbackFromProps from '../../utils/getEventToCallbackFromProps';

// Single empty array reference to avoid default props from
// updating their value refs whenever AtlasSeats re-renders.
const emptyArray = [];

export function AtlasSpaces({
  map,
  layers,
  availableIds, // Intentionally has no default.
  bookedIds = emptyArray,
  inUseIds = emptyArray,
  selectedIds = emptyArray,
  restrictedIds = emptyArray,
  warningIds = emptyArray,
  disabledIds = emptyArray,
  focusedIds = emptyArray,
  reducedIds = emptyArray,
  renderTooltipContents,
  ...restOfProps
}) {
  const [hovered, setHovered] = React.useState(false);

  const eventsToCallbacks = React.useMemo(() => {
    const eventsToCallbacks = getEventToCallbackFromProps(restOfProps);
    return {
      ...eventsToCallbacks,
      [LayerEventType.MOUSEENTER]: (...args) => {
        setHovered(true);
        if (eventsToCallbacks[LayerEventType.MOUSEENTER]) {
          return eventsToCallbacks[LayerEventType.MOUSEENTER](...args);
        }
      },
      [LayerEventType.MOUSELEAVE]: (...args) => {
        setHovered(false);
        if (eventsToCallbacks[LayerEventType.MOUSELEAVE]) {
          return eventsToCallbacks[LayerEventType.MOUSELEAVE](...args);
        }
      }
    };
  }, Object.values(restOfProps));

  function getStateFromFeature(feature) {
    const id = feature.properties.ownerId;
    const states = {
      inUse: inUseIds.includes(id),
      restricted: restrictedIds.includes(id),
      warning: warningIds.includes(id),
      disabled: disabledIds.includes(id),
      reduced: reducedIds.includes(id),
      booked: bookedIds.includes(id),
      selected: selectedIds.includes(id),
      focused: focusedIds.includes(id),
      available: Array.isArray(availableIds) ? availableIds.includes(id) : true
    };
    return states;
  }

  const reloadFeatureStates = useLayerFeatureStates({
    map,
    layers,
    getStateFromFeature
  });

  React.useEffect(() => reloadFeatureStates(), [
    availableIds,
    inUseIds,
    restrictedIds,
    warningIds,
    disabledIds,
    reducedIds,
    bookedIds,
    selectedIds,
    focusedIds
  ]);

  const layersWithoutLabels = React.useMemo(
    () => excludeSpaceLabelsLayer(layers),
    [layers]
  );

  return (
    <React.Fragment>
      <LayerEvents
        map={map}
        layers={layersWithoutLabels}
        eventsToCallbacks={eventsToCallbacks}
      />
      {renderTooltipContents && (
        <Tooltips
          layers={layersWithoutLabels}
          renderContents={renderTooltipContents}
        />
      )}
      {hovered && eventsToCallbacks[LayerEventType.CLICK] && (
        <CursorStyle cursor="pointer" />
      )}
    </React.Fragment>
  );
}

/** @TODO
 * We don't want to attach events to the space labels or the space outlines,
 * so we exlude that layer here.
 * Knowing this layer association should be supported by the style spec.
 */
function excludeSpaceLabelsLayer(layers) {
  return layers.filter(
    (layer) => layer !== 'spaces' && layer !== 'space-labels'
  );
}

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.onClick === nextProps.onClick &&
    prevProps.onMouseover === nextProps.onMouseover &&
    prevProps.onMousedown === nextProps.onMousedown &&
    prevProps.onMouseup === nextProps.onMouseup &&
    prevProps.onMousemove === nextProps.onMousemove &&
    prevProps.onDblclick === nextProps.onDblclick &&
    prevProps.onMouseenter === nextProps.onMouseenter &&
    prevProps.onMouseleave === nextProps.onMouseleave &&
    prevProps.onMouseout === nextProps.onMouseout &&
    prevProps.onContextmenu === nextProps.onContextmenu &&
    prevProps.onWheel === nextProps.onWheel &&
    prevProps.renderTooltipContents === nextProps.renderTooltipContents &&
    shallowEqualArrays(prevProps.availableIds, nextProps.availableIds) &&
    shallowEqualArrays(prevProps.inUseIds, nextProps.inUseIds) &&
    shallowEqualArrays(prevProps.restrictedIds, nextProps.restrictedIds) &&
    shallowEqualArrays(prevProps.warningIds, nextProps.warningIds) &&
    shallowEqualArrays(prevProps.disabledIds, nextProps.disabledIds) &&
    shallowEqualArrays(prevProps.reducedIds, nextProps.reducedIds) &&
    shallowEqualArrays(prevProps.bookedIds, nextProps.bookedIds) &&
    shallowEqualArrays(prevProps.selectedIds, nextProps.selectedIds) &&
    shallowEqualArrays(prevProps.focusedIds, nextProps.focusedIds)
  );
}

AtlasSpaces.propTypes = {
  /**
   * Space IDs which should display as booked.
   */
  bookedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as in use.
   */
  inUseIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as restricted.
   */
  restrictedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display a warning state.
   */
  warningIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as disabled.
   */
  disabledIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as focused.
   */
  focusedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as available.
   */
  availableIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as selected.
   */
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Space IDs which should display as reduced.
   */
  reducedIds: PropTypes.arrayOf(PropTypes.number),
  /**
   * Callback called when a space is clicked.
   */
  onClick: PropTypes.func,
  /**
   * Callback called when a space is hovered.
   */
  onMouseover: PropTypes.func,
  /**
   * Callback for mousedown event.
   */
  onMousedown: PropTypes.func,
  /**
   * Callback for mouseup event.
   */
  onMouseup: PropTypes.func,
  /**
   * Callback for mousemove event.
   */
  onMousemove: PropTypes.func,
  /**
   * Callback for dblclick event.
   */
  onDblclick: PropTypes.func,
  /**
   * Callback for mouseenter event.
   */
  onMouseenter: PropTypes.func,
  /**
   * Callback for mouseleave event.
   */
  onMouseleave: PropTypes.func,
  /**
   * Callback for mouseout event.
   */
  onMouseout: PropTypes.func,
  /**
   * Callback for contextmenu event.
   */
  onContextmenu: PropTypes.func,
  /**
   * Callback for wheel event.
   */
  onWheel: PropTypes.func,
  renderTooltipContents: PropTypes.func,
  map: PropTypes.object.isRequired,
  layers: PropTypes.arrayOf(PropTypes.string)
};

const connect = compose(withMap, withLayers('spaces'));
export default React.memo(connect(AtlasSpaces), arePropsEqual);

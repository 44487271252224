import { Dropdown, DropdownProps, Flex } from '@fluentui/react-northstar';
import moment from 'moment';
import { Moment } from 'moment';
import { useMemo } from 'react';
import { Colors } from '@robinpowered/design-system';
import { ClockSolid } from '@robinpowered/icons';
type TimePickerProps = {
  onTimeChange: (time: Moment) => void;
  selectedTime: Moment;
  use24HrTime?: boolean;
  timezone?: string;
  min?: Moment;
  max?: Moment;
  type?: string;
  containerStyle?: React.CSSProperties;
};

type TimeDropdownItemValue = {
  key: string;
  header: string;
  selected: boolean;
};

const generateTimes = (
  min: Moment,
  max: Moment,
  step: number,
  maxSelectable: boolean
) => {
  if (!min || !max || !step || min.isAfter(max, 'minutes')) {
    return [];
  }

  const times = [];

  const loopTime = min.clone().startOf('minute');

  if (loopTime.minutes() % step !== 0) {
    const remainder = step - (loopTime.minute() % step);

    times.push(min.clone());
    loopTime.add(remainder, 'minutes');
  }

  while (loopTime.clone().isBefore(max)) {
    times.push(loopTime.clone());
    loopTime.add(step, 'minutes');
  }

  if (maxSelectable && !times.some((time) => time.isSame(max, 'minute'))) {
    times.push(max);
  }

  return times;
};

export const TimePicker = ({
  selectedTime,
  onTimeChange,
  use24HrTime = false,
  timezone,
  max,
  min,
  containerStyle,
  type,
}: TimePickerProps) => {
  const currentTimezone = timezone || moment.tz.guess();
  const timeMin = min || moment.tz(currentTimezone).startOf('minute');
  const timeMax =
    max || moment.tz(currentTimezone).startOf('day').add(1, 'day');

  const theSelectedTime = useMemo(() => {
    return selectedTime || moment.tz(currentTimezone).startOf('minute');
  }, [selectedTime, currentTimezone]);

  const times = generateTimes(timeMin, timeMax, 30, true);

  const timeFormat = useMemo(
    () => (use24HrTime ? 'HH:mm' : 'h:mm a'),
    [use24HrTime]
  );

  const items: TimeDropdownItemValue[] = times.map((t) => ({
    key: t.toISOString(),
    header: t.format(timeFormat),
    selected: selectedTime.isSame(t),
  }));

  const handleTimeChange = (
    event: React.MouseEvent | React.KeyboardEvent | null,
    data: DropdownProps
  ) => {
    const timeChangeValue = data.value as TimeDropdownItemValue;
    onTimeChange(moment(timeChangeValue.key)); // The key is an ISO string
  };

  return (
    <Flex
      vAlign="center"
      style={{
        boxShadow: '0px 2px 4px 0px #00000024',
        paddingLeft: '8px',
        borderRadius: '4px',
      }}
    >
      <ClockSolid size={20} color={Colors.Gray70} />
      <Dropdown
        highlightedIndex={items.findIndex(
          (f) => f.header === theSelectedTime.format(timeFormat)
        )}
        items={items}
        value={theSelectedTime.format(timeFormat)}
        onChange={handleTimeChange}
        fluid
        inverted
      />
    </Flex>
  );
};

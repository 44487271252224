import styled from '@emotion/styled';
import { Colors, Flex, Row, Skeleton } from '@robinpowered/design-system';

const Loader = (): JSX.Element => {
  return (
    <Flex
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      p="16px"
      borderBottom={`1px solid ${Colors.Tan20}`}
    >
      <Row width="40%" justifyContent="space-between" mb={'7px'}>
        <Skeleton width="100%" height="10px" alignItems="center" />
        <Skeleton width="100%" height="10px" alignItems="center" ml={'10px'} />
      </Row>
      <Skeleton width="80%" height="13px" alignItems="center" />
      <Row width="100%" mt="10px" alignItems="center">
        <AvatarContainer width="67px" height={'25px'}>
          <Circle width="24px" height="24px" />
          <Circle width="24px" height="24px" />
          <Circle width="24px" height="24px" />
        </AvatarContainer>
        <Skeleton width="40%" height="10px" ml={'10px'} />
      </Row>
    </Flex>
  );
};

const AvatarContainer = styled(Row)`
  position: relative;
  & div:nth-of-type(2) {
    left: 19px;
  }

  & div:nth-of-type(3) {
    left: 38px;
  }
`;

const Circle = styled(Skeleton)`
  border-radius: 50%;
  position: absolute;
`;

export const EventsListLoader = ({
  count = 5,
}: {
  count?: number;
}): JSX.Element => {
  return (
    <Flex width="100%" display="flex" flexDirection="column">
      {Array(count)
        .fill('')
        .map((_, i) => (
          <Loader key={i} />
        ))}
    </Flex>
  );
};

import { KeyboardEventHandler, useRef } from 'react';
import { Dropdown } from '@robinpowered/design-system';
import { useIsHover } from '../../../../utilities/dom';

type DropdownItemProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
  tabIndex?: number;
  onKeyPress?: KeyboardEventHandler<Element> | undefined;
  selected?: boolean;
};

// @TODO add hovering functionality default to component library
export const DropdownItem = ({
  style,
  children,
  onClick,
  onKeyPress,
  tabIndex = 0,
  selected,
}: DropdownItemProps): JSX.Element => {
  const hoverRef = useRef(null);
  const isHovering = useIsHover(hoverRef);
  return (
    <Dropdown.Item
      style={style}
      onKeyPress={onKeyPress}
      isActive={isHovering}
      ref={hoverRef}
      onClick={onClick}
      tabIndex={tabIndex}
      isSelected={selected}
    >
      {children}
    </Dropdown.Item>
  );
};

import styled from '@emotion/styled';
import { Heading, Colors, Skeleton } from '@robinpowered/design-system';
import ChevronRightOutline from '@robinpowered/icons/ChevronRightOutline';
import { ReservationsEventsWithoutSpaces } from './ReservationsEventsWithoutSpaces';
import {
  useSettingsContext,
  useLocationsContext,
  useUserScheduleContext,
} from '../../../contexts';
import { Event } from '../../../components/EventsList/graphql/useMyEvents';
import { ReservationsDeskInfo } from './ReservationsDeskInfo';
import { useCallback, useMemo } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { filterForEventsThatNeedSuggestedSpaces } from '../../../utilities/Events';
import moment from 'moment';
import { Tooltip } from '../../Tooltip';
import { UserReservation } from '../../../contexts/UserSchedule/hooks';
import { teamsTheme } from '@fluentui/react-northstar';
import { useSidebarContext } from '../../../contexts/Sidebar';

export const ReservationsCard = ({
  date,
  reservation,
  events,
  lookForSuggestedSpaces,
  loading,
}: {
  date: Moment;
  reservation?: UserReservation;
  events: Event[];
  lookForSuggestedSpaces: boolean;
  loading: boolean;
}) => {
  const { t } = useTranslation('weekSchedule');
  const { selectedLocation } = useLocationsContext();
  const { suggestedSpacesEnabled } = useSettingsContext();
  const { isUserInOfficeForScheduleDay } = useUserScheduleContext();
  const { openDaySidebar } = useSidebarContext();

  const userIsRemote = useMemo(() => {
    return !isUserInOfficeForScheduleDay(date);
  }, [isUserInOfficeForScheduleDay, date]);

  const now = moment();

  const numEventsWithoutSpace = useMemo(() => {
    if (!events || userIsRemote) {
      return 0;
    }

    const futureEventsWithoutSpaces = filterForEventsThatNeedSuggestedSpaces(
      events,
      now
    );

    return futureEventsWithoutSpaces.length;
  }, [events, now, userIsRemote]);

  const isHighlighted = useMemo(
    () =>
      !!suggestedSpacesEnabled &&
      lookForSuggestedSpaces &&
      numEventsWithoutSpace > 0,
    [lookForSuggestedSpaces, numEventsWithoutSpace, suggestedSpacesEnabled]
  );

  const handleLinkClick = useCallback(() => {
    openDaySidebar(date);
  }, [date, openDaySidebar]);

  if (!selectedLocation) {
    return null;
  }

  return (
    <ReservationsCardContainer
      isHighlighted={isHighlighted}
      onClick={handleLinkClick}
    >
      <ReservationsCardHeader>
        <Heading.Small>
          {t('officeDay.reservationsCard.reservations')}
        </Heading.Small>
        <ChevronRightOutline size={16} />
      </ReservationsCardHeader>

      {loading && (
        <>
          <Skeleton width={'100%'} height={36} />
          <Skeleton width={'100%'} height={36} />
        </>
      )}

      {!loading && (
        <>
          {numEventsWithoutSpace > 0 && (
            <ReservationsEventsWithoutSpaces
              numWithoutSpaces={numEventsWithoutSpace}
            />
          )}

          <ReservationsDeskInfo reservation={reservation} />
        </>
      )}

      <TooltipContainer>
        <Heading.Small>{t('officeDay.reservationsCard.tooltip')}</Heading.Small>
      </TooltipContainer>
    </ReservationsCardContainer>
  );
};

const ReservationsCardContainer = styled.button<{ isHighlighted: boolean }>`
  border: 1px solid
    ${({ isHighlighted }) =>
      isHighlighted
        ? teamsTheme.siteVariables.colorScheme.brand.background
        : Colors.Gray10};

  box-shadow: ${({ isHighlighted }) =>
    isHighlighted
      ? `0 0 16px ${teamsTheme.siteVariables.colorScheme.brand.background1}`
      : 'unset'};

  background-color: white;
  color: black;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  text-align: left;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  position: relative;
  @media (hover: hover) {
    &:hover {
      background-color: ${({ isHighlighted }) =>
        isHighlighted ? Colors.Tan5 : Colors.Tan10};

      > div {
        display: flex;
      }
    }
  }
`;

const ReservationsCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TooltipContainer = styled(Tooltip)`
  display: none;
  width: auto;
  right: 12px;
  top: -48px;
  color: white;
`;

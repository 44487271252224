"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Creates a map of component props to event names.
 * For example: `{onMousedown: 'mousedown'}`
 * @param {Array.<string>} layerEvents The list of layer events to map.
 * @returns {Object} Map of layer event names to React prop names.
 */
function createPropToEventMap(layerEvents) {
    return layerEvents.reduce(function (map, eventName) {
        var _a;
        return (__assign(__assign({}, map), (_a = {}, _a["on" + eventName[0].toUpperCase() + eventName.substr(1)] = eventName, _a)));
    }, {});
}
exports.createPropToEventMap = createPropToEventMap;
/**
 * Extracts only the supported layer event callbacks from the given props.
 *
 * @param {Object} props The props to filter.
 * @param {Array.<string>} layerEvents The supported layer events.
 * @returns {Object} Filtered props containing only the callback events we support.
 */
function extractEventCallbackProps(props, layerEvents) {
    var propToEventMap = createPropToEventMap(layerEvents);
    return Object.keys(props).reduce(function (extracted, propKey) {
        if (propToEventMap[propKey]) {
            extracted[propKey] = props[propKey];
        }
        return extracted;
    }, {});
}
exports.extractEventCallbackProps = extractEventCallbackProps;

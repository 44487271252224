import {useEffect} from 'react';

const getIconImageCondition = (prefix, seatsToAvatars) => [
  'match',
  ['get', 'ownerId'],
  ...Object.entries(seatsToAvatars).reduce((carry, [seatId, value]) => {
    carry.push(parseInt(seatId), [
      'concat',
      prefix,
      [
        'string',
        `{"image": ${JSON.stringify(value.image)}, "fallback": ${JSON.stringify(
          value.fallback
        )}}`
      ]
    ]);

    return carry;
  }, []),
  ''
];

export default function SeatAvatars({map, seatsToAvatars}) {
  if (!map || !map.componentInstance || !map.api) {
    return null;
  }

  const prefix = map.componentInstance.getMetadata('avatar-renderer:prefix');

  if (!prefix) {
    return null;
  }

  const avatarLayers = map.componentInstance.getMetadata(
    'avatar-renderer:seat-layers'
  );

  useEffect(() => {
    if (!seatsToAvatars || Object.keys(seatsToAvatars).length === 0) {
      avatarLayers.forEach((layer) =>
        map.api.setLayoutProperty(layer, 'icon-image')
      );
    } else {
      const iconImage = getIconImageCondition(prefix, seatsToAvatars);
      avatarLayers.forEach((layer) =>
        map.api.setLayoutProperty(layer, 'icon-image', iconImage)
      );
    }

    return () => {
      if (!map || !map.api || !map.api.style) {
        return;
      }
      avatarLayers.forEach((layer) =>
        map.api.setLayoutProperty(layer, 'icon-image')
      );
    };
  }, [seatsToAvatars, avatarLayers]);

  return null;
}

import { MapboxFeature, withLayers } from '@robinpowered/atlas-common';
import {
  Map,
  MapRef,
  AtlasSeats,
  withMap,
  LayerFeatures,
  AtlasSpaces,
} from '@robinpowered/atlas-web';
import { useCallback, useMemo, useRef } from 'react';
import { useApolloContext } from '../../../contexts';
import {
  DeskSearchParams,
  useDeskAvailability,
} from '../../../contexts/DeskBooking/hooks/useDeskAvailability';
import { OfficeMapLoading } from './OfficeMapLoading';
import { config } from '../../../config';
import { robinLogger } from '../../../utilities/RobinLogger';

type OfficeMapProps = {
  style?: React.CSSProperties;
  levelId: string;
  userId: string;
  visible?: boolean;
  interactive?: boolean;
  theme?: string;
  zoom?: number;
  transformRequest?: Function;
  deskSearch?: DeskSearchParams;
  selectedDeskId?: string;
  onSelectDesk: (deskId: string) => void;
};

type RequestTransformer = (url: string) => {
  url: string;
  headers?: { Authorization: string };
};

const SpaceDisabler = withMap(
  withLayers('spaces')((sources) => (
    <LayerFeatures specifier={sources.layers}>
      {({ features }: { features: MapboxFeature<{ ownerId: number }>[] }) => (
        <AtlasSpaces
          disabledIds={features.map((feature) => feature.properties.ownerId)}
        />
      )}
    </LayerFeatures>
  ))
);

export const OfficeMap = ({
  style,
  userId,
  levelId,
  visible = true,
  interactive = true,
  theme = 'light',
  zoom = 3,
  deskSearch,
  selectedDeskId,
  onSelectDesk,
}: OfficeMapProps) => {
  const mapRef = useRef<MapRef>(null);
  const { jwt } = useApolloContext();
  const { atlasServiceUrl } = config;

  const { availabilityStates, seatsToAvatars, loading } = useDeskAvailability(
    userId,
    levelId,
    {
      params: deskSearch,
      visible,
    }
  );

  const handlePressDesk = useCallback(
    (deskFeature) => {
      onSelectDesk(String(deskFeature?.properties.ownerId));
    },
    [onSelectDesk]
  );

  const selectedDeskIds = useMemo(
    () =>
      typeof selectedDeskId !== 'undefined' ? [Number(selectedDeskId)] : [],
    [selectedDeskId]
  );

  const createRequestTransformer = (
    atlasServiceUrl: string,
    bearerToken: string | undefined
  ): RequestTransformer => {
    return (url) => {
      if (url.indexOf(atlasServiceUrl) !== 0) {
        // If the URL isn't Atlas service as given exactly - skip.
        return { url };
      }

      return {
        url,
        headers: {
          // Add Auth headers.
          Authorization: `Bearer ${bearerToken}`,
        },
      };
    };
  };

  return (
    <>
      {loading && <OfficeMapLoading />}

      {!loading && (
        <Map
          key={levelId}
          ref={mapRef}
          zoom={zoom}
          transformRequest={createRequestTransformer(atlasServiceUrl, jwt)}
          interactive={interactive}
          styleURL={`${atlasServiceUrl}/v1.2/styles/levels/${levelId}/${theme}/style.json`}
          serverUrl={atlasServiceUrl}
          serverToken={jwt || ''}
          style={{
            width: '100%',
            height: '100%',
            ...style,
          }}
          loader={<OfficeMapLoading />}
        >
          {availabilityStates && (
            <AtlasSeats
              seatsToAvatars={seatsToAvatars}
              selectedIds={selectedDeskIds}
              availableIds={availabilityStates?.availableIds}
              busyIds={availabilityStates?.busyIds}
              forbiddenIds={availabilityStates?.forbiddenIds}
              dimmedIds={availabilityStates?.dimmedIds}
              onClick={(deskFeature) => {
                robinLogger().reportEvent('map-interaction-seat');
                handlePressDesk(deskFeature);
              }}
            />
          )}
          <SpaceDisabler />
        </Map>
      )}
    </>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Determines if given features are the same feature.
 * We only need to check for matching iDs and sources to make sure
 * that that's true.
 *
 * @param  {Object} featureA The first feature to check.
 * @param  {Object} featureB The second feature to check.
 * @return {Boolean} True if both given features are the same feature, false otherwise.
 */
exports.isSameFeature = function (featureA, featureB) {
    return (featureA &&
        featureB &&
        featureA.id === featureB.id &&
        featureA.source === featureB.source);
};
/**
 * Ensures that a list only contains unique features.
 *
 * @param  {Array<Object>} features The features which to filter.
 * @return {Array<Object>} The filtered, unique features.
 */
exports.ensureUniqueFeatures = function (features) {
    var seenIds = new Set();
    function keyOfFeature(feature) {
        return feature.source + "-" + feature.id;
    }
    return features.filter(function (feature) {
        var key = keyOfFeature(feature);
        // Skip if we've seen this feature already.
        if (seenIds.has(key)) {
            return false;
        }
        // Otherwise, mark this feature as seen and continue.
        seenIds.add(key);
        return true;
    });
};

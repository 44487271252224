import { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Heading,
  Body,
  Row,
  AvatarProps,
  AvatarStack,
  Colors,
} from '@robinpowered/design-system';
import { CalendarOutline, ClockSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useUsers } from '../../contexts/SpaceBooking/hooks/useUsers';
import { useUserContext } from '../../contexts';
import { useLocalizationContext } from '../../contexts/Localization/LocalizationContext';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';

type MeetingSummarySectionProps = {
  event: Event;
};

export const MeetingSummarySection = ({
  event,
}: MeetingSummarySectionProps): JSX.Element => {
  const { currentUser } = useUserContext();
  const { timeFormat, timeFormatWithoutMeridian } = useLocalizationContext();
  const { t } = useTranslation('spaceBooking');

  const amIInOffice = useMemo(() => {
    const me = event.whosInData.whosIn?.find(
      (who) => who.userId === currentUser?.id
    );
    return me ? true : false;
  }, [currentUser?.id, event.whosInData.whosIn]);

  const userIds = useMemo(() => {
    // bring current user to end of list because other people are more interesting
    return (
      currentUser &&
      event?.whosInData.whosIn
        ?.flatMap(({ userId }) =>
          userId && userId !== currentUser.id ? userId : []
        )
        .concat(amIInOffice ? [currentUser.id] : [])
    );
  }, [amIInOffice, currentUser, event?.whosInData.whosIn]);
  const users = useUsers(userIds || undefined, { limit: 3 });
  const avatars = useMemo(() => {
    return (
      users.data &&
      users.data.getUsersByIds.reduce<AvatarProps[]>((acc, cur) => {
        return [
          ...acc,
          {
            name: cur.name ? cur.name : '',
            src: cur.avatar ? cur.avatar : undefined,
          },
        ];
      }, [])
    );
  }, [users]);

  const totalNumberOfWhosIn = event.whosInData.whosIn?.length || 0;
  const onlyIAmInOffice = amIInOffice && totalNumberOfWhosIn === 1;

  return (
    <MeetingSummarySectionContainer>
      <Heading.Regular style={{ paddingBottom: '4px' }}>
        {event.title}
      </Heading.Regular>
      {totalNumberOfWhosIn > 0 && !onlyIAmInOffice && (
        <Row alignItems="center" mt={'2px'} pb="4px">
          {
            // eslint-disable-next-line
            // @ts-ignore: Bad type definition in design system
            avatars && <AvatarList avatars={avatars} limit={3} />
          }
          <Body.Small>
            {t(
              `${
                totalNumberOfWhosIn !== 1
                  ? 'attendees_in_office_plural'
                  : 'attendees_in_office'
              }`,
              {
                count: totalNumberOfWhosIn,
              }
            )}
          </Body.Small>
        </Row>
      )}
      {/* Whats the point of having a DS Flex comp that isn't already set to flex... */}
      <Flex flexDirection={'row'} style={{ display: 'flex', gap: '20px' }}>
        <Flex flexDirection={'row'} alignItems={'center'} display={'flex'}>
          <CalendarOutline
            size={14}
            style={{ marginRight: '4px' }}
            color={Colors.Gray60}
          />
          <Body.Small>
            {moment(event?.startTime).format('ddd, MMMM D')}
          </Body.Small>
        </Flex>
        <Flex display={'flex'} alignItems={'center'}>
          <ClockSolid
            size={14}
            style={{ marginRight: '4px' }}
            color={Colors.Gray60}
          />
          <Body.Small>
            {t('event_time', {
              start: moment(event?.startTime).format(timeFormatWithoutMeridian),
              end: moment(event?.endTime).format(timeFormat),
            })}
          </Body.Small>
        </Flex>
      </Flex>
    </MeetingSummarySectionContainer>
  );
};

const MeetingSummarySectionContainer = styled(Flex)`
  padding: 20px;
  background-color: ${Colors.White0};
  border-top: 1px solid ${Colors.Gray30};
  border-bottom: 1px solid ${Colors.Gray30};
`;

const AvatarList = styled(AvatarStack)`
  div {
    width: 24px;
    height: 24px;
  }
  margin-right: 10px;
`;

import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Colors, Dropdown } from '@robinpowered/design-system';
import MoreHorizontalSolid from '@robinpowered/icons/MoreHorizontalSolid';
import { GetUserScheduleDeskReservations as Reservation } from '../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { TrashSolid } from '@robinpowered/icons';
import { DropdownItem } from '../Dropdown';

type Props = {
  reservation?: Reservation;
  dateIsToday: boolean;
  onCancelDesk: () => void;
};

export function ReservationDropdownOptions({
  reservation,
  dateIsToday,
  onCancelDesk,
}: Props): JSX.Element {
  const { t } = useTranslation('weekSchedule');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleKeyPress = useCallback((e, callback: () => void) => {
    if (e.key === 'Enter' || e.key === ' ') {
      callback();
    }
  }, []);

  return (
    <DropdownWrapper>
      <ButtonWrapper
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        aria-label={t('officeDay.visitCard.more.open') as string}
      >
        <MoreHorizontalSolid size={20} color={Colors.Gray80} />
      </ButtonWrapper>

      {isDropdownOpen && (
        <Dropdown.List
          isOpen={isDropdownOpen}
          ref={dropdownRef}
          style={dropdownListStyle}
        >
          {reservation && (
            <>
              <DropdownItem
                onClick={() => {
                  setIsDropdownOpen(false);
                  onCancelDesk();
                }}
                onKeyPress={(e) => {
                  handleKeyPress(e, () => {
                    setIsDropdownOpen(false);
                    onCancelDesk();
                  });
                }}
                style={dropdownItemStyle}
              >
                <TrashSolid
                  size={16}
                  color={Colors.RedProduct}
                  style={{ marginRight: '8px' }}
                />
                <span style={redProductStyle}>
                  {t('officeDay.visitCard.more.cancel')}
                </span>
              </DropdownItem>
            </>
          )}
        </Dropdown.List>
      )}
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled(Dropdown)`
  margin-left: auto;
`;

const ButtonWrapper = styled.button`
  border: none;
  background: transparent;

  &:hover {
    cursor: pointer;
  }
`;

const dropdownListStyle = {
  right: '0',
  minWidth: '215px',
};

const dropdownItemStyle = {
  display: 'flex',
  alignItems: 'center',
};

const redProductStyle = {
  color: Colors.RedProduct,
};

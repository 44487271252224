import React, { useState, useMemo, useContext, useEffect } from 'react';
import moment, { Moment } from 'moment-timezone';
import { useApolloContext, useUserContext } from '..';
import { DAYS_TO_RENDER } from '../../constants/dates';
import { useQuery } from '@apollo/client';
import { GET_USER_SETTING } from './hooks/getUserSetting';
import {
  GetUserSettingQuery,
  GetUserSettingQueryVariables,
} from './hooks/__generated__/getUserSetting.generated';
import { buildDatesArray, getStartDate } from '../../utilities';
import { useIsShowWeekendsEnabledForMe } from '../../components/OfficeDayList/DayList/graphql/useShowWeekendsEnabledForMe';

type Props = {
  children: React.ReactNode;
  timezone?: string;
};

type DateContextApi = {
  weekStartDate: Moment;
  weekEndDate: Moment;
  setWeekStartDate: (date: Moment) => void;
  hideWeekends: boolean;
  minCalendarDate: Moment;
  weekScheduleDates: Moment[];
  weekIndex: number;
  setWeekIndex: (index: number) => void;
};

export const DateContext = React.createContext<DateContextApi>({
  weekStartDate: moment(),
  weekEndDate: moment(),
  hideWeekends: true,
  setWeekStartDate: () => null,
  minCalendarDate: moment(),
  weekScheduleDates: [],
  setWeekIndex: () => null,
  weekIndex: 0,
});

export function DateProvider({
  children,
  timezone = moment.tz.guess(),
}: Props): JSX.Element {
  const { currentUser } = useUserContext();
  const { tenantId } = useApolloContext();
  const { getShowWeekends } = useIsShowWeekendsEnabledForMe(tenantId);

  const [weekIndex, setWeekIndex] = useState(0);

  const hideWeekends = useMemo(
    () => !getShowWeekends?.data?.showWeekends,
    [getShowWeekends?.data?.showWeekends]
  );
  const { data: userSettings } = useQuery<
    GetUserSettingQuery,
    GetUserSettingQueryVariables
  >(GET_USER_SETTING, {
    skip: !currentUser?.id || !tenantId,
    variables: {
      userId: currentUser?.id as string,
      settingSlug: 'week-starting-day',
    },
    fetchPolicy: 'cache-and-network',
  });

  const userWeekStartSetting = useMemo(
    () =>
      userSettings?.getUserSetting?.value
        ? parseInt(userSettings?.getUserSetting?.value) % 7
        : 0,
    [userSettings]
  );

  const startOfUserWeek = useMemo(() => {
    // If user start setting is in the future, then set it back a week
    return moment()
      .tz(timezone)
      .isoWeekday(userWeekStartSetting)
      .startOf('day')
      .isAfter(moment().tz(timezone).startOf('day'))
      ? moment()
          .tz(timezone)
          .isoWeekday(userWeekStartSetting)
          .subtract(1, 'week')
          .startOf('day')
      : moment().tz(timezone).isoWeekday(userWeekStartSetting).startOf('day');
  }, [userWeekStartSetting, timezone]);

  const [weekStartDate, setWeekStartDate] = useState(startOfUserWeek);
  const [minCalendarDate, setMinCalendarDate] = useState(startOfUserWeek);

  useEffect(() => {
    const startDate = getStartDate(userWeekStartSetting, false);

    setWeekStartDate(startDate);
    setMinCalendarDate(startDate);
  }, [userWeekStartSetting]);

  // Dates the user reflects in their 'week schedule'
  const weekScheduleDates = useMemo(
    () =>
      buildDatesArray(
        startOfUserWeek.clone().add(weekIndex, 'weeks'),
        DAYS_TO_RENDER,
        timezone,
        hideWeekends
      ),
    [startOfUserWeek, hideWeekends, weekIndex, timezone]
  );

  const api = {
    weekStartDate: weekScheduleDates[0],
    weekEndDate: weekScheduleDates[weekScheduleDates.length - 1]
      .clone()
      .endOf('day'),
    hideWeekends,
    setWeekStartDate,
    minCalendarDate,
    weekScheduleDates,
    setWeekIndex,
    weekIndex,
  };
  return <DateContext.Provider value={api}>{children}</DateContext.Provider>;
}

export const useDateContext = (): DateContextApi => {
  return useContext(DateContext);
};

import styled from '@emotion/styled';
import {
  Box,
  Shadow01,
  Colors,
  Heading07,
  Img,
  Column,
  Flex,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { useApolloContext, useUserContext } from '../../contexts';
import { useMemo } from 'react';

export const WeekViewPreview = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { currentOrgs } = useUserContext();
  const { tenantId } = useApolloContext();
  const logo = useMemo(() => {
    return currentOrgs?.find((org) => org.id === tenantId)?.avatar;
  }, [currentOrgs, tenantId]);

  return (
    <Column
      className={className}
      display="flex"
      flex-direction="column"
      backgroundColor={Colors.Tan5}
      border={Colors.Tan5}
      box-shadow={Shadow01}
      border-radius="8px"
      width="330px"
      height="190px"
    >
      <Box
        width="100%"
        height="18px"
        backgroundColor={Colors.Gray100}
        borderTopLeftRadius="8px"
        borderTopRightRadius="8px"
      />
      {logo ? (
        <Img
          src={logo}
          alt={t('org_logo_img_alt')}
          width="15%"
          height="auto"
          ml="5%"
          my="3%"
        />
      ) : (
        <Copy ml="5%" my="3%">
          {t('this_week')}
        </Copy>
      )}
      <Flex
        display="flex"
        justifyContent="space-between"
        height="100%"
        mb="5%"
        mx="7%"
      >
        <Pillar />
        <Pillar />
        <Pillar />
        <Pillar />
        <Pillar />
      </Flex>
    </Column>
  );
};

const Copy = styled(Heading07)`
  font-size: 14px;
`;

const Pillar = styled(Box)`
  width: 17%;
  height: 100%;
  background: ${Colors.White0};
  border-radius: 8px;
`;

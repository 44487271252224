import { Flex, Text } from '@fluentui/react-northstar';

export const OfficeStatusNoAccess = () => {
  return (
    <Flex
      padding="padding.medium"
      style={{
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
        padding: '48px 10px',
      }}
    >
      <Text align="center" weight="semibold">
        Contact your office manager to request access.
      </Text>
    </Flex>
  );
};

import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  GetSpaceNamesAndLocationIdsByIdsGetSpacesByIds,
  GetSpaceNamesAndLocationIdsByIdsQuery,
  GetSpaceNamesAndLocationIdsByIdsVariables,
} from './__generated__/useSpaces.generated';

export const GET_SPACE_NAMES_AND_LOCATION_IDS_BY_IDS = gql`
  query GetSpaceNamesAndLocationIdsByIds($ids: [ID!]!) {
    getSpacesByIds(ids: $ids) {
      id
      name
      locationId
      levelId
    }
  }
`;

export type SpaceWithNameAndLocation =
  GetSpaceNamesAndLocationIdsByIdsGetSpacesByIds;

type SpacesById = Record<string, SpaceWithNameAndLocation>;

export const useSpaces = (
  spaceIds: string[],
  options: Omit<
    QueryHookOptions<
      GetSpaceNamesAndLocationIdsByIdsQuery,
      GetSpaceNamesAndLocationIdsByIdsVariables
    >,
    'variables'
  > = {}
): QueryResult<
  GetSpaceNamesAndLocationIdsByIdsQuery,
  GetSpaceNamesAndLocationIdsByIdsVariables
> & {
  spacesById: null | SpacesById;
} => {
  const results = useQuery<
    GetSpaceNamesAndLocationIdsByIdsQuery,
    GetSpaceNamesAndLocationIdsByIdsVariables
  >(GET_SPACE_NAMES_AND_LOCATION_IDS_BY_IDS, {
    ...options,
    skip: options.skip || spaceIds.length < 1,
    variables: {
      ids: spaceIds,
    },
  });

  const getSpacesByIds = results.data?.getSpacesByIds;

  const spacesById = useMemo(
    () =>
      getSpacesByIds?.reduce<SpacesById>((acc, space) => {
        acc[space.id] = space;
        return acc;
      }, {}) ?? null,
    [getSpacesByIds]
  );

  return {
    ...results,
    spacesById,
  };
};

import React from 'react';

const useLayerFeatureStates = ({map, layers, getStateFromFeature}) => {
  function getFeaturesFromLayers(layers) {
    try {
      return map.componentInstance.getFeatures(layers);
    } catch (e) {
      return [];
    }
  }

  function removeFeatureStates() {
    const features = getFeaturesFromLayers(layers);
    features.forEach((feature) => {
      map.removeFeatureState(feature);
    });
  }

  function addFeatureStates() {
    const features = getFeaturesFromLayers(layers);
    features.forEach((feature) => {
      const states = getStateFromFeature(feature);
      map.setFeatureState(feature, states);
    });
  }

  function reloadFeatureState() {
    addFeatureStates();
  }

  React.useEffect(() => {
    addFeatureStates();
    return () => removeFeatureStates();
  }, [layers]);

  return reloadFeatureState;
};

export default useLayerFeatureStates;

import { FC } from 'react';
import moment from 'moment';
import { useUserContext } from '../../../contexts';
import { Availability } from '../../../__generated__/types';
import { useGetZoneAvailabilitiesWithReservation } from '../../../contexts/DeskBooking/hooks/useGetZoneAvailabilitiesWithReservation';
import { Flex } from '@fluentui/react-northstar';
import { DeskListItem } from './DeskListItem';
import { DeskListItemLoading } from './DeskListItemLoading';

type Props = {
  zoneID: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
};

export const DeskList: FC<Props> = ({ zoneID, startTime, endTime }) => {
  const { currentUser } = useUserContext();

  const { zoneDeskDetails: zoneDeskData, loading } =
    useGetZoneAvailabilitiesWithReservation(
      zoneID,
      startTime,
      endTime,
      Number(currentUser?.id)
    );

  return (
    <Flex column gap="gap.small" style={{ paddingTop: '12px' }}>
      {loading && (
        <>
          <DeskListItemLoading />
          <DeskListItemLoading />
          <DeskListItemLoading />
        </>
      )}

      {!loading && (
        <>
          {zoneDeskData?.map((zoneDesk) => {
            const reservation = zoneDesk.reservations[0];

            if (zoneDesk.availability.status !== Availability.UNAVAILABLE) {
              return (
                <DeskListItem
                  key={zoneDesk.id}
                  isAvailable={
                    zoneDesk.availability.status === Availability.AVAILABLE
                  }
                  deskType={zoneDesk.type}
                  deskName={zoneDesk.name}
                  deskId={zoneDesk.id}
                  seatReservationVisibilityEnabled={
                    zoneDesk.seatReservationVisibilityEnabled
                  }
                  levelName={zoneDesk.level?.name}
                  reservation={reservation || []}
                  startTime={startTime}
                  endTime={endTime}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </Flex>
  );
};

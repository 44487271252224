import { Flex, Text } from '@fluentui/react-northstar';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { useUserContext, useDeskBookingContext } from '../../contexts';
import { useDeskSearch } from '../../contexts/DeskBooking/hooks/useDeskSearch';
import { DeskInfo } from './DeskInfo';
import { ZoneList } from './ZoneList';
import { MapControls } from './MapControls';
import { OfficeMap } from './OfficeMap';
import { InfoPanel } from './ZoneList/InfoPanel';
import { MAP_ZOOM_CLOSE, MAP_ZOOM_FAR } from '../../constants/map';
import { DeskInfoContainer } from './DeskInfo/DeskInfoContainer';

export const DeskBooking = () => {
  const { currentUser } = useUserContext();
  const {
    currentLocation,
    currentDate,
    currentLevel,
    selectedDeskId,
    setSelectedDeskId,
  } = useDeskBookingContext();

  const isMobileScreen = useMedia('(max-width: 850px)');
  const [isLeftPanelOpenOnMobile, setIsLeftPanelOpenOnMobile] =
    useState<boolean>(false);

  const isLeftPanelVisible = useMemo(() => {
    return isMobileScreen ? isLeftPanelOpenOnMobile : true;
  }, [isLeftPanelOpenOnMobile, isMobileScreen]);

  const toggleLeftPanelVisibilityOnMobile = useCallback(() => {
    setIsLeftPanelOpenOnMobile((isOpen) => !isOpen);
  }, []);

  const timeZone = useMemo(
    () =>
      currentLocation?.timezone || currentUser?.timeZone || moment.tz.guess(),
    [currentLocation, currentUser]
  );

  const {
    initialTimeParams: initialTime,
    deskTime: deskDateTime,
    changeStartTime,
    changeEndTime,
  } = useDeskSearch(currentDate, timeZone, currentLocation || null);

  const zones = useMemo(() => {
    return (
      currentLocation?.levels.find(({ id }) => id === currentLevel?.id)
        ?.zones || []
    );
  }, [currentLevel?.id, currentLocation?.levels]);

  return (
    <Flex column style={{ height: '100vh', overflow: 'hidden' }}>
      <MapControls
        initialTime={initialTime}
        deskDateTime={deskDateTime}
        isLeftPanelVisible={isLeftPanelVisible}
        changeStartTime={changeStartTime}
        changeEndTime={changeEndTime}
        toggleLeftPanelVisibilityOnMobile={toggleLeftPanelVisibilityOnMobile}
      />

      <Flex style={{ height: '100%', overflow: 'hidden scroll' }}>
        {(isLeftPanelVisible || selectedDeskId) && (
          <InfoPanel>
            {selectedDeskId ? (
              <DeskInfoContainer
                deskId={selectedDeskId}
                start={deskDateTime.start}
                end={deskDateTime.end}
                timeZone={timeZone}
              />
            ) : (
              <ZoneList
                zones={zones}
                start={deskDateTime.start}
                end={deskDateTime.end}
              />
            )}
          </InfoPanel>
        )}

        {currentUser && currentLevel?.mapIsAvailable && (
          <OfficeMap
            levelId={currentLevel.id || '5255'}
            userId={currentUser.id}
            deskSearch={deskDateTime}
            selectedDeskId={selectedDeskId}
            onSelectDesk={setSelectedDeskId}
            zoom={isMobileScreen ? MAP_ZOOM_FAR : MAP_ZOOM_CLOSE}
          />
        )}

        {currentUser && currentLevel && !currentLevel?.mapIsAvailable && (
          <Flex
            vAlign="center"
            hAlign="center"
            style={{ height: '100%', width: '100%' }}
          >
            <Text weight="bold" size="large">
              No map available
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

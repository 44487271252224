import { FC, useCallback, useMemo, useState } from 'react';
import { Moment } from 'moment';
import { Availability } from '../../../__generated__/types';
import { useUserContext, Zone } from '../../../contexts';
import {
  Button,
  Flex,
  Text,
  ChevronEndIcon,
  ChevronDownIcon,
} from '@fluentui/react-northstar';
import { useZoneListItemData } from './graphql/useZoneListItemData';

type Props = {
  zone: Zone;
  start: Moment;
  end: Moment;
};

export const ZoneListItem: FC<Props> = ({ zone, start, end, children }) => {
  const { currentUser } = useUserContext();
  const { loading, data } = useZoneListItemData(
    zone.id,
    currentUser?.id,
    start,
    end
  );

  const [isOpen, setIsOpen] = useState(false);

  const { value, total } = useMemo(() => {
    const desks = data?.filteredDesksByAmenityIds || [];
    return {
      total: desks.length,
      value: desks.filter(
        ({ state }) => state.availability.status === Availability.AVAILABLE
      ).length,
    };
  }, [data]);

  const isDisabled = !loading && !value;

  const handleToggle = useCallback(() => {
    if (!isDisabled) {
      setIsOpen((s) => !s);
    }
  }, [isDisabled]);

  return (
    <Flex
      column
      style={{ borderBottom: '1px solid #D1D1D1', padding: '12px 0' }}
    >
      <Button
        text
        style={{ justifyContent: 'start' }}
        onClick={handleToggle}
        disabled={isDisabled}
      >
        {isOpen ? <ChevronDownIcon /> : <ChevronEndIcon />}

        <Flex column hAlign="start" style={{ paddingLeft: '12px' }}>
          <Text weight="bold">{zone.name}</Text>
          <Text>
            {value}/{total} available
          </Text>
        </Flex>
      </Button>

      {isOpen && children}
    </Flex>
  );
};

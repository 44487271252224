import { Checkbox, Flex, Text } from '@fluentui/react-northstar';
import {
  NotificationSettingType,
  UserNotificationsSetting,
} from '../../__generated__/types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

type UserSettingProps = {
  setting: UserNotificationsSetting;
  index: number;
  isNewSetting: boolean;
  onChange: (setting: UserNotificationsSetting) => void;
};

export const UserSetting = ({
  setting,
  index,
  isNewSetting,
  onChange,
}: UserSettingProps) => {
  const { t } = useTranslation('notifications');

  const settingTitle = t(`notifications.${setting?.name}.title`);
  const settingDescription = t(`notifications.${setting?.name}.description`);
  const isEnabled =
    setting?.values.includes(NotificationSettingType.TEAMS) || false;

  return (
    <Flex space="between" key={`${setting.name}-group`}>
      <Flex column padding="padding.medium" gap="gap.smaller">
        <Flex>
          <Text key={`${settingTitle}_${index}`} weight="semibold">
            {settingTitle}
          </Text>

          {isNewSetting && <PillLabel>{t('newLabel')}</PillLabel>}
        </Flex>

        <Text key={`${settingDescription}_${index}`}>{settingDescription}</Text>
      </Flex>
      <Checkbox toggle checked={isEnabled} onChange={() => onChange(setting)} />
    </Flex>
  );
};

const PillLabel = styled.span`
  background-color: #5b5fc7;
  color: white;
  font-size: 10px;
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 10px;
`;

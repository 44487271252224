import { Button, Flex, Loader, Skeleton } from '@fluentui/react-northstar';

export const OfficeStatusLoading = () => {
  return (
    <Flex
      padding="padding.medium"
      gap="gap.small"
      hAlign="center"
      vAlign="center"
      style={{
        width: '190px',
        backgroundColor: '#F5F5F5',
        borderRadius: '4px',
      }}
    >
      <Loader size="small" />
    </Flex>
  );
};

import { Flex, Loader } from '@fluentui/react-northstar';

export const OfficeMapLoading = () => {
  return (
    <Flex
      vAlign="center"
      hAlign="center"
      style={{ height: '100%', width: '100%' }}
    >
      <Loader />
    </Flex>
  );
};

import { useMemo } from 'react';
import { gql, useQuery, ApolloError } from '@apollo/client';
import { Moment } from 'moment';
import { SeatsToAvatars } from '@robinpowered/atlas-web';
import {
  GetFavoritesWithReservationsQuery,
  GetFavoritesWithReservationsVariables,
  GetDeskAvailabilityByLevelIdForMeQuery,
  GetDeskAvailabilityByLevelIdForMeQueryVariables,
} from './../hooks/__generated__/useDeskAvailability.generated';

export type DeskSearchParams = {
  start: Moment;
  end: Moment;
};

const MAP_FAVORITES_QUERY = gql`
  query GetFavoritesWithReservations(
    $teamName: String! = "default"
    $startTime: Date!
    $endTime: Date!
  ) {
    getAuthedTeamUsersByName(teamName: $teamName) {
      id
      avatar
      primaryEmail {
        email
      }
      name
      reservations(startTime: $startTime, endTime: $endTime) {
        visibility
        seat {
          id
        }
      }
    }
  }
`;

const MAP_DESK_AVAILABILITY_QUERY = gql`
  query GetDeskAvailabilityByLevelIdForMe(
    $scope: GetDeskAvailabilityByLevelIdScopeInput!
    $viewOptions: GetDeskAvailabilityByLevelIdViewOptionsInput!
    $filters: GetDeskAvailabilityByLevelIdFiltersInput
  ) {
    getDeskAvailabilityByLevelIdForMe(
      scope: $scope
      viewOptions: $viewOptions
      filters: $filters
    ) {
      availableDeskIds
      busyDeskIds
      forbiddenDeskIds
    }
  }
`;

type DeskMapAvailabilityStates = {
  availableIds: number[];
  busyIds: number[];
  forbiddenIds: number[];
  dimmedIds: number[];
};

type ReturnType = {
  loading: boolean;
  error?: ApolloError;
  availabilityStates?: DeskMapAvailabilityStates;
  seatsToAvatars: SeatsToAvatars;
};

type Options = {
  params?: DeskSearchParams;
  visible?: boolean;
};

export const useDeskAvailability = (
  userId: string,
  levelId: string,
  options?: Options
): ReturnType => {
  const skipAvailability =
    !userId || !levelId || !options?.params || !options?.visible;

  const { data, loading, error } = useQuery<
    GetDeskAvailabilityByLevelIdForMeQuery,
    GetDeskAvailabilityByLevelIdForMeQueryVariables
  >(MAP_DESK_AVAILABILITY_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      scope: {
        levelId,
      },
      viewOptions: {
        startTime: options?.params?.start.clone().format(),
        endTime: options?.params?.end.clone().format(),
      },
      filters: {
        deskAmenityIds: [],
      },
    },
    skip: skipAvailability,
  });

  const availabilityStates = useMemo<DeskMapAvailabilityStates>(() => {
    const states = data?.getDeskAvailabilityByLevelIdForMe;
    return {
      availableIds: states?.availableDeskIds || [],
      busyIds: states?.busyDeskIds || [],
      forbiddenIds: states?.forbiddenDeskIds || [],
      dimmedIds: [],
    };
  }, [data?.getDeskAvailabilityByLevelIdForMe]);

  const { data: favoritesData } = useQuery<
    GetFavoritesWithReservationsQuery,
    GetFavoritesWithReservationsVariables
  >(MAP_FAVORITES_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      teamName: 'default',
      startTime: options?.params?.start.clone().format(),
      endTime: options?.params?.end.clone().format(),
    },
    skip: skipAvailability,
  });

  const seatsToAvatars = useMemo<SeatsToAvatars>(() => {
    return (
      favoritesData?.getAuthedTeamUsersByName.reduce(
        (acc: SeatsToAvatars, user) => {
          const reservation = user.reservations?.[0];
          if (reservation) {
            acc[reservation.seat.id] = {
              image: user.avatar || '',
              fallback: user.name || user.primaryEmail?.email || '',
            };
          }
          return acc;
        },
        {}
      ) || {}
    );
  }, [favoritesData?.getAuthedTeamUsersByName]);

  return {
    loading,
    error,
    availabilityStates,
    seatsToAvatars,
  };
};

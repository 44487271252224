import { gql, QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useLocationsContext } from '../../../contexts';
import moment from 'moment';
import { useMemo } from 'react';
import { GetMyEventsInTheRangeGetMyEventsInTheRange } from './__generated__/useMyEvents.generated';
import {
  GetUpdatePermissionsForEventsQuery,
  GetUpdatePermissionsForEventsQueryVariables,
} from './__generated__/useEventUpdatePermissions.generated';

type Event = GetMyEventsInTheRangeGetMyEventsInTheRange;

export const GET_UPDATE_PERMISSIONS_FOR_EVENTS = gql`
  query GetUpdatePermissionsForEvents(
    $eventIds: [ID!]!
    $startTime: ISODateTime!
    $endTime: ISODateTime!
  ) {
    getUpdatePermissionsForEvents(
      eventIds: $eventIds
      startTime: $startTime
      endTime: $endTime
    ) {
      events {
        id
        eventId
        isPermitted
      }
    }
  }
`;

export type EventUpdatePermission = Exclude<
  GetUpdatePermissionsForEventsQuery['getUpdatePermissionsForEvents']['events'][number],
  null
>;

export type EventUpdatePermissionsByEventId = Record<
  string,
  EventUpdatePermission | null
>;

export type IsPermittedByEventId = Record<string, boolean | null>;

export const useEventUpdatePermissionsForEvents = (
  {
    events,
  }: {
    events: Event[];
  },
  options: Omit<
    QueryHookOptions<
      GetUpdatePermissionsForEventsQuery,
      GetUpdatePermissionsForEventsQueryVariables
    >,
    'variables'
  > = {}
): QueryResult<
  GetUpdatePermissionsForEventsQuery,
  GetUpdatePermissionsForEventsQueryVariables
> & {
  eventUpdatePermissionsByEventId: null | EventUpdatePermissionsByEventId;
  isPermittedByEventId: null | IsPermittedByEventId;
} => {
  const { locations } = useLocationsContext();

  // if org has no spaces, there's no point checking update permissions because space cannot be added
  // TODO: there might be a way to check this at the org level instead of looping through locations
  const doesAnyLocationHaveSpaces = useMemo(() => {
    return !!locations.find((location) => location.totalSpaces > 0);
  }, [locations]);

  // skip events that have already ended or are missing times
  const eventsToGetPermissionsFor = useMemo(
    () =>
      events.filter((event) => {
        if (!event.startTime || !event.endTime) {
          return false;
        }
        const momentEnd = moment(event.endTime);
        return momentEnd >= moment();
      }),
    [events]
  );

  const startTime = useMemo(() => {
    const epochs = eventsToGetPermissionsFor.flatMap((e) =>
      e.startTime ? [moment(e.startTime).toDate().getTime()] : []
    );
    return epochs.length > 0 ? moment(Math.min(...epochs)) : null;
  }, [eventsToGetPermissionsFor]);

  const endTime = useMemo(() => {
    const epochs = eventsToGetPermissionsFor.flatMap((e) =>
      e.endTime ? [moment(e.endTime).toDate().getTime()] : []
    );
    return epochs.length > 0 ? moment(Math.max(...epochs)) : null;
  }, [eventsToGetPermissionsFor]);

  const skip =
    options.skip ||
    // no events or they all ended
    eventsToGetPermissionsFor.length < 1 ||
    !startTime ||
    !endTime ||
    !doesAnyLocationHaveSpaces; // no reason to update events if no spaces

  // note that apollo client has a special typepolicy for this query in ApolloContext
  const results = useQuery<
    GetUpdatePermissionsForEventsQuery,
    GetUpdatePermissionsForEventsQueryVariables
  >(GET_UPDATE_PERMISSIONS_FOR_EVENTS, {
    ...options,
    skip: skip,
    variables: {
      eventIds: eventsToGetPermissionsFor.map((e) => e.id),
      startTime,
      endTime,
    },
  });

  const eventUpdatePermissionsByEventId = useMemo(
    () =>
      results.data?.getUpdatePermissionsForEvents.events?.reduce<EventUpdatePermissionsByEventId>(
        (acc, perm) => {
          if (perm) {
            acc[perm.eventId] = perm;
          }
          return acc;
        },
        {}
      ) ?? null,
    [results]
  );

  const isPermittedByEventId = useMemo(
    () =>
      !eventUpdatePermissionsByEventId
        ? null
        : Object.fromEntries(
            Object.entries(eventUpdatePermissionsByEventId).map(([k, v]) => [
              k,
              v?.isPermitted ?? null,
            ])
          ),
    [eventUpdatePermissionsByEventId]
  );

  return {
    ...results,
    eventUpdatePermissionsByEventId,
    isPermittedByEventId,
  };
};

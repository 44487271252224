import { OrgLocation } from '../../../contexts';
import styled from '@emotion/styled';
import { BuildingSolid } from '@robinpowered/icons';
import HomeSolid from '@robinpowered/icons/HomeSolid';
import { Dropdown, Flex } from '@fluentui/react-northstar';
import { GetUserScheduleEmployeeVisits as Visit } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';

type JoinOfficeDropdownProps = {
  disabled: boolean;
  selectedLocation?: OrgLocation;
  handleBookVisit: (location: OrgLocation) => Promise<void>;
  handleCancelVisitAndDesk: () => Promise<void>;
  visit: Visit | undefined;
  userInOffice: boolean;
};

type DropdownSelectionProps = {
  props: {
    id: string;
  };
};

export const JoinOfficeDropdown = ({
  selectedLocation,
  handleBookVisit,
  handleCancelVisitAndDesk,
  disabled,
  userInOffice,
}: JoinOfficeDropdownProps) => {
  const items = officeSelection(userInOffice, selectedLocation);

  const handleDropdownSelection = async (data: DropdownSelectionProps) => {
    if (data.props.id === 'remote') {
      await handleCancelVisitAndDesk();
    } else {
      if (selectedLocation) {
        await handleBookVisit(selectedLocation);
      }
    }
  };

  return (
    <DropdownWrapper>
      <Dropdown
        onChange={(e, data) =>
          handleDropdownSelection(data.value as DropdownSelectionProps)
        }
        value={userInOffice ? 'In Office' : 'Remote'}
        disabled={disabled}
        fluid
        items={items}
      ></Dropdown>
    </DropdownWrapper>
  );
};

const officeSelection = (
  userInOffice: boolean,
  selectedLocation?: OrgLocation
) => {
  const generateLocationRow = (id: string, name: string, selected = false) => {
    const location = RowItem({ id, name, selected });
    return selected ? (
      <DropdownItem id={id} key={id} selected={true} header={location} />
    ) : (
      <DropdownItem id={id} key={id} header={location} />
    );
  };

  const remoteRow = userInOffice
    ? generateLocationRow('remote', 'Remote')
    : generateLocationRow('remote', 'Remote', true);
  let selectedLocationItems: JSX.Element[] = [];

  //only allow location selection when status is remote
  if (!userInOffice && selectedLocation) {
    selectedLocationItems = [
      generateLocationRow(selectedLocation.id, 'In Office'),
    ];
  }

  return [remoteRow, ...selectedLocationItems];
};

const RowItem = (props: { id: string; name: string; selected: boolean }) => (
  <RowWrapper gap="gap.small">
    <ItemIconWrapper>
      {props.id === 'remote' ? (
        <HomeSolid size={20} color={props.selected ? '#242424' : '#616161'} />
      ) : (
        <BuildingSolid
          size={20}
          color={props.selected ? '#242424' : '#616161'}
        />
      )}
    </ItemIconWrapper>
    <ItemTextWrapper>
      <>{props.name}</>
    </ItemTextWrapper>
  </RowWrapper>
);

const DropdownWrapper = styled.div`
  width: 100%;
`;

const DropdownItem = styled(Dropdown.Item)`
  margin-left: -15px;
  height: 20px;
`;

const RowWrapper = styled(Flex)`
  :hover {
    cursor: pointer;
  }
`;

const ItemIconWrapper = styled.div`
  min-width: 20px;
`;
const ItemTextWrapper = styled.div`
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

import { useMemo, useCallback } from 'react';
import {
  AvatarProps,
  Flex,
  Colors,
  Box,
  TextLink,
  ButtonV4,
} from '@robinpowered/design-system';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Add } from '@robinpowered/icons';
import moment, { Moment } from 'moment';
import { SpaceWithNameAndLocation } from './graphql/useSpaces';
import { useLocationsContext, useUserContext } from '../../contexts';
import { KeyboardKeys } from '../../utilities/useEscape';
import { EventsListSummary } from './EventsListSummary';
import { SpaceSuggestion } from './SpaceSuggestion';
import { SuggestedSpace } from './graphql/useSuggestedSpacesForEvents';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';
import { robinLogger } from '../../utilities/RobinLogger';
import { useEventListSummaryContainerData } from './graphql/useEventListSummaryContainerData';
import { ExistingSpace } from './ExistingSpace';

type EventListSummaryContainerProps = {
  event: Event;
  existingSpace?: SpaceWithNameAndLocation;
  loadingSuggestions: boolean;
  suggestedSpace: SuggestedSpace | null;
  permittedToUpdateEvent: boolean | null;
  amIInOffice?: boolean;
};

export const EventListSummaryContainer = ({
  event,
  existingSpace,
  loadingSuggestions,
  suggestedSpace,
  permittedToUpdateEvent,
  amIInOffice,
}: EventListSummaryContainerProps): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { currentUser } = useUserContext();
  const { openModal } = useSpaceBookingContext();
  const logger = robinLogger();

  const { selectedLocation } = useLocationsContext();

  const userIds = useMemo(() => {
    // bring current user to end of list because other people are more interesting
    return (
      currentUser &&
      event?.whosInData.whosIn
        ?.flatMap(({ userId }) =>
          userId && userId !== currentUser.id ? userId : []
        )
        .concat(amIInOffice ? [currentUser.id] : [])
    );
  }, [amIInOffice, currentUser, event?.whosInData.whosIn]);

  const users = useEventListSummaryContainerData((userIds as string[]) || null);

  const avatars = useMemo(() => {
    return (
      users.users &&
      users.users.reduce<AvatarProps[]>((acc, cur) => {
        return [
          ...acc,
          {
            name: cur.name ? cur.name : '',
            src: cur.avatar ? cur.avatar : undefined,
          },
        ];
      }, [])
    );
  }, [users]);

  const eventInFuture = useMemo(() => {
    return moment(event?.endTime).isAfter(moment.now());
  }, [event]);

  const showSuggestion = useMemo(
    () => suggestedSpace && event && eventInFuture,
    [event, eventInFuture, suggestedSpace]
  );

  const IsSuggestibleSpace = (datetimeStart: Moment, datetimeEnd: Moment) => {
    const now = moment();

    const durationInMinutes = datetimeEnd.diff(datetimeStart, 'minutes');
    return (
      !event.isAllDay &&
      durationInMinutes < 720 &&
      durationInMinutes > 10 &&
      !event.spaces.length &&
      event.invitees.length >= 2 &&
      moment(event.endTime).isAfter(now)
    );
  };

  const weMightNeedToShowAddSpaceButton =
    eventInFuture &&
    /* @TODO remove when we figure out what to do with n without space */
    IsSuggestibleSpace(moment(event.startTime), moment(event.endTime)) &&
    permittedToUpdateEvent !== false;

  const handleOpenModal = useCallback(
    (e) => {
      if (!event) return;
      if (e.key === KeyboardKeys.ENTER || e.button === 0 || e.button === 1) {
        logger.reportEvent('clicked-open-add-space');
        if (selectedLocation) {
          openModal({ event, selectedLocationId: selectedLocation.id });
        }
      }
    },
    [event, selectedLocation, openModal, logger]
  );

  return (
    <Wrapper>
      <Flex opacity={!eventInFuture && 0.7}>
        <EventsListSummary
          event={event}
          amIInOffice={amIInOffice}
          avatars={avatars}
        />
      </Flex>
      <Box>
        {existingSpace ? (
          <ExistingSpace
            space={existingSpace}
            event={event}
            eventInFuture={eventInFuture}
          />
        ) : showSuggestion && suggestedSpace ? (
          <Flex mt="2px">
            <SpaceSuggestion
              event={event}
              suggestedSpace={suggestedSpace}
              loading={loadingSuggestions}
              openSpaceModal={handleOpenModal}
            />
          </Flex>
        ) : weMightNeedToShowAddSpaceButton ? (
          <Flex mt="8px" mb="6px" display={'flex'} alignItems={'center'}>
            {permittedToUpdateEvent ? (
              <>
                <AddSpaceLink
                  iconLeft={() => <AddIcon color={Colors.Gray60} size={24} />}
                  onClick={handleOpenModal}
                  onKeyPress={handleOpenModal}
                  label={t('add_space')}
                  tabIndex={0}
                />
              </>
            ) : (
              <Flex></Flex>
            )}
          </Flex>
        ) : null}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  padding: 16px;
  border: 1px solid ${Colors.Gray30};
  border-radius: 8px;
  margin: 12px;
`;

const AddSpaceLink = styled(TextLink)`
  line-height: 24px;

  &:hover {
    svg > path {
      stroke: ${Colors.Blue100};
    }
  }
`;

const AddIcon = styled(Add)`
  margin-right: 4px;
  cursor: pointer;
  path {
    stroke-width: 4px;
  }
`;

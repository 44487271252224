import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withMap} from '../Map';
import BaseControl from '../BaseControl';

const getControlClassNames = (disabled, additional) => {
  return `mapboxgl-ctrl-icon ${additional}${
    disabled ? ' mapboxgl-ctrl-icon-disabled' : ''
  }`;
};

const DefaultZoomButtonGroup = ({
  zoomIn,
  zoomOut,
  zoomInDisabled,
  zoomOutDisabled
}) => (
  <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button
      onClick={zoomIn}
      type="button"
      className={getControlClassNames(zoomInDisabled, 'mapboxgl-ctrl-zoom-in')}
      disabled={zoomInDisabled}
      aria-disabled={zoomInDisabled}
    >
      <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
    </button>
    <button
      onClick={zoomOut}
      type="button"
      className={getControlClassNames(
        zoomOutDisabled,
        'mapboxgl-ctrl-zoom-out'
      )}
      disabled={zoomOutDisabled}
      aria-disabled={zoomOutDisabled}
    >
      <span className="mapboxgl-ctrl-icon" aria-hidden="true"></span>
    </button>
  </div>
);

DefaultZoomButtonGroup.propTypes = {
  zoomIn: PropTypes.number.isRequired,
  zoomOut: PropTypes.number.isRequired,
  zoomInDisabled: PropTypes.bool.isRequired,
  zoomOutDisabled: PropTypes.bool.isRequired
};

export class ZoomControl extends Component {
  static propTypes = {
    /**
     * Allows overriding the default control with a custom one.
     */
    control: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    /**
     * The control position.
     */
    position: PropTypes.oneOf([
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right'
    ]),
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  static defaultProps = {
    control: DefaultZoomButtonGroup
  };

  state = {
    ...this.getZoomButtonState()
  };

  componentWillUnmount() {
    this.props.map.off('zoom', this.zoomListener);
  }

  componentDidMount() {
    this.props.map.on('zoom', this.zoomListener);
  }

  getZoomButtonState() {
    const currentZoom = this.props.map.getZoom();
    const minZoom = this.props.map.getMinZoom();
    const maxZoom = this.props.map.getMaxZoom();

    return {
      zoomInDisabled: currentZoom >= maxZoom,
      zoomOutDisabled: currentZoom <= minZoom
    };
  }

  zoomListener = () => {
    const {zoomInDisabled, zoomOutDisabled} = this.getZoomButtonState();

    if (
      zoomInDisabled !== this.state.zoomInDisabled ||
      zoomOutDisabled !== this.state.zoomOutDisabled
    ) {
      this.setState({
        zoomInDisabled,
        zoomOutDisabled
      });
    }
  };

  handleZoomIn = () => {
    this.props.map.zoomIn();
  };

  handleZoomOut = () => {
    this.props.map.zoomOut();
  };

  render() {
    const {position, control: Control} = this.props;

    const componentProps = {
      zoomIn: this.handleZoomIn,
      zoomOut: this.handleZoomOut,
      zoomInDisabled: this.state.zoomInDisabled,
      zoomOutDisabled: this.state.zoomOutDisabled
    };

    return (
      <BaseControl position={position}>
        {typeof Control === 'function'
          ? Control(componentProps)
          : React.cloneElement(Control, componentProps)}
      </BaseControl>
    );
  }
}

export default withMap(ZoomControl);

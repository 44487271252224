import { FC, createContext, useContext, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Toast, Toasts, ToastType } from '../components/Toasts';

type ToastContext = {
  toasts: Toast[];
  success: (message: string) => void;
  error: (message: string) => void;
};

export type ToastProviderOptions = {
  duration?: number;
};

export const toastContext = createContext<ToastContext>({
  toasts: [],
  success: () => undefined,
  error: () => undefined,
});

export const ToastContextProvider: FC<ToastProviderOptions> = ({
  children,
  duration = 5000,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const appendToast = (type: ToastType, message: string) =>
    setToasts([{ type, message }]);
  const clearToasts = () => setToasts([]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (toasts.length > 0) {
      timeout = setTimeout(() => {
        clearToasts();
      }, duration);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [duration, toasts]);

  const api: ToastContext = {
    toasts,
    success: (message: string) => appendToast(ToastType.SUCCESS, message),
    error: (message: string) => appendToast(ToastType.ERROR, message),
  };

  return (
    <toastContext.Provider value={api}>
      {children}
      {createPortal(<Toasts toasts={toasts} />, document.body)}
    </toastContext.Provider>
  );
};

export const useToast = (): ToastContext => {
  return useContext(toastContext);
};

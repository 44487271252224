import { useEffect, useMemo } from 'react';
import { MapContext } from '@robinpowered/atlas-web';
import { MapboxFeature } from '@robinpowered/atlas-common';
import {
  createWorkAreasCentroidsBuckets,
  createWorkAreasFeaturesBuckets,
  addWorkAreaSources,
  addWorkAreaLayers,
} from '../../../components/SpaceBookingModal/WorkAreas/utils';
import { Position } from '@turf/helpers';
import { WorkArea } from './useWorkAreas';

interface UseWorkAreaMapInteractionsInterface {
  getWorkAreaCentroid: (workArea: WorkArea) => Position | undefined;
}

export const useWorkAreaMapInteractions = (
  map: MapContext,
  seatFeatures: MapboxFeature[],
  workAreas: WorkArea[]
): UseWorkAreaMapInteractionsInterface => {
  const featuresBuckets = useMemo(
    () => createWorkAreasFeaturesBuckets(workAreas, seatFeatures),
    [workAreas, seatFeatures]
  );

  const centroidsBuckets = useMemo(
    () => createWorkAreasCentroidsBuckets(featuresBuckets),
    [featuresBuckets]
  );

  // Initialize work areas on map.
  useEffect(() => {
    if (map) {
      addWorkAreaSources(map);
      addWorkAreaLayers(map);
    }
  }, [map]);

  const getWorkAreaCentroid = (workArea: WorkArea): Position | undefined => {
    return centroidsBuckets[workArea.id];
  };

  return {
    getWorkAreaCentroid,
  };
};

import React, { FC, useContext } from 'react';
import { useUserContext } from '../User/UserContext';
import { useUserLocale } from './hooks/useUserLocale';

const timeFormats = {
  TWELVE_HOUR: 'h:mm a',
  TWELVE_HOUR_NO_MERIDIAN: 'h:mm',
  TWENTY_FOUR_HOUR: 'HH:mm',
};

export type LocalizationContextType = {
  timeFormat: string;
  timeFormatWithoutMeridian: string;
};

const Context = React.createContext<LocalizationContextType>({
  timeFormat: timeFormats.TWELVE_HOUR,
  timeFormatWithoutMeridian: timeFormats.TWELVE_HOUR_NO_MERIDIAN,
});

export const LocalizationProvider: FC = ({ children }) => {
  const { currentUser } = useUserContext();
  const { use24HourTime } = useUserLocale(currentUser?.id);

  const timeFormat = use24HourTime
    ? timeFormats.TWENTY_FOUR_HOUR
    : timeFormats.TWELVE_HOUR;

  const timeFormatWithoutMeridian = use24HourTime
    ? timeFormats.TWENTY_FOUR_HOUR
    : timeFormats.TWELVE_HOUR_NO_MERIDIAN;

  return (
    <Context.Provider value={{ timeFormat, timeFormatWithoutMeridian }}>
      {children}
    </Context.Provider>
  );
};

export function useLocalizationContext(): LocalizationContextType {
  return useContext(Context);
}

import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Heading,
  Body,
  Colors,
  BrownFontStack,
} from '@robinpowered/design-system';
import Close from '@robinpowered/icons/Close';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { useTranslation } from 'react-i18next';

export const FilterSelectionPills: FC = () => {
  const { t } = useTranslation('spaceBooking', { keyPrefix: 'filters' });
  const {
    updateAmenityFilter,
    updateSpaceTypeFilter,
    updateCapacityFilter,
    filters,
    resetFilters,
  } = useSpaceBookingContext();

  const handleResetFilters = useCallback(
    (e) => {
      e.preventDefault();
      resetFilters();
    },
    [resetFilters]
  );

  return (
    <Flex margin={'0 24px'} mb={'16px'}>
      <Flex
        display={'flex'}
        style={{ justifyContent: 'space-between', padding: '0 2px' }}
        mb={'16px'}
      >
        {(!!filters.amenities.length ||
          filters.capacity !== 'any' ||
          !!filters.spaceTypes.length) && (
          <>
            <Heading.Regular>{t('selected_filters')}</Heading.Regular>
            <ClearSelection href="" onClick={handleResetFilters}>
              {t('clear')}
            </ClearSelection>
          </>
        )}
      </Flex>
      <Flex>
        {filters.capacity !== 'any' && (
          <FilterPill>
            <Body.Small style={{ color: Colors.Maroon100 }}>
              {filters.capacity} {t('people')}
            </Body.Small>
            <Close
              onClick={() => updateCapacityFilter('any')}
              size={14}
              color={Colors.Gray60}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
          </FilterPill>
        )}
        {filters.amenities.map((amenity) => {
          return (
            <FilterPill key={amenity.id}>
              <Body.Small style={{ color: Colors.Maroon100 }}>
                {amenity.name}
              </Body.Small>
              <Close
                onClick={() =>
                  updateAmenityFilter(
                    filters.amenities.filter((item) => item.id !== amenity.id)
                  )
                }
                size={14}
                color={Colors.Gray60}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </FilterPill>
          );
        })}
        {filters.spaceTypes.map(({ spaceType, id }) => {
          return (
            <FilterPill key={id}>
              <SpaceTypes
                style={{
                  color: Colors.Maroon100,
                  textTransform: 'lowercase',
                }}
              >
                {spaceType}
              </SpaceTypes>
              <Close
                onClick={() =>
                  updateSpaceTypeFilter(
                    filters.spaceTypes.filter((item) => item.id !== id)
                  )
                }
                size={14}
                color={Colors.Gray60}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              />
            </FilterPill>
          );
        })}
      </Flex>
    </Flex>
  );
};

const ClearSelection = styled.a`
  font-family: ${BrownFontStack};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  border-bottom: 1px solid ${Colors.Gray30};
  &:visited {
    color: ${Colors.Gray100};
  }
  &:hover {
    text-decoration: none;
  }
`;

const FilterPill = styled.div`
  background-color: ${Colors.Magenta10};
  padding: 5px 12px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: auto;
  margin: 2px;
`;

const SpaceTypes = styled(Body.Small)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

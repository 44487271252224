"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MAX_MAP_NAME_LENGTH = 10; // longest possible name is "Mmmmmmmmm…"
var initial = function (name) { return name[0] + "."; };
/**
 * Splits a string, and removes any empty strings in the result.
 *
 * @param splitter {string|RegExp}
 * @param str {string}
 * @returns {Array.<string>}
 */
var splitClean = function (splitter, str) {
    return str.split(splitter).filter(function (str) { return !!str; });
};
var spacesPattern = /\s+/g;
var splitCleanSpaces = splitClean.bind(null, spacesPattern);
var State;
(function (State) {
    State[State["Reading"] = 0] = "Reading";
    State[State["Skipping"] = 1] = "Skipping";
})(State || (State = {}));
/***
 * Given a string, remove any nested substrings that are surrounded by some
 * delimiters like parens, brackets, quotes, etc.
 *
 * Examples:
 *   Nicholas "nickzuber" Zuber  ->   Nicholas Zuber
 *   Chris Hemsworth (Actor)     ->   Chris Hemsworth
 *   Mark ("Cool" guy) Hoppus    ->   Mark Hoppus
 *
 * We intentionally do not aggressively handle n-depth nesting because we support
 * delimiters of the same value - for example, quotes. Since there is no way to tell
 * if a quote is the starting or ending character of its nested sequence (without a lookahead),
 * it makes more sense to simply not handle this case.
 *
 * Keep in mind this happening in practice is a super edge case, and can be argued that strings
 * of that nature are outside the scope of what we consider a valid name to be.
 *
 * @param {string} str The string to clean.
 * @returns {string} The string without the nested text.
 */
function removeNestedStrings(str) {
    var finalString = '';
    var state = State.Reading;
    var stack = [];
    var chars = str.split('');
    var startDelimiters = new Set(['(', '[', '"']);
    var endDelimiters = new Map([
        ['(', ')'],
        ['[', ']'],
        ['"', '"']
    ]);
    while (chars.length > 2 &&
        startDelimiters.has(chars[0]) &&
        endDelimiters.get(chars[0]) === chars[chars.length - 1]) {
        // Handle a case, where an entire string is a nested string - at that point
        // we should try to remove as many nested strings as possible before proceeding.
        chars.splice(0, 1);
        chars.splice(-1);
    }
    for (var i = 0; i < chars.length; i++) {
        var char = chars[i];
        switch (+state) {
            case State.Reading:
                if (startDelimiters.has(char)) {
                    stack.push(endDelimiters.get(char));
                    state = State.Skipping;
                }
                else {
                    finalString += char;
                }
                break;
            case State.Skipping:
                if (stack[stack.length - 1] === char) {
                    stack.pop();
                }
                else if (startDelimiters.has(char)) {
                    stack.push(endDelimiters.get(char));
                }
                // If the stack is empty, we've finished skipping all
                // wrapped content and can continue reading.
                if (stack.length === 0) {
                    state = State.Reading;
                }
                break;
            default: // no-op, unreachable
        }
    }
    // Clean the title by normalizing any inconsistent spacing.
    // Basically convert 'a   b  c' -> 'a b c'.
    // This also trims the string for free.
    return splitCleanSpaces(finalString).join(' ');
}
exports.removeNestedStrings = removeNestedStrings;
/**
 * Given a name returns a tuple of the first and last name.
 *
 * @example
 *  getFirstAndLastNames('First Last') //=> ['First', 'Last']
 *
 * @example
 *  getFirstAndLastNames('Last, First') //=> ['First', 'Last']
 *
 * @example
 *  getFirstAndLastNames('First Middle Last') //=> ['First', 'Last']
 *
 * @param name {string}
 * @returns {[string, string]}
 */
exports.getFirstAndLastNames = function (name) {
    if (!name) {
        return ['', ''];
    }
    // Clean the name string by removing any non-name related data.
    name = removeNestedStrings(name);
    // If a name has a comma, let's assume that the name is of the format "last, first".
    if (name.includes(',')) {
        var _a = splitClean(',', name), end = _a[0], begining = _a.slice(1);
        begining.push(end);
        name = begining.join(' ');
    }
    var _b = splitCleanSpaces(name), first = _b[0], rest = _b.slice(1);
    var last = rest[rest.length - 1] || '';
    return [first, last];
};
/**
 * Truncate a full name such that we try to get the first name with the
 * last name being abreviated to a single letter.
 * @param {string} name The full name.
 * @returns {string} The truncated name.
 */
function truncateUserName(name) {
    // If we're not given a name we can work with, return empty string.
    if (!name) {
        return '';
    }
    var _a = exports.getFirstAndLastNames(name), first = _a[0], last = _a[1];
    if (first.length > MAX_MAP_NAME_LENGTH) {
        return first.slice(0, MAX_MAP_NAME_LENGTH - 1) + "\u2026";
    }
    else if (last && first.length > MAX_MAP_NAME_LENGTH - 2) {
        return first + "\u2026";
    }
    else if (first && last) {
        return first + " " + initial(last);
    }
    else if (first) {
        return "" + first;
    }
    else if (last) {
        return "" + last;
    }
    else {
        return '';
    }
}
exports.truncateUserName = truncateUserName;
/**
 * Convert a reservee model to a seat label string.
 * @param {RobinReservee} reservee The reservee.
 * @returns {string} The truncated user name of the reservee.
 */
function reserveeToSeatLabel(reservee) {
    var user = reservee ? reservee.user : null;
    var name = user ? user.name : null;
    return name ? truncateUserName(name) : '';
}
exports.default = reserveeToSeatLabel;

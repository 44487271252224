import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import EventReactor from '../EventReactor';
import {createPropToEventMap, isSameFeature} from '@robinpowered/atlas-common';
import {LayerEventTypeList} from '../../constants/layerEventType';

const propToEventMap = createPropToEventMap(LayerEventTypeList);
const eventToPropMap = Object.entries(propToEventMap).reduce(
  (map, [prop, event]) => ({...map, [event]: prop}),
  {}
);
const propList = Object.keys(propToEventMap);

export default class FeatureEvents extends Component {
  static propTypes = {
    /**
     * Handler for mousedown event.
     */
    onMousedown: PropTypes.func,
    /**
     * Handler for mouseup event.
     */
    onMouseup: PropTypes.func,
    /**
     * Handler for mouseover event.
     */
    onMouseover: PropTypes.func,
    /**
     * Handler for mousemove event.
     */
    onMousemove: PropTypes.func,
    /**
     * Handler for click event.
     */
    onClick: PropTypes.func,
    /**
     * Handler for dblclick event.
     */
    onDblclick: PropTypes.func,
    /**
     * Handler for mouseenter event.
     */
    onMouseenter: PropTypes.func,
    /**
     * Handler for mouseleave event.
     */
    onMouseleave: PropTypes.func,
    /**
     * Handler for mouseout event.
     */
    onMouseout: PropTypes.func,
    /**
     * Handler for contextmenu event.
     */
    onContextmenu: PropTypes.func,
    /**
     * Handler for wheel event.
     */
    onWheel: PropTypes.func,
    /**
     * Handler for touchstart event.
     */
    onTouchstart: PropTypes.func,
    /**
     * Handler for touchend event.
     */
    onTouchend: PropTypes.func,
    /**
     * Handler for touchmove event.
     */
    onTouchmove: PropTypes.func,
    /**
     * Handler for touchcancel event.
     */
    onTouchcancel: PropTypes.func,
    /**
     * The layer specifier for the event listener. Will only listen for events for a given feature which happened
     * on layers of this specifier.
     */
    specifier: PropTypes.string,
    /**
     * The feature for which's event to listen.
     */
    feature: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps) {
    // Avoid component updates when feature or specifier stayed the same.
    // Since we bind the callback dynamically, changing it has no effect for us.
    if (
      !isSameFeature(this.props.feature, nextProps.feature) ||
      this.props.specifier !== nextProps.specifier
    ) {
      return true;
    }

    return false;
  }

  callback = (...args) => {
    const [event] = args;

    const callback = this.props[eventToPropMap[event.type]];

    if (!callback || event.defaultPrevented) {
      return;
    }

    if (
      // If event has no features, it was a generic event, not targeting a specific feature (i.e. wheel),
      // or it was an event, which can't associate particular event with a feature, like `mouseleave`, as
      // once mouse left, there's no indication of _which_ feature it left. In such cases we still want to
      // run the callback, as the listener might change internal state based on previous state - for example:
      // space was hovered, and internal reference was kept to its ID. Then `mouseleave` event fires,
      // and the consumer should be safe to assume that if it had previous state, where space was hovered,
      // now that space is no longer hovered.
      !Array.isArray(event.features) ||
      (event.features.length > 0 &&
        event.features[0].id === this.props.feature.id)
    ) {
      // Prevent default, so that the event propagation would stop.
      // This will ensure that we'll not fire any events X times for different layers/sources
      // which might compose an entity.
      // However we only want to prevent default if we know that we matched the feature,
      // otherwise we still want to fan out.
      if (Array.isArray(event.features)) {
        event.preventDefault();
      }

      callback(...args);
      return;
    }
  };

  render() {
    return (
      <Fragment>
        {propList.reduce((listeners, eventProp) => {
          // The Map SDK event name.
          const eventName = propToEventMap[eventProp];
          // Check if this component has a prop for specified event.
          if (this.props[eventProp]) {
            listeners.push(
              <EventReactor
                key={eventProp}
                event={eventName}
                specifier={this.props.specifier}
                callback={this.callback}
              />
            );
          }
          return listeners;
        }, [])}
      </Fragment>
    );
  }
}

import * as React from 'react';

export const GoogleLogo = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="52"
      height="17"
      viewBox="0 0 52 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.44946 5.78544V7.52319H10.6031C10.476 8.49802 10.151 9.21149 9.65653 9.71304C9.04902 10.3205 8.10244 10.9846 6.44946 10.9846C3.89228 10.9846 1.89316 8.92187 1.89316 6.36469C1.89316 3.80751 3.89228 1.74481 6.44946 1.74481C7.82694 1.74481 8.8371 2.28874 9.57882 2.98808L10.8009 1.76601C9.76955 0.762915 8.385 0 6.44946 0C2.9457 0 0 2.85387 0 6.35763C0 9.86138 2.9457 12.7153 6.44946 12.7153C8.34262 12.7153 9.76955 12.0936 10.8857 10.9351C12.03 9.79074 12.3903 8.17308 12.3903 6.8733C12.3903 6.47065 12.362 6.09626 12.2985 5.78544H6.44946Z"
        fill="#4285F4"
      />
      <path
        d="M17.5832 4.37256C15.3156 4.37256 13.4648 6.09618 13.4648 8.47676C13.4648 10.8361 15.3156 12.581 17.5832 12.581C19.8507 12.581 21.7015 10.8432 21.7015 8.47676C21.7015 6.09618 19.8507 4.37256 17.5832 4.37256ZM17.5832 10.9633C16.3399 10.9633 15.2662 9.93901 15.2662 8.47676C15.2662 7.00038 16.3399 5.99022 17.5832 5.99022C18.8264 5.99022 19.9002 7.00038 19.9002 8.47676C19.9002 9.93901 18.8264 10.9633 17.5832 10.9633Z"
        fill="#EA4335"
      />
      <path
        d="M37.7719 5.29088H37.7083C37.3056 4.81053 36.5286 4.37256 35.5467 4.37256C33.4981 4.37256 31.7109 6.15976 31.7109 8.47676C31.7109 10.7796 33.4981 12.581 35.5467 12.581C36.5286 12.581 37.3056 12.143 37.7083 11.6485H37.7719V12.2207C37.7719 13.7889 36.9313 14.6295 35.582 14.6295C34.48 14.6295 33.7948 13.8384 33.5123 13.1673L31.9441 13.8172C32.3961 14.905 33.59 16.2401 35.582 16.2401C37.6942 16.2401 39.4814 14.9969 39.4814 11.9664V4.58448H37.7719V5.29088ZM35.7021 10.9633C34.4588 10.9633 33.5123 9.90369 33.5123 8.47676C33.5123 7.02863 34.4588 5.99022 35.7021 5.99022C36.9313 5.99022 37.892 7.04983 37.892 8.49089C37.899 9.92489 36.9313 10.9633 35.7021 10.9633Z"
        fill="#4285F4"
      />
      <path
        d="M26.7648 4.37256C24.4973 4.37256 22.6465 6.09618 22.6465 8.47676C22.6465 10.8361 24.4973 12.581 26.7648 12.581C29.0324 12.581 30.8831 10.8432 30.8831 8.47676C30.8831 6.09618 29.0324 4.37256 26.7648 4.37256ZM26.7648 10.9633C25.5215 10.9633 24.4478 9.93901 24.4478 8.47676C24.4478 7.00038 25.5215 5.99022 26.7648 5.99022C28.0081 5.99022 29.0818 7.00038 29.0818 8.47676C29.0818 9.93901 28.0081 10.9633 26.7648 10.9633Z"
        fill="#FBBC05"
      />
      <path
        d="M40.8926 0.169434H42.6656V12.5809H40.8926V0.169434Z"
        fill="#34A853"
      />
      <path
        d="M48.1411 10.9633C47.2228 10.9633 46.5729 10.5465 46.1491 9.72003L51.6378 7.45247L51.4542 6.98625C51.1151 6.06793 50.0696 4.37256 47.9434 4.37256C45.8312 4.37256 44.0723 6.03261 44.0723 8.47676C44.0723 10.7796 45.81 12.581 48.1411 12.581C50.0202 12.581 51.108 11.4295 51.5601 10.7655L50.1615 9.83305C49.6952 10.5112 49.0595 10.9633 48.1411 10.9633ZM48.014 5.91252C48.7416 5.91252 49.3632 6.28691 49.5681 6.81671L45.8595 8.34961C45.8595 6.62598 47.0815 5.91252 48.014 5.91252Z"
        fill="#EA4335"
      />
    </svg>
  );
};

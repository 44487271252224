import { gql, QueryResult, useQuery } from '@apollo/client';
import { GetSuggestedSpacesEnabledForOrgQuery } from './__generated__/useGetSuggestedSpacesEnabled.generated';
import { useApolloContext } from '../../ApolloContext';

export const GET_SUGGESTED_SPACES_ENABLED_FOR_ORG = gql`
  query GetSuggestedSpacesEnabledForOrg {
    suggestedSpacesEnabled: getSuggestedSpacesEnabledForOrg
  }
`;

export function useGetSuggestedSpacesEnabledForOrg(
  featureFlagEnabled = false
): QueryResult<GetSuggestedSpacesEnabledForOrgQuery> {
  const { tenantId } = useApolloContext();
  return useQuery(GET_SUGGESTED_SPACES_ENABLED_FOR_ORG, {
    skip: !featureFlagEnabled || !tenantId,
  });
}

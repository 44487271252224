import { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useDeskBookingContext, useToast } from '../../../contexts';
import { useDeskDetails } from '../../../contexts/DeskBooking/hooks/useDeskDetails';
import { robinLogger } from '../../../utilities/RobinLogger';
import { DeskInfo } from './DeskInfo';
import { deskDetailsSelector } from './utility';
import { DeskReservationVisibilityType, DeskReservation } from '../../../types';

type DeskInfoContainerProps = {
  deskId: string;
  start: Moment;
  end: Moment;
  timeZone: string;
};

export const DeskInfoContainer = ({
  deskId,
  start,
  end,
  timeZone,
}: DeskInfoContainerProps) => {
  const { closeMap, setSelectedDeskId } = useDeskBookingContext();
  const toast = useToast();

  const [visibility, setVisibility] =
    useState<DeskReservationVisibilityType>('EVERYONE');

  const {
    details: deskDetails,
    loading,
    reserveDesk,
    isReserving,
  } = useDeskDetails(timeZone, start, end, deskId, visibility);

  const getDeskInfoHelper = useMemo(() => {
    return deskDetailsSelector();
  }, [deskDetails]);

  const reservation: DeskReservation | undefined = useMemo(() => {
    return getDeskInfoHelper.reservation(deskDetails);
  }, [deskDetails]);

  const amenityList = useMemo(
    () => getDeskInfoHelper.amenityList(deskDetails),
    [deskDetails?.amenities]
  );

  const isAvailable = useMemo(
    () => getDeskInfoHelper.isAvailable(deskDetails),
    [deskDetails]
  );

  const deskVisibilityPolicyEnabled = useMemo(
    () => getDeskInfoHelper.deskVisibilityPolicyEnabled(deskDetails),
    [deskDetails]
  );

  const unbookableReasonsNoAccess = useMemo(
    () => getDeskInfoHelper.deskVisibilityPolicyEnabled(deskDetails),
    [deskDetails]
  );

  const isUnbookable = getDeskInfoHelper.isUnbookable(deskDetails);

  const deskType = useMemo(
    () => getDeskInfoHelper.deskType(deskDetails),
    [deskDetails]
  );

  const handleReserveDesk = useCallback(async () => {
    if (isAvailable) {
      try {
        await reserveDesk();
        robinLogger().reportEvent('booked-desk');
        toast.success('Desk booked!');
        closeMap();
      } catch (e) {
        toast.error('Failed to book desk.');
      }
    }
  }, [isAvailable, reserveDesk, toast, closeMap]);

  return (
    <DeskInfo
      visibility={visibility}
      unbookableReasonsNoAccess={unbookableReasonsNoAccess}
      deskDetails={deskDetails}
      isAvailable={isAvailable}
      isUnbookable={isUnbookable}
      isReserving={isReserving}
      deskVisibilityPolicyEnabled={deskVisibilityPolicyEnabled}
      loading={loading}
      deskType={deskType}
      reservation={reservation}
      amenityList={amenityList}
      start={start}
      end={end}
      setVisibility={setVisibility}
      handleReserveDesk={handleReserveDesk}
      setSelectedDeskId={setSelectedDeskId}
    />
  );
};

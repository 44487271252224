import moment from 'moment';
import { DeskUnbookableReasonType } from '../__generated__/types';
import { UnbookableReason } from '../types';

export const deskUnbookableReasons = {
  no_access: [
    'UNAVAILABLE_ASSIGNED',
    'NO_ACCESS',
    'MAX_CAPACITY_REACHED',
    'LACKING_PERMISSIONS',
    'UNKNOWN',
  ],
  policy: [
    'SCHEDULE_VIOLATED',
    'SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD',
    'SEAT_RESERVATION_WORKING_HOURS_RESTRICTED',
    'VIOLATE_HOT_DESK_POLICY',
    'SEAT_RESERVATION_MAX_LENGTH',
  ],
};

// TODO: use i18n to translate these strings
const translateReason = (
  reason: UnbookableReason['reason'],
  value: UnbookableReason['value'],
  floorName?: string
): string | undefined => {
  switch (reason) {
    case DeskUnbookableReasonType.NO_ACCESS:
      return "You don't have access to book this desk";

    case DeskUnbookableReasonType.SCHEDULE_VIOLATED:
      return 'No access this date';

    case DeskUnbookableReasonType.SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD:
      return `Your organization does not allow booking more than ${moment
        .duration(value || '')
        .humanize()} in advance`;

    case DeskUnbookableReasonType.SEAT_RESERVATION_WORKING_HOURS_RESTRICTED:
      return "Can't be reserved outside of working hours";

    case DeskUnbookableReasonType.VIOLATE_HOT_DESK_POLICY:
      return 'Same-day reservations only (hot desk)';

    case DeskUnbookableReasonType.SEAT_RESERVATION_MAX_LENGTH:
      return `Max reservation length is ${moment
        .duration(value || '')
        .humanize()}`;

    case DeskUnbookableReasonType.MAX_CAPACITY_REACHED:
      return `${floorName} is at capacity`;

    case DeskUnbookableReasonType.LACKING_PERMISSIONS:
      return "You don't have permission to book this desk";

    default:
      return 'Desk is currently unavailable';
  }
};

export const translateUnbookableReason = (
  unavailableReasons: UnbookableReason[],
  floorName?: string
) => {
  if (!unavailableReasons?.length) {
    return ['Desk is currently unavailable'];
  }
  return unavailableReasons?.reduce((acc: string[], unavailableReason) => {
    const message = translateReason(
      unavailableReason.reason,
      unavailableReason.value,
      floorName
    );
    if (message) {
      acc.push(message);
    }
    return acc;
  }, []);
};

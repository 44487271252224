import {Component} from 'react';
import PropTypes from 'prop-types';
import {withMap} from '../Map';

export class EventReactor extends Component {
  static propTypes = {
    /**
     * The event to listen for.
     * @see {@link https://docs.mapbox.com/mapbox-gl-js/api/#events}
     */
    event: PropTypes.string.isRequired,
    /**
     * The layer specifier for the event listener. Will only listen for events for a given feature which happened
     * on layers of this specifier.
     */
    specifier: PropTypes.string,
    /**
     * The event callback.
     */
    callback: PropTypes.func.isRequired,
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  shouldComponentUpdate(nextProps) {
    // Only update the component if event or specifier changed,
    // as then we need to re-bind the event listeners. Otherwise callback doesn't need to be updated.
    return (
      nextProps.event !== this.props.event ||
      nextProps.specifier !== this.props.specifier
    );
  }

  componentDidUpdate(prevProps) {
    // Whenever component updates, we are free to assume that we need to re-register the handlers.
    this.props.map.off(...this.getDerrivedArgsFromProps(prevProps));
    this.registerEventHandler(this.props.map);
  }

  componentWillUnmount() {
    this.unregisterEventHandler(this.props.map);
  }

  componentDidMount() {
    this.registerEventHandler(this.props.map);
  }

  callback = (...args) => {
    const {callback, specifier} = this.props;
    if (!specifier) {
      // If there's no specifier given, we can just pass through the event,
      // since we don't care if any features actually were present for this event.
      callback(...args);
      return;
    }

    const [event] = args;

    if (
      // Event will not contain features when the event being fired is not specific to a particular feature (`wheel`),
      // or if it's not something where we're able to know the feature, since it requires previous state.
      // An example of this is `mouseleave` event, where it's not known which feature was left, just that it happened.
      // In such case we still want to call the callback, since the event is not associated with a single feature,
      // but rather with all features in given specifier(s).
      !Array.isArray(event.features) ||
      event.features.length > 0
    ) {
      // Only call the callback when there are features which conform to the specifier.
      callback(...args);
    }
  };

  getDerrivedArgsFromProps({event, specifier}) {
    const args = [];
    if (specifier) {
      args.push(event, specifier, this.callback);
    } else {
      args.push(event, this.callback);
    }
    return args;
  }

  registerEventHandler(map) {
    map.on(...this.getDerrivedArgsFromProps(this.props));
  }

  unregisterEventHandler(map) {
    map.off(...this.getDerrivedArgsFromProps(this.props));
  }

  render() {
    return null;
  }
}

export default withMap(EventReactor);

import { Flex, Skeleton } from '@fluentui/react-northstar';

export const DeskListItemLoading = () => {
  return (
    <Flex
      gap="gap.small"
      vAlign="center"
      style={{
        border: '1px solid #d1d1d1',
        padding: '12px',
        margin: '6px 12px',
      }}
    >
      <Skeleton animation="pulse" style={{ maxWidth: '32px' }}>
        <Skeleton.Shape round width="32px" height="32px" />
      </Skeleton>

      <Skeleton animation="pulse">
        <Skeleton.Line width="40%" />
        <Skeleton.Line width="60%" />
      </Skeleton>
    </Flex>
  );
};

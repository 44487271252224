import ky from 'ky-universal';
import { config } from '../config';

type AccessTokenResponse = {
  token: { access_token: string };
};

export const getAccessToken = async (
  azureAdId: string
): Promise<AccessTokenResponse> => {
  const { teamsBotServiceUrl } = config;

  return await ky
    .get(`${teamsBotServiceUrl}/token`, {
      searchParams: {
        userObjectId: azureAdId,
      },
    })
    .json<AccessTokenResponse>();
};

import { Colors } from '@robinpowered/design-system';
import { InfoSolid } from '@robinpowered/icons';
import SvgCircleCheckmarkSolid from '@robinpowered/icons/CircleCheckmarkSolid';
import SvgCircleXSolid from '@robinpowered/icons/CircleXSolid';
import moment from 'moment';
import { GetUserScheduleDeskReservations as Reservation } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { getReservationInfo } from '../../../utilities/Reservation';

const getIconAndCopy = (reservation: Reservation) => {
  const {
    hasCheckIn,
    hasLocalCheckIn,
    checkInStatus,
    hasHealthCheckpoint,
    healthCheckpointStatus,
  } = getReservationInfo(reservation);
  const { checkInComplete, checkInRequiredFuture, checkInRequiredNow } =
    checkInStatus;
  const {
    healthCheckpointComplete,
    healthCheckpointFailed,
    healthCheckpointPending,
  } = healthCheckpointStatus;

  if (!hasCheckIn && !hasHealthCheckpoint) return null;

  const { icon, copy } = (() => {
    // LOCAL CHECK IN
    if (hasLocalCheckIn) {
      return {
        icon: 'WARN' as const,
        copy: 'Local check in required',
      };
    }
    // Health Check and Check In
    if (hasHealthCheckpoint && hasCheckIn) {
      // Health check failed
      if (healthCheckpointFailed) {
        return {
          icon: 'FAIL' as const,
          copy: 'Failed health checkpoint',
        };
      }
      // check in and/or HC not complete
      if (healthCheckpointPending) {
        return {
          icon: 'WARN' as const,
          copy: checkInRequiredFuture
            ? `Health checkpoint required. Check in starts at ${moment(
                reservation.confirmation?.confirmationWindowStart
              ).format('hh:mm A')}`
            : checkInRequiredNow
            ? `Health checkpoint required. Check in required by ${moment(
                reservation.confirmation?.confirmationWindowEnd
              ).format('hh:mm A')}`
            : checkInComplete
            ? 'Health checkpoint and check in are required'
            : '',
        };
      }
      // both complete
      if (healthCheckpointComplete && checkInComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: 'Health checkpoint and check in complete',
        };
      }
    }
    // Just check in
    if (hasCheckIn) {
      if (checkInComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: 'Check in complete',
        };
      }
      if (checkInRequiredNow) {
        return {
          icon: 'WARN' as const,
          copy: `Check in required by ${moment(
            reservation.confirmation?.confirmationWindowEnd
          ).format('hh:mm A')}`,
        };
      }
      if (checkInRequiredFuture) {
        return {
          icon: 'WARN' as const,
          copy: `Check in starts at ${moment(
            reservation.confirmation?.confirmationWindowStart
          ).format('hh:mm A')}`,
        };
      }
    }

    // just Health checkpoint
    if (hasHealthCheckpoint) {
      if (healthCheckpointFailed) {
        return {
          icon: 'FAIL' as const,
          copy: 'Failed health checkpoint',
        };
      }
      if (healthCheckpointPending) {
        return {
          icon: 'WARN' as const,
          copy: 'Health checkpoint is required',
        };
      }
      if (healthCheckpointComplete) {
        return {
          icon: 'SUCCESS' as const,
          copy: 'Health checkpoint complete',
        };
      }
    }
    return {
      icon: 'SUCCESS' as const,
      copy: '',
    };
  })();
  return {
    icon,
    copy,
  };
};

export const CheckInRequirementsIcon = (props: {
  reservation: Reservation;
}): JSX.Element | null => {
  const iconAndCopy = getIconAndCopy(props.reservation);
  const getIcon = () => {
    switch (iconAndCopy?.icon) {
      case 'SUCCESS':
        return (
          <SvgCircleCheckmarkSolid size={16} color={Colors.GreenProduct} />
        );
      case 'WARN':
        return <InfoSolid size={16} color={Colors.Gray60} />;
      case 'FAIL':
        return <SvgCircleXSolid size={16} color={Colors.RedProduct} />;
      default:
        return null;
    }
  };
  return getIcon();
};

export const CheckInRequirementsText = (res: Reservation) => {
  return getIconAndCopy(res)?.copy;
};

import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';
import { SidebarHeader } from './SidebarHeader';
import { useFocusTrap } from '../../utilities/dom';
import { useEscapeForRef } from '../../utilities/useEscape';

type SidebarProps = {
  onClose: () => void;
  onBack?: () => void;
  title: string;
  children: ReactNode;
};

export const Sidebar = ({
  onClose,
  onBack,
  title,
  children,
}: SidebarProps): JSX.Element => {
  const focus = useFocusTrap<HTMLDivElement>();
  useEscapeForRef(focus, onClose);

  return (
    <SidebarContainer ref={focus}>
      <SidebarHeader onBack={onBack} onClose={onClose} />
      {children}
    </SidebarContainer>
  );
};

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 30;
  height: 100%;
  width: 375px;
  top: 0;
  right: 0;
  border: 1px solid ${Colors.Tan20};
  background-color: ${Colors.White0};
  padding-bottom: 30px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

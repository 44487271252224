import { Flex, Text } from '@fluentui/react-northstar';
import { UserNotificationSettings } from './UserNotificationSettings';

export type UserSettingsProps = {
  userId: string;
};
export const UserSettings = ({ userId }: UserSettingsProps) => {
  return (
    <Flex column padding="padding.medium" vAlign="center" gap="gap.small">
      <Text size="largest" weight="bold">
        Settings
      </Text>
      <UserNotificationSettings userId={userId} />
    </Flex>
  );
};

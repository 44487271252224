import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';

export const i18n = i18next.use(initReactI18next);

const resources = { en } as const;
i18n.init({
  fallbackLng: 'en',
  returnNull: false,
  returnObjects: true,
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  resources,
});

export default i18n;

import {Component} from 'react';
import PropTypes from 'prop-types';
import {getMetadata} from '@robinpowered/atlas-common';
import {LngLatLike, LngLatBoundsLike} from '@robinpowered/atlas-common';
import {
  compareLngLatLike,
  compareLngLatBoundsLike
} from '@robinpowered/atlas-common';
import {withMap} from '../Map';

export class Camera extends Component {
  static propTypes = {
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired,
    /**
     * Center these bounds in the viewport and use the highest zoom level
     * up to and including `Map.getMaxZoom()` that fits them in the viewport.
     */
    bounds: LngLatBoundsLike,
    /**
     * The amount of space to pad around the given bounds, in pixels.
     * @TODO https://docs.mapbox.com/mapbox-gl-js/api/#paddingoptions
     */
    padding: PropTypes.number,
    /**
     * The center of the given bounds relative to the map's center, in pixels.
     */
    offset: LngLatLike,
    /**
     * The maximum zoom level to allow when the map view transitions to the specified bounds.
     */
    maxZoom: PropTypes.number,
    /**
     * The coordinates to center the viewport on. Will use the zoom level specified
     * by the `zoom` prop.
     */
    center: LngLatLike,
    /**
     * The desired zoom level when centering on a point.
     */
    zoom: PropTypes.number,
    /**
     * The desired pitch when flying to a coordinate, in degrees.
     */
    pitch: PropTypes.number,
    /**
     *  The desired bearing, in degrees. The bearing is the compass direction that
     *  is "up"; for example, a bearing of 90° orients the map so that east is up.
     */
    bearing: PropTypes.number
  };

  componentDidMount() {
    this.triggerChanges();
  }

  componentDidUpdate(prevProps) {
    this.triggerChanges(prevProps);
  }

  triggerChanges = (prevProps = {}) => {
    // Check for coordinates configuration.
    if (this.shouldFlyToCoordinate(prevProps)) {
      return this.flyToCoordinate(this.props.center);
    }
    // If no coordinates, check for bounds configuration.
    if (this.shouldFlyToBounds(prevProps)) {
      return this.flyToBounds(this.props.bounds);
    }
  };

  shouldFlyToCoordinate = (prevProps = {}) => {
    // Check if the center prop was set or if its been changed.
    const centerChanged =
      (this.props.center && !prevProps.center) ||
      (this.props.center &&
        prevProps.center &&
        !compareLngLatLike(this.props.center, prevProps.center));

    const zoomChanged =
      typeof this.props.zoom === 'number' && this.props.zoom !== prevProps.zoom;

    const pitchChanged =
      typeof this.props.pitch === 'number' &&
      this.props.pitch !== prevProps.pitch;

    const bearingChanged =
      typeof this.props.bearing === 'number' &&
      this.props.bearing !== prevProps.bearing;

    return (
      // Regardless of which props updated, if we don't have a center prop we cannot fly to any coordinates.
      this.props.center &&
      (centerChanged || zoomChanged || pitchChanged || bearingChanged)
    );
  };

  shouldFlyToBounds = (prevProps) => {
    // Check if the bounds prop was set or if its been changed.
    const boundsChanged =
      (this.props.bounds && !prevProps.bounds) ||
      (this.props.bounds &&
        prevProps.bounds &&
        !compareLngLatBoundsLike(this.props.bounds, prevProps.bounds));

    const paddingChanged =
      typeof this.props.padding === 'number' &&
      this.props.padding !== prevProps.padding;

    const offsetChanged =
      (this.props.offset && !prevProps.offset) ||
      (this.props.offset &&
        prevProps.offset &&
        !compareLngLatLike(this.props.offset, prevProps.offset));

    const maxZoomChanged =
      typeof this.props.maxZoom === 'number' &&
      this.props.maxZoom !== prevProps.maxZoom;

    return (
      // Regardless of which props updated, if we don't have a bounds prop we fit to any bounds.
      this.props.bounds &&
      (boundsChanged || paddingChanged || offsetChanged || maxZoomChanged)
    );
  };

  flyToBounds = (bounds) => {
    const defaultMaxZoom = getMetadata(this.props.map.getStyle(), 'max-zoom');
    this.props.map.fitBounds(bounds, {
      // The zooming "curve" that will occur along the flight path.
      // A high value maximizes zooming for an exaggerated animation,
      // while a low value minimizes zooming for an effect.
      curve: 1,
      easing: (t) => t * (2 - t),
      duration: 1250,
      maxZoom: this.props.maxZoom || defaultMaxZoom,
      ...(this.props.offset ? {offset: this.props.offset} : null),
      ...(this.props.padding ? {padding: this.props.padding} : null)
    });
  };

  flyToCoordinate = (coordinate) => {
    // Make sure to use `easeTo` instead of `flyTo` because of the zooming
    // out jitters. That bug was addressed only for the `easeTo` method.
    //
    // The "zooming out jitters" refers to how if you try to zoom outside
    // of the maxBounds, the screen flickers and jitters because mapbox
    // is trying to zoom you outside of the bounds and fit you into the
    // bounds at the same time (causing the flickering).
    //
    // Using `easeTo` doesn't prevent this from happening 100% of the
    // time, but it does prevent it most of the time. For 100% consistency
    // with zooming out, also use `map#getMinZoomForMaxBounds` to get the min
    // zoom level to help prevent you from trying to zoom out too far.
    //
    // https://github.com/mapbox/mapbox-gl-js/issues/2521
    // https://github.com/mapbox/mapbox-gl-js/pull/4602
    this.props.map.easeTo({
      duration: 1000,
      easing: (t) => t * (2 - t),
      curve: 1,
      center: coordinate,
      ...(typeof this.props.zoom === 'number' ? {zoom: this.props.zoom} : null),
      ...(typeof this.props.pitch === 'number'
        ? {pitch: this.props.pitch}
        : null),
      ...(typeof this.props.bearing === 'number'
        ? {bearing: this.props.bearing}
        : null)
    });
  };

  render() {
    return null;
  }
}

export default withMap(Camera);

import React, { FC } from 'react';
import styled from '@emotion/styled';

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Toast = {
  type: ToastType;
  message: string;
};

type ToastsProps = {
  toasts: Toast[];
};

export const Toasts: FC<ToastsProps> = ({ toasts }) => {
  if (!toasts.length) {
    return null;
  }
  return (
    <Container>
      {toasts.map(({ type, message }) => (
        <Card type={type} key={message}>
          {message}
        </Card>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1500;
`;

const Card = styled.div<{ type: ToastType }>`
  text-align: center;
  width: 400px;
  border-radius: 6px;
  line-height: 1.2;
  padding: 10px;
  color: white;
  background: ${({ type }) =>
    type === ToastType.SUCCESS ? '#237B4B' : '#8E192E'};
`;

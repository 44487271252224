import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useState,
  useCallback,
} from 'react';
import { Body, Column, Heading, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import ChevronDownOutline from '@robinpowered/icons/ChevronDownOutline';
import ChevronUpOutline from '@robinpowered/icons/ChevronUpOutline';

/*
  Accordion implenentation based on https://github.com/robinpowered/robin-extensions/commit/49c1abd623db8c01e37f121d05823bae66a2d7b9
*/

type AccordionProps = PropsWithChildren<{
  label: ReactNode;
  startOpen?: boolean;
  info?: ReactNode;
}>;

type AccordionSectionProps = {
  isOpen: boolean;
};

export const Accordion: FC<AccordionProps> = ({
  children,
  label,
  startOpen = false,
  info,
}) => {
  const [isOpen, setIsOpen] = useState(startOpen);
  const toggleOpen = useCallback(() => setIsOpen((s) => !s), []);

  return (
    <Column>
      <AccordionSectionHeaderContainer onClick={toggleOpen} isOpen={isOpen}>
        <Row>
          <Heading.Regular>{label}</Heading.Regular>
          {info && (
            <>
              <Heading.Regular>⸱</Heading.Regular>
              <Body.Small>{info}</Body.Small>
            </>
          )}
        </Row>
        {isOpen ? (
          <ChevronUpOutline size={16} />
        ) : (
          <ChevronDownOutline size={16} />
        )}
      </AccordionSectionHeaderContainer>
      <AccordionSectionContent isOpen={isOpen}>
        {children}
      </AccordionSectionContent>
    </Column>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
`;

const WrapperButton = styled(Row)`
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;
  box-sizing: border-box;
  text-align: inherit;
  text-decoration: none;
  vertical-align: baseline;

  cursor: pointer;

  &:hover,
  &:focus,
  &:focus-visible,
  &:focus-within,
  &:active,
  &:visited,
  &:target,
  &::-moz-focus-inner,
  &:disabled {
    outline: none;
    box-shadow: none;
    background: transparent;
  }

  &:hover {
    background-color: ${Colors.Tan10};
  }

  &:focus,
  &:focus-visible,
  &:active {
    z-index: 5;
    background-color: ${Colors.Tan20};
    box-shadow: 0 0 0 4px ${Colors.Blue100};
  }

  &:disabled {
    color: ${Colors.Gray40};
    cursor: not-allowed;
  }
`.withComponent('button');

const AccordionSectionHeaderContainer = styled(
  WrapperButton
)<AccordionSectionProps>`
  height: 56px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ isOpen }) => (isOpen ? Colors.Tan20 : Colors.Tan5)};
  ${Heading.Regular.styles}
  & > div {
    gap: 8px;
    align-items: baseline;
  }
`;

const AccordionSectionContent = styled(Column)<AccordionSectionProps>`
  display: ${({ isOpen }) => (isOpen ? 'unset' : 'none')};
  flex-grow: 2;
  padding: 8px;
`;

export const AccordionContent = styled(Column)`
  gap: 8px;
  ${Body.Small.styles}
`;

import { useEffect, useMemo } from 'react';
import { GetUserScheduleGetUserInOfficeDataByDay as ScheduleType } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { DeskConfirmationStatus } from '../../../__generated__/types';
import moment from 'moment';
import {
  useCalendarContext,
  useDateContext,
  useLocationsContext,
  useUserContext,
  useUserScheduleContext,
  useWhosInContext,
} from '../../../contexts';
import { robinLogger } from '../../../utilities/RobinLogger';
import styled from '@emotion/styled';
import { useMyEvents } from '../../EventsList/graphql/useMyEvents';
import { isUserInOffice } from '../utlity';
import { DayCard } from '../DayCard/DayCard';
import { useSidebarContext } from '../../../contexts/Sidebar';

export const DayList = () => {
  const { loading: userLoading } = useUserContext();
  const { weekScheduleDates } = useDateContext();
  const { loading: loadingEvents, data: myEvents } = useMyEvents(100);
  const { loading: calendarLoading } = useCalendarContext();
  const { isSidebarOpen } = useSidebarContext();
  const { selectedLocation, loading: locationsLoading } = useLocationsContext();

  const {
    data: userScheduleData,
    loading: userScheduleLoading,
    refetch: refetchUserSchedule,
  } = useUserScheduleContext();

  const {
    data: whoIsInData,
    loading: whoIsInLoading,
    refetch: refetchWhosIn,
  } = useWhosInContext();

  useEffect(() => {
    robinLogger().reportEvent('show_workweek');
  }, []);

  useEffect(() => {
    if (userScheduleData && refetchWhosIn && refetchUserSchedule) {
      refetchWhosIn();
      refetchUserSchedule();
    }
  }, [userScheduleData, refetchWhosIn, refetchUserSchedule]);

  const officeDays = useMemo(() => {
    const inOfficeSchedule = userScheduleData?.getUserInOfficeDataByDay;

    return weekScheduleDates.map((currentDay, i) => {
      const scheduleDay: ScheduleType | undefined = inOfficeSchedule?.find(
        (schedule) => {
          return currentDay.isSame(schedule.date, 'date');
        }
      );

      if (!scheduleDay) {
        return {
          date: currentDay,
          visit: undefined,
          reservation: undefined,
        };
      }

      const reservation = scheduleDay?.userInOffice?.deskReservations?.find(
        (reservation) => {
          if (selectedLocation?.id !== reservation.seat.location.id) {
            return false;
          }
          if (
            reservation.confirmation?.status === DeskConfirmationStatus.DECLINED
          ) {
            return false;
          }
          if (
            moment().isSame(currentDay, 'day') &&
            moment().isAfter(reservation.endTime)
          ) {
            return false;
          }
          return true;
        }
      );

      const visit = scheduleDay?.userInOffice?.employeeVisits?.find((visit) => {
        return selectedLocation?.id === visit.locationId;
      });

      const events = myEvents?.getMyEventsInTheRange.filter((event) => {
        return moment(event.startTime).isSame(currentDay, 'd');
      });

      return {
        date: currentDay,
        visit: visit,
        reservation,
        events,
      };
    });
  }, [
    userScheduleData?.getUserInOfficeDataByDay,
    weekScheduleDates,
    myEvents?.getMyEventsInTheRange,
    selectedLocation,
  ]);

  const officeDayThatShouldHaveSuggestedSpaces = useMemo(
    () =>
      officeDays.find(
        (officeDay) =>
          officeDay.date.isSameOrAfter(moment(), 'date') &&
          isUserInOffice(officeDay.visit, officeDay.reservation)
      ),
    [officeDays]
  );

  const isLoading = useMemo(
    () =>
      userScheduleLoading ||
      whoIsInLoading ||
      locationsLoading ||
      userLoading ||
      calendarLoading,
    [
      userScheduleLoading,
      whoIsInLoading,
      locationsLoading,
      userLoading,
      calendarLoading,
    ]
  );

  return (
    <Container isSidebarOpen={isSidebarOpen}>
      <>
        {officeDays.map((day, i) => (
          <DayCard
            key={i}
            index={i}
            isCardLoading={isLoading}
            whosIn={whoIsInData}
            date={day.date}
            visit={day.visit}
            reservation={day.reservation}
            loadingReservation={userScheduleLoading}
            events={day.events || []}
            loadingEvents={loadingEvents}
            lookingForSuggestedSpaces={day.date.isSame(
              officeDayThatShouldHaveSuggestedSpaces?.date
            )}
          />
        ))}
      </>
    </Container>
  );
};

const Container = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  gap: 0.625rem;
  overflow-x: auto;
  width: ${({ isSidebarOpen }) =>
    isSidebarOpen ? 'calc(100vw - 373px)' : '100vw'};

  @media (max-width: 480px) {
    flex-direction: column;
    min-width: 100vw;
  }
`;

import maplibre from 'maplibre-gl';

/**
 * Custom style cache, which allows us to plug into Mapbox's internals,
 * which expect this interface.
 */
const CustomStyle = function() {
  this._layers = {};
  this.stylesheet = {
    version: 8
  };
  this.sourceCaches = {
    mock: {
      pause() {},
      getSource() {
        return {type: 'geojson'};
      }
    }
  };
  this._order = [];
  this._serializedLayers = {};
  this._removedLayers = {};
  this._updatedLayers = {};
  this._updatedSources = {};
  this._updatedPaintProps = {};
};

CustomStyle.prototype = maplibre.Style.prototype;
CustomStyle.prototype._checkLoaded = function() {};
CustomStyle.prototype._validate = function() {};

const style = new CustomStyle();
// To execute the expression, we simply add a "layer", and then call "evaluate" on it.
style.addLayer({
  id: 'expression',
  type: 'line',
  source: 'mock',
  paint: {}
});

/**
 * Evaluates given Style Spec expression using given value & prop to act as variables.
 *
 * @param  {string} valueProp The name of the variable.
 * @param  {*} value The value of the variable.
 * @param  {Array} expression The expression to execute.
 * @return {*} The resulting value from the expression.
 */
const evaluateExpressionWithValue = (valueProp, value, expression) => {
  style.setPaintProperty(
    'expression',
    'line-width',
    ['let', valueProp, value, expression],
    {
      validate: false
    }
  );

  return style._layers.expression._transitionablePaint._values[
    'line-width'
  ].value.expression.evaluateWithoutErrorHandling();
};

export default evaluateExpressionWithValue;

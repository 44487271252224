import {Component} from 'react';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';
import {withMap} from '../Map';

export class BaseControl extends Component {
  static propTypes = {
    /**
     * The control position.
     */
    position: PropTypes.oneOf([
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right'
    ]),
    /**
     * The control contents.
     */
    children: PropTypes.node.isRequired,
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  state = {
    mounted: false
  };

  container = document.createElement('div');

  componentDidUpdate(prevProps) {
    if (prevProps.position !== this.props.position) {
      // To update position of the control, we simply need to re-add as a control.
      // Because the class instance is the same, as well as the container, it moves the element instead,
      // insteaof of adding a new one.
      this.props.map.addControl(this, this.props.position);
    }
  }

  componentWillUnmount() {
    // Indicate that the component is being unmounted, thus we should avoid invoking lifecycle
    // methods (i.e. setState), as those will not have any effect.
    // This allows us to perform the removing of the DOM node synchronously.
    this.componentUnmounting = true;
    this.props.map.removeControl(this);
  }

  componentDidMount() {
    // Default class for the container.
    this.container.className = 'mapboxgl-ctrl';
    this.setState({mounted: true}, () =>
      this.props.map.addControl(this, this.props.position)
    );
  }

  onAdd = () => {
    return this.container;
  };

  /**
   * Gets called by map when control is removed from the map.
   * Unlike `onAdd` which just returns the container, this might also get called from
   * outside of the component, as there are no guarantees as to when the control might get removed.
   * To that extent, we have to implement state management in this method, to always ensre that we exit cleanly.
   * If this removal were to happen, though, this would mean that the remaining node in the React tree might not be
   * showing the children as it expects.
   *
   * This could be addressed by introducing a callback for this component which would be fired once component is
   * removed by other means.
   */
  onRemove = () => {
    const remove = () => {
      if (this.container && this.container.parentNode) {
        this.container.parentNode.removeChild(this.container);
      }
    };

    if (!this.state.mounted || this.componentUnmounting) {
      // Already unmounted or will become unmounted via componentWillUnmount, remove the DOM node.
      return remove();
    }

    // Wait for state to become unmounted, and then remove the DOM nodes.
    this.setState({mounted: false}, () => remove());
  };

  render() {
    const {mounted} = this.state;

    if (!mounted) {
      return null;
    }

    return createPortal(this.props.children, this.container);
  }
}

export default withMap(BaseControl);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withMap} from '../Map';
import BaseControl from '../BaseControl';

export class ZoomLevelIndicator extends Component {
  static propTypes = {
    /**
     * The control position.
     */
    position: PropTypes.oneOf([
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right'
    ]),
    /**
     * The map instance.
     */
    map: PropTypes.object.isRequired
  };

  state = {
    zoom: this.getZoomPercentage()
  };

  componentWillUnmount() {
    this.props.map.off('zoom', this.zoomListener);
  }

  componentDidMount() {
    this.props.map.on('zoom', this.zoomListener);
  }

  getZoomPercentage() {
    const currentZoom = this.props.map.getZoom();
    const minZoom = this.props.map.getMinZoom();
    const maxZoom = this.props.map.getMaxZoom();
    return Math.round(((currentZoom - minZoom) / (maxZoom - minZoom)) * 100);
  }

  zoomListener = () => {
    this.setState({
      zoom: this.getZoomPercentage()
    });
  };

  render() {
    const {position} = this.props;
    const {zoom} = this.state;

    return (
      <BaseControl position={position}>
        {/* eslint-disable react/jsx-no-literals */}
        Zoom Level: {zoom}%{/* eslint-enable react/jsx-no-literals */}
      </BaseControl>
    );
  }
}

export default withMap(ZoomLevelIndicator);

import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OrgLocation as Location } from '../../Location';

export const getWorkingHoursForDay = (location: Location, date: Moment) => {
  // Default to 7am-7pm if working hours aren't set, the office is closed, etc.
  const defaults = { start: 7, end: 19 };
  const dayOfWeek = date.isoWeekday();

  const timeFrame = location.workingHours?.find(({ day }) => day === dayOfWeek)
    ?.timeFrames?.[0];

  if (!timeFrame) {
    return defaults;
  }

  return {
    start: moment.duration(timeFrame.start).asHours(),
    end: moment.duration(timeFrame.end).asHours(),
  };
};

type ReturnType = {
  initialTimeParams: DeskTime;
  deskTime: DeskTime;
  changeStartTime: (time: moment.Moment) => void;
  changeEndTime: (time: moment.Moment) => void;
};

export type DeskTime = {
  start: Moment;
  end: Moment;
};

export const useDeskSearch = (
  date: moment.Moment | undefined,
  timezone: string,
  location: Location | null
): ReturnType => {
  const initialTimeParams = useMemo(() => {
    if (!location) {
      return { start: moment().startOf('day'), end: moment().endOf('day') };
    }
    const selectedDate = date || moment.tz(timezone).startOf('day');
    const workingHours = getWorkingHoursForDay(location, selectedDate);
    const time = {
      start: selectedDate
        .clone()
        .startOf('day')
        .add(workingHours.start, 'hours'),
      end: selectedDate.clone().startOf('day').add(workingHours.end, 'hours'),
    };
    return time;
  }, [date, location, timezone]);

  const [deskTime, setDeskTime] = useState<DeskTime>(initialTimeParams);

  useEffect(() => {
    setDeskTime({
      start: moment.tz(timezone).isBefore(initialTimeParams.start)
        ? initialTimeParams.start
        : moment.tz(timezone).startOf('minute'),
      end: initialTimeParams.end,
    });
  }, [initialTimeParams, timezone]);

  const changeStartTime = useCallback((startTime: moment.Moment) => {
    setDeskTime((prevTime) => {
      return { start: startTime, end: prevTime.end };
    });
  }, []);

  const changeEndTime = useCallback((endTime: moment.Moment) => {
    setDeskTime((prevTime) => {
      return { start: prevTime.start, end: endTime };
    });
  }, []);

  return {
    initialTimeParams,
    deskTime,
    changeStartTime,
    changeEndTime,
  };
};

import styled from '@emotion/styled';
import Checkmark from '@robinpowered/icons/Checkmark';
import { Colors } from '@robinpowered/design-system';
import { OrgLocation, useLocationsContext } from '../../../contexts';
import { css } from '@emotion/core';

export const Ellipsis = css`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  location?: OrgLocation;
  onClick: () => void;
};

export const LocationRow = ({ location, onClick }: Props): JSX.Element => {
  const { setSelectedLocationId, selectedLocation } = useLocationsContext();
  const isSelected = selectedLocation?.id === location?.id;
  return (
    <RowButton
      onClick={() => {
        setSelectedLocationId(location?.id);
        onClick();
      }}
    >
      <Checker>{isSelected && <Checkmark size={20} />}</Checker>
      <Row isSelected={isSelected}>{location?.name}</Row>
    </RowButton>
  );
};

const RowButton = styled.button`
  background-color: ${Colors.White0};
  border: none;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.Tan5};
    text-decoration: underline;
  }
`;

const Checker = styled.div`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const Row = styled.div<{ isSelected?: boolean }>`
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: 80%;
  ${Ellipsis}
  ${({ isSelected }) =>
    isSelected &&
    `
    color: ${Colors.Maroon100};
  `}
`;

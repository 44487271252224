import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { robinLogger } from '../../utilities/RobinLogger';
import { ErrorMessage } from './ErrorMessage';

type State = {
  error: null | Error;
};

export class ErrorBoundaryComponent extends Component<
  WithTranslation<'errorBoundary'>,
  State
> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error): void {
    this.setState({ error: error });
    robinLogger().reportError(error);
  }

  render(): React.ReactNode {
    if (!this.state.error) {
      return this.props.children;
    }
    return <ErrorMessage error={this.state.error} />;
  }
}

export const ErrorBoundary = withTranslation('globalErrorBoundary')(
  ErrorBoundaryComponent
);

import React, { FC, useCallback } from 'react';
import { CheckboxV4 } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import {
  SpaceTypeWithID,
  useSpaceBookingContext,
} from '../../contexts/SpaceBooking/SpaceBookingContext';


type Props = {
  spaceTypesWithId: SpaceTypeWithID[];
  onChange: (selectedTypes: SpaceTypeWithID[]) => void;
};

export const SpaceTypesFilter: FC<Props> = ({ spaceTypesWithId, onChange }) => {
  const { filters } = useSpaceBookingContext();

  const toggleSpaceTypes = useCallback(
    (spaceTypeWithId: SpaceTypeWithID) => {
      if (filters.spaceTypes.includes(spaceTypeWithId)) {
        onChange(filters.spaceTypes.filter((item) => item !== spaceTypeWithId));
      } else {
        onChange([...filters.spaceTypes, spaceTypeWithId]);
      }
    },
    [filters.spaceTypes, onChange]
  );

  return (
    <Container>
      {spaceTypesWithId.map((spaceTypeWithId, index) => {
        return (
          <CheckboxWrapper key={index}>
            <CheckboxV4
              key={index}
              label={spaceTypeWithId.spaceType ?? ''}
              checked={filters.spaceTypes.includes(spaceTypeWithId)}
              onChange={() => toggleSpaceTypes(spaceTypeWithId)}
            />
          </CheckboxWrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  max-height: 220px;
  overflow: auto;
`;

const CheckboxWrapper = styled.div`
  margin-bottom: 8px;
  label {
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
`;

import moment, { Moment } from 'moment';
import { createContext, useCallback, useContext, useState } from 'react';

const MOBILE_MAX_WIDTH = 1024;

type SidebarContextType = {
  isSidebarOpen: boolean;
  openDaySidebar: (val: Moment, calculateWidthBeforeOpening?: boolean) => void;
  closeSidebar: () => void;
  daySidebarDate: Moment;
};

const SidebarContext = createContext<SidebarContextType>({
  isSidebarOpen: window.innerWidth > MOBILE_MAX_WIDTH,
  openDaySidebar: (val: Moment, calculateWidthBeforeOpening?: boolean) => null,
  closeSidebar: () => null,
  daySidebarDate: moment(),
});

export const SidebarProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(
    window.innerWidth > MOBILE_MAX_WIDTH
  );
  const [daySidebarDate, setDaysideBarDate] = useState<Moment>(moment());
  const openDaySidebar = useCallback(
    (val: Moment, calculateWidthBeforeOpening?: boolean) => {
      setDaysideBarDate(val);
      if (calculateWidthBeforeOpening) {
        window.innerWidth > MOBILE_MAX_WIDTH && setIsSidebarOpen(true);
      } else {
        setIsSidebarOpen(true);
      }
    },
    []
  );
  const closeSidebar = () => setIsSidebarOpen(false);
  return (
    <SidebarContext.Provider
      value={{
        isSidebarOpen,
        openDaySidebar,
        closeSidebar,
        daySidebarDate,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = (): SidebarContextType => {
  return useContext(SidebarContext);
};

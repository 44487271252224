import { Flex, Heading02, UtilityButton } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { ChevronLeftOutline, Close } from '@robinpowered/icons';
import { useSidebarContext } from '../../contexts/Sidebar';
import moment from 'moment';

type SidebarHeaderProps = {
  onBack?: () => void;
  onClose?: () => void;
};

export const SidebarHeader = ({
  onBack,
  onClose,
}: SidebarHeaderProps): JSX.Element => {
  const { t } = useTranslation('sidebar');
  const { daySidebarDate } = useSidebarContext();
  const headerTitle = useMemo(() => {
    return moment(daySidebarDate).format('ddd, MMMM DD');
  }, [daySidebarDate]);
  return (
    <Flex
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      as="header"
      width="100%"
      p="16px"
    >
      <Flex display="flex" flexDirection="row" alignItems="center">
        {onBack && (
          <UtilityButton
            icon={ChevronLeftOutline}
            aria-label={t('aria.close_sidebar')}
            onClick={onBack}
            autoFocus
          />
        )}
        <Heading02 ml={onBack && '12px'}>{headerTitle}</Heading02>
      </Flex>
      {onClose && (
        <UtilityButton
          icon={Close}
          aria-label={t('aria.close_sidebar')}
          onClick={onClose}
          autoFocus
        />
      )}
    </Flex>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Prefix which namespaces all related metadata.
 * @type {String}
 */
var METADATA_PREFIX = 'robin:';
exports.default = (function (style, key, prefix) {
    if (prefix === void 0) { prefix = METADATA_PREFIX; }
    if (style &&
        style.metadata &&
        style.metadata["" + prefix + key] !== undefined) {
        return style.metadata["" + prefix + key];
    }
    return null;
});

import React, { FC, useCallback, useMemo } from 'react';
import {
  BrownFontStack,
  Colors,
  Flex,
  Skeleton,
  Body,
  ButtonV4,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import MinorPinSolid from '@robinpowered/icons/MinorPinSolid';
import WarningSolid from '@robinpowered/icons/WarningSolid';
import SparkleSolid from '@robinpowered/icons/SparkleSolid';
import styled from '@emotion/styled';
import { SuggestedSpace } from './graphql/useSuggestedSpacesForEvents';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';
import { teamsTheme } from '@fluentui/react-northstar';
import { robinLogger } from '../../utilities/RobinLogger';
import { useEventsListContext } from '../../contexts';

type Props = {
  suggestedSpace: SuggestedSpace;
  event: Event;
  loading: boolean;
  openSpaceModal: (e: { key?: string; button?: number }) => void;
};

export const SpaceSuggestion: FC<Props> = ({
  event,
  suggestedSpace,
  loading,
  openSpaceModal,
}) => {
  const { t } = useTranslation('sidebar');
  const { reportEvent } = robinLogger();
  const { spaceBookingState, bookSuggestedSpace } = useEventsListContext();

  const attemptedSuggestedSpace =
    spaceBookingState.spaceBookingStatus[event.id];

  const isLoading = useMemo(
    () =>
      attemptedSuggestedSpace?.isLoading ||
      spaceBookingState.bookingAllSuggestedSpaces,
    [
      attemptedSuggestedSpace?.isLoading,
      spaceBookingState.bookingAllSuggestedSpaces,
    ]
  );

  const handleBookButton = useCallback(async () => {
    bookSuggestedSpace(event, suggestedSpace.id, t);
    reportEvent('clicked-book-suggested-space');
  }, [bookSuggestedSpace, event, reportEvent, suggestedSpace.id, t]);

  if (loading) {
    return (
      <Flex display="flex" mt="8px">
        <Skeleton width="100%" height="20px" mr={'10px'} />
        <Skeleton width="30%" height="20px" />
      </Flex>
    );
  }

  return (
    <Container>
      <Flex display="flex" width="100%" flexDirection="column">
        <Flex
          flex={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt="4px"
        >
          <Flex display="flex" alignItems="flex-start" mr="8px">
            {attemptedSuggestedSpace?.success ? (
              <MinorPinSolid
                size={16}
                color={Colors.Gray60}
                style={{ marginTop: '2px' }}
              />
            ) : (
              <>
                <SparkleSolid
                  color={teamsTheme.siteVariables.colorScheme.brand.background}
                  size={16}
                  style={{
                    alignSelf: 'baseline',
                    marginTop: '2px',
                    marginRight: '4px',
                  }}
                />
              </>
            )}

            <Body.Small
              style={{
                color: teamsTheme.siteVariables.colorScheme.brand.background,
              }}
            >
              {t('events.suggested_space.available', {
                spaceName: suggestedSpace.name,
              })}
            </Body.Small>
          </Flex>
        </Flex>

        <Flex
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingTop={'8px'}
        >
          {!attemptedSuggestedSpace?.success && (
            <BookSuggestedSpaceAction
              isLoading={isLoading}
              onClick={handleBookButton}
              label={t('events.suggested_space.add_to_event')}
              variant="secondary"
              size="small"
            />
          )}

          {!isLoading && (
            <FindAnotherSpaceAction onClick={openSpaceModal}>
              {t('events.suggested_space.find_another')}
            </FindAnotherSpaceAction>
          )}
        </Flex>
      </Flex>

      {attemptedSuggestedSpace?.errorMessage && (
        <Flex
          flex={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          mt={'8px'}
        >
          <WarningSolid
            color={Colors.RedProduct}
            size={15}
            style={{ marginRight: '5px' }}
          />
          <Body.Small color={Colors.RedProduct}>
            {attemptedSuggestedSpace.errorMessage}
          </Body.Small>
        </Flex>
      )}
    </Container>
  );
};

const BookSuggestedSpaceAction = styled(ButtonV4)`
  flex-grow: 1;
  max-width: 50%;
`;

const FindAnotherSpaceAction = styled.button`
  flex-grow: 1;
  border: none;
  background-color: unset;
  cursor: pointer;
  padding: 8px;
  font-family: ${BrownFontStack};
  font-size: 14px;
`;

const Container = styled.div`
  background-color: ${teamsTheme.siteVariables.colorScheme.brand.background1};
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, Colors } from '@robinpowered/design-system';
// eslint-disable-next-line max-len
import { GetUserScheduleDeskReservations as Reservation } from '../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { DeskConfirmationStatus } from '../../../../__generated__/types';
import { teamsTheme } from '@fluentui/react-northstar';

type Props = {
  handleCheckIn: () => void;
  isCardDisabled: boolean;
  reservation?: Reservation;
  isLoading?: boolean;
};

export const CheckInButton = ({
  handleCheckIn,
  isCardDisabled,
  reservation,
  isLoading,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('weekSchedule');

  if (
    !reservation ||
    reservation.confirmation?.status !== DeskConfirmationStatus.UNCONFIRMED
  ) {
    return null;
  }

  return (
    <CheckInButtonWrapper
      variant="primary"
      size="small"
      name="check in"
      disabled={
        isCardDisabled ||
        reservation.seat.reservationPolicies?.checkinPolicies
          ?.enforceLocalCheckinOnly
      }
      onClick={handleCheckIn}
      isLoading={isLoading}
    >
      {t('officeDay.visitCard.check_in.check_in')}
    </CheckInButtonWrapper>
  );
};

const CheckInButtonWrapper = styled(Button)`
  width: 100%;
  margin: 0;
  color: ${Colors.White0};
  background: ${teamsTheme.siteVariables.colorScheme.brand.background};
  &:hover {
    color: ${Colors.White0};
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
  }

  &:focus,
  &:focus-visible,
  &:active {
    color: ${Colors.White0};
    background-color: ${teamsTheme.siteVariables.colorScheme.brand
      .backgroundHover};
  }
`;

import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import {
  useApolloContext,
  useLocationsContext,
  useUserContext,
} from '../../contexts';
import { Dropdown, DropdownProps } from '@fluentui/react-northstar';

type OrgDropdownItem = {
  key: number;
  header: string;
  avatar: string;
};

export const OrganizationDropdown = () => {
  const { currentOrgs } = useUserContext();
  const { tenantId, setTenantId } = useApolloContext();
  const { refetch } = useLocationsContext();

  const items: OrgDropdownItem[] =
    currentOrgs?.map(({ id, name, avatar }) => ({
      key: Number(id),
      header: name,
      avatar,
    })) || [];

  const handleOrganizationChange = (
    _event: React.MouseEvent | React.KeyboardEvent | null,
    data: DropdownProps
  ) => {
    const { key } = data.value as OrgDropdownItem;
    setTenantId(key.toString());
    refetch();
  };

  if (!currentOrgs?.length || currentOrgs?.length <= 1) {
    return null;
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <Dropdown
          items={items}
          value={items.find((i) => i.key === Number(tenantId))?.header}
          onChange={handleOrganizationChange}
          onClick={() => client.resetStore()}
          highlightedIndex={items.findIndex((i) => i.key === Number(tenantId))}
          fluid
          inverted
        />
      )}
    </ApolloConsumer>
  );
};

import React from 'react';
import { Dialog } from '@robinpowered/design-system';
import { useFocusTrap } from '../../../utilities/dom';


type Props = {
  onClose: () => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  visible?: boolean;
};

export const Modal = ({
  onClose,
  children,
  style,
  visible = true,
}: Props): JSX.Element => {
  const localStyle = { ...style };

  const focus = useFocusTrap<HTMLDivElement>();

  // Hide the modal visibly.
  if (!visible) {
    localStyle.visibility = 'hidden';
  }

  return (
    <div ref={focus}>
      {/* Overlay style is redefined here as design system library isn't up-to-date with the current design */}
      {visible && (
        <Dialog.Overlay
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 11 }}
        />
      )}
      <Dialog style={{ ...localStyle, zIndex: 12 }} onOutsideClick={onClose}>
        {children}
      </Dialog>
    </div>
  );
};

import { Button } from '@fluentui/react-northstar';
import { Moment } from 'moment';
import { OrgLocation, useLocationsContext } from '../../../contexts';
import { GetUserScheduleEmployeeVisits as Visit } from '../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';

type BookDeskButtonProps = {
  date: Moment;
  visit: Visit | undefined;
  onBookDeskButtonClick: (loc: OrgLocation, date: Moment) => void;
  disabled: boolean;
};

export const BookDeskButton = ({
  date,
  disabled,
  onBookDeskButtonClick,
}: BookDeskButtonProps) => {
  const { selectedLocation } = useLocationsContext();
  return (
    (selectedLocation !== undefined && (
      <Button
        disabled={disabled}
        fluid
        secondary
        content="Book a desk"
        onClick={() => onBookDeskButtonClick(selectedLocation, date)}
      />
    )) || <></>
  );
};

import { Colors, Column, Shadow03 } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
const AvatarsGrid = require('./Images/AvatarsGrid.png');

export const OfficeCardPreview = ({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}): JSX.Element => {
  const { t } = useTranslation('previewPage');
  return (
    <Column
      className={className}
      padding="20px"
      width="187px"
      height="198px"
      backgroundColor={Colors.White0}
      border={`1px solid ${Colors.Tan30}`}
      boxShadow={Shadow03}
      borderRadius="8px"
    >
      {children}
      <img src={AvatarsGrid} alt={'placeholder'} />
    </Column>
  );
};

import React from 'react';
import styled from '@emotion/styled';
import { Body06, Button, Colors, Flex } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

//TODO : Refetch
type Props = {
  //refetch: () => void;
};

export const SidebarError = (): JSX.Element => {
  const { t } = useTranslation('sidebar');

  return (
    <Flex
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      alignItems="center"
      mt="60px"
    >
      <Body06 color={Colors.Gray80}>{t('error')}</Body06>
      <RetryButton variant="secondary" mt="8px" size="small">
        {t('events.try_again')}
      </RetryButton>
    </Flex>
  );
};

const RetryButton = styled(Button)`
  width: max-content;
`;

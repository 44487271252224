import { gql, MutationTuple, useMutation } from '@apollo/client';
import {
  RemoveSpaceFromEventMutation,
  RemoveSpaceFromEventMutationVariables,
} from './__generated__/removeSpaceFromEvent.generated';
import { robinLogger } from '../../utilities/RobinLogger';

export const REMOVE_SPACE_FROM_EVENT = gql`
  mutation RemoveSpaceFromEvent($input: RemoveSpaceFromEventInput!) {
    removeSpaceFromEvent(input: $input) {
      eventUpdated
      updatedEvent {
        id
        startTime
        endTime
        spaces {
          id
          name
          locationId
        }
      }
      error {
        message
        reason
      }
    }
  }
`;

type RemoveSpaceMutationTuple = MutationTuple<
  RemoveSpaceFromEventMutation,
  RemoveSpaceFromEventMutationVariables
>;

type EnhancedFunction = (
  options: Parameters<RemoveSpaceMutationTuple[0]>[0]
) => ReturnType<RemoveSpaceMutationTuple[0]>;

type EnhancedRemoveSpaceMutationTuple = [
  EnhancedFunction,
  RemoveSpaceMutationTuple[1]
];

export function useRemoveSpaceFromEvent(): EnhancedRemoveSpaceMutationTuple {
  const { reportError, reportEvent } = robinLogger();

  const [originalFunction, result] = useMutation<
    RemoveSpaceFromEventMutation,
    RemoveSpaceFromEventMutationVariables
  >(REMOVE_SPACE_FROM_EVENT, { fetchPolicy: 'network-only' });
  const enhancedFunction: EnhancedFunction = async ({ ...rest }) => {
    const { variables } = rest;
    let originalReturnValue;
    try {
      originalReturnValue = await originalFunction(rest);
    } catch (e) {
      if (e instanceof Error) {
        reportError(e);
      } else {
        reportError(new Error(JSON.stringify(e)));
      }

      throw e;
    }
    const error = originalReturnValue.data?.removeSpaceFromEvent.error;
    if (error) {
      reportError(new Error(`${error.reason}: ${error.message}`));
    } else {
      reportEvent('removed-space-from-event', {
        event: variables?.input?.eventId,
        space: variables?.input?.spaceId,
      });
    }
    return originalReturnValue;
  };

  return [enhancedFunction, result];
}

import { gql, useQuery } from '@apollo/client';
import { i18n } from '../../../i18n';
import { useEffect } from 'react';
import moment from 'moment';
import { useApolloContext } from '../..';
import { DEFAULT_LOCALE } from '../../../constants/localization';
import { GetUserLocaleQuery } from './__generated__/useUserLocale.generated';

export const GET_USER_LOCALE_QUERY = gql`
  query GetUserLocale($userId: ID!) {
    getMyLanguageTag {
      languageTag
    }
    getUserSetting(userId: $userId, settingSlug: "24-hour-time") {
      value
    }
  }
`;

type UserLocale = {
  loading: boolean;
  lang: string;
  use24HourTime: boolean;
};

export const useUserLocale = (userId?: string): UserLocale => {
  const { tenantId } = useApolloContext();
  const result = useQuery<GetUserLocaleQuery>(GET_USER_LOCALE_QUERY, {
    skip: !userId || !tenantId,
    variables: {
      userId: userId as string,
    },
  });
  const lang = result.data?.getMyLanguageTag.languageTag ?? DEFAULT_LOCALE;
  const use24HourTime = result.data?.getUserSetting?.value
    ? JSON.parse(result.data?.getUserSetting.value)
    : false;
  const loading = lang !== i18n.language;

  useEffect(() => {
    if (loading) {
      void Promise.resolve()
        .then(() => lang !== DEFAULT_LOCALE && import(`moment/locale/${lang}`))
        .then(() => i18n.changeLanguage(lang))
        .then(() => moment.locale(lang));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this next time file is edited
  }, [lang]);

  return {
    loading,
    use24HourTime,
    lang,
  };
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Fastest way to determine if object is empty.
 *
 * @param  {Object} obj The object to check if it's empty.
 * @return {boolean} True if object is empty.
 */
exports.default = (function (obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
});

import React, { useCallback, useMemo } from 'react';
import { Button } from '@robinpowered/design-system';
import { GetUserScheduleDeskReservations as Reservation } from '../../../../contexts/UserSchedule/hooks/__generated__/useUserOfficeSchedule.generated';
import { useTranslation } from 'react-i18next';
import { isReservationToday } from '../../../../utilities';
import styled from '@emotion/styled';
import { teamsTheme } from '@fluentui/react-northstar';

type Props = {
  reservation?: Reservation;
};

export const HealthCheckpointButton = ({
  reservation,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('weekSchedule');
  // TODO: Add amplitude
  // const { trackEvent } = useAmplitude();

  const isResToday = useMemo(() => {
    return isReservationToday(reservation?.startTime);
  }, [reservation]);

  const handleClick = useCallback(() => {
    if (reservation) {
      //trackEvent(AmplitudeEvents.STARTED_HEALTH_CHECK);
      window.location.href = `${reservation.healthCheckpoint?.surveyUrl}${reservation.healthCheckpoint?.surveyId}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this next time file is edited
  }, [reservation]);

  if (!isResToday) {
    return null;
  }

  return (
    <CheckpointButton
      variant="primary"
      name="join"
      style={{ width: '100%' }}
      size={'small'}
      marginBottom="8px"
      onClick={handleClick}
    >
      {t('officeDay.healthCheck.button')}
    </CheckpointButton>
  );
};

const CheckpointButton = styled(Button)`
  width: 100%;
  margin: 0;
  background: ${teamsTheme.siteVariables.colorScheme.brand.background};
`;

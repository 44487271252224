import React from 'react';
import PropTypes from 'prop-types';
import {ClassNames} from '@emotion/core';
import centroid from '@turf/centroid';
import mapboxgl from 'maplibre-gl';
import Popup from '../Popup';
import {withMap} from '../Map';

// Get the top left bounds of a feature.
function getTopLeftOfFeature(feature) {
  const coordinates = feature.geometry.coordinates[0];
  const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);
  for (const coord of coordinates) {
    bounds.extend(coord);
  }
  return bounds.getNorthWest();
}

export function Tooltip({map, feature, children}) {
  const anchor = React.useMemo(() => {
    if (!feature) {
      return null;
    }

    const topLeft = getTopLeftOfFeature(feature);
    const center = centroid(feature);
    return [
      (topLeft.lng + center.geometry.coordinates[0]) / 2,
      (topLeft.lat + center.geometry.coordinates[1]) / 2
    ];
  }, [feature]);

  return (
    <ClassNames>
      {({css}) => (
        <Popup
          tipClassName={css`
            display: none;
          `}
          containerClassName={css`
            box-shadow: 0 0 0 transparent !important;
            background: none !important;
            display: flex !important;
            flex-direction: column !important;
            padding: 0px !important;
            pointer-events: none !important;
          `}
          lngLat={anchor}
          anchor="bottom-left"
          closeOnClick={true}
        >
          <Container>{children}</Container>
        </Popup>
      )}
    </ClassNames>
  );
}

function Container({children, delayMs = 250, delayOffset = 25}) {
  const [opacity, setOpacity] = React.useState(0);
  const [visibility, setVisibility] = React.useState('none');

  React.useEffect(() => {
    // Delay for the tooltip being interactive.
    const visibilityTimeout = setTimeout(
      () => setVisibility('block'),
      delayMs - delayOffset
    );

    // Delay for the tooltip fading in.
    const opacityTimeout = setTimeout(() => setOpacity(1), delayMs);

    return () => {
      clearTimeout(visibilityTimeout);
      clearTimeout(opacityTimeout);
    };
  });

  return (
    <ClassNames>
      {({css}) => (
        <div
          className={css`
            box-shadow: 0px 2px 3px rgb(0 0 0 / 15%), 0px 2px 12px rgb(0 0 0 / 6%); !important;
            background: #0e1924 !important;
            border-radius: 8px 8px 8px 2px !important;
            transition: opacity 150ms ease !important;
            padding: 10px 16px 12px !important;
            opacity: ${opacity} !important;
            display: ${visibility} !important;
          `}
        >
          {children}
        </div>
      )}
    </ClassNames>
  );
}

// Feature objects don't come back from events with the same reference (sad),
// so we can tell if they're the same feature by comparing the feature ID and source name
// which should be a unique combination by definition.
function compareFeatures(f1, f2) {
  const key1 = f1 ? `${f1.source}-${f1.id}` : null;
  const key2 = f2 ? `${f2.source}-${f2.id}` : null;
  return key1 === key2;
}

function arePropsEqual(prevProps, nextProps) {
  return compareFeatures(prevProps.feature, nextProps.feature);
}

Tooltip.propTypes = {
  map: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired
};

export default React.memo(withMap(Tooltip), arePropsEqual);

import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Body,
  Colors,
  Img,
  ButtonV4,
  Link,
  Input,
  UtilityButton,
  SpinnerLoader,
} from '@robinpowered/design-system';
import FilterSolid from '@robinpowered/icons/FilterSolid';
import { useTranslation } from 'react-i18next';
import { SpaceFeaturesByline } from './SpaceFeaturesByline';
import { GetMyEventsInTheRangeGetMyEventsInTheRange as Event } from './graphql/__generated__/useMyEvents.generated';
import { MeetingSummarySection } from './MeetingSummarySection';
import { LocationPicker } from './LocationPicker';
import SparkleSolid from '@robinpowered/icons/SparkleSolid';
import SearchOutline from '@robinpowered/icons/SearchOutline';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { MapSpaceStateForLevelSpaces } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';
import { useLocationsContext } from '../../contexts';
import { robinLogger } from '../../utilities/RobinLogger';
import { teamsTheme } from '@fluentui/react-northstar';

type Space = MapSpaceStateForLevelSpaces;

type ListViewProps = {
  event: Event;
  spaces?: Space[];
  selectedLevelId?: string;
};

export const SpaceListView: FC<ListViewProps> = ({
  event,
  spaces,
  selectedLevelId,
}) => {
  const { t } = useTranslation('spaceBooking');
  const { locations } = useLocationsContext();
  const {
    loadingSpaceStateData,
    spaceIdCanBeBooked,
    setSelectedLevel,
    suggestedSpaceId,
    openFilters,
  } = useSpaceBookingContext();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const selectedLocationId = useMemo(() => {
    return locations.find((location) =>
      location.levels.find((level) => level.id === selectedLevelId)
    )?.id;
  }, [selectedLevelId, locations]);

  // Separate out the suggested space from the rest of the list and filter out unavailable spaces.
  const filteredSpaces = useMemo(() => {
    return spaces?.reduce(
      (groups: { suggested: Space | null; spaces: Space[] }, space) => {
        if (
          !spaceIdCanBeBooked(space.id) ||
          !space.name
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase() ?? '')
        ) {
          return groups;
        }
        if (space.id === suggestedSpaceId) {
          groups.suggested = space;
        } else {
          groups.spaces.push(space);
        }
        return groups;
      },
      { suggested: null, spaces: [] }
    );
  }, [spaces, suggestedSpaceId, spaceIdCanBeBooked, searchTerm]);

  return (
    <Container>
      <SpaceListViewContainer>
        <LocationPicker
          locations={locations}
          selected={{
            locationId: selectedLocationId,
            levelId: selectedLevelId,
          }}
          onSelect={setSelectedLevel}
        />
        <Flex display="flex" flexDirection="row" height="32px" mt="10px">
          {/*
          // eslint-disable-next-line
          // @ts-ignore: Bad type definition in design system */}
          <Input
            value={searchTerm}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchTerm(e.target.value)
            }
            icon={<SearchOutline size={16} />}
            placeholder={t('space_list.search_placeholder') || ''}
            containerStyle={{
              height: '32px',
              borderRadius: '4px',
              border: `1px solid ${Colors.Gray30}`,
              marginBottom: 0,
              marginRight: '4px',
            }}
          />
          <UtilityButton
            style={{
              border: `1px solid ${Colors.Gray30}`,
              borderRadius: '4px',
            }}
            icon={FilterSolid}
            aria-label={t('aria.open_filters')}
            onClick={openFilters}
          />
        </Flex>

        {loadingSpaceStateData ? (
          <Flex p="16px" display="flex" justifyContent="center">
            <SpinnerLoader />
          </Flex>
        ) : !spaces?.length ? (
          <Flex mt="16px" p="16px 0">
            <Body.Small>{t('space_list.no_available_spaces')}</Body.Small>
          </Flex>
        ) : !filteredSpaces?.suggested && !filteredSpaces?.spaces.length ? (
          <Flex mt="16px" p="16px 0">
            <Body.Small>{t('space_list.no_available_for_criteria')}</Body.Small>
          </Flex>
        ) : (
          <>
            {filteredSpaces?.suggested && event && (
              <SpaceListItem
                space={filteredSpaces?.suggested}
                event={event}
                isSuggested={true}
              />
            )}
            {filteredSpaces?.spaces.map((space) => (
              <SpaceListItem space={space} event={event} isSuggested={false} />
            ))}
          </>
        )}
      </SpaceListViewContainer>
      <MeetingSummarySection event={event} />
    </Container>
  );
};

type ListItemProps = {
  space: Space;
  event: Event;
  isSuggested: boolean;
};

const SpaceListItem: FC<ListItemProps> = ({ space, isSuggested }) => {
  const logger = robinLogger();
  const { t } = useTranslation('spaceBooking');
  const { addSpace, addSpaceResult, event, openSpaceDetails } =
    useSpaceBookingContext();

  const handleOpenSpaceDetails = useCallback(
    (e) => {
      e.preventDefault();
      openSpaceDetails(space.id);
    },
    [space, openSpaceDetails]
  );

  // addSpaceResult.loading is not sufficient alone, otherwise every button would get a spinner
  // TODO: should prob lift a "currentlyLoadingSpaceId" up to context so loading state shared between details/list
  const [loading, setLoading] = useState(false);

  const onBookButtonClick = useCallback(() => {
    setLoading(true);
    logger.reportEvent('clicked-book-space', {
      space: space.id,
      event: event?.id,
      buttonLocation: 'add space modal list view',
      // TODO: when suggestion is highlighted in UI, we should pass true here if space was suggested
      // https://robinpowered.atlassian.net/browse/PHO-216
      spaceWasSuggested: isSuggested,
    });
    addSpace(space.id, isSuggested);
  }, [addSpace, event?.id, space.id, isSuggested, logger]);

  useEffect(() => {
    if (!addSpaceResult.loading) {
      setLoading(false);
    }
  }, [addSpaceResult.loading]);

  return (
    <>
      <ListItem>
        {isSuggested && (
          <Flex display="flex" alignItems="center" mb="8px">
            <SparkleSolid
              color={teamsTheme.siteVariables.colorScheme.brand.background}
              size={16}
              style={{ marginRight: '4px' }}
            />
            <Body.XSmall
              style={{
                color: teamsTheme.siteVariables.colorScheme.brand.background,
              }}
            >
              {t('space_details.good_fit_for_meeting')}
            </Body.XSmall>
          </Flex>
        )}
        <Flex display="flex" flexDirection="row" justifyContent="space-between">
          <Flex style={{ maxWidth: '100%' }}>
            <Body.Regular style={{ wordBreak: 'break-all' }}>
              {space.name}
            </Body.Regular>
            <SpaceFeaturesByline
              capacity={space.capacity}
              amenities={space.amenities}
            />
            <Flex
              display="flex"
              flexDirection="row"
              mt="10px"
              alignItems="center"
            >
              {
                <BookButton
                  variant="secondary"
                  size="small"
                  label={t('actions.book')}
                  disabled={addSpaceResult.loading}
                  onClick={onBookButtonClick}
                  isLoading={loading}
                />
              }
              <Link href="" onClick={handleOpenSpaceDetails}>
                {t('actions.view_details')}
              </Link>
            </Flex>
          </Flex>
          <Flex marginLeft={'10px'}>
            <Img src={space.image} height={'48px'} width={'64px'} />
          </Flex>
        </Flex>
      </ListItem>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-bottom-left-radius: 8px;
`;

const ListItem = styled.div`
  padding: 16px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${Colors.Gray10};
  }
`;

const BookButton = styled(ButtonV4)`
  margin-right: 12px;
`;

const SpaceListViewContainer = styled(Flex)`
  background-color: ${Colors.White0};
  padding: 16px;
  height: 100%;
  overflow-y: auto;
`;

import styled from '@emotion/styled';
import { Colors } from '@robinpowered/design-system';

export const Tooltip = styled.div<{ width?: string }>`
  background: ${Colors.Gray110};
  border-radius: 8px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1), 0px 2px 12px rgba(0, 0, 0, 0.06);
  padding: 8px;
  position: absolute;
  width: ${({ width }) => (width ? width : '198px')};
  text-align: center;
  z-index: 40;
  display: none;
`;

import { DeskDetails } from '../../../types';
import { deskUnbookableReasons } from '../../../utilities/DeskBooking';

export type DeskType = 'Anonymous' | 'Assigned' | 'Flexible';

export const deskDetailsSelector = () => {
  const isAvailable = (d?: DeskDetails) =>
    d?.availability.status === 'AVAILABLE';
  const deskVisibilityPolicyEnabled = (d?: DeskDetails): boolean => {
    if (!d || d.seatReservationVisibilityEnabled == undefined) {
      return true;
    } else {
      return d.seatReservationVisibilityEnabled;
    }
  };
  const reservation = (d?: DeskDetails) => d?.reservations[0];
  const isAnonymous = (d?: DeskDetails) =>
    deskVisibilityPolicyEnabled(d) && reservation(d)?.visibility === 'JUST_ME';
  return {
    reservation,
    isAvailable,
    deskVisibilityPolicyEnabled,
    amenityList: (d?: DeskDetails) =>
      d?.amenities?.map(({ name }) => name)?.join(', '),
    isUnbookable: (d?: DeskDetails) =>
      !!d?.availability.unbookableReasons.length || !isAvailable(d),
    deskType: (d?: DeskDetails) =>
      isAnonymous(d)
        ? 'Anonymous'
        : d?.type === 'ASSIGNED'
        ? 'Assigned'
        : 'Flexible',
    unbookableReasonsNoAccess: (d?: DeskDetails) => {
      if (d && d.availability.unbookableReasons) {
        const reason = d.availability.unbookableReasons[0]?.reason;
        return deskUnbookableReasons.no_access.includes(reason);
      }
    },
  };
};

import { Flex, Text } from '@fluentui/react-northstar';
import { Moment } from 'moment';
import { Zone } from '../../../contexts';
import { DeskList } from './DeskList';
import { ZoneListItem } from './ZoneListItem';

type ZoneListProps = {
  zones: Zone[];
  start: Moment;
  end: Moment;
};

export const ZoneList = ({ zones, start, end }: ZoneListProps) => {
  return (
    <Flex column style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
      {zones.length === 0 && (
        <Flex vAlign="center" hAlign="center" style={{ height: '100%' }}>
          <Text weight="bold" size="large">
            No pods on this floor
          </Text>
        </Flex>
      )}

      {zones.map((zone) => (
        <ZoneListItem key={zone.id} zone={zone} start={start} end={end}>
          <DeskList zoneID={zone.id} startTime={start} endTime={end} />
        </ZoneListItem>
      ))}
    </Flex>
  );
};

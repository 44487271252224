import React from 'react';
import styled from '@emotion/styled';
import { Position } from '@turf/helpers';
import { Popup } from '@robinpowered/atlas-web';
import { Colors } from '@robinpowered/design-system';
import { WorkArea } from '../../../contexts/SpaceBooking/hooks/useWorkAreas';
import './WorkAreaPopup.css';

type WorkAreaPopupProps = {
  workArea: WorkArea;
  centroid?: Position;
};

export const WorkAreaPopup = ({
  workArea,
  centroid,
}: WorkAreaPopupProps): JSX.Element | null => {
  if (!centroid) {
    return null;
  }

  return (
    <Popup key={workArea.id} lngLat={centroid} className="work-area-popup">
      <WorkAreaLabelContainer data-label="workarea">
        {workArea.name}
      </WorkAreaLabelContainer>
    </Popup>
  );
};

const labelStyles = `
  font-size: 16px;
  padding: 6px 12px;
  background-color: ${Colors.White0};
  color: ${Colors.Maroon100};
  border-radius: 30px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15), 0px 2px 12px rgba(0, 0, 0, 0.06);
  text-align: center;
`;

export const WorkAreaLabelContainer = styled.div`
  ${labelStyles}
  user-select: none;
  cursor: pointer;
  user-select: none;
  word-wrap: break-word;
`;

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ArrivalDisplayRegistrationKey: any;
  BigInt: any;
  Date: any;
  DocumentBody: any;
  IANATimezone: any;
  IPAddress: any;
  IPAddressCIDR: any;
  ISO8601Duration: any;
  ISODateTime: any;
  Json: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Markdown: any;
  TODO: any;
  UtcTime: any;
  Void: any;
  WorkflowEmail: any;
};

export type AbstractTicketQuestionExpectingChoices = {
  chooseOnlyOneOption: Scalars['Boolean'];
  id: Scalars['ID'];
  options: Array<AbstractTicketQuestionOption>;
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type AbstractTicketQuestionOption = {
  description: Maybe<Scalars['Markdown']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AccessPassResolvedPlaces = Campus | Level | Location | Organization | Space;

export type AccessPlace = {
  __typename: 'AccessPlace';
  id: Scalars['ID'];
  type: AccessPlaceType;
};

export enum AccessPlaceType {
  CAMPUS = 'CAMPUS',
  LEVEL = 'LEVEL',
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION',
  SPACE = 'SPACE'
}

export type AccountReservee = {
  __typename: 'AccountReservee';
  email: Scalars['String'];
  participationStatus: Maybe<ParticipationStatus>;
  user: Maybe<AccountUser>;
  visitorId: Maybe<Scalars['ID']>;
};

export type AccountUser = {
  __typename: 'AccountUser';
  avatar: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  defaultLocation: Maybe<Location>;
  department: Maybe<Scalars['String']>;
  familyName: Maybe<Scalars['String']>;
  givenName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMyFavorite: Maybe<Scalars['Boolean']>;
  managerId: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  primaryEmail: Maybe<Email>;
  slug: Maybe<Scalars['String']>;
  timeZone: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  workingHours: Maybe<Array<Maybe<UserWorkingHour>>>;
};

export type ActivitiesByLocation = {
  __typename: 'ActivitiesByLocation';
  activities: Array<ActivityForCurrentUser>;
  locationId: Maybe<Scalars['String']>;
};

export type ActivitiesForDateByLocationIdsInput = {
  date: Scalars['LocalDate'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
  locationIds: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type ActivitiesForTimeRangeByLocationIdsInput = {
  end: Scalars['ISODateTime'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
  locationIds: Array<Scalars['String']>;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
};

export type ActivitiesForTimeRangeByLocationIdsPagingInput = {
  activityLimitPerDay?: InputMaybe<Scalars['Int']>;
};

export type ActivityCoverImagesObject = {
  __typename: 'ActivityCoverImagesObject';
  birthday: Scalars['String'];
  celebration: Scalars['String'];
  drinks: Scalars['String'];
  funAndGames: Scalars['String'];
  funAndMusic: Scalars['String'];
  gathering: Scalars['String'];
  holiday: Scalars['String'];
  lunch: Scalars['String'];
};

export type ActivityForCurrentUser = {
  __typename: 'ActivityForCurrentUser';
  activityLocation: Maybe<Scalars['String']>;
  audienceLocationId: Scalars['ID'];
  canceledAt: Maybe<Scalars['String']>;
  coverImageUrl: Maybe<Scalars['String']>;
  creator: User;
  description: Maybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  id: Scalars['ID'];
  joined: ActivityJoinersList;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  userRelationship: ActivityUserRelationship;
  virtualLocation: Maybe<Scalars['String']>;
};

export type ActivityJoinersList = {
  __typename: 'ActivityJoinersList';
  joiners: Array<User>;
  totalCount: Scalars['Int'];
};

export type ActivityUserRelationship = {
  __typename: 'ActivityUserRelationship';
  canManage: Scalars['Boolean'];
  isAttending: Scalars['Boolean'];
};

export type AddLevelToLocationResponse = {
  __typename: 'AddLevelToLocationResponse';
  id: Scalars['Int'];
};

export type AddLevelsToLocationResponse = {
  __typename: 'AddLevelsToLocationResponse';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type AddLocationToOrganizationResponse = {
  __typename: 'AddLocationToOrganizationResponse';
  id: Scalars['Int'];
};

export type AddMeetingServiceErrorResponse = {
  __typename: 'AddMeetingServiceErrorResponse';
  reason: Maybe<Scalars['TODO']>;
};

export type AddMeetingServiceInput = {
  /** The existence of approvers means approval is required before notifying assignees */
  approvers?: InputMaybe<UserOrGroupInput>;
  /** At least one user or group is required */
  assignees: UsersAndGroupsInput;
  /** Must have permission to manage services in these places */
  availableInPlaces: Array<MeetingServiceAvailabilityInput>;
  categoryId: Scalars['ID'];
  description?: InputMaybe<Scalars['Markdown']>;
  name: Scalars['String'];
  /** List of questions to ask when this service is requested, in order */
  questions: Array<TicketQuestionInput>;
};

export type AddMeetingServiceResponse = AddMeetingServiceErrorResponse | AddMeetingServiceSuccessResponse;

export type AddMeetingServiceSuccessResponse = {
  __typename: 'AddMeetingServiceSuccessResponse';
  meetingServiceId: Scalars['ID'];
};

export enum AddMeetingServicesCategoryErrorReason {
  NAME_IS_NOT_UNIQUE = 'NAME_IS_NOT_UNIQUE'
}

export type AddMeetingServicesCategoryErrorResponse = {
  __typename: 'AddMeetingServicesCategoryErrorResponse';
  reason: AddMeetingServicesCategoryErrorReason;
};

export type AddMeetingServicesCategoryInput = {
  /** 'CAT', 'TEC', etc. */
  key: Scalars['String'];
  /** Name must be unique in org among meeting services categories */
  name: Scalars['String'];
};

export type AddMeetingServicesCategoryResponse = AddMeetingServicesCategoryErrorResponse | AddMeetingServicesCategorySuccessResponse;

export type AddMeetingServicesCategorySuccessResponse = {
  __typename: 'AddMeetingServicesCategorySuccessResponse';
  meetingServicesCategoryId: Scalars['ID'];
};

export type AddOrganizationResponse = {
  __typename: 'AddOrganizationResponse';
  id: Scalars['Int'];
};

export type AddSpaceToEventRequest = {
  endTime: Scalars['Date'];
  eventId: Scalars['String'];
  spaceId: Scalars['String'];
  startTime: Scalars['Date'];
};

export type AllSchedules = {
  __typename: 'AllSchedules';
  schedules: Maybe<Array<Maybe<WeeklyScheduleResult>>>;
};

export type AllocateStickersToOrgByDisplayCodeRequest = {
  displayCodes: Array<Scalars['Int']>;
  orgId: Scalars['ID'];
};

export type AllocateStickersToOrgRequest = {
  orgId: Scalars['ID'];
  stickerIds: Array<Scalars['ID']>;
};

export type Amenity = {
  __typename: 'Amenity';
  /** If the account ID is null, this means it's a global amenity and available to all orgs */
  accountId: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  ownerTypes: Array<OwnerType>;
  updatedAt: Scalars['Date'];
};

export type AnalyticsAuthorizationResponse = {
  __typename: 'AnalyticsAuthorizationResponse';
  token: Scalars['String'];
};

/** An Announcement is an office message created for given location(s) in an organization. It can be acknowledged by each user in a given org/location, and updated/archived by an admin(?). */
export type Announcement = {
  __typename: 'Announcement';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt: Maybe<Scalars['Date']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String'];
  /** Datetime of creation */
  createdAt: Scalars['Date'];
  /** Datetime of end of announcement */
  endAt: Maybe<Scalars['Date']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
};

/** Acknowledgement of an announcement for a user at a location. */
export type AnnouncementAcknowledgement = {
  __typename: 'AnnouncementAcknowledgement';
  announcementId: Scalars['ID'];
  date: Scalars['ISODateTime'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AnnouncementCreateInput = {
  content: Scalars['String'];
  distributions?: InputMaybe<Array<DistributionInput>>;
  endAt?: InputMaybe<Scalars['ISODateTime']>;
  locations: Array<Scalars['ID']>;
  name: Scalars['String'];
  priorityStatus?: InputMaybe<AnnouncementPriorityStatus>;
  shouldSendPushNotification?: InputMaybe<Scalars['Boolean']>;
  startAt?: InputMaybe<Scalars['ISODateTime']>;
};

/** Each announcement distribution will represent a service the announcement is cross posted to along with its recipients. */
export type AnnouncementDistribution = {
  __typename: 'AnnouncementDistribution';
  distributionService: DistributionService;
  id: Scalars['ID'];
  recipients: Array<DistributionRecipient>;
  sentAt: Maybe<Scalars['Date']>;
};

/** Each announcement distribution for location will represent a service the announcement is cross posted to along with its recipients and the announcement with its location. */
export type AnnouncementDistributionForLocation = {
  __typename: 'AnnouncementDistributionForLocation';
  announcement: Maybe<AnnouncementForLocation>;
  distributionService: DistributionService;
  id: Scalars['ID'];
  recipients: Array<DistributionRecipient>;
  sentAt: Maybe<Scalars['Date']>;
};

/** An Announcement within a context of a specific location. */
export type AnnouncementForLocation = {
  __typename: 'AnnouncementForLocation';
  /** Datetime of when annoucnement was instantly archived */
  archivedAt: Maybe<Scalars['Date']>;
  /** User that created the announcement */
  author: User;
  /** Current content of the announcement */
  content: Scalars['String'];
  /** Datetime of creation */
  createdAt: Scalars['Date'];
  /** Datetime of end of announcement */
  endAt: Maybe<Scalars['Date']>;
  /** Unique identifier */
  id: Scalars['ID'];
  /** List of locations for which the announcement has been published */
  locations: Array<Maybe<Location>>;
  /** Display name */
  name: Scalars['String'];
  /** Standard or priority announcement */
  priorityStatus: AnnouncementPriorityStatus;
  /** Datetime of start of announcement */
  startAt: Scalars['Date'];
  /** Current status of the announcement */
  status: AnnouncementStatus;
  /** When the requesting user has first viewed the announcement at this location */
  viewedAt: Maybe<Scalars['String']>;
};

export enum AnnouncementPriorityStatus {
  PRIORITY = 'PRIORITY',
  STANDARD = 'STANDARD'
}

export enum AnnouncementStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  SCHEDULED = 'SCHEDULED'
}

export type AnnouncementUpdateInput = {
  content: Scalars['String'];
  endAt?: InputMaybe<Scalars['ISODateTime']>;
  locations: Array<Scalars['ID']>;
  name: Scalars['String'];
  startAt?: InputMaybe<Scalars['ISODateTime']>;
};

/** View of an announcement for a user at a location. */
export type AnnouncementView = {
  __typename: 'AnnouncementView';
  announcementId: Scalars['ID'];
  date: Scalars['ISODateTime'];
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AnnouncementViewAcknowledgeCount = {
  __typename: 'AnnouncementViewAcknowledgeCount';
  acknowledgeCount: Scalars['Int'];
  viewCount: Scalars['Int'];
};

export type AnnouncementViewAcknowledgeCountWithId = {
  __typename: 'AnnouncementViewAcknowledgeCountWithId';
  acknowledgeCount: Scalars['Int'];
  id: Scalars['ID'];
  viewCount: Scalars['Int'];
};

export type AnsweredTicketQuestion = {
  question: TicketQuestion;
};

export type AnsweredTicketQuestionExpectingChoices = AnsweredTicketQuestion & {
  __typename: 'AnsweredTicketQuestionExpectingChoices';
  choices: Array<TicketQuestionOption>;
  question: TicketQuestionExpectingChoices;
};

export type AnsweredTicketQuestionExpectingMenuChoices = AnsweredTicketQuestion & {
  __typename: 'AnsweredTicketQuestionExpectingMenuChoices';
  choices: Array<TicketAnswerMenuChoice>;
  question: TicketQuestionExpectingMenuChoices;
};

export type AnsweredTicketQuestionExpectingText = AnsweredTicketQuestion & {
  __typename: 'AnsweredTicketQuestionExpectingText';
  answer: Scalars['String'];
  question: TicketQuestionExpectingText;
};

export type ApiOrganizationExternalAccount = {
  __typename: 'ApiOrganizationExternalAccount';
  accessToken: Maybe<Scalars['String']>;
  accountId: Scalars['ID'];
  domain: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type ArrivalDisplay = {
  __typename: 'ArrivalDisplay';
  arrivalDisplayDeviceStatusReport: Maybe<DeviceStatusReport>;
  badgePrinter: Maybe<BadgePrinter>;
  /** the device status of the associated badge printer */
  badgePrinterDeviceStatusReport: Maybe<DeviceStatusReport>;
  /** @deprecated prefer traversing tree to badge printer to get IP address */
  badgePrinterIPAddress: Scalars['String'];
  /** The date this arrival display was created */
  createdAt: Scalars['ISODateTime'];
  id: Scalars['ID'];
  isGuestSelfRegistrationEnabled: Scalars['Boolean'];
  /** location where display resides */
  location: Location;
  /** Name of this arrival display */
  name: Scalars['String'];
  /** True if the registration key has been consumed */
  registrationConsumed: Scalars['Boolean'];
  /** ISO datetime representing when the registration key was consumed */
  registrationConsumedAt: Maybe<Scalars['ISODateTime']>;
  /** ISO datetime encoded expiration of the active device registration key */
  registrationExpiresAt: Maybe<Scalars['ISODateTime']>;
  /** One-time registration key used to connect arrival display for first authentication, nullable */
  registrationKey: Maybe<Scalars['ArrivalDisplayRegistrationKey']>;
};

export type ArrivalDisplayRegistrationResponse = {
  __typename: 'ArrivalDisplayRegistrationResponse';
  authorization: DeviceAuthorizationResponse;
  certKey: Scalars['String'];
  device: ArrivalDisplay;
  id: Scalars['ID'];
};

export type ArrivalDisplayRescueResponse = {
  __typename: 'ArrivalDisplayRescueResponse';
  authorization: Maybe<DeviceAuthorizationResponse>;
  id: Scalars['ID'];
  isPaired: Scalars['Boolean'];
};

export type AssignStickerRequest = {
  assignments: Array<StickerAssignmentInput>;
};

export type AssignStickerResponse = {
  __typename: 'AssignStickerResponse';
  assignments: Array<StickerAssignment>;
};

export type AssignStickerToDeskByShortCodeRequest = {
  deskId: Scalars['Int'];
  stickerShortCode: Scalars['String'];
};

export type AssignStickerToDeskByShortCodeResponse = {
  __typename: 'AssignStickerToDeskByShortCodeResponse';
  deskId: Scalars['Int'];
  stickerShortCode: Scalars['String'];
};

export type AssignedUser = {
  __typename: 'AssignedUser';
  email: Maybe<Scalars['String']>;
  familyName: Maybe<Scalars['String']>;
  givenName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['ID']>;
};

export enum AuthContextAccountUserRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  OWNER = 'OWNER'
}

export enum AuthContextAppScope {
  BASIC_READ = 'BASIC_READ',
  BASIC_WRITE = 'BASIC_WRITE',
  PRIVILEGED = 'PRIVILEGED'
}

export enum AuthContextOrganizationScope {
  BASIC_READ = 'BASIC_READ',
  BASIC_WRITE = 'BASIC_WRITE'
}

export type AuthMethod = {
  __typename: 'AuthMethod';
  type: Scalars['String'];
  uri: Maybe<Scalars['String']>;
};

export type AuthorizationResponse = {
  __typename: 'AuthorizationResponse';
  bearerToken: Scalars['String'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  refreshToken: Scalars['String'];
  userId: Scalars['ID'];
};

export type AutoAllocateStickersToOrgRequest = {
  orgId: Scalars['ID'];
  quantity: Scalars['Int'];
  startingDisplayCode: Scalars['Int'];
};

export type AutoDeskBookingOrgInput = {
  enabled: Scalars['Boolean'];
  locationIds: Array<InputMaybe<Scalars['String']>>;
};

export type AutoDeskBookingOrgResponse = {
  __typename: 'AutoDeskBookingOrgResponse';
  enabled: Scalars['Boolean'];
  locationIds: Array<Scalars['String']>;
};

export enum Availability {
  /** Currently available */
  AVAILABLE = 'AVAILABLE',
  /** Resource has been booked */
  BOOKED = 'BOOKED',
  /** Resource marked as unavailable for booking */
  UNAVAILABLE = 'UNAVAILABLE',
  /** Resource marked as unknown due to desk inexistence */
  UNKNOWN = 'UNKNOWN'
}

export type BadgePrinter = {
  __typename: 'BadgePrinter';
  arrivalDisplays: Maybe<Array<Maybe<ArrivalDisplay>>>;
  /** The date this badge printer was created */
  createdAt: Scalars['ISODateTime'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  location: Location;
  /** Name of this badge printer */
  name: Scalars['String'];
};

export type BookedSchedulingAggregateOfDesks = {
  __typename: 'BookedSchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are booked */
  deskAssignedBookedTotal: Scalars['Int'];
  /** Total number of booked desks */
  deskBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are booked */
  deskHotBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are booked */
  deskHotelBookedTotal: Scalars['Int'];
  /** Represents the end time window for assessed desk booking status */
  endTime: Scalars['Date'];
  /** Represents the start time window for assessed desk booking status */
  startTime: Scalars['Date'];
};

export enum BookingPolicyType {
  /** Indicate the desk may be indefinitely assigned to a particular user. */
  ASSIGNED = 'ASSIGNED',
  /** Indicate that the desk is available to be booked for short term use. */
  HOT = 'HOT',
  /** Indicate that the desk may be reserved for a defined period duration. */
  HOTELED = 'HOTELED',
  /** Indicate that the desk may be reverse hoteled on an excluded assigned reservation. */
  REVERSE_HOTEL = 'REVERSE_HOTEL',
  /** Indicate that more people can be assigned to a single desk. Only used in combination with either ASSIGNED or HOTELED types */
  SHARED = 'SHARED'
}

export type Building = {
  __typename: 'Building';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type Calendar = {
  __typename: 'Calendar';
  calendarId: Scalars['String'];
  createdAt: Maybe<Scalars['Date']>;
  remoteType: Scalars['String'];
  spaceId: Scalars['ID'];
  spaceResourceEmail: Maybe<Scalars['String']>;
  spaceResourceId: Maybe<Scalars['String']>;
  subscriberExpiresAt: Maybe<Scalars['Date']>;
  subscriberId: Maybe<Scalars['String']>;
  syncToken: Maybe<Scalars['String']>;
};

export type Campus = {
  __typename: 'Campus';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  /** Total desks within the campus that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  id: Scalars['ID'];
  locations: Array<Location>;
  name: Scalars['String'];
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type ChatMessage = {
  __typename: 'ChatMessage';
  id: Scalars['ID'];
  message: Scalars['String'];
  sentAt: Scalars['ISODateTime'];
  sentByUser: UserWithDefault;
};

export type CheckInStatus = {
  __typename: 'CheckInStatus';
  checkInComplete: Scalars['Boolean'];
  checkInPossibleFuture: Scalars['Boolean'];
  checkInPossibleNow: Scalars['Boolean'];
};

export type CheckOrganizationNameAvailabilityResponse = {
  __typename: 'CheckOrganizationNameAvailabilityResponse';
  available: Scalars['Boolean'];
};

export type CompletedTicketForm = {
  __typename: 'CompletedTicketForm';
  id: Scalars['ID'];
  questions: Array<AnsweredTicketQuestion>;
};

export enum ConfirmationMethod {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE'
}

export type ConnectRipplingAccountForOrganizationAuthDataInput = {
  access_token: Scalars['String'];
  account_id: Scalars['String'];
  expires_in: Scalars['Int'];
  id_token: Scalars['String'];
  refresh_token: Scalars['String'];
  scope: Scalars['String'];
  token_type: Scalars['String'];
  uid: Scalars['String'];
};

export type ConnectRipplingAccountForOrganizationInput = {
  authData: ConnectRipplingAccountForOrganizationAuthDataInput;
  organizationId: Scalars['ID'];
};

export type ConnectRipplingAccountForOrganizationResponse = {
  __typename: 'ConnectRipplingAccountForOrganizationResponse';
  id: Scalars['ID'];
};

export type ConvertGroupToExternalRipplingGroupInput = {
  slug: Scalars['ID'];
};

export type ConvertGroupToExternalRipplingGroupResponse = {
  __typename: 'ConvertGroupToExternalRipplingGroupResponse';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  slug: Scalars['String'];
};

export type CountMeetingServicesInput = {
  filters?: InputMaybe<ListMeetingServicesFiltersInput>;
};

export type CountMeetingServicesResponse = {
  __typename: 'CountMeetingServicesResponse';
  count: Scalars['Int'];
};

export type CreateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']>;
  audienceLocationId: Scalars['ID'];
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  externalEventId?: InputMaybe<Scalars['String']>;
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  /** Should be a URL */
  virtualLocation?: InputMaybe<Scalars['String']>;
};

export type CreateActivityReturnType = {
  __typename: 'CreateActivityReturnType';
  id: Scalars['String'];
};

export type CreateAndMapBuildingsFromRipplingBuildingsInput = {
  address: Scalars['String'];
  name: Scalars['String'];
  ripplingBuildingId?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
};

export type CreateAndMapBuildingsFromRipplingInput = {
  buildings: Array<CreateAndMapBuildingsFromRipplingBuildingsInput>;
  organizationId: Scalars['ID'];
};

export type CreateAndMapBuildingsFromRipplingResponse = {
  __typename: 'CreateAndMapBuildingsFromRipplingResponse';
  id: Scalars['ID'];
};

export type CreateGenericHealthCheckpointResponse = {
  __typename: 'CreateGenericHealthCheckpointResponse';
  id: Scalars['String'];
};

export type CreateGroupAndMakeItExternalRipplingGroupInput = {
  description: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CreateGroupAndMakeItExternalRipplingGroupResponse = {
  __typename: 'CreateGroupAndMakeItExternalRipplingGroupResponse';
  id: Scalars['ID'];
  slug: Scalars['String'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataInput = {
  code: Scalars['String'];
};

export type CreateRobinOrganizationFromRipplingOAuthDataResponse = {
  __typename: 'CreateRobinOrganizationFromRipplingOAuthDataResponse';
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateWorkAreaInput = {
  deskIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CurrentUserAndOrgs = {
  __typename: 'CurrentUserAndOrgs';
  /** If true, user can see approval notifications for the organization specified in the tenant-id header. */
  canSeeApprovalNotificationsForCurrentTenant: Scalars['Boolean'];
  hasActivitiesCreateForCurrentTenant: Scalars['Boolean'];
  hasActivitiesManageForCurrentTenant: Scalars['Boolean'];
  hasAmenitiesManageForCurrentTenant: Scalars['Boolean'];
  hasAnalyticsPermissionForCurrentTenant: Scalars['Boolean'];
  hasAnnouncementsManageForCurrentTenant: Scalars['Boolean'];
  hasBillingPermissionForCurrentTenant: Scalars['Boolean'];
  hasCheckpointsViewPermissionForCurrentTenant: Scalars['Boolean'];
  hasDevicesManageForCurrentTenant: Scalars['Boolean'];
  hasDevicesViewForCurrentTenant: Scalars['Boolean'];
  hasGroupsManageForCurrentTenant: Scalars['Boolean'];
  hasListUserPermissionForCurrentTenant: Scalars['Boolean'];
  hasManageUserPermissionForCurrentTenant: Scalars['Boolean'];
  hasOfficeAccessManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasOfficeAccessViewPermissionForCurrentTenant: Scalars['Boolean'];
  hasPlacesManageForCurrentTenant: Scalars['Boolean'];
  hasRolesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasSeatsCoworkingReserveForCurrentTenant: Scalars['Boolean'];
  hasVisitsManageForCurrentTenant: Scalars['Boolean'];
  hasWorkPoliciesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasWorkplaceServicesManagePermissionForCurrentTenant: Scalars['Boolean'];
  hasWorkweekViewForCurrentTenant: Scalars['Boolean'];
  organizations: Array<Organization>;
  totalPendingApprovalNotificationsForOrganization: Scalars['Int'];
  user: User;
  userId: Scalars['ID'];
};

export type CustomWorkingHours = DateSpecificWorkingHours & {
  __typename: 'CustomWorkingHours';
  date: Scalars['Date'];
  id: Scalars['ID'];
  reason: Maybe<Scalars['String']>;
  timeFrames: Array<TimeFrame>;
};

export type CustomWorkingHoursBulkUpdateInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  id?: InputMaybe<Scalars['ID']>;
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

/** `date` is formated as YYYY-MM-DD while `start` and `end` are formated as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is exclusive in the time range If `start` and `end` is `null`, that denotes an all day closure */
export type CustomWorkingHoursInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  locationId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

/** `date` is formated as YYYY-MM-DD while `start` and `end` are formated as HH:MM from 00:00 -> 24:00. `start` is inclusive and `end` is exclusive in the time range If `start` and `end` is `null`, that denotes an all day closure */
export type CustomWorkingHoursUpdateInput = {
  date: Scalars['LocalDate'];
  end?: InputMaybe<Scalars['LocalEndTime']>;
  reason?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['LocalEndTime']>;
};

export type Customizations = {
  __typename: 'Customizations';
  failBody: Maybe<Scalars['String']>;
  failHeadline: Maybe<Scalars['String']>;
  failureEmails: Array<Scalars['String']>;
  passBody: Maybe<Scalars['String']>;
  passHeadline: Maybe<Scalars['String']>;
};

export enum DeviceType {
  UNKNOWN = 'UNKNOWN',
  VISITOR_ARRIVAL = 'VISITOR_ARRIVAL',
  VISITOR_BADGE_PRINTER = 'VISITOR_BADGE_PRINTER'
}

export type DailyActivitiesSummary = {
  __typename: 'DailyActivitiesSummary';
  activities: Array<ActivityForCurrentUser>;
  date: Scalars['Date'];
  totalActivities: Scalars['Int'];
};

export type DateFilterInput = {
  endTimeInclusive?: InputMaybe<Scalars['ISODateTime']>;
  startTimeExclusive?: InputMaybe<Scalars['ISODateTime']>;
};

export type DateFilterMandatoryInput = {
  endInclusivity?: InputMaybe<TimeInclusivityType>;
  endTime?: InputMaybe<Scalars['ISODateTime']>;
  startInclusivity?: InputMaybe<TimeInclusivityType>;
  startTime?: InputMaybe<Scalars['ISODateTime']>;
};

export type DateFilterOptionalInput = {
  endTime?: InputMaybe<Scalars['ISODateTime']>;
  startTime?: InputMaybe<Scalars['ISODateTime']>;
};

export type DateGroupedUserReservations = {
  __typename: 'DateGroupedUserReservations';
  reservationsByDate: Array<UserDeskReservationByDate>;
  users: Array<Reservee>;
};

export type DateRangeInput = {
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
  timeZone?: InputMaybe<Scalars['String']>;
};

export type DateSpecificWorkingHours = {
  date: Scalars['Date'];
  timeFrames: Array<TimeFrame>;
};

export type DateWithTimezone = {
  dateTime: Scalars['String'];
  timeZone: Scalars['String'];
};

export enum DayOfWeek {
  FRIDAY = 'FRIDAY',
  MONDAY = 'MONDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
  THURSDAY = 'THURSDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY'
}

export type DefaultLocationAndLevel = {
  __typename: 'DefaultLocationAndLevel';
  levelId: Maybe<Scalars['ID']>;
  locationId: Maybe<Scalars['ID']>;
  suggestedLevelId: Maybe<Scalars['ID']>;
  suggestedLocationId: Maybe<Scalars['ID']>;
};

export type DeleteExternalRipplingGroupInput = {
  slug: Scalars['String'];
};

export type DeleteExternalRipplingGroupResponse = {
  __typename: 'DeleteExternalRipplingGroupResponse';
  id: Scalars['ID'];
  slug: Scalars['String'];
};

export type Desk = {
  __typename: 'Desk';
  amenities: Array<DeskAmenity>;
  assignees: Array<AccountUser>;
  availableAt: Maybe<Availability>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  instantAvailabilityAt: InstantAvailability;
  isDisabled: Maybe<Scalars['Boolean']>;
  /** Indicate whether the desk has been explicitly marked as not available. */
  isReservable: Scalars['Boolean'];
  level: Maybe<Level>;
  location: Location;
  name: Scalars['String'];
  permissions: Array<Permission>;
  rawType: Array<DeskType>;
  reservationPolicies: Maybe<DeskReservationPolicies>;
  settings: Maybe<Array<Setting>>;
  space: Space;
  state: DeskState;
  stickers: Array<Sticker>;
  /** Specify the type of booking supported for this desk (Assigned, Hot, Hoteled). */
  type: BookingPolicyType;
  updatedAt: Scalars['Date'];
  windowedAvailability: Array<WindowAvailability>;
  zone: Maybe<Zone>;
};


export type DeskAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type DeskInstantAvailabilityAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type DeskStateArgs = {
  endTime: Scalars['Date'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  userId?: InputMaybe<Scalars['Int']>;
};


export type DeskWindowedAvailabilityArgs = {
  intervalInMinutes: Scalars['Int'];
  numberOfWindows: Scalars['Int'];
  startTime?: InputMaybe<Scalars['Date']>;
};

export type DeskAmenity = {
  __typename: 'DeskAmenity';
  amenityId: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type DeskAssignmentSchedule = {
  __typename: 'DeskAssignmentSchedule';
  floor: Floor;
  id: Scalars['ID'];
  label: Scalars['String'];
  recurrence: Scalars['String'];
};

export type DeskAvailability = {
  __typename: 'DeskAvailability';
  id: Scalars['ID'];
  nextAvailableMeta: NextAvailableMeta;
  status: Availability;
  unbookableReasons: Array<DeskunbookableReasons>;
};

export type DeskAvailabilityByLevelId = {
  __typename: 'DeskAvailabilityByLevelId';
  availableDeskIds: Array<Scalars['Int']>;
  busyDeskIds: Array<Scalars['Int']>;
  forbiddenDeskIds: Array<Scalars['Int']>;
};

export type DeskAvailabilityLocalizedDate = {
  __typename: 'DeskAvailabilityLocalizedDate';
  availability: Availability;
  dates: Array<Scalars['LocalDate']>;
  deskId: Scalars['ID'];
  timezone: Scalars['IANATimezone'];
  /** Specify the type of booking supported for this desk (Assigned, Hot, Hoteled). */
  type: BookingPolicyType;
};

export type DeskConfirmation = {
  __typename: 'DeskConfirmation';
  confirmationWindowEnd: Maybe<Scalars['Date']>;
  confirmationWindowStart: Maybe<Scalars['Date']>;
  confirmedAt: Maybe<Scalars['String']>;
  deviceId: Maybe<Scalars['ID']>;
  method: Maybe<ConfirmationMethod>;
  seatReservationId: Scalars['ID'];
  status: DeskConfirmationStatus;
  unconfirmableReasons: Array<UnconfirmableReason>;
  user: Maybe<User>;
};

export enum DeskConfirmationStatus {
  /** Desk reservation cannot be confirmed */
  CANNOT_CONFIRM = 'CANNOT_CONFIRM',
  /** Desk reservation has been confirmed */
  CONFIRMED = 'CONFIRMED',
  /** Desk reservation has been declined */
  DECLINED = 'DECLINED',
  /** Desk reservation has not been confirmed */
  UNCONFIRMED = 'UNCONFIRMED'
}

export enum DeskConfirmationType {
  COMPASS = 'Compass',
  DASHBOARD = 'Dashboard',
  EMAIL = 'Email',
  IP = 'IP',
  NFC = 'NFC',
  QR = 'QR',
  SLACK = 'Slack',
  TEAMS = 'Teams'
}

export enum DeskFilters {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED'
}

export type DeskReservationCheckinPolicies = {
  __typename: 'DeskReservationCheckinPolicies';
  assignedCheckInEnabled: Maybe<Scalars['Boolean']>;
  checkInWindow: Maybe<Scalars['String']>;
  /** there are other checkin policies available, but we only expose this one */
  enforceLocalCheckinOnly: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type DeskReservationPolicies = {
  __typename: 'DeskReservationPolicies';
  abandonedDeskProtectionEnabled: Maybe<Scalars['Boolean']>;
  abandonedDeskProtectionThreshold: Maybe<Scalars['String']>;
  allowExclusions: Maybe<Scalars['Boolean']>;
  allowOverlappingReservations: Maybe<Scalars['Boolean']>;
  /** If this is null, checkin policies are not enabled for the desk */
  checkinPolicies: Maybe<DeskReservationCheckinPolicies>;
  id: Scalars['ID'];
  seatReservationAdvancedBookingThreshold: Maybe<Scalars['String']>;
  seatReservationMaxLength: Maybe<Scalars['String']>;
  seatReservationTypesAllowed: Maybe<Scalars['String']>;
  seatReservationVisibilityEnabled: Maybe<Scalars['Boolean']>;
};

export enum DeskReservationVisibility {
  EVERYONE = 'EVERYONE',
  JUST_ME = 'JUST_ME'
}

export type DeskSchedule = {
  __typename: 'DeskSchedule';
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
  recurrence: Scalars['String'];
};

export type DeskState = {
  __typename: 'DeskState';
  availability: DeskAvailability;
  exclusions: Array<Exclusion>;
  id: Scalars['ID'];
  primaryReservation: Maybe<Reservation>;
  reservations: Array<Reservation>;
};

export enum DeskType {
  ASSIGNED = 'assigned',
  HOT = 'hot',
  HOTELED = 'hoteled',
  SHARED = 'shared'
}

export enum DeskUnbookableReasonType {
  LACKING_PERMISSIONS = 'LACKING_PERMISSIONS',
  MAX_CAPACITY_REACHED = 'MAX_CAPACITY_REACHED',
  NO_ACCESS = 'NO_ACCESS',
  SCHEDULE_VIOLATED = 'SCHEDULE_VIOLATED',
  SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD = 'SEAT_RESERVATION_ADVANCED_BOOKING_THRESHOLD',
  SEAT_RESERVATION_MAX_LENGTH = 'SEAT_RESERVATION_MAX_LENGTH',
  SEAT_RESERVATION_RECURRENCE_ALLOWED = 'SEAT_RESERVATION_RECURRENCE_ALLOWED',
  SEAT_RESERVATION_WORKING_HOURS_RESTRICTED = 'SEAT_RESERVATION_WORKING_HOURS_RESTRICTED',
  UNKNOWN = 'UNKNOWN',
  VIOLATE_HOT_DESK_POLICY = 'VIOLATE_HOT_DESK_POLICY'
}

export type DeskWithAssignees = {
  __typename: 'DeskWithAssignees';
  assignees: Array<AssignedUser>;
  isDisabled: Scalars['Boolean'];
  isReservable: Scalars['Boolean'];
  permissions: Array<Permission>;
  seatId: Scalars['ID'];
  seatType: Array<BookingPolicyType>;
};

export type DeskunbookableReasons = {
  __typename: 'DeskunbookableReasons';
  reason: DeskUnbookableReasonType;
  value: Maybe<Scalars['String']>;
};

export type DeviceAuthorizationResponse = {
  __typename: 'DeviceAuthorizationResponse';
  bearerToken: Scalars['String'];
  deviceName: Scalars['String'];
  deviceType: DeviceType;
  /** device ID */
  id: Scalars['ID'];
  location: Location;
  organizationId: Scalars['ID'];
  refreshToken: Scalars['String'];
};

export enum DeviceFeatureFlagType {
  /** Feature flag for enabling badge printing from an arrival display */
  ARRIVAL_DISPLAY_BADGE_PRINTING = 'ARRIVAL_DISPLAY_BADGE_PRINTING',
  /** Enables using custom visit types on devices like the Arrival Display or GuestUI */
  CUSTOM_VISIT_TYPES = 'CUSTOM_VISIT_TYPES',
  /** Language selection feature flag for project global */
  LANGUAGE_SELECTION_ENABLED = 'LANGUAGE_SELECTION_ENABLED',
  /** Language selection feature flag for a location global */
  LOCATION_LANGUAGE_SELECTION_ENABLED = 'LOCATION_LANGUAGE_SELECTION_ENABLED'
}

export enum DeviceStatus {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  UNKNOWN = 'UNKNOWN'
}

export enum DeviceStatusInput {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export type DeviceStatusReport = {
  __typename: 'DeviceStatusReport';
  lastCheckinMessage: Maybe<Scalars['String']>;
  lastCheckinResult: Maybe<DeviceStatusInput>;
  lastCheckinTime: Maybe<Scalars['String']>;
  status: DeviceStatus;
};

export type DistributionInput = {
  distributionService: DistributionService;
  id?: InputMaybe<Scalars['ID']>;
  recipients: Array<DistributionRecipientInput>;
};

/** display -> what is shown to user (ie slack channel name) value -> identifier data used to cross post (ie slack channel conversationId) */
export type DistributionRecipient = {
  __typename: 'DistributionRecipient';
  display: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type DistributionRecipientInput = {
  display?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  value: Scalars['String'];
};

export enum DistributionService {
  EMAIL = 'EMAIL',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS'
}

export type Email = {
  __typename: 'Email';
  email: Scalars['String'];
  isVerified: Scalars['Boolean'];
};

/** Emails of users who are deactivated or not existing in the tenant will be grouped in `invalid` */
export type EmailCheckResponse = {
  __typename: 'EmailCheckResponse';
  invalid: Array<Scalars['String']>;
  unverified: Array<Scalars['String']>;
  verified: Array<Scalars['String']>;
};

export type EmployeeVisit = {
  __typename: 'EmployeeVisit';
  confirmation: EmployeeVisitConfirmation;
  endTime: Scalars['ISODateTime'];
  id: Scalars['ID'];
  location: Maybe<Location>;
  locationId: Scalars['ID'];
  scheduleId: Maybe<Scalars['ID']>;
  startTime: Scalars['ISODateTime'];
  userId: Scalars['ID'];
};

export type EmployeeVisitConfirmation = {
  __typename: 'EmployeeVisitConfirmation';
  confirmed_at: Maybe<Scalars['ISODateTime']>;
  employeeVisitId: Scalars['ID'];
  status: EmployeeVisitConfirmationStatus;
};

export enum EmployeeVisitConfirmationStatus {
  CANCELED = 'CANCELED',
  CONFIRMED = 'CONFIRMED',
  UNKNOWN = 'UNKNOWN'
}

export type EmployeeVisitMutations = {
  __typename: 'EmployeeVisitMutations';
  excludeDaysFromAllSchedules: Maybe<Scalars['Void']>;
  setWeeklySchedule: Maybe<Scalars['ID']>;
};


export type EmployeeVisitMutationsExcludeDaysFromAllSchedulesArgs = {
  input?: InputMaybe<ExcludeAllDaysFromScheduleInput>;
};


export type EmployeeVisitMutationsSetWeeklyScheduleArgs = {
  input: SetWeeklyScheduleInput;
};

export type EmployeeVisitQueries = {
  __typename: 'EmployeeVisitQueries';
  /** Get all of an employee's visits during a specific time range within a specific org */
  getAllEmployeeVisitsForEmployee: Array<EmployeeVisit>;
  getAllWeeklySchedulesForUser: Maybe<AllSchedules>;
  getWeeklyScheduleForUser: Maybe<WeeklyScheduleResult>;
};


export type EmployeeVisitQueriesGetAllEmployeeVisitsForEmployeeArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
};


export type EmployeeVisitQueriesGetAllWeeklySchedulesForUserArgs = {
  userId: Scalars['String'];
};


export type EmployeeVisitQueriesGetWeeklyScheduleForUserArgs = {
  locationId: Scalars['String'];
  userId: Scalars['String'];
};

export type EmployeeVisitSchedule = {
  __typename: 'EmployeeVisitSchedule';
  id: Maybe<Scalars['String']>;
};

export type EmployeeVisitsByDate = {
  __typename: 'EmployeeVisitsByDate';
  date: Scalars['Date'];
  employeeVisits: Array<EmployeeVisit>;
};

export type EndOrCancelDelegatedReservationResponse = {
  __typename: 'EndOrCancelDelegatedReservationResponse';
  reservationId: Scalars['ID'];
};

export type EndOrCancelReservationResponse = {
  __typename: 'EndOrCancelReservationResponse';
  reservationId: Scalars['ID'];
};

export type Evaluations = {
  workingHours?: InputMaybe<Scalars['Boolean']>;
};

export type Event = {
  __typename: 'Event';
  creationType: EventCreationType;
  creatorEmail: Maybe<Scalars['String']>;
  creatorId: Maybe<Scalars['ID']>;
  description: Maybe<Scalars['String']>;
  endTime: Maybe<Scalars['String']>;
  eventWithCheckInStatus: Maybe<EventWithCheckInStatus>;
  hasMeetingServiceRequests: Maybe<Scalars['Boolean']>;
  /** Event Id */
  id: Scalars['ID'];
  invitees: Array<EventInvitee>;
  isAllDay: Scalars['Boolean'];
  meetingServiceRequests: Maybe<Array<MeetingServiceRequest>>;
  organizerEmail: Maybe<Scalars['String']>;
  organizerId: Maybe<Scalars['ID']>;
  recurrence: Maybe<Scalars['String']>;
  seriesId: Maybe<Scalars['String']>;
  spaces: Array<Space>;
  startTime: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  uid: Maybe<Scalars['String']>;
  visibility: Maybe<Scalars['String']>;
  whosInData: WhosInData;
};


export type EventWhosInDataArgs = {
  locationIds: Array<Scalars['ID']>;
};

export type EventConfirmation = {
  __typename: 'EventConfirmation';
  confirmedAt: Maybe<Scalars['String']>;
  deviceId: Maybe<Scalars['Int']>;
  eventId: Scalars['ID'];
  userId: Maybe<Scalars['ID']>;
};

export enum EventCreationType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  PROXIED = 'PROXIED',
  SYNCED = 'SYNCED',
  UNKNOWN = 'UNKNOWN'
}

export type EventInvitee = {
  __typename: 'EventInvitee';
  displayName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  eventId: Scalars['ID'];
  id: Maybe<Scalars['ID']>;
  isOrganizer: Scalars['Boolean'];
  isResource: Scalars['Boolean'];
  participationRole: EventParticipationRole;
  responseStatus: EventResponseStatus;
  spaceId: Maybe<Scalars['ID']>;
  user: Maybe<User>;
};

export enum EventParticipationRole {
  CHAIR = 'CHAIR',
  NON_PARTICIPANT = 'NON_PARTICIPANT',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED',
  UNKNOWN = 'UNKNOWN'
}

export enum EventResponseStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  DELEGATED = 'DELEGATED',
  NOT_RESPONDED = 'NOT_RESPONDED',
  TENTATIVE = 'TENTATIVE',
  UNKNOWN = 'UNKNOWN'
}

export enum EventUpdateFailReasons {
  LACKING_EVENT_UPDATE_PERMISSIONS = 'LACKING_EVENT_UPDATE_PERMISSIONS',
  SPACE_DECLINED = 'SPACE_DECLINED',
  SPACE_UNAVAILABLE = 'SPACE_UNAVAILABLE',
  UNKNOWN = 'UNKNOWN'
}

export type EventUpdatePermission = {
  __typename: 'EventUpdatePermission';
  eventId: Scalars['String'];
  id: Scalars['String'];
  isPermitted: Scalars['Boolean'];
};

export type EventWithCheckInStatus = {
  __typename: 'EventWithCheckInStatus';
  checkInEndTime: Scalars['String'];
  checkInStartTime: Scalars['String'];
  checkInStatus: CheckInStatus;
  inviteeEvent: Maybe<InviteeEvent>;
  spaceId: Scalars['String'];
};

export type ExcludeAllDaysFromScheduleInput = {
  fri?: InputMaybe<Scalars['Boolean']>;
  mon?: InputMaybe<Scalars['Boolean']>;
  sat?: InputMaybe<Scalars['Boolean']>;
  sun?: InputMaybe<Scalars['Boolean']>;
  thu?: InputMaybe<Scalars['Boolean']>;
  tue?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
  wed?: InputMaybe<Scalars['Boolean']>;
};

export type Exclusion = {
  __typename: 'Exclusion';
  endTime: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated temporally broken because API currently provides series master id instead of the instance id */
  reservation: Maybe<Reservation>;
  startTime: Scalars['String'];
};

export type ExternalEvent = {
  __typename: 'ExternalEvent';
  description: Scalars['String'];
  endDate: Scalars['ISODateTime'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  sourceUrl: Scalars['String'];
  startDate: Scalars['ISODateTime'];
  ticketUrl: Maybe<Scalars['String']>;
  venue: ExternalEventVenue;
};

export type ExternalEventVenue = {
  __typename: 'ExternalEventVenue';
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  region: Scalars['String'];
};

export type ExternalIntegration = {
  __typename: 'ExternalIntegration';
  archivedAt: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['Date']>;
  enabled: Maybe<Scalars['Boolean']>;
  externalId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  integrationJson: Maybe<Scalars['Json']>;
  name: Maybe<Scalars['String']>;
  tenantId: Scalars['ID'];
  updatedAt: Maybe<Scalars['Date']>;
};

export enum FeatureFlagType {
  /** Enables category images for activities */
  ACTIVITIES_CATEGORY_IMAGES_SUPPORT = 'ACTIVITIES_CATEGORY_IMAGES_SUPPORT',
  /** Enable Chat Functionality on Activities */
  ACTIVITY_CHAT = 'ACTIVITY_CHAT',
  /** Enables add a space modal on web */
  ADD_SPACE_MODAL_WEB = 'ADD_SPACE_MODAL_WEB',
  /** Enables the ui for booking multiples days in a week */
  BOOK_DESK_AHEAD = 'BOOK_DESK_AHEAD',
  /** Enables the bulk map uploads */
  BULK_MAP_UPLOADS = 'BULK_MAP_UPLOADS',
  /** Enables the schedule Information tab for campus app */
  CAMPUS_APP = 'CAMPUS_APP',
  /** New booking flow */
  CONSOLIDATED_BOOKING_FLOW = 'CONSOLIDATED_BOOKING_FLOW',
  /** Enables adding/removing custom visit types */
  CUSTOM_VISIT_TYPES = 'CUSTOM_VISIT_TYPES',
  /** Enables the create org functionality in the dashboard apps project */
  DASHBOARD_APPS_CREATE_ORGANIZATION = 'DASHBOARD_APPS_CREATE_ORGANIZATION',
  /** Allow user to view the new onboarding flow */
  DASHBOARD_APPS_ONBOARDING = 'DASHBOARD_APPS_ONBOARDING',
  /** Enables the new schedule tab in the dashboard apps project */
  DASHBOARD_APPS_SCHEDULE_TAB = 'DASHBOARD_APPS_SCHEDULE_TAB',
  /** Enables desk issue reporting */
  DESK_ISSUE_REPORTING = 'DESK_ISSUE_REPORTING',
  /** Document agreements (NDAs, liability waivers, etc.) */
  DOCUMENT_AGREEMENTS = 'DOCUMENT_AGREEMENTS',
  /** Enables desk reservation edits */
  EDIT_DESK_RESERVATIONS = 'EDIT_DESK_RESERVATIONS',
  /** Enables the employee visits feature set */
  EMPLOYEE_VISITS = 'EMPLOYEE_VISITS',
  /** Enables external event sync from OccasionGenius */
  EXTERNAL_OCCASION_GENIUS_EVENT_SYNC = 'EXTERNAL_OCCASION_GENIUS_EVENT_SYNC',
  /** Enables Global Hybrid Index page */
  HYBRID_INDEX = 'HYBRID_INDEX',
  /** Enables new analytics visualizations */
  INSIGHTS_UI_REDESIGN = 'INSIGHTS_UI_REDESIGN',
  /** Enables issue reporting V2 - report issues with spaces */
  ISSUE_REPORTING_V2 = 'ISSUE_REPORTING_V2',
  /** Language selection feature flag for project Global */
  LANGUAGE_SELECTION_ENABLED = 'LANGUAGE_SELECTION_ENABLED',
  /** Enables the mobile week view home tab */
  MOBILE_HOME_TAB_WEEK_VIEW = 'MOBILE_HOME_TAB_WEEK_VIEW',
  /** Enables the meetings/space functionality on the Schedule tab in MS Teams */
  MS_TEAMS_SPACE_BOOKING = 'MS_TEAMS_SPACE_BOOKING',
  /** Enables the use of office activities */
  OFFICE_ACTIVITIES = 'OFFICE_ACTIVITIES',
  /** Enables the use of office activities for mobile */
  OFFICE_ACTIVITIES_MOBILE = 'OFFICE_ACTIVITIES_MOBILE',
  /** Enables components intented to improve the office tab performance */
  OFFICE_TAB_PERFORMANCE = 'OFFICE_TAB_PERFORMANCE',
  /** Enables the onboarding map upload flow */
  ONBOARDING_MAP_UPLOAD = 'ONBOARDING_MAP_UPLOAD',
  /** Enables passive check in */
  PASSIVE_CHECK_IN = 'PASSIVE_CHECK_IN',
  /** Enables the new People tab within insights */
  PEOPLE_INSIGHTS = 'PEOPLE_INSIGHTS',
  /** Optimizes people search */
  PEOPLE_SEARCH_OPTIMIZATION = 'PEOPLE_SEARCH_OPTIMIZATION',
  /** Enables people tab in dashboard apps */
  PEOPLE_TAB_DASHBOARD_APPS = 'PEOPLE_TAB_DASHBOARD_APPS',
  /** Enables Office Utilization insights page */
  REAL_ESTATE_OPTIMIZATION_INSIGHTS = 'REAL_ESTATE_OPTIMIZATION_INSIGHTS',
  /** Allow user to view the new schedule desk booking flow */
  SCHEDULE_DESK_BOOKING_ENABLED = 'SCHEDULE_DESK_BOOKING_ENABLED',
  /** Sharptooth team feature flag for development */
  SHARPTOOTH_DEVELOPMENT = 'SHARPTOOTH_DEVELOPMENT',
  /** Enables suggested spaces on mobile */
  SPACE_SUGGESTIONS_MOBILE = 'SPACE_SUGGESTIONS_MOBILE',
  /** Enables space recommendations */
  SUGGESTED_SPACES = 'SUGGESTED_SPACES',
  /** Enables the ability to add badge printing functionality to arrival displays */
  VISITOR_BADGE_PRINTING = 'VISITOR_BADGE_PRINTING',
  /** Enables visit list table csv export */
  VISITOR_CSV_EXPORT = 'VISITOR_CSV_EXPORT',
  /** Enables the ability to select a range of dates for a visitor to export */
  VISITOR_EXPORT_DATE_RANGE = 'VISITOR_EXPORT_DATE_RANGE',
  /** Visitor managment location enablement */
  VISITOR_LOCATION_ENABLEMENT = 'VISITOR_LOCATION_ENABLEMENT',
  /** Enables who's in sidebar on week view page */
  WEEKVIEW_WHOSIN_SIDEBAR_ENABLED = 'WEEKVIEW_WHOSIN_SIDEBAR_ENABLED',
  /** Reveals features for managing work areas */
  WORK_AREAS = 'WORK_AREAS',
  /** Reveals feature for work areas on mobile */
  WORK_AREAS_MOBILE = 'WORK_AREAS_MOBILE',
  /** Reveals features for work area insights */
  WORK_AREA_INSIGHTS = 'WORK_AREA_INSIGHTS',
  /** Enables the work week view as the homepage */
  WORK_WEEK_HOMEPAGE = 'WORK_WEEK_HOMEPAGE',
  /** Toggles the updated work week UX/UI */
  WORK_WEEK_IMPROVEMENT_ENABLED = 'WORK_WEEK_IMPROVEMENT_ENABLED'
}

export enum FeatureType {
  ACTIVATED_USERS = 'ACTIVATED_USERS',
  ACTIVE_USERS = 'ACTIVE_USERS',
  BOOKABLE_SEATS = 'BOOKABLE_SEATS',
  BOOKABLE_SPACES = 'BOOKABLE_SPACES',
  EXPORTABLE_DATA_DAYS = 'EXPORTABLE_DATA_DAYS',
  LEVELS = 'LEVELS',
  LOCATIONS = 'LOCATIONS',
  RIPPLING_USERS = 'RIPPLING_USERS',
  USERS = 'USERS',
  VIEWABLE_DATA_DAYS = 'VIEWABLE_DATA_DAYS',
  VISITOR_ENABLED_LOCATIONS = 'VISITOR_ENABLED_LOCATIONS'
}

export type Floor = {
  __typename: 'Floor';
  building: Building;
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type ForwardPageInfo = {
  __typename: 'ForwardPageInfo';
  /** Pass this into `after` to get results after this page. Null if no results. */
  endCursor: Maybe<Scalars['String']>;
  /** True if there are more results after endCursor */
  hasNextPage: Scalars['Boolean'];
};

export type GenerateStickerBatchRequest = {
  count?: InputMaybe<Scalars['Int']>;
};

export type GenerateStickerBatchResponse = {
  __typename: 'GenerateStickerBatchResponse';
  batch: StickerBatch;
};

export enum GenericHealthCheckpointEntityType {
  GUEST_INVITE = 'GUEST_INVITE'
}

export type GenericHealthCheckpointResponse = {
  __typename: 'GenericHealthCheckpointResponse';
  id: Scalars['ID'];
  status: GenericHealthCheckpointStatus;
};

export enum GenericHealthCheckpointStatus {
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  INCOMPLETE = 'INCOMPLETE',
  PASSED = 'PASSED',
  UNKNOWN = 'UNKNOWN'
}

export type GetDeskAvailabilityByLevelIdFiltersInput = {
  deskAmenityIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetDeskAvailabilityByLevelIdScopeInput = {
  levelId: Scalars['ID'];
};

export type GetDeskAvailabilityByLevelIdViewOptionsInput = {
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
};

export type GetEventsWithCheckInStatusForProxiedEventIdReponse = {
  __typename: 'GetEventsWithCheckInStatusForProxiedEventIdReponse';
  events: Array<EventWithCheckInStatus>;
};

export type GetMeetingServiceByIdResponse = {
  __typename: 'GetMeetingServiceByIdResponse';
  meetingService: Maybe<MeetingService>;
};

export type GetRipplingAccountStateResponse = {
  __typename: 'GetRipplingAccountStateResponse';
  lastSync: Scalars['String'];
  state: SyncState;
  syncedUsers: Scalars['Int'];
};

export type GetSpaceSuggestableEventsForUserResponse = {
  __typename: 'GetSpaceSuggestableEventsForUserResponse';
  events: Array<Event>;
};

export type GetSuggestedSpaceForEventsEventInput = {
  endTime: Scalars['ISODateTime'];
  id: Scalars['ID'];
  /** Represents the number of attendees that are in the office for the filtered location and also accepted the event */
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['ISODateTime'];
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForEventsAlgorithm {
  FRECENCY_V1 = 'FRECENCY_V1',
  FRECENCY_V2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForEventsInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  events: Array<GetSuggestedSpaceForEventsEventInput>;
  spaceFilters: GetSuggestedSpacesForEventsSpaceFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForEventsSpacePreferencesInput>;
};

export type GetSuggestedSpacesForEventsResponse = {
  __typename: 'GetSuggestedSpacesForEventsResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm: Maybe<GetSuggestedSpacesForEventsAlgorithm>;
  suggestedSpaces: Array<SuggestedSpaceForEvent>;
};

export type GetSuggestedSpacesForEventsSpaceFiltersInput = {
  locationIds: Array<Scalars['ID']>;
};

export type GetSuggestedSpacesForEventsSpacePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** How to decide which spaces to suggest - for A/B testing */
export enum GetSuggestedSpacesForTimeFrameAlgorithm {
  FRECENCY_V1 = 'FRECENCY_V1',
  FRECENCY_V2 = 'FRECENCY_V2'
}

export type GetSuggestedSpacesForTimeFrameFiltersInput = {
  locationIds: Array<Scalars['ID']>;
};

export type GetSuggestedSpacesForTimeFrameInput = {
  algorithm?: InputMaybe<GetSuggestedSpacesForEventsAlgorithm>;
  endTime: Scalars['ISODateTime'];
  filters: GetSuggestedSpacesForTimeFrameFiltersInput;
  /** Loose preferences for spaces - attempt to fit criteria but no guarantees */
  spacePreferences?: InputMaybe<GetSuggestedSpacesForTimeFramePreferencesInput>;
  startTime: Scalars['ISODateTime'];
};

export type GetSuggestedSpacesForTimeFramePreferencesInput = {
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
  preferredSpaceCapacity?: InputMaybe<Scalars['Int']>;
};

export type GetSuggestedSpacesForTimeFrameResponse = {
  __typename: 'GetSuggestedSpacesForTimeFrameResponse';
  /** Nullable to force resilience in the face of changing enum values */
  algorithm: Maybe<GetSuggestedSpacesForTimeFrameAlgorithm>;
  suggestedSpaces: Array<Space>;
};

/** Filters for getPaginatedSurveyResponses */
export type GetSurveyResponsesFilters = {
  /** FIlter results by one or more building(s) */
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** End of search window */
  inOfficeAtEnd: Scalars['ISODateTime'];
  /** Beginning of search window */
  inOfficeAtStart: Scalars['ISODateTime'];
  /** Include pending, expired, and dismissed survey responses */
  includeIncomplete?: InputMaybe<Scalars['Boolean']>;
  /** Filter results by one or more rating(s) */
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Filter resuts by a user ID */
  userId?: InputMaybe<Scalars['String']>;
};

/** Input for sorting survey responses by different keys */
export type GetSurveyResponsesSort = {
  /** The key to sort responses by */
  key: SurveyResponseSortKey;
  /** The order to sort that key */
  order: SortOrder;
};

export type GetTimeZonesForRipplingAddressesInput = {
  addresses: Array<Scalars['String']>;
};

export type GetTimeZonesForRipplingAddressesResponse = {
  __typename: 'GetTimeZonesForRipplingAddressesResponse';
  timezones: Array<GetTimeZonesForRipplingAddressesResponseTimeZone>;
};

export type GetTimeZonesForRipplingAddressesResponseTimeZone = {
  __typename: 'GetTimeZonesForRipplingAddressesResponseTimeZone';
  address: Scalars['String'];
  zone: Maybe<Scalars['String']>;
};

export type GetUpdatePermissionsForEventsResponse = {
  __typename: 'GetUpdatePermissionsForEventsResponse';
  events: Array<Maybe<EventUpdatePermission>>;
};

export type GoogleBuilding = {
  __typename: 'GoogleBuilding';
  buildingId: Maybe<Scalars['String']>;
  buildingName: Maybe<Scalars['String']>;
};

export type GoogleToRobinInput = {
  locationId: Scalars['String'];
  remoteGoogleId?: InputMaybe<Scalars['String']>;
  remoteGoogleLabel?: InputMaybe<Scalars['String']>;
};

export enum GoogleWorkingLocationSourceOfTruth {
  GOOGLE = 'GOOGLE',
  ROBIN = 'ROBIN'
}

export type GoogleWorkingLocationSyncSetting = {
  __typename: 'GoogleWorkingLocationSyncSetting';
  organizationId: Scalars['ID'];
  sourceOfTruth: Maybe<Scalars['String']>;
};

export type Group = {
  __typename: 'Group';
  id: Scalars['ID'];
  members: Array<User>;
  name: Scalars['String'];
};

/** A Guest is someone who was invited to a Visit. A single guest may be invited to multiple Visits, via a GuestInvite that's unique for each visit. */
export type Guest = {
  __typename: 'Guest';
  /** Email address of the guest */
  guestEmail: Scalars['String'];
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String'];
  /** Guest unique identifier */
  id: Scalars['ID'];
  /** Guest personal identity information has been obfuscated */
  isObfuscated: Scalars['Boolean'];
  /** If this guest has been PII obfuscated, it occurred on this date and time */
  obfuscatedOn: Maybe<Scalars['ISODateTime']>;
};

export type GuestInputType = {
  /** Email address of the guest */
  guestEmail: Scalars['String'];
  /** An optional, client-generated ID for this guest's invite record */
  guestInviteId?: InputMaybe<Scalars['ID']>;
  /** Name of the guest, ex Jeff Jefferson */
  guestName: Scalars['String'];
};

/** A GuestInvite is the pairing of a Guest with a particular visit. It contains details about that guest's visit. GuestInvite is unique to the Visit, but a Guest can be associated with multiple Visits as a part of different GuestInvites. */
export type GuestInvite = {
  __typename: 'GuestInvite';
  /** Visit canceled by robin user */
  canceledBy: Maybe<UserWithDefault>;
  /** Date and time of cancelation */
  canceledOn: Maybe<Scalars['ISODateTime']>;
  /** Why the visit was canceled, if specified */
  canceledReason: Maybe<GuestInviteCanceledReason>;
  /** Date and time of the check-in, null if never checked-in */
  checkinOn: Maybe<Scalars['String']>;
  /** Date and time of the checkout, null if never checked-out */
  checkoutOn: Maybe<Scalars['String']>;
  /** Invited Guest */
  guest: Guest;
  /** The health checkpoint survey response associated with the visit */
  healthCheckpointSurveyResponse: Maybe<GenericHealthCheckpointResponse>;
  /** Guest Invite unique identifier */
  id: Scalars['ID'];
  /** The guest's magic link */
  magicLink: Scalars['String'];
  /** Status of guest invitation */
  status: GuestInviteStatus;
  /** The visit the guest invite is for */
  visit: Maybe<Visit>;
};

export enum GuestInviteCanceledReason {
  CANCELED_BY_ADMIN = 'CANCELED_BY_ADMIN',
  FAILED_HEALTH_CHECKPOINT = 'FAILED_HEALTH_CHECKPOINT'
}

export enum GuestInviteStatus {
  CANCELED = 'CANCELED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  COMPLETED_REQUIREMENTS = 'COMPLETED_REQUIREMENTS',
  EXPECTED = 'EXPECTED',
  INVITED = 'INVITED',
  PENDING_INVITE = 'PENDING_INVITE'
}

export type Guideline = {
  __typename: 'Guideline';
  id: Scalars['ID'];
  interval: GuidelineInterval;
  message: Maybe<Scalars['String']>;
  tooltipMessage: Maybe<Scalars['String']>;
};

export type GuidelineInput = {
  interval: GuidelineIntervalInput;
  message?: InputMaybe<Scalars['String']>;
  tooltipMessage?: InputMaybe<Scalars['String']>;
};

export type GuidelineInterval = {
  __typename: 'GuidelineInterval';
  frequency: Scalars['Int'];
  intervalType: GuidelineIntervalType;
};

export type GuidelineIntervalInput = {
  frequency: Scalars['Int'];
  intervalType: GuidelineIntervalType;
};

export enum GuidelineIntervalType {
  WEEKLY = 'WEEKLY'
}

export type GuidelineWithProgress = {
  __typename: 'GuidelineWithProgress';
  id: Scalars['ID'];
  interval: GuidelineInterval;
  message: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
  tooltipMessage: Maybe<Scalars['String']>;
};

export type HealthCheckpoint = {
  __typename: 'HealthCheckpoint';
  customizations: Maybe<Customizations>;
  status: HealthCheckpointStatus;
  surveyId: Maybe<Scalars['String']>;
  surveyUrl: Maybe<Scalars['String']>;
};

export enum HealthCheckpointStatus {
  COMPLETE = 'COMPLETE',
  DISABLED = 'DISABLED',
  EXEMPT = 'EXEMPT',
  FAILED = 'FAILED',
  INCOMPLETE = 'INCOMPLETE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  PASSED = 'PASSED'
}

export enum HomepageType {
  OFFICE = 'OFFICE',
  SCHEDULE = 'SCHEDULE'
}

export type InOfficeData = {
  __typename: 'InOfficeData';
  peopleInTheOffice: Array<User>;
  totalNumberOfPeopleInTheOffice: Scalars['Int'];
};


export type InOfficeDataPeopleInTheOfficeArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type InOfficeDataByDay = {
  __typename: 'InOfficeDataByDay';
  date: Scalars['ISODateTime'];
  inOfficeData: InOfficeData;
};

export type InitiateFullRipplingUserSyncInput = {
  organizationId: Scalars['ID'];
};

export type InitiateFullRipplingUserSyncResponse = {
  __typename: 'InitiateFullRipplingUserSyncResponse';
  id: Scalars['ID'];
};

export type InitiateRipplingBuildingSyncInput = {
  id: Scalars['ID'];
};

export type InitiateRipplingBuildingSyncResponse = {
  __typename: 'InitiateRipplingBuildingSyncResponse';
  id: Scalars['ID'];
};

export type InitiateRipplingConnectionAndSyncInput = {
  agreeToTosForCurrentActor?: InputMaybe<Scalars['Boolean']>;
  organizationId: Scalars['ID'];
};

export type InitiateRipplingConnectionAndSyncResponse = {
  __typename: 'InitiateRipplingConnectionAndSyncResponse';
  buildingSyncId: Scalars['ID'];
};

export type InitiateRipplingUserSyncInput = {
  organizationId: Scalars['ID'];
};

export type InitiateRipplingUserSyncResponse = {
  __typename: 'InitiateRipplingUserSyncResponse';
  syncId: Scalars['ID'];
};

export type InstantAvailability = {
  __typename: 'InstantAvailability';
  availability: Availability;
  reservation: Maybe<Reservation>;
};

export type InvalidCidrError = {
  __typename: 'InvalidCidrError';
  cidr: Scalars['IPAddressCIDR'];
  message: Scalars['String'];
};

export type InviteeEvent = {
  __typename: 'InviteeEvent';
  confirmation: Maybe<EventConfirmation>;
  id: Scalars['ID'];
};

export type IsPassiveCheckInFeatureEnabledForBuildingInput = {
  buildingId: Scalars['ID'];
};

export type LegacyIntegration = {
  __typename: 'LegacyIntegration';
  configuration: Scalars['Json'];
  createdAt: Scalars['String'];
  externalAccountId: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  organizationId: Scalars['Int'];
  resourceType: LegacyIntegrationResourceType;
  state: Scalars['String'];
  type: LegacyIntegrationType;
  updatedAt: Scalars['String'];
  user: User;
  userId: Scalars['Int'];
};

export enum LegacyIntegrationResourceType {
  CALENDAR = 'CALENDAR',
  EVENT = 'EVENT',
  NOTIFICATION = 'NOTIFICATION'
}

export enum LegacyIntegrationType {
  BLUEJEANS = 'BLUEJEANS',
  BLUEJEANS_RELAY = 'BLUEJEANS_RELAY',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS',
  ZOOM = 'ZOOM'
}

export type Level = {
  __typename: 'Level';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount: Scalars['Int'];
  availableDeskAmenities: Array<MinimizedAmenityViewModel>;
  availableSpaceAmenities: Array<MinimizedAmenityViewModel>;
  campusId: Maybe<Scalars['ID']>;
  createdAt: Maybe<Scalars['Date']>;
  /** Total desks on the floor - including inactive. */
  deskCount: Scalars['Int'];
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  filteredSpacesByAmenityIds: Array<Space>;
  id: Scalars['ID'];
  levelNumber: Maybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  /** check whether the level has a map or not */
  mapIsAvailable: Maybe<Scalars['Boolean']>;
  mapUploadStatus: Maybe<MapUploadStatus>;
  maxCapacity: Scalars['Int'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  spaces: Array<Space>;
  state: LevelState;
  timezone: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int'];
  updatedAt: Maybe<Scalars['Date']>;
  workAreas: Array<WorkArea>;
  /** Total desks within the location where booked counts are bound to the current workday and the configured hours of operation for the location */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
  zones: Array<Zone>;
};


export type LevelDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


export type LevelFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type LevelFilteredSpacesByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type LevelSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LevelStateArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type LevelTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LevelDeskReservation = {
  __typename: 'LevelDeskReservation';
  levelId: Scalars['ID'];
  reservations: Array<Reservation>;
};

export type LevelState = {
  __typename: 'LevelState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int'];
};

export type ListBuildingCidrsInput = {
  buildingId: Scalars['ID'];
};

export type ListBuildingCidrsResponse = {
  __typename: 'ListBuildingCidrsResponse';
  cidrs: Array<Scalars['IPAddressCIDR']>;
};

export type ListMeetingServicesCategoriesFiltersInput = {
  nameContainsString?: InputMaybe<Scalars['String']>;
};

export type ListMeetingServicesCategoriesInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ListMeetingServicesCategoriesFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int'];
  sortBy?: InputMaybe<ListMeetingServicesCategoriesSortByInput>;
};

export type ListMeetingServicesCategoriesResponse = {
  __typename: 'ListMeetingServicesCategoriesResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServicesCategories: Array<MeetingServicesCategory>;
};

export enum ListMeetingServicesCategoriesSortByCriteria {
  KEY = 'KEY',
  NAME = 'NAME'
}

export type ListMeetingServicesCategoriesSortByInput = {
  criteria: ListMeetingServicesCategoriesSortByCriteria;
  order: SortOrder;
};

export type ListMeetingServicesFiltersInput = {
  /** userIds match filter transitively through group membership */
  assignedToAny?: InputMaybe<UsersAndGroupsInput>;
  availableInAnyBuildingIdsOrTheirDescendants?: InputMaybe<Array<Scalars['ID']>>;
  belongsToAnyCategoryId?: InputMaybe<Array<Scalars['ID']>>;
  nameContainsString?: InputMaybe<Scalars['String']>;
};

export type ListMeetingServicesInput = {
  /** If after is provided, we only return results that come after the previous result set */
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<ListMeetingServicesFiltersInput>;
  /** Return only the first N results */
  first: Scalars['Int'];
  /** Skip N results, e.g. if you need to jump more than one page ahead. This stacks on top of `after` if provided. Avoid use if possible - prefer using `after` alone. */
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ListMeetingServicesSortByInput>;
};

export type ListMeetingServicesResponse = {
  __typename: 'ListMeetingServicesResponse';
  forwardPageInfo: ForwardPageInfo;
  meetingServices: Array<MeetingService>;
};

export enum ListMeetingServicesSortByCriteria {
  CATEGORY_NAME = 'CATEGORY_NAME',
  SERVICE_NAME = 'SERVICE_NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type ListMeetingServicesSortByInput = {
  criteria: ListMeetingServicesSortByCriteria;
  order: SortOrder;
};

export type Location = {
  __typename: 'Location';
  /** Total desks in the building - excluding inactive. */
  activeDeskCount: Maybe<Scalars['Int']>;
  address: Maybe<Scalars['String']>;
  campus: Maybe<Campus>;
  campusId: Maybe<Scalars['ID']>;
  /** Excluding request-only and on-demand spaces. See SpaceScheduleConfiguration.SCHEDULED. */
  containsAtLeastOneScheduledSpace: Scalars['Boolean'];
  createdAt: Maybe<Scalars['Date']>;
  /** customWorkingHours are limited to 100 entries and will only grab custom working hours from the current date of the location and upcoming custom working hours It paginates via optional `startFromDateTime` parameter This will grab custom working hours that has a date time greater than `startFromDateTime` provided Format expected is `YYYY-MM-DD HH:mm` */
  customWorkingHours: Array<CustomWorkingHours>;
  description: Maybe<Scalars['String']>;
  /** Total desks in the building - including inactive. */
  deskCount: Scalars['Int'];
  /** Total desks within the location that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  /** Expects a `startDate` and `endDate` formated as YYYY-MM-DD `startDate` and `endDate` are inclusive */
  hoursForTimeRange: Array<DateSpecificWorkingHours>;
  id: Scalars['ID'];
  image: Maybe<Scalars['String']>;
  isHealthCheckpointRequired: Scalars['Boolean'];
  isVisitorManagementEnabled: Scalars['Boolean'];
  latitude: Maybe<Scalars['Float']>;
  levels: Array<Level>;
  longitude: Maybe<Scalars['Float']>;
  maxCapacity: Scalars['Int'];
  name: Scalars['String'];
  officeAccess: Array<OfficeAccess>;
  organizationId: Scalars['ID'];
  preferredLanguageTag: Maybe<Scalars['String']>;
  remoteGoogleId: Maybe<Scalars['ID']>;
  remoteGoogleLabel: Maybe<Scalars['String']>;
  spaces: Array<Space>;
  state: LocationState;
  stateByDay: Array<LocationStateByDay>;
  timezone: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  totalLevels: Scalars['Int'];
  /** total spaces will be contextual to the filter */
  totalSpaces: Scalars['Int'];
  updatedAt: Scalars['Date'];
  workingHours: Array<WorkingHours>;
  /** Total desks within the location where booked counts are bound to the current workday and the configured hours of operation for the location */
  workplaceDeskSchedulingAggregates: BookedSchedulingAggregateOfDesks;
};


export type LocationCustomWorkingHoursArgs = {
  startFromDateTime?: InputMaybe<Scalars['Date']>;
};


export type LocationHoursForTimeRangeArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type LocationOfficeAccessArgs = {
  userId: Scalars['ID'];
};


export type LocationSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};


export type LocationStateArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  userId: Scalars['ID'];
};


export type LocationStateByDayArgs = {
  endTime: Scalars['Date'];
  evaluations?: InputMaybe<Evaluations>;
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type LocationTotalSpacesArgs = {
  filters?: InputMaybe<Array<SpaceFilters>>;
};

export type LocationDeskReservation = {
  __typename: 'LocationDeskReservation';
  locationId: Scalars['ID'];
  reservations: Array<Reservation>;
};

export type LocationState = {
  __typename: 'LocationState';
  availability: PlaceAvailability;
  freeCapacity: Scalars['Int'];
  isOpen: Maybe<Scalars['Boolean']>;
};

export type LocationStateByDay = {
  __typename: 'LocationStateByDay';
  availability: PlaceAvailability;
  day: Scalars['Date'];
};

export type LogSuggestedSpaceBookedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type LogSuggestedSpaceDismissedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type LogSuggestedSpaceViewedInput = {
  suggestedSpaceRecordId: Scalars['ID'];
};

export type MapBuildingToRipplingWorkLocationInput = {
  buildingId: Scalars['ID'];
  organizationId: Scalars['ID'];
  ripplingWorkLocationId: Scalars['ID'];
};

export type MapBuildingToRipplingWorkLocationResponse = {
  __typename: 'MapBuildingToRipplingWorkLocationResponse';
  id: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsInput = {
  mappings: Array<MapBuildingsToRipplingWorkLocationsMappingsInput>;
  organizationId: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsMappingsInput = {
  buildingId: Scalars['ID'];
  ripplingWorkLocationId: Scalars['ID'];
};

export type MapBuildingsToRipplingWorkLocationsResponse = {
  __typename: 'MapBuildingsToRipplingWorkLocationsResponse';
  id: Scalars['ID'];
};

export enum MapUploadStatus {
  AWAITING_UPLOAD = 'AWAITING_UPLOAD',
  UNKNOWN = 'UNKNOWN',
  UPLOADED = 'UPLOADED'
}

export type MeOrganization = {
  __typename: 'MeOrganization';
  authMethods: Maybe<Array<AuthMethod>>;
  avatar: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type MeetingService = {
  __typename: 'MeetingService';
  /** null if admin approval is not required */
  approvers: Maybe<UserOrGroup>;
  /** Assignees are required when service is created, but could become null/empty due to user/group deletion */
  assignees: Maybe<UsersAndGroups>;
  availableInPlaces: Array<MeetingServiceAvailability>;
  category: MeetingServicesCategory;
  description: Maybe<Scalars['Markdown']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: Array<TicketQuestion>;
  /** If true, approver must approve creation/changes before assignees are emailed */
  requireAdminApproval: Scalars['Boolean'];
  updatedAt: Scalars['ISODateTime'];
};

export type MeetingServiceAvailability = {
  __typename: 'MeetingServiceAvailability';
  placeId: Scalars['ID'];
  placeType: MeetingServiceAvailabilityPlaceType;
};

export type MeetingServiceAvailabilityInput = {
  placeId: Scalars['ID'];
  placeType: MeetingServiceAvailabilityPlaceType;
};

/** Meeting services can be assigned to spaces or higher in the hierarchy */
export enum MeetingServiceAvailabilityPlaceType {
  BUILDING = 'BUILDING',
  CAMPUS = 'CAMPUS',
  FLOOR = 'FLOOR',
  ORGANIZATION = 'ORGANIZATION',
  SPACE = 'SPACE'
}

export type MeetingServiceRequest = Ticket & {
  __typename: 'MeetingServiceRequest';
  assignees: Maybe<UsersAndGroups>;
  completedForm: CompletedTicketForm;
  id: Scalars['ID'];
  key: Scalars['String'];
  /** If you are looking for form questions, consult completedForm which shows questions as they were at the time of ticket creation. meetingService.questions are presented for *new* service requests. */
  meetingService: MeetingService;
  regardsEventAtSpace: TicketRegardsEventAtSpace;
  requester: Maybe<User>;
  title: Scalars['String'];
};

export type MeetingServicesCategory = {
  __typename: 'MeetingServicesCategory';
  id: Scalars['ID'];
  key: Scalars['String'];
  name: Scalars['String'];
};

export type MeteredFeature = {
  __typename: 'MeteredFeature';
  billableTotal: Maybe<Scalars['Int']>;
  feature: FeatureType;
  limit: Maybe<Scalars['Int']>;
  overProvisioningLimit: Maybe<Scalars['Int']>;
  total: Maybe<Scalars['Int']>;
};

export type MigrateOrgMeetingServicesInput = {
  organizationId: Scalars['ID'];
  thisDisablesTheOldUIAreYouSure: Scalars['Boolean'];
};

export type MigrateOrgMeetingServicesResponse = {
  __typename: 'MigrateOrgMeetingServicesResponse';
  success: Maybe<Scalars['Boolean']>;
};

export type MinimizedAmenityViewModel = {
  __typename: 'MinimizedAmenityViewModel';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type MultiPlacePassInput = {
  groups: Array<Scalars['ID']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  places: Array<PassPlaceInput>;
  schedule: Array<Scalars['String']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type Mutation = {
  __typename: 'Mutation';
  /** Requesting user acknowledges a given announcement for a given location */
  acknowledgeAnnouncement: Maybe<Scalars['Void']>;
  acknowledgeAnnouncementForMultipleLocations: Maybe<Scalars['Void']>;
  /** Acknowledge the handling of visit events to prevent duplication of event driven side-effects */
  acknowledgeVisitEventHandled: Maybe<Scalars['Void']>;
  addCustomWorkingHours: Maybe<Scalars['Void']>;
  addLevelToLocation: Maybe<AddLevelToLocationResponse>;
  addLevelsToLocation: Maybe<Array<Maybe<AddLevelsToLocationResponse>>>;
  addLocationToOrganization: Maybe<AddLocationToOrganizationResponse>;
  /** Add a meeting service, e.g. 'Breakfast' */
  addMeetingService: AddMeetingServiceResponse;
  /** Add a category for meeting services, e.g. 'Catering'. Idempotent - if category already exists with same attributes, return old category. */
  addMeetingServicesCategory: AddMeetingServicesCategoryResponse;
  addOfficeAdmin: Maybe<Scalars['Void']>;
  addOrganization: Maybe<AddOrganizationResponse>;
  addPinnedUserSpaces: Maybe<Scalars['Void']>;
  addSpaceToEvent: UpdateEventResponse;
  addUserToFavorites: Maybe<Scalars['Void']>;
  addUserToRole: Maybe<Scalars['Void']>;
  /** Created a org_visit_type entry matching the given visitTypeName. If visit_type exists, simply enable it for given org. */
  addVisitTypeForOrg: Maybe<Scalars['Void']>;
  /** Allows a robin administrator to register a guest for a visit at some location */
  administratorRegisterGuest: Maybe<Scalars['Void']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrg: Maybe<Scalars['Void']>;
  /** @deprecated Use the `autoAllocateStickersToOrg` operation instead */
  allocateStickersToOrgByDisplayCode: Maybe<Scalars['Void']>;
  /** Archive an announcement by ID. Changes the `status` to ARCHIVED. */
  archiveAnnouncement: Maybe<Scalars['Void']>;
  /** Archive a SLACK announcement by ID. Changes the `status` to ARCHIVED. */
  archiveAnnouncementForSlack: Maybe<Scalars['Void']>;
  /** Archives an integration by id */
  archiveIntegrationById: Maybe<Scalars['Void']>;
  archiveMeetingService: Maybe<Scalars['TODO']>;
  archiveMeetingServicesCategory: Maybe<Scalars['TODO']>;
  /** Recover your arrival display using a client signed certificate of authority */
  arrivalDisplayCertRecovery: ArrivalDisplayRescueResponse;
  /** Provided the expressed name and email, either find or create a guest record. Returns guest ID in all cases. */
  assertGuestIdentity: Scalars['ID'];
  /** Creates a new assigned desk reservation for a given user on your behalf. */
  assignDelegatedDesk: Reservation;
  /** Creates a new assigned desk reservation for the current user. */
  assignDeskToMe: Reservation;
  assignStickerToDeskByShortCode: Maybe<AssignStickerToDeskByShortCodeResponse>;
  assignStickers: AssignStickerResponse;
  /** Associate a health checkpoint response record to a guest invite */
  associateHealthCheckpointWithVisit: Maybe<Scalars['Void']>;
  /** Generate an authentication bearer token in exchange for a magic link token */
  authenticateVisitorWithMagicLink: VisitMagicLinkAuthResponse;
  autoAllocateStickersToMultipleOrgs: Maybe<Scalars['Void']>;
  autoAllocateStickersToOrg: Maybe<Scalars['Void']>;
  /** Archive multiple announcements by IDs, modifying it's status to ARCHIVED. If one announcement is unable to be processed, it fails the entire operation */
  bulkArchiveAnnouncements: Maybe<Scalars['Void']>;
  /** Bulk updates custom working hours for a single location by overriding all the custom working hours to the custom working hour objects provided in the payload. There are three scenarios: If a custom working hour object in the payload has an ID, it updates that object in the db with the corresponding ID If no ID is provided with a custom working hour object, a new object is created in the db If there are objects in the db that is not defined in the payload, they are deleted If there is a failure for one of the objects, the entire operation fails and reverts to custom working hours before the request was made. */
  bulkUpdateCustomWorkingHours: Maybe<Scalars['Void']>;
  cancelActivity: Maybe<Scalars['Void']>;
  /** Cancel an existing employee visit */
  cancelEmployeeVisit: Maybe<Scalars['Void']>;
  cancelEmployeeVisitsAtLocationForEmployee: Maybe<Scalars['Void']>;
  cancelEmployeeVisitsForEmployee: Maybe<Scalars['Void']>;
  /** Cancel event by event ID */
  cancelEvent: Maybe<Scalars['Void']>;
  cancelGuestInvite: Maybe<Scalars['Void']>;
  cancelVisit: Maybe<Scalars['Void']>;
  /** check into a visit that was self-registered from the arrival display */
  checkIntoSelfRegisteredVisitFromArrivalDisplay: Maybe<Scalars['Void']>;
  /**
   * check into a visit using a guest's magic link
   * @deprecated checkIntoVisit is deprecated. Use checkIntoVisitWithMagicLink instead.
   */
  checkIntoVisit: Maybe<Scalars['Void']>;
  /** check into a visit for a location that does not require HCP from the arrival display */
  checkIntoVisitFromArrivalDisplay: Maybe<Scalars['Void']>;
  /**
   * check into a visit for a location that requires HCP from the arrival display
   * @deprecated Use checkIntoSelfRegisteredVisitFromArrivalDisplay instead
   */
  checkIntoVisitRequiringHCPFromArrivalDisplay: Maybe<Scalars['Void']>;
  /** check into a visit using a guest's magic link */
  checkIntoVisitWithMagicLink: Maybe<Scalars['Void']>;
  /** check out a guest from the arrival display */
  checkOutGuestFromArrivalDisplay: Maybe<Scalars['Void']>;
  /** check out a guest from the visit admin dashboard */
  checkOutGuestFromDashboard: Maybe<Scalars['Void']>;
  /** Complete a a survey response */
  completeSurveyResponse: Maybe<SurveyResponse>;
  confirmDeskReservation: Maybe<Scalars['Void']>;
  /** Confirm the desk reservation for the given user. Requires a token with elevated scopes. */
  confirmDeskReservationDelegated: Maybe<Scalars['Void']>;
  /** Confirm event by event ID */
  confirmEvent: Maybe<Scalars['Void']>;
  /** Converts an existing API token to a JWT claims token and refresh token pair */
  convertApiTokenToJwtAuth: AuthorizationResponse;
  convertGroupToExternalRipplingGroup: ConvertGroupToExternalRipplingGroupResponse;
  createActivity: CreateActivityReturnType;
  createAndMapBuildingsFromRippling: CreateAndMapBuildingsFromRipplingResponse;
  /** Create a new announcement for given locations. `status` is ACTIVE by default. - Note: only user actors are allowed to create announcements */
  createAnnouncement: Maybe<Scalars['Void']>;
  /** Create a new announcement to be consumed by SLACK. `status` is ACTIVE by default. */
  createAnnouncementForSlack: Maybe<Scalars['Void']>;
  /** From dashboard on creation of the new arrival display. Creates new device for the given tenant. In event land: Successful create causes event handler to generate new device authentication which is registered against this entity. */
  createArrivalDisplay: Scalars['ArrivalDisplayRegistrationKey'];
  /** From dashboard on creation of the new badge printer. Creates new device for the given tenant. */
  createBadgePrinter: Maybe<Scalars['Void']>;
  createGenericHealthCheckpoint: CreateGenericHealthCheckpointResponse;
  createGroupAndMakeItExternalRipplingGroup: CreateGroupAndMakeItExternalRipplingGroupResponse;
  /** Creates a new integration */
  createIntegration: Maybe<ExternalIntegration>;
  createLegacyIntegration: Maybe<LegacyIntegration>;
  createOfficeAdminRole: Maybe<Scalars['Void']>;
  /** @deprecated createPass is deprecated. Use createPassForPlaces instead. */
  createPass: Maybe<Scalars['Void']>;
  /** Creates a pass for one or more places */
  createPassForPlaces: Maybe<Scalars['Void']>;
  createRobinOrganizationFromRipplingOAuthData: CreateRobinOrganizationFromRipplingOAuthDataResponse;
  createRole: Maybe<Scalars['Void']>;
  /** Creates a Sigma user if it doesn't exist */
  createSigmaMember: Maybe<Scalars['ID']>;
  /** Creates a new Sigma workbook in My Documents or Shared */
  createSigmaWorkbook: Maybe<SigmaWorkbook>;
  /**
   * Internal method to create a new visit with zero side effects.
   * @deprecated Use createVisitWithCustomVisitType
   */
  createVisit: Maybe<Scalars['Void']>;
  /** Internal method to create a new visit with zero side effects using a string visit type. */
  createVisitWithCustomVisitType: Maybe<Scalars['Void']>;
  createWorkPolicy: Maybe<Scalars['Void']>;
  /** Disable a org_visit_type entry matching the given visitTypeName. */
  deactivateVisitTypeForOrg: Maybe<Scalars['Void']>;
  /**
   * Set a decision for a document for a visiting guest for one document within a specified workflow.
   * @deprecated Use decideOutcomeForVisitDocumentFromArrivalDisplay instead
   */
  decideOutcomeForVisitDocument: Maybe<Scalars['Void']>;
  /** Set a decision from the Arrival Display for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromArrivalDisplay: Maybe<Scalars['Void']>;
  /** Set a decision from the GuestUI for a document for a visiting guest for one document within a specified workflow. */
  decideOutcomeForVisitDocumentFromGuestUI: Maybe<Scalars['Void']>;
  /** Ends or cancels a reservation and updates the user's participation status. Should be used to cancel actor's own reservation. */
  declineAndEndReservation: EndOrCancelReservationResponse;
  deleteExternalRipplingGroup: DeleteExternalRipplingGroupResponse;
  /** Deletes a legacy integration exists for the user by the given type and resource-type Tenant ID is also taken into account Returns the id of the deleted integration */
  deleteLegacyIntegrationByType: Maybe<Scalars['Int']>;
  deletePass: Maybe<Scalars['Void']>;
  deleteWorkAreaDesks: Maybe<Scalars['Void']>;
  disableOrganizationUser: Maybe<Scalars['Void']>;
  disablePassiveCheckInFeatureForBuilding: Maybe<Scalars['Void']>;
  /** Dismiss a survey response */
  dismissSurveyResponse: Maybe<SurveyResponse>;
  employeeVisit: EmployeeVisitMutations;
  enableOrganizationUser: Maybe<Scalars['Void']>;
  enablePassiveCheckInFeatureForBuilding: Maybe<Scalars['Void']>;
  /** Ends or cancels a reservation via delegation */
  endOrCancelDelegatedReservation: EndOrCancelReservationResponse;
  /** Ends or cancels a reservation. Should be used to cancel actor's own reservation. */
  endOrCancelReservation: EndOrCancelReservationResponse;
  generateStickerBatch: GenerateStickerBatchResponse;
  /** A guest invite failed their health checkpoint */
  guestHealthCheckpointFailed: Maybe<Scalars['Void']>;
  /** Called when a guest's invitation email has been sent */
  guestInvited: Maybe<Scalars['Void']>;
  initiateRipplingConnectionAndSync: InitiateRipplingConnectionAndSyncResponse;
  initiateRipplingUserSync: InitiateRipplingUserSyncResponse;
  inviteOfficeAdminUsers: Maybe<Scalars['Void']>;
  joinActivity: Maybe<Scalars['Void']>;
  /** Perform whichever action makes sense to allow a user to "Join" the office. If there is a desk reservation reservation, confirm it if allowed. Otherwise, schedule an employee visit. */
  joinOffice: Maybe<Scalars['Void']>;
  linkArrivalDisplayAndBadgePrinter: Maybe<Scalars['Void']>;
  logSpaceSuggestionBooked: Maybe<Scalars['Void']>;
  logSpaceSuggestionDismissed: Maybe<Scalars['Void']>;
  logSpaceSuggestionViewed: Maybe<Scalars['Void']>;
  mapBuildingToRipplingWorkLocation: MapBuildingToRipplingWorkLocationResponse;
  mapBuildingsToRipplingWorkLocations: Array<MapBuildingsToRipplingWorkLocationsResponse>;
  /** Moves all of the old meeting services contacts to new ticketing system. Services are created called "Facilities", "Office Admin", "AV and Tech", or "Catering". Each of these will be set up so that the same email addresses get emails for the same things, so admins can theoretically click one button to move over. They should probably follow up with further configuration of services. After this action is taken, changing meeting services contacts in the old system is disallowed. */
  migrateOrgMeetingServices: MigrateOrgMeetingServicesResponse;
  publishSharedScheduleForDesk: Maybe<Scalars['Void']>;
  refreshDeviceAuthorization: DeviceAuthorizationResponse;
  /** Refreshes an existing JWT claims token and refresh token pair */
  refreshJwtAuthToken: AuthorizationResponse;
  /** Register arrival display for service. Each key only works one time. */
  registerArrivalDisplay: ArrivalDisplayRegistrationResponse;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display - automatically checks the visit in
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplay
   */
  registerGuestFromArrivalDisplay: Maybe<Scalars['Void']>;
  /**
   * create a new visit from dashboard. guests listed will be upserted into the set of known guests, matched by email.
   * @deprecated This creation method will be replaced with a more open usage of visit type. Replaced by administratorRegisterGuest.
   */
  registerGuestFromDashboard: Maybe<Scalars['Void']>;
  /**
   * register a guest in a location that requires a HCP from the arrival display
   * @deprecated Use registerGuestWithHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithHCPFromArrivalDisplay: Maybe<Scalars['Void']>;
  /** register a guest in a location that requires a HCP from the arrival display using a string visit type */
  registerGuestWithHCPFromArrivalDisplayWithCustomVisitType: Maybe<Scalars['Void']>;
  /**
   * register a guest in a location that doesn't require HCP from the arrival display
   * @deprecated Use registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType
   */
  registerGuestWithoutHCPFromArrivalDisplay: Maybe<Scalars['Void']>;
  /** register a guest in a location that doesn't require HCP from the arrival display using a string visit type */
  registerGuestWithoutHCPFromArrivalDisplayWithCustomVisitType: Maybe<Scalars['Void']>;
  /** Unregister and de-authenticate an arrival display. */
  removeArrivalDisplay: Maybe<Scalars['Void']>;
  /** Unregister a badge printer. */
  removeBadgePrinter: Maybe<Scalars['Void']>;
  /** Removes messages by ID from a chat on activity */
  removeChatMessagesFromActivity: Maybe<Scalars['Void']>;
  removeCustomWorkingHours: Maybe<Scalars['Void']>;
  removeOfficeAdmin: Maybe<Scalars['Void']>;
  removePinnedUserSpaces: Maybe<Scalars['Void']>;
  removeSpaceFromEvent: RemoveSpaceFromEventResponse;
  removeUserFromFavorites: Maybe<Scalars['Void']>;
  removeUserFromRole: Maybe<Scalars['Void']>;
  renameMeetingServicesCategory: Maybe<Scalars['TODO']>;
  /** Extends the expiration period on an expired, unused arrival display registration. */
  renewArrivalDisplayRegistration: Maybe<Scalars['Void']>;
  /** Used by arrival displays to report their status and to report the status of their connected badge printer. */
  reportDeviceStatus: Maybe<Scalars['Void']>;
  /** Report an issue with a desk - chair is broken, monitor is busted, etc */
  reportIssueWithDesk: ReportIssueResponse;
  /** Report an issue with a space - TV isn't working, table is wobbly, etc */
  reportIssueWithSpace: ReportIssueResponse;
  /** Tells us the device's current IP address. From this, we can infer that the user is present with their device at a building associated with that IP address, and perform side effects like checking them in to their reservations at that building. Don't bother calling this if device is on a cellular data connection or a VPN. */
  reportUserPresenceAtIPAddress: ReportUserPresenceAtIpAddressResponse;
  requestMeetingServiceForExistingEventAtSpace: Maybe<Scalars['TODO']>;
  requestMeetingServiceForNewEventAtSpace: Maybe<Scalars['TODO']>;
  /** Creates a new hot/hoteled desk reservationfor a given user on your behalf. */
  reserveDelegatedDesk: Reservation;
  /** Creates a new hot/hoteled desk reservation for the current user. */
  reserveDeskForMe: Reservation;
  /** Reserve a space */
  reserveSpace: ReserveSpaceResponse;
  saveMappedGoogleLocations: Maybe<Scalars['Void']>;
  /** Save a new survey configuration */
  saveSurveyConfiguration: Maybe<Survey>;
  scaffoldDemoFloorPlan: Maybe<Scalars['Void']>;
  /** Create a new employee visit */
  scheduleEmployeeVisitAtLocation: Maybe<Scalars['Void']>;
  /**
   * Add a chat message to an activity
   * @deprecated Deprecated in favor of `sendChatOnActivityWithId`
   */
  sendChatOnActivity: Maybe<Scalars['Void']>;
  /** Add a chat message to an activity with client specified UUID identity */
  sendChatOnActivityWithId: Maybe<Scalars['Void']>;
  /** Sets the auto desk booking setting for current (user) actor */
  setAutoDeskBooking: Maybe<Scalars['Void']>;
  setAutoDeskBookingForOrg: Maybe<Scalars['Void']>;
  /** Requires places:manage permission for the given building. Sets public CIDRs associated with the given building, for use with passive check-in. Idempotent. Number of CIDRs, size of subnet are both limited. Rejects CIDRs that might technically be valid but are likely user error: "1", "1.2", etc. Tries to reject bogons and martians: https://en.wikipedia.org/wiki/Bogon_filtering */
  setBuildingCidrs: SetBuildingCidrsResponse;
  /** Sets a channel ID for a user's personal calendar integration. */
  setChannelAndResourceIdsForUserCalendar: Maybe<Scalars['Void']>;
  setDefaultLocationForUser: Maybe<Scalars['Void']>;
  /** Sets sentAt field for distribution and resolves sendResult to SUCCESS for all provided recipient ids. An error will be thrown if client tries to set send data of a distribution or recipient that has already been set. */
  setDistributionSendData: Maybe<Scalars['Void']>;
  setEmployeeVisitsEnabledForOrg: Maybe<Scalars['Void']>;
  setGoogleWorkingLocationSyncSettingForOrg: Maybe<Scalars['Void']>;
  /** Sets the sync google working locations setting user. 0 for off, org ID for on. */
  setGoogleWorkingLocationSyncSettingForUser: Maybe<Scalars['Void']>;
  /** Sets the homepage preference for current (user) actor. */
  setMyHomepage: Maybe<Scalars['Void']>;
  /** Sets a languge tag for current (user) actor */
  setMyLanguageTag: Maybe<Scalars['Void']>;
  /** Update the given notification preference for the current user */
  setNotificationPreferenceForCurrentUser: Maybe<Scalars['Void']>;
  setOrganization14Day100UsersTrial: Maybe<Scalars['Void']>;
  /** Updates startAt date for recurring distribution announcements and keeps the sentAt field set to null. Sent the startAt date as a ISODateTime */
  setRecurringAnnouncementData: Maybe<Scalars['Void']>;
  /** Sets the show weekends setting for current (user) actor */
  setShowWeekendsForMe: Maybe<Scalars['Void']>;
  setSuggestedSpacesEnabledForOrg: Maybe<Scalars['Void']>;
  setTeamMembershipByName: Maybe<Scalars['Void']>;
  setTicketStatus: Maybe<Scalars['TODO']>;
  /** Sets a user notification setting. */
  setUserNotificationSetting: Maybe<Scalars['Void']>;
  /** Temporary migration query which generates and returns the client secret for existing arrival displays */
  tempArrivalDisplayAuthMigration: ArrivalDisplayRegistrationResponse;
  ticketAddToTestTable: TicketAddToTestTableResponse;
  ticketTestMutation: Maybe<Scalars['Void']>;
  unassignDeskFromMe: Maybe<Scalars['Void']>;
  unassignSticker: Maybe<Scalars['Void']>;
  unjoinActivity: Maybe<Scalars['Void']>;
  unlinkArrivalDisplayAndBadgePrinter: Maybe<Scalars['Void']>;
  /** This mutation understands the input as the state of the activity, therefore it will update every field according to the input */
  updateActivity: Maybe<Scalars['Void']>;
  /** Update a single (ACTIVE or SCHEDULED) announcement. Author cannot change. */
  updateAnnouncement: Maybe<Scalars['Void']>;
  /** From the Dashboard, users can edit arrival displays they've already created. */
  updateArrivalDisplay: Maybe<Scalars['Void']>;
  /** From the Dashboard, users can edit badge printers they've already created. */
  updateBadgePrinter: Maybe<Scalars['Void']>;
  updateCustomWorkingHours: Maybe<Scalars['Void']>;
  /** Updates the default pass setting */
  updateDefaultPass: Maybe<Scalars['Void']>;
  updateDistributionsByAnnouncementId: Maybe<Scalars['Void']>;
  updateMeetingService: Maybe<Scalars['TODO']>;
  updateMeetingServiceRequest: Maybe<Scalars['TODO']>;
  /** Updates the desk, start time, and end time for the current user's non-recurring desk reservation. */
  updateMyReservationDeskAndTimeFrame: UpdateDeskReservationResponse;
  /** @deprecated updatePass is deprecated. Use updatePassForPlaces instead. */
  updatePass: Maybe<Scalars['Void']>;
  /** Updates a pass for one or more places */
  updatePassForPlaces: Maybe<Scalars['Void']>;
  /** Updates the visibility of a desk reservation. */
  updateReservationVisibility: Maybe<Scalars['Void']>;
  updateStandardWorkingHoursForLocation: Maybe<Scalars['Void']>;
  /** Update an existing survey configuration */
  updateSurveyConfiguration: Maybe<Survey>;
  updateWorkAreas: Maybe<Scalars['TODO']>;
  updateWorkPolicy: Maybe<Scalars['Void']>;
  /** Store data that user has viewed an announcement at the given location. */
  viewAnnouncement: Maybe<Scalars['Void']>;
  viewAnnouncementForMultipleLocations: Maybe<Scalars['Void']>;
  /** Add a new document task to visit workflows for the specified visit types and locations */
  visitAddDocumentTask: Maybe<Scalars['Void']>;
  /** Archive an existing document. The specified document is removed from all live workflows. */
  visitArchiveDocument: Maybe<Scalars['Void']>;
  /** Update the status of an async visit request. This mutation is only accessible using an internal service auth token. */
  visitAsyncRequestStatusReport: Maybe<Scalars['Void']>;
  /**
   * Update a document task to apply to a new visit workflow for the specified visit types and locations
   * @deprecated Use visitChangeDocumentTaskAssociationsWithCustomVisitType instead
   */
  visitChangeDocumentTaskAssociations: Maybe<Scalars['Void']>;
  /** Update a document task to apply to a new visit workflow for the specified visit types and locations */
  visitChangeDocumentTaskAssociationsWithCustomVisitType: Maybe<Scalars['Void']>;
};


export type MutationAcknowledgeAnnouncementArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationAcknowledgeAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
};


export type MutationAcknowledgeVisitEventHandledArgs = {
  eventType: VisitEventAcknowledgement;
  visitId: Scalars['ID'];
};


export type MutationAddCustomWorkingHoursArgs = {
  customWorkingHours: CustomWorkingHoursInput;
};


export type MutationAddLevelToLocationArgs = {
  locationId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationAddLevelsToLocationArgs = {
  locationId: Scalars['ID'];
  names: Array<Scalars['String']>;
};


export type MutationAddLocationToOrganizationArgs = {
  address: Scalars['String'];
  name: Scalars['String'];
  timeZone: Scalars['String'];
};


export type MutationAddMeetingServiceArgs = {
  input: AddMeetingServiceInput;
};


export type MutationAddMeetingServicesCategoryArgs = {
  input: AddMeetingServicesCategoryInput;
};


export type MutationAddOfficeAdminArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddOrganizationArgs = {
  name: Scalars['String'];
  slug: Scalars['String'];
};


export type MutationAddPinnedUserSpacesArgs = {
  locationId: Scalars['String'];
  spaceIds: Array<Scalars['String']>;
};


export type MutationAddSpaceToEventArgs = {
  request?: InputMaybe<AddSpaceToEventRequest>;
};


export type MutationAddUserToFavoritesArgs = {
  userId: Scalars['String'];
};


export type MutationAddUserToRoleArgs = {
  roleSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationAddVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String'];
};


export type MutationAdministratorRegisterGuestArgs = {
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  arrivalLocationId: Scalars['ID'];
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitType: Scalars['String'];
};


export type MutationAllocateStickersToOrgArgs = {
  input: AllocateStickersToOrgRequest;
};


export type MutationAllocateStickersToOrgByDisplayCodeArgs = {
  input: AllocateStickersToOrgByDisplayCodeRequest;
};


export type MutationArchiveAnnouncementArgs = {
  announcementId: Scalars['ID'];
};


export type MutationArchiveAnnouncementForSlackArgs = {
  announcementId: Scalars['ID'];
};


export type MutationArchiveIntegrationByIdArgs = {
  id: Scalars['ID'];
};


export type MutationArchiveMeetingServiceArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationArchiveMeetingServicesCategoryArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationAssertGuestIdentityArgs = {
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
};


export type MutationAssignDelegatedDeskArgs = {
  email: Scalars['String'];
  notify?: InputMaybe<Scalars['Boolean']>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignDeskToMeArgs = {
  notify?: InputMaybe<Scalars['Boolean']>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationAssignStickerToDeskByShortCodeArgs = {
  input: AssignStickerToDeskByShortCodeRequest;
};


export type MutationAssignStickersArgs = {
  input: AssignStickerRequest;
};


export type MutationAssociateHealthCheckpointWithVisitArgs = {
  guestInviteId: Scalars['ID'];
  healthCheckpointId: Scalars['ID'];
};


export type MutationAuthenticateVisitorWithMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type MutationAutoAllocateStickersToMultipleOrgsArgs = {
  input: Array<AutoAllocateStickersToOrgRequest>;
};


export type MutationAutoAllocateStickersToOrgArgs = {
  input: AutoAllocateStickersToOrgRequest;
};


export type MutationBulkArchiveAnnouncementsArgs = {
  announcementIds: Array<Scalars['ID']>;
};


export type MutationBulkUpdateCustomWorkingHoursArgs = {
  customWorkingHoursUpdates: Array<CustomWorkingHoursBulkUpdateInput>;
  locationId: Scalars['ID'];
};


export type MutationCancelActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationCancelEmployeeVisitArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']>;
  employeeVisitId: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
};


export type MutationCancelEmployeeVisitsAtLocationForEmployeeArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']>;
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
  startTime: Scalars['ISODateTime'];
};


export type MutationCancelEmployeeVisitsForEmployeeArgs = {
  cancelReservations?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['LocalDate'];
  employeeId: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
};


export type MutationCancelEventArgs = {
  id: Scalars['ID'];
};


export type MutationCancelGuestInviteArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<GuestInviteCanceledReason>;
};


export type MutationCancelVisitArgs = {
  id: Scalars['ID'];
  reason?: InputMaybe<GuestInviteCanceledReason>;
};


export type MutationCheckIntoSelfRegisteredVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitArgs = {
  magicLink: Scalars['String'];
};


export type MutationCheckIntoVisitFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitRequiringHcpFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckIntoVisitWithMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type MutationCheckOutGuestFromArrivalDisplayArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCheckOutGuestFromDashboardArgs = {
  guestInviteId: Scalars['ID'];
};


export type MutationCompleteSurveyResponseArgs = {
  comment?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  rating: Scalars['Int'];
  reservationId?: InputMaybe<Scalars['String']>;
  surveyResponseId?: InputMaybe<Scalars['ID']>;
};


export type MutationConfirmDeskReservationArgs = {
  reservationId: Scalars['ID'];
  type: DeskConfirmationType;
};


export type MutationConfirmDeskReservationDelegatedArgs = {
  reservationId: Scalars['ID'];
  type: DeskConfirmationType;
  userId: Scalars['ID'];
};


export type MutationConfirmEventArgs = {
  id: Scalars['ID'];
};


export type MutationConvertGroupToExternalRipplingGroupArgs = {
  input: ConvertGroupToExternalRipplingGroupInput;
};


export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


export type MutationCreateAndMapBuildingsFromRipplingArgs = {
  input: CreateAndMapBuildingsFromRipplingInput;
};


export type MutationCreateAnnouncementArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateAnnouncementForSlackArgs = {
  announcement: AnnouncementCreateInput;
};


export type MutationCreateArrivalDisplayArgs = {
  badgePrinterIPAddress?: InputMaybe<Scalars['String']>;
  badgePrinterId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateBadgePrinterArgs = {
  arrivalDisplayIds?: InputMaybe<Array<Scalars['ID']>>;
  badgePrinterIPAddress: Scalars['String'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationCreateGenericHealthCheckpointArgs = {
  entityId: Scalars['ID'];
  entityType: GenericHealthCheckpointEntityType;
  id?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};


export type MutationCreateGroupAndMakeItExternalRipplingGroupArgs = {
  input: CreateGroupAndMakeItExternalRipplingGroupInput;
};


export type MutationCreateIntegrationArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  externalId: Scalars['String'];
  integrationJson?: InputMaybe<Scalars['Json']>;
  name: Scalars['String'];
  tenantId: Scalars['ID'];
};


export type MutationCreateLegacyIntegrationArgs = {
  configuration: Scalars['Json'];
  resourceType: Scalars['String'];
  state: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateOfficeAdminRoleArgs = {
  locationId: Scalars['ID'];
};


export type MutationCreatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationCreatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationCreateRobinOrganizationFromRipplingOAuthDataArgs = {
  input: CreateRobinOrganizationFromRipplingOAuthDataInput;
};


export type MutationCreateRoleArgs = {
  input: RoleCreationInput;
};


export type MutationCreateSigmaWorkbookArgs = {
  location: SigmaLocationType;
  name: Scalars['String'];
};


export type MutationCreateVisitArgs = {
  guests: Array<GuestInputType>;
  organizerId: Scalars['ID'];
  organizerType: VisitOrganizerType;
  visitDetails: VisitWithIdInputType;
};


export type MutationCreateVisitWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guests: Array<GuestInputType>;
  hostUserId: Scalars['ID'];
  id: Scalars['ID'];
  organizerId: Scalars['ID'];
  organizerType: VisitOrganizerType;
  startTime: Scalars['ISODateTime'];
  visitType: Scalars['String'];
};


export type MutationCreateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
};


export type MutationDeactivateVisitTypeForOrgArgs = {
  visitTypeName: Scalars['String'];
};


export type MutationDecideOutcomeForVisitDocumentArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDecideOutcomeForVisitDocumentFromArrivalDisplayArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']>;
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDecideOutcomeForVisitDocumentFromGuestUiArgs = {
  guestId: Scalars['ID'];
  outcomeId: Scalars['ID'];
  sendCopyToGuest?: InputMaybe<Scalars['Boolean']>;
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  workflowId: Scalars['ID'];
};


export type MutationDeclineAndEndReservationArgs = {
  reservationId: Scalars['ID'];
};


export type MutationDeleteExternalRipplingGroupArgs = {
  input: DeleteExternalRipplingGroupInput;
};


export type MutationDeleteLegacyIntegrationByTypeArgs = {
  resourceType: LegacyIntegrationResourceType;
  type: LegacyIntegrationType;
};


export type MutationDeletePassArgs = {
  passId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteWorkAreaDesksArgs = {
  deskIds: Array<Scalars['ID']>;
};


export type MutationDisableOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDisablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationDismissSurveyResponseArgs = {
  reservationId?: InputMaybe<Scalars['String']>;
  surveyResponseId?: InputMaybe<Scalars['ID']>;
};


export type MutationEnableOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationEnablePassiveCheckInFeatureForBuildingArgs = {
  input: SetPassiveCheckInFeatureForBuildingInput;
};


export type MutationEndOrCancelDelegatedReservationArgs = {
  notifyAssignee?: InputMaybe<Scalars['Boolean']>;
  reservationId: Scalars['ID'];
};


export type MutationEndOrCancelReservationArgs = {
  reservationId: Scalars['ID'];
};


export type MutationGenerateStickerBatchArgs = {
  input: GenerateStickerBatchRequest;
};


export type MutationGuestHealthCheckpointFailedArgs = {
  healthCheckpointResponseId: Scalars['ID'];
};


export type MutationGuestInvitedArgs = {
  magicLink: Scalars['String'];
};


export type MutationInitiateRipplingConnectionAndSyncArgs = {
  input: InitiateRipplingConnectionAndSyncInput;
};


export type MutationInitiateRipplingUserSyncArgs = {
  input: InitiateRipplingUserSyncInput;
};


export type MutationInviteOfficeAdminUsersArgs = {
  emailAddresses: Array<Scalars['String']>;
};


export type MutationJoinActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationJoinOfficeArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  type: DeskConfirmationType;
  userId: Scalars['ID'];
};


export type MutationLinkArrivalDisplayAndBadgePrinterArgs = {
  arrivalDisplayId: Scalars['ID'];
  badgePrinterId: Scalars['ID'];
};


export type MutationLogSpaceSuggestionBookedArgs = {
  input: LogSuggestedSpaceBookedInput;
};


export type MutationLogSpaceSuggestionDismissedArgs = {
  input: LogSuggestedSpaceDismissedInput;
};


export type MutationLogSpaceSuggestionViewedArgs = {
  input: LogSuggestedSpaceViewedInput;
};


export type MutationMapBuildingToRipplingWorkLocationArgs = {
  input: MapBuildingToRipplingWorkLocationInput;
};


export type MutationMapBuildingsToRipplingWorkLocationsArgs = {
  input: MapBuildingsToRipplingWorkLocationsInput;
};


export type MutationMigrateOrgMeetingServicesArgs = {
  input: MigrateOrgMeetingServicesInput;
};


export type MutationPublishSharedScheduleForDeskArgs = {
  input: SharedDeskSchedulesInput;
};


export type MutationRefreshDeviceAuthorizationArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRefreshJwtAuthTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationRegisterArrivalDisplayArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey'];
  organizationId: Scalars['ID'];
};


export type MutationRegisterGuestFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId?: InputMaybe<Scalars['ID']>;
  visitInviteId?: InputMaybe<Scalars['ID']>;
  visitType: VisitType;
};


export type MutationRegisterGuestFromDashboardArgs = {
  guests: Array<GuestInputType>;
  visitDetails: VisitInputType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestInviteId: Scalars['ID'];
  guestName: Scalars['String'];
  healthCheckpointId: Scalars['ID'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestInviteId: Scalars['ID'];
  guestName: Scalars['String'];
  healthCheckpointId: Scalars['ID'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: VisitType;
};


export type MutationRegisterGuestWithoutHcpFromArrivalDisplayWithCustomVisitTypeArgs = {
  arrivalLocationId: Scalars['ID'];
  guestEmail: Scalars['String'];
  guestName: Scalars['String'];
  hostUserId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  visitId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type MutationRemoveArrivalDisplayArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveBadgePrinterArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveChatMessagesFromActivityArgs = {
  chatMessageIdentifiers: Array<Scalars['ID']>;
};


export type MutationRemoveCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID'];
};


export type MutationRemoveOfficeAdminArgs = {
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemovePinnedUserSpacesArgs = {
  spaceIds: Array<Scalars['String']>;
};


export type MutationRemoveSpaceFromEventArgs = {
  input: RemoveSpaceFromEventInput;
};


export type MutationRemoveUserFromFavoritesArgs = {
  userId: Scalars['String'];
};


export type MutationRemoveUserFromRoleArgs = {
  roleSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationRenameMeetingServicesCategoryArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationRenewArrivalDisplayRegistrationArgs = {
  key: Scalars['ArrivalDisplayRegistrationKey'];
};


export type MutationReportDeviceStatusArgs = {
  arrivalDisplayStatus?: InputMaybe<DeviceStatusInput>;
  arrivalDisplayStatusMessage?: InputMaybe<Scalars['String']>;
  badgePrinterStatus?: InputMaybe<DeviceStatusInput>;
  badgePrinterStatusMessage?: InputMaybe<Scalars['String']>;
};


export type MutationReportIssueWithDeskArgs = {
  input: ReportIssueWithDeskInput;
};


export type MutationReportIssueWithSpaceArgs = {
  input: ReportIssueWithSpaceInput;
};


export type MutationReportUserPresenceAtIpAddressArgs = {
  input: ReportUserPresenceAtIpAddressInput;
};


export type MutationRequestMeetingServiceForExistingEventAtSpaceArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationRequestMeetingServiceForNewEventAtSpaceArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationReserveDelegatedDeskArgs = {
  email: Scalars['String'];
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<Array<Scalars['String']>>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  type: Scalars['String'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveDeskForMeArgs = {
  end: DateWithTimezone;
  notify?: InputMaybe<Scalars['Boolean']>;
  recurrence?: InputMaybe<Array<Scalars['String']>>;
  seatId: Scalars['Int'];
  start: DateWithTimezone;
  type: Scalars['String'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationReserveSpaceArgs = {
  request: ReserveSpaceRequest;
};


export type MutationSaveMappedGoogleLocationsArgs = {
  input?: InputMaybe<Array<InputMaybe<GoogleToRobinInput>>>;
};


export type MutationSaveSurveyConfigurationArgs = {
  allowAnonymous: Scalars['Boolean'];
  buildingIds: Array<Scalars['String']>;
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};


export type MutationScaffoldDemoFloorPlanArgs = {
  locationId: Scalars['ID'];
};


export type MutationScheduleEmployeeVisitAtLocationArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  source?: InputMaybe<Scalars['String']>;
  startTime: Scalars['ISODateTime'];
};


export type MutationSendChatOnActivityArgs = {
  activityID: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationSendChatOnActivityWithIdArgs = {
  activityID: Scalars['ID'];
  message: Scalars['String'];
  messageID: Scalars['ID'];
};


export type MutationSetAutoDeskBookingArgs = {
  input: SetAutoDeskBookingInput;
};


export type MutationSetAutoDeskBookingForOrgArgs = {
  input: AutoDeskBookingOrgInput;
};


export type MutationSetBuildingCidrsArgs = {
  input: SetBuildingCidrsInput;
};


export type MutationSetChannelAndResourceIdsForUserCalendarArgs = {
  channelId?: InputMaybe<Scalars['String']>;
  expiration?: InputMaybe<Scalars['String']>;
  resourceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type MutationSetDefaultLocationForUserArgs = {
  levelId?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
};


export type MutationSetDistributionSendDataArgs = {
  distributionId: Scalars['ID'];
  recipientIds: Array<Scalars['ID']>;
};


export type MutationSetEmployeeVisitsEnabledForOrgArgs = {
  organizationId: Scalars['ID'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSetGoogleWorkingLocationSyncSettingForOrgArgs = {
  organizationId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationSetGoogleWorkingLocationSyncSettingForUserArgs = {
  organizationId: Scalars['String'];
  sourceOfTruth?: InputMaybe<GoogleWorkingLocationSourceOfTruth>;
  userId: Scalars['ID'];
};


export type MutationSetMyHomepageArgs = {
  input: SetMyHomepageInput;
};


export type MutationSetMyLanguageTagArgs = {
  input: SetMyLanguageTagInput;
};


export type MutationSetNotificationPreferenceForCurrentUserArgs = {
  isEnabled: Scalars['Boolean'];
  preferenceKey?: InputMaybe<NotificationPreferenceKey>;
};


export type MutationSetRecurringAnnouncementDataArgs = {
  announcementId: Scalars['ID'];
  startAt: Scalars['String'];
};


export type MutationSetShowWeekendsForMeArgs = {
  input: SetShowWeekendsForMeInput;
};


export type MutationSetSuggestedSpacesEnabledForOrgArgs = {
  organizationId: Scalars['ID'];
  value: Scalars['Boolean'];
};


export type MutationSetTeamMembershipByNameArgs = {
  teamMemberUserIds: Array<Scalars['String']>;
  teamName: Scalars['String'];
};


export type MutationSetTicketStatusArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationSetUserNotificationSettingArgs = {
  input: SetUserNotificationSettingInput;
};


export type MutationTicketAddToTestTableArgs = {
  input: TicketAddToTestTableInput;
};


export type MutationUnassignDeskFromMeArgs = {
  deskId: Scalars['ID'];
};


export type MutationUnassignStickerArgs = {
  input: UnassignStickerRequest;
};


export type MutationUnjoinActivityArgs = {
  activityId: Scalars['ID'];
};


export type MutationUnlinkArrivalDisplayAndBadgePrinterArgs = {
  arrivalDisplayId: Scalars['ID'];
  badgePrinterId: Scalars['ID'];
};


export type MutationUpdateActivityArgs = {
  activityId: Scalars['ID'];
  input: UpdateActivityInput;
};


export type MutationUpdateAnnouncementArgs = {
  announcementId: Scalars['ID'];
  content: AnnouncementUpdateInput;
};


export type MutationUpdateArrivalDisplayArgs = {
  badgePrinterIPAddress?: InputMaybe<Scalars['String']>;
  badgePrinterId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  name: Scalars['String'];
  selfRegistrationEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateBadgePrinterArgs = {
  arrivalDisplayIds?: InputMaybe<Array<Scalars['ID']>>;
  badgePrinterIPAddress?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locationId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateCustomWorkingHoursArgs = {
  customWorkingHoursId: Scalars['ID'];
  customWorkingHoursUpdate: CustomWorkingHoursUpdateInput;
};


export type MutationUpdateDefaultPassArgs = {
  input: UpdateDefaultPassInput;
};


export type MutationUpdateDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
  distributions: Array<DistributionInput>;
};


export type MutationUpdateMeetingServiceArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationUpdateMeetingServiceRequestArgs = {
  input?: InputMaybe<Scalars['TODO']>;
};


export type MutationUpdateMyReservationDeskAndTimeFrameArgs = {
  input: UpdateMyReservationDeskAndTimeFrameInput;
};


export type MutationUpdatePassArgs = {
  input?: InputMaybe<PassInput>;
};


export type MutationUpdatePassForPlacesArgs = {
  input?: InputMaybe<MultiPlacePassInput>;
};


export type MutationUpdateReservationVisibilityArgs = {
  reservationId: Scalars['ID'];
  visibility?: InputMaybe<DeskReservationVisibility>;
};


export type MutationUpdateStandardWorkingHoursForLocationArgs = {
  locationId: Scalars['ID'];
  workingHours: Array<WorkingHoursInput>;
};


export type MutationUpdateSurveyConfigurationArgs = {
  allowAnonymous?: InputMaybe<Scalars['Boolean']>;
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['ID'];
};


export type MutationUpdateWorkAreasArgs = {
  input: UpdateWorkAreasInput;
  levelId: Scalars['ID'];
};


export type MutationUpdateWorkPolicyArgs = {
  workPolicy: WorkPolicyInput;
  workPolicyId: Scalars['String'];
};


export type MutationViewAnnouncementArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type MutationViewAnnouncementForMultipleLocationsArgs = {
  announcementId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
};


export type MutationVisitAddDocumentTaskArgs = {
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  notificationEmails?: InputMaybe<Array<Scalars['WorkflowEmail']>>;
  visitTypes?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationVisitArchiveDocumentArgs = {
  documentId: Scalars['ID'];
};


export type MutationVisitAsyncRequestStatusReportArgs = {
  errorText?: InputMaybe<Array<Scalars['String']>>;
  status: VisitAsyncRequestReport;
  visitId: Scalars['ID'];
};


export type MutationVisitChangeDocumentTaskAssociationsArgs = {
  documentId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
  notificationEmails: Array<Scalars['WorkflowEmail']>;
  visitTypes: Array<Scalars['String']>;
};


export type MutationVisitChangeDocumentTaskAssociationsWithCustomVisitTypeArgs = {
  documentId: Scalars['ID'];
  locationIds: Array<Scalars['ID']>;
  notificationEmails: Array<Scalars['WorkflowEmail']>;
  visitTypes: Array<Scalars['String']>;
};

export type MyAutoDeskBookingResponse = {
  __typename: 'MyAutoDeskBookingResponse';
  autoDeskBooking: Scalars['Boolean'];
};

export type MyHomepageResponse = {
  __typename: 'MyHomepageResponse';
  homepage: HomepageType;
};

export type MyLanguageTagResponse = {
  __typename: 'MyLanguageTagResponse';
  languageTag: Maybe<Scalars['String']>;
};

export type MyShowWeekendsResponse = {
  __typename: 'MyShowWeekendsResponse';
  showWeekends: Scalars['Boolean'];
};

export type NextAvailableMeta = {
  __typename: 'NextAvailableMeta';
  end: Maybe<Scalars['String']>;
  start: Maybe<Scalars['String']>;
};

export enum NotificationPreferenceKey {
  WHOS_IN_DIGEST_EMAIL = 'WHOS_IN_DIGEST_EMAIL'
}

export type NotificationPreferences = {
  __typename: 'NotificationPreferences';
  isSubscribedToWhosInDigestEmail: Scalars['Boolean'];
};

export enum NotificationSettingType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SLACK = 'SLACK',
  TEAMS = 'TEAMS'
}

export type OfficeAccess = {
  __typename: 'OfficeAccess';
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  campus: Maybe<Campus>;
  groups: Array<Group>;
  /** @deprecated Guidelines are now policies and they're disassociated from access passes */
  guideline: Maybe<Guideline>;
  healthCheckpointRequired: Scalars['Boolean'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  location: Maybe<Location>;
  name: Maybe<Scalars['String']>;
  /** @deprecated Deprecated. Please use `resolvedPlaces`. */
  organization: Maybe<Organization>;
  permissions: OfficeAccessPermissions;
  /** @deprecated Deprecated in favor of places */
  place: Maybe<AccessPlace>;
  places: Maybe<Array<Maybe<AccessPlace>>>;
  resolvedPlaces: Maybe<Array<Maybe<AccessPassResolvedPlaces>>>;
  schedule: Array<Scalars['String']>;
  /** @deprecated Deprecated in favor of id */
  slug: Maybe<Scalars['String']>;
  theme: Theme;
  users: Array<User>;
};


export type OfficeAccessGroupsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type OfficeAccessPermissions = {
  __typename: 'OfficeAccessPermissions';
  canManage: Scalars['Boolean'];
  canView: Scalars['Boolean'];
};

export enum OperatingSystem {
  ANDROID = 'ANDROID',
  IOS = 'IOS'
}

export type Organization = {
  __typename: 'Organization';
  /** Total desks on the floor - excluding inactive. */
  activeDeskCount: Maybe<Scalars['Int']>;
  avatar: Maybe<Scalars['String']>;
  campuses: Array<Campus>;
  createdAt: Maybe<Scalars['Date']>;
  /**
   * Total number of booked desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskBookedTotal: Scalars['Int'];
  /** Total desks within the organization that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  /**
   * Total number of unavailable desks within the organization
   * @deprecated Deprecated in favor of deskSchedulingAggregates
   */
  deskUnavailableTotal: Scalars['Int'];
  features: Maybe<OrganizationFeatures>;
  /** Indicates if organization has active locations (meaning any locations added to an org). */
  hasActiveLocations: Scalars['Boolean'];
  /** Indicates if organization has at least one active map (floorplan). */
  hasActiveMaps: Scalars['Boolean'];
  /** Indicates if organization has uploaded/submitted at least a single map (floorplan). */
  hasUploadedMaps: Scalars['Boolean'];
  id: Scalars['ID'];
  locations: Array<Location>;
  logo: Maybe<Scalars['String']>;
  mapOnboardingStatus: OrganizationMapOnboardingStatus;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  /** @deprecated Use `activeDeskCount` instead */
  totalDesks: Scalars['Int'];
  updatedAt: Scalars['Date'];
};


export type OrganizationLocationsArgs = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
};

export type OrganizationExternalAccount = {
  __typename: 'OrganizationExternalAccount';
  accessToken: Maybe<Scalars['String']>;
  accountId: Scalars['ID'];
  domain: Maybe<Scalars['String']>;
  externalId: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  state: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
};

export type OrganizationFeatureLimits = {
  __typename: 'OrganizationFeatureLimits';
  activatedUsers: MeteredFeature;
  activeUsers: MeteredFeature;
  bookableSeats: MeteredFeature;
  bookableSpaces: MeteredFeature;
  campuses: MeteredFeature;
  exportableDataDays: MeteredFeature;
  invitedUsers: MeteredFeature;
  levels: MeteredFeature;
  locations: MeteredFeature;
  ripplingUsers: MeteredFeature;
  viewableDataDays: MeteredFeature;
  visitorManagementLocations: MeteredFeature;
};

export type OrganizationFeatures = {
  __typename: 'OrganizationFeatures';
  adminOnlyBooking: Scalars['Boolean'];
  amenities: Scalars['Boolean'];
  announcements: Scalars['Boolean'];
  billingUIV1: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  bookableSeatLimit: Maybe<Scalars['Int']>;
  /** @deprecated Use `limits` instead */
  bookableSpaceLimit: Maybe<Scalars['Int']>;
  campuses: Scalars['Boolean'];
  customLogo: Scalars['Boolean'];
  customRoles: Scalars['Boolean'];
  customThemes: Scalars['Boolean'];
  documentAgreements: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  exportableDataLimitDays: Maybe<Scalars['Int']>;
  hotSeatReservations: Scalars['Boolean'];
  hoteledSeatReservations: Scalars['Boolean'];
  insights: Scalars['Boolean'];
  insightsExportsApi: Scalars['Boolean'];
  issueReporting: Scalars['Boolean'];
  levels: Scalars['Boolean'];
  limits: OrganizationFeatureLimits;
  maps: Scalars['Boolean'];
  meetingApprovals: Scalars['Boolean'];
  meetingServices: Scalars['Boolean'];
  meridianMapping: Scalars['Boolean'];
  noPersonalCalendars: Scalars['Boolean'];
  overProvisioning: Scalars['Boolean'];
  peopleDirectory: Scalars['Boolean'];
  /** @deprecated Use `prioritySupport` instead. */
  premierSupport: Scalars['Boolean'];
  prioritySupport: Scalars['Boolean'];
  reverseHoteling: Scalars['Boolean'];
  ripplingIntegration: Scalars['Boolean'];
  samlAuthentication: Scalars['Boolean'];
  scim: Scalars['Boolean'];
  statusBoards: Scalars['Boolean'];
  /** @deprecated Use `limits` instead */
  userLimit: Maybe<Scalars['Int']>;
  /** @deprecated Use `limits` instead */
  viewableDataLimitDays: Maybe<Scalars['Int']>;
  visitorManagement: Scalars['Boolean'];
  workweek: Scalars['Boolean'];
};

export type OrganizationIdHashResponse = {
  __typename: 'OrganizationIdHashResponse';
  hash: Maybe<Scalars['String']>;
};

export type OrganizationLocation = {
  __typename: 'OrganizationLocation';
  locationId: Maybe<Scalars['String']>;
  orgId: Maybe<Scalars['String']>;
};

/** Indicates the status for map upload onboarding. It also provides related entities which help drive the UIs. */
export type OrganizationMapOnboardingStatus = {
  __typename: 'OrganizationMapOnboardingStatus';
  level: Maybe<Level>;
  /** This location will be the one that has a level, if organization has a level at all. */
  location: Maybe<Location>;
  shouldShowSetup: Scalars['Boolean'];
};

export enum OwnerType {
  SEAT = 'SEAT',
  SPACE = 'SPACE',
  UNASSIGNED = 'UNASSIGNED'
}

export type PagedUserFilterInput = {
  favoriteFilter?: InputMaybe<Scalars['Boolean']>;
  hasActivity?: InputMaybe<Scalars['Boolean']>;
  preferredLocationFilter?: InputMaybe<Scalars['ID']>;
  userNameFilter?: InputMaybe<Scalars['String']>;
};

export type PagedUserResult = {
  __typename: 'PagedUserResult';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  nextPageOffset: Scalars['Int'];
  users: Array<User>;
};

export type PagedUserResultV2 = {
  __typename: 'PagedUserResultV2';
  accountUsers: Array<AccountUser>;
  accountUsersTotal: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  nextPageOffset: Scalars['Int'];
};

export enum ParticipationStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NOT_RESPONDED = 'NOT_RESPONDED',
  TENTATIVE = 'TENTATIVE',
  UNKNOWN = 'UNKNOWN'
}

export type PassInput = {
  groups: Array<Scalars['ID']>;
  healthCheckpointRequired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  place: PassPlaceInput;
  schedule: Array<Scalars['String']>;
  userChanges?: InputMaybe<PassUsersInput>;
};

export type PassPlaceInput = {
  id: Scalars['ID'];
  type: AccessPlaceType;
};

export type PassUsersInput = {
  added?: InputMaybe<Array<Scalars['ID']>>;
  removed?: InputMaybe<Array<Scalars['ID']>>;
};

export type Permission = {
  __typename: 'Permission';
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type PinnedUserSpaceEntity = {
  __typename: 'PinnedUserSpaceEntity';
  locationId: Scalars['String'];
  spaceIds: Array<Scalars['String']>;
};

export type PlaceAvailability = {
  __typename: 'PlaceAvailability';
  status: PlaceAvailabilityType;
  unavailableReasons: Array<PlaceUnavailableReason>;
};

export enum PlaceAvailabilityType {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

export enum PlaceType {
  LOCATION = 'location'
}

export enum PlaceUnavailableReason {
  /** The location is closed according to its working hours */
  BUILDING_CLOSED = 'BUILDING_CLOSED',
  /** The maxiumum number of flexible desks have been booked in this location */
  MAX_CAPACITY_REACHED = 'MAX_CAPACITY_REACHED',
  /** The user doesn't have a pass */
  NO_ACCESS = 'NO_ACCESS',
  /** The user has a pass but it doesn't grant access to the location */
  NO_LOCATION_ACCESS = 'NO_LOCATION_ACCESS',
  /** The user has a pass to the location, but it's not valid during the specified start and end times */
  SCHEDULE_VIOLATION = 'SCHEDULE_VIOLATION'
}

export type Policy = {
  __typename: 'Policy';
  is_allowed: Scalars['Boolean'];
  object_id: Scalars['String'];
  object_type: Scalars['String'];
  permission_operation: Scalars['String'];
};

export enum ProxiedEventResponseStatus {
  CANCELLED = 'CANCELLED',
  CONFIRMED = 'CONFIRMED',
  TENTATIVE = 'TENTATIVE',
  UNKNOWN = 'UNKNOWN'
}

export type PublicArrivalDisplay = {
  __typename: 'PublicArrivalDisplay';
  id: Scalars['ID'];
  location: PublicLocationOrDefault;
  name: Scalars['String'];
  organization: PublicOrganization;
};

export type PublicGuest = {
  __typename: 'PublicGuest';
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicLocationOrDefault = {
  __typename: 'PublicLocationOrDefault';
  address: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isHealthCheckpointRequired: Scalars['Boolean'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  timezone: Maybe<Scalars['String']>;
};

export type PublicOrganization = {
  __typename: 'PublicOrganization';
  id: Scalars['ID'];
  logo: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
};

export type PublicUserWithDefault = {
  __typename: 'PublicUserWithDefault';
  avatar: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type PublicUserWithEmail = {
  __typename: 'PublicUserWithEmail';
  avatar: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

/** PublicVisitResult represents the visit for a single GuestInvite. The result is "public" because it is available to the public via Arrival Display kiosk devices. A PublicVisitResult is the guest (GuestInvite) along with information about their associated Visit. */
export type PublicVisitResult = {
  __typename: 'PublicVisitResult';
  arrivalInstructions: Maybe<Scalars['String']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String'];
  checkinOn: Maybe<Scalars['String']>;
  checkoutOn: Maybe<Scalars['String']>;
  customVisitType: Scalars['String'];
  guest: PublicGuest;
  healthCheckpointSurveyResponse: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID'];
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime'];
  status: GuestInviteStatus;
  /** The ID of the associated visit */
  visitId: Scalars['ID'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum QueryVisitDocumentSortBy {
  ADDED_ON = 'ADDED_ON',
  NAME = 'NAME',
  STATUS = 'STATUS'
}

export enum QueryWorkflowSortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export type Query = {
  __typename: 'Query';
  /** Allows arrival displays to self-rescue. If you know, you know. Vague by design. */
  arrivalDisplaySelfRescue: Array<Scalars['String']>;
  /** Badge printer IP address */
  badgePrinterIPAddress: Scalars['String'];
  checkOrganizationNameAvailability: Maybe<CheckOrganizationNameAvailabilityResponse>;
  checkUserEmails: EmailCheckResponse;
  /** Requires permission to manage services in at least one place. Can use with listMeetingServices to get total result count for pagination. */
  countMeetingServices: CountMeetingServicesResponse;
  /** Total count of filtered visit documents */
  countVisitFilteredDocuments: Scalars['Int'];
  /** Tells you if the current user is allowed to report issues on a desk */
  deskAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  doIHaveACalendar: Maybe<Scalars['Boolean']>;
  employeeVisit: EmployeeVisitQueries;
  getAccountUsersByIds: Maybe<Array<Maybe<AccountUser>>>;
  /** Get all active announcements for a given location, within the specific location context & for current user. (e.g. viewedAt - when has the current user first viewed this announcement at provided location) */
  getActiveAnnouncementsByLocationId: Array<AnnouncementForLocation>;
  /** Get all active announcements for given locations, within the specific location context & for current user. (e.g. viewedAt - when has the current user first viewed this announcement at provided location) */
  getActiveAnnouncementsByLocationIds: Array<AnnouncementForLocation>;
  getActiveDistributionsByAuthorId: Array<Maybe<AnnouncementDistributionForLocation>>;
  getActiveDistributionsByOrganizationId: Array<Maybe<AnnouncementDistributionForLocation>>;
  /** Get list of active visit types for org */
  getActiveVisitTypesForOrg: Array<Scalars['String']>;
  getActivitiesSummaryForTimeRangeByLocationIds: Array<DailyActivitiesSummary>;
  /** Gets the latest messages for activity chat. Window from latest message ID. Retrieves messages newer than the referenced message. */
  getActivityChatNewerMessages: Array<ChatMessage>;
  /** Returns the set of message IDs in chat that have been removed after the specified timestamp. */
  getActivityChatRemovedMessagesSince: Array<Scalars['ID']>;
  /** Gets the latest messages for activity chat. Optional parameter @messagesPriorTo is the identity of the earliest ChatMessage of which the client is aware. Providing @messagesPriorTo retrieves the previous page of messages. Default @pageSize is 5. */
  getActivityChatTail: Array<ChatMessage>;
  getActivityForCurrentUserById: ActivityForCurrentUser;
  getAllActivitiesForDateByLocationIds: Array<ActivitiesByLocation>;
  getAllJoinedUsersByActivityId: Array<Maybe<User>>;
  getAllOrganizationLocations: Array<Maybe<OrganizationLocation>>;
  /** Returns filtered view of all visit data for the organization specified in the tenant ID header */
  getAllVisits: Array<Visit>;
  getAmenitiesByIds: Array<Amenity>;
  getAmenityById: Maybe<Amenity>;
  getAnalyticsAuthorizationToken: AnalyticsAuthorizationResponse;
  /** Get a single announcement by ID */
  getAnnouncementById: Maybe<Announcement>;
  /** Get a single announcement as type AnnouncementForLocation by ID */
  getAnnouncementForLocationById: Maybe<AnnouncementForLocation>;
  getAnnouncementViewAcknowledgeCount: Maybe<AnnouncementViewAcknowledgeCount>;
  /** Get all the announcements for an organization, filterable by location id and status */
  getAnnouncements: Array<Maybe<Announcement>>;
  getAnyDeskById: Maybe<Desk>;
  getAnyDesksByIds: Array<Desk>;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplay: Maybe<ArrivalDisplay>;
  /** Get a list of arrival displays registered to the organization of the requesting user */
  getArrivalDisplays: Array<ArrivalDisplay>;
  getAuthedTeamUsersByName: Array<User>;
  /** Gets the auto desk booking setting for current (user) actor */
  getAutoDeskBooking: MyAutoDeskBookingResponse;
  getAutoDeskBookingForOrg: AutoDeskBookingOrgResponse;
  /** Gets a single badge printer via its id */
  getBadgePrinter: Maybe<BadgePrinter>;
  /** Get a list of badge printers registered to the organization of the requesting user */
  getBadgePrinters: Array<BadgePrinter>;
  /** Get a badge printers for organization by locationId */
  getBadgePrintersByLocationId: Array<BadgePrinter>;
  /** Get view count & acknowledge count for each given announcement ID */
  getBulkAnnouncementViewAcknowledgeCount: Array<AnnouncementViewAcknowledgeCountWithId>;
  getCampusById: Maybe<Campus>;
  getCampusesByIds: Array<Campus>;
  /** Return a list of visits given a guest's partial email */
  getCheckedInVisitsByPartialEmail: Array<PublicVisitResult>;
  getCoverImagesForActivities: ActivityCoverImagesObject;
  getCurrentUserAndOrgs: Maybe<CurrentUserAndOrgs>;
  /** @deprecated Default guideline is just a policy with rank 0 now */
  getDefaultGuideline: Maybe<Guideline>;
  getDeskAvailabilityByLevelIdForMe: DeskAvailabilityByLevelId;
  getDeskById: Maybe<Desk>;
  getDeskExclusionById: Maybe<Exclusion>;
  getDeskExclusionsByIds: Array<Exclusion>;
  getDeskReservationById: Maybe<Reservation>;
  getDeskReservationInstancesById: Array<Reservation>;
  getDeskReservationsByIds: Array<Reservation>;
  getDeskReservationsByLevelId: LevelDeskReservation;
  getDeskReservationsByLocationId: LocationDeskReservation;
  getDeskReservationsBySpaceIds: Array<ReservationsBySpacesForStatusBoard>;
  getDeskReservationsByUserId: UserDeskReservation;
  getDeskReservationsByUserIds: Array<UserDeskReservation>;
  getDeskReservationsByZoneId: ZoneDeskReservation;
  /** Query reservations for a set of users between two moments at a specific location. Results are grouped by date relative to the location ISO time offset. */
  getDeskReservationsForUsersAtLocationGroupedByDateAscending: DateGroupedUserReservations;
  /** Query reservations for a set of users between two moments. Results are grouped by date relative to the requesting users ISO time offset. */
  getDeskReservationsForUsersGroupedByDateAscending: DateGroupedUserReservations;
  getDesksByIds: Array<Desk>;
  getDesksWithAssigneesByLevelId: Array<DeskWithAssignees>;
  getDisabledDeskById: Maybe<Desk>;
  getDisabledDesksByIds: Array<Desk>;
  getDistributionById: AnnouncementDistribution;
  getDistributionsByAnnouncementId: Array<AnnouncementDistribution>;
  /** Get all of an employee's visits during a specific time range at a specific location */
  getEmployeeVisitsAtLocationForEmployee: Array<EmployeeVisit>;
  /** Get all of the visits during a specific time range at a specific location for several employees */
  getEmployeeVisitsForUsersAtLocationGroupedByDateAscending: Array<EmployeeVisitsByDate>;
  getEventById: Maybe<Event>;
  getEventsWithCheckInStatusForProxiedEventId: GetEventsWithCheckInStatusForProxiedEventIdReponse;
  getExternalEvents: Array<ExternalEvent>;
  /** Gets all Google buildings tied to the provided external account ID. */
  getGoogleBuildings: Maybe<Array<Maybe<GoogleBuilding>>>;
  getGoogleWorkingLocationSyncSettingForOrg: Scalars['Boolean'];
  /** Gets the sync google working locations setting user */
  getGoogleWorkingLocationSyncSettingForUser: GoogleWorkingLocationSyncSetting;
  getGroupsByKeyword: Array<Group>;
  getGuestInviteById: Maybe<GuestInvite>;
  /** Returns a count of guest invites matching criteria */
  getGuestInviteCount: Scalars['Int'];
  /** Returns filtered view of all guest invites */
  getGuestInvites: Array<GuestInvite>;
  /** Get a guest invite of a visit by its ID */
  getGuestVisitById: Maybe<PublicVisitResult>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocation: InOfficeData;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByLocationByDay: Array<InOfficeDataByDay>;
  /** @deprecated In office has been migrated to Whos In */
  getInOfficeDataByUserIdsAndLocation: InOfficeData;
  getInitialWorkPolicyCreatedAt: Maybe<Scalars['ISODateTime']>;
  /** Gets a external integration by externalId */
  getIntegrationByExternalId: Maybe<Array<Maybe<ExternalIntegration>>>;
  /** Gets a external integration by id */
  getIntegrationById: Maybe<ExternalIntegration>;
  /** Gets a external integration by orgId */
  getIntegrationByOrgId: Maybe<Array<Maybe<ExternalIntegration>>>;
  /** Returns a legacy integration from ACL by its userId */
  getLegacyIntegrationByUserId: Maybe<LegacyIntegration>;
  /** Returns if a legacy integration exists for the user by the given type and resource-type Tenant ID is also taken into account */
  getLegacyIntegrationExistsByType: Maybe<Scalars['Boolean']>;
  /** Returns a legacy integrations from ACL by it orgId */
  getLegacyIntegrationsByOrgId: Maybe<Array<Maybe<LegacyIntegration>>>;
  getLevelById: Maybe<Level>;
  getLevelsByIds: Array<Level>;
  getLocationById: Maybe<Location>;
  getLocationByRemoteGoogleId: Maybe<SimpleLocation>;
  getLocationsByIds: Array<Location>;
  getMeetingServiceById: GetMeetingServiceByIdResponse;
  getMeetingServiceCategoriesAvailableInSpaceId: Maybe<Scalars['TODO']>;
  getMeetingServicesAvailableInCategoryAndSpaceId: Maybe<Scalars['TODO']>;
  getMyDefaultLocationOrFallback: Maybe<Location>;
  getMyEventsInTheRange: Array<Event>;
  getMyEventsInTheRangeWithCheckInStatus: Array<Event>;
  /** @deprecated getMyWorkPolicyWithProgress should be used instead. */
  getMyGuideline: Maybe<Guideline>;
  /** @deprecated getMyWorkPolicyWithProgress should be used instead. */
  getMyGuidelineWithProgress: Maybe<GuidelineWithProgress>;
  getMyHomepage: MyHomepageResponse;
  /** Gets a language tag for current (user) actor */
  getMyLanguageTag: MyLanguageTagResponse;
  getMyTeamNames: Array<Scalars['String']>;
  /** Returns the set of unacknowledged announcements for the requesting user for the given location. (Only PRIORITY announcements, as they can be acknowledged) */
  getMyUnacknowledgedActiveAnnouncementsByLocationId: Array<Announcement>;
  /** Returns the set of unacknowledged announcements for the requesting user for the given locations. (Only PRIORITY announcements, as they can be acknowledged) */
  getMyUnacknowledgedActiveAnnouncementsByLocationIds: Array<Announcement>;
  getMyWorkPolicyWithProgress: Maybe<WorkPolicyWithProgress>;
  getOfficeAdmins: Array<User>;
  getOrSuggestMyDefaultLocationOrLevel: Maybe<DefaultLocationAndLevel>;
  getOrganizationById: Maybe<Organization>;
  /** Gets a legacy integration by externalId and type */
  getOrganizationByIntegration: Maybe<ApiOrganizationExternalAccount>;
  getOrganizationExternalAccount: Maybe<OrganizationExternalAccount>;
  /** Gets an encoded version of an organization ID */
  getOrganizationIdHash: OrganizationIdHashResponse;
  getOrganizationsByIds: Array<Organization>;
  getOrganizationsByUserEmail: Array<Maybe<MeOrganization>>;
  getPagedUsers: PagedUserResult;
  /** @deprecated Deprecated -- continue using getPagedUsers. This is being removed */
  getPagedUsersV2: PagedUserResult;
  /** Get all survey responses with pagination based on the filters By default returns only completed responses. */
  getPaginatedSurveyResponses: Maybe<Array<Maybe<SurveyResponse>>>;
  getPaginatedSurveyResponsesTotalPageCount: Maybe<Scalars['Int']>;
  getPassesByOrganizationId: Array<OfficeAccess>;
  /** Get the most recent pending survey response for a user */
  getPendingSurveyResponseForUser: Maybe<SurveyResponse>;
  getPinnedUserSpacesByLocationIds: Array<PinnedUserSpaceEntity>;
  getRipplingAccountState: Maybe<GetRipplingAccountStateResponse>;
  getRipplingWorkLocationsAndExistingMappings: Maybe<RipplingWorkLocationsWithMappings>;
  getRobinUserInfoBySlackId: Maybe<RobinUserInfoForSlack>;
  getRole: Maybe<Role>;
  getSeatIdByStickerShortCode: Maybe<Scalars['ID']>;
  getSharedDeskSchedule: Maybe<SharedDeskSchedule>;
  /** Returns the appropriate embed mode for the user */
  getSigmaEmbedType: Maybe<SigmaEmbedType>;
  /** Constructs a Sigma embed URL */
  getSigmaEmbedUrl: Maybe<SigmaEmbedUrl>;
  /** Lists all favorited Sigma workbooks for a user */
  getSigmaFavorites: Maybe<Array<Maybe<SigmaFile>>>;
  /** Returns the appropriate embed mode for the user */
  getSigmaMemberId: Maybe<Scalars['ID']>;
  /** Get a Sigma workbook */
  getSigmaWorkbook: Maybe<SigmaFile>;
  /** Lists all Sigma workbooks accessable by a user */
  getSigmaWorkbooks: Maybe<Array<Maybe<SigmaFile>>>;
  /** Get all of an employee's one-off visits during a specific time range within a specific org */
  getSingleDayEmployeeVisitsForEmployee: Array<EmployeeVisit>;
  getSpaceById: Maybe<Space>;
  /** Returns user events which fall within the given time frame and which do not have a space associated with them. Also filters events which are not considered "meetings" and therefore do not warrent a space suggestion. Also makes sure the org has space suggestions enabled - if not, no events are returned. Also makes sure the specified building contains scheduled spaces. Finally, this also checks that the user has permission to modify the event, otherwise it will not be returned, as the use of this query is intended to inform the user that meetings are lacking spaces and to prompt them to add one. */
  getSpaceSuggestableEventsForUser: GetSpaceSuggestableEventsForUserResponse;
  getSpacesByIds: Array<Space>;
  getStickerBatchById: Maybe<StickerBatch>;
  getStickerById: Maybe<Sticker>;
  getStickerByShortCode: Maybe<Sticker>;
  getStickersByOrgId: Array<Maybe<Sticker>>;
  getSuggestedSpacesEnabledForOrg: Scalars['Boolean'];
  /** Given a set of event IDs and space filter criteria, return a suggested space matching that filter criteria for each event. All suggested spaces are simultaneously reservable by the current user. * All returned spaces are available for their event's timeframe. * If events overlap in time, we try our best to return distinct spaces per overlapping event, but can return null if impossible * No other policies (e.g. max duration) prevent reservation */
  getSuggestedSpacesForEvents: GetSuggestedSpacesForEventsResponse;
  /** This is a query which calls the same underlying suggested spaces algorithm as the above, but doesn't consider existing events when querying for suggested spaces. Every other condition is the same, and it uses the same datasource as the above query. Use this one if you don't have an event for which you are querying suggested spaces. */
  getSuggestedSpacesForTimeFrame: GetSuggestedSpacesForTimeFrameResponse;
  /** Get all survey configurations for an organization */
  getSurveyConfigurations: Maybe<Array<Maybe<Survey>>>;
  /** Get summary of the ratings of all survey responses with the given filters */
  getSurveyResponseRatingSummary: Maybe<SurveyResponseRatingSummary>;
  /** Get summary of the statuses of all survey responses with the given filters */
  getSurveyResponseStatusSummary: Maybe<SurveyResponseStatusSummary>;
  /** Get all survey responses based on the criteria. By default returns only completed responses. */
  getSurveyResponses: Maybe<Array<Maybe<SurveyResponse>>>;
  /** @deprecated Deprecated -- use getAuthedTeamUsersByName instead to avoid getting invalid favorites */
  getTeamUsersByName: Array<User>;
  /** Return associated tenant ID for a visit given a magic link */
  getTenantIdByMagicLink: Scalars['ID'];
  getTimeZonesForRipplingAddresses: GetTimeZonesForRipplingAddressesResponse;
  /** Gets publicly available information about a arrival display iff that display has not yet been registered */
  getUnauthorizedArrivalDisplayDetails: Maybe<PublicArrivalDisplay>;
  /** Returns whether the current user has access to update the passed through events. This is currently used to see whether we should allow them to add a space to these events. All events should fall within the time window. */
  getUpdatePermissionsForEvents: GetUpdatePermissionsForEventsResponse;
  /** Check if a user has acknowledged an announcement in a given location */
  getUserAcknowledgementByAnnouncementId: Maybe<AnnouncementAcknowledgement>;
  getUserAssignments: Maybe<Array<Maybe<UserAssignment>>>;
  getUserById: Maybe<User>;
  /** Returns the user's desk reservations and visits per day. Should not be used in conjunction with Who's In queries beacuse those will not count deactivate users in its queries but getUserInOfficeDataByDay() will return a result for a deactivated user Reason being is the reservations and visits is not removed on deactivation. */
  getUserInOfficeDataByDay: Array<UserInOfficeByDay>;
  /** Get the notification preferences for the given user */
  getUserNotificationPreferences: Maybe<NotificationPreferences>;
  getUserNotificationSettings: Maybe<Array<Maybe<UserNotificationsSetting>>>;
  getUserSetting: Maybe<UserSetting>;
  getUserSyncStatus: Maybe<UserSyncStatus>;
  getUsersByEmails: Array<User>;
  getUsersByIds: Array<User>;
  getUsersByKeyword: Array<User>;
  getUsersByRole: Array<User>;
  getUsersForTeams: Array<Maybe<TeamsUserSearchResult>>;
  /** Gets the state of an asynchronous visit request */
  getVisitAsyncRequestStatus: VisitAsyncRequestStatusResponse;
  /** Get a visit by its ID */
  getVisitById: Maybe<Visit>;
  /** Return details for a visit given a magic link */
  getVisitByMagicLink: Maybe<UnauthenticatedVisitResult>;
  /** Returns a count of all visits matching the specified search. */
  getVisitCount: Scalars['Int'];
  /** Return a list of visits given a guest's email */
  getVisitsByEmail: Array<PublicVisitResult>;
  /** @deprecated Deprecated in favor of getWhosInDataByLocations */
  getWhosInDataByLocation: WhosInData;
  /** @deprecated Deprecated in favor of getWhosInDataByLocationsByDay */
  getWhosInDataByLocationByDay: Array<WhosInDataByDay>;
  getWhosInDataByLocationsByDay: Array<WhosInDataByDay>;
  getWhosInDataForLocations: WhosInData;
  getWorkAreaById: Maybe<WorkArea>;
  getWorkAreasByLevel: Array<WorkArea>;
  getWorkPoliciesForOrganization: Array<WorkPolicy>;
  getWorkPolicyById: Maybe<WorkPolicy>;
  /** Gets a single zone by its identifier */
  getZoneById: Maybe<Zone>;
  /** Gets several zones by their identifiers */
  getZonesByIds: Array<Zone>;
  /** @deprecated Replaced with getInitialWorkPolicyCreatedAt for that check */
  hasVirtualWorkPolicy: Scalars['Boolean'];
  /** Is the arrival display paired? Returns false if the device has been removed. */
  isArrivalDisplayPaired: Scalars['Boolean'];
  /** Is the user part of the EAP allow list that grants individual users access to Sigma. */
  isCustomAnalyticsEarlyAccess: Maybe<Scalars['Boolean']>;
  /** Returns whether or not custom analytics is enabled for the particular organization. Includes the EAP allow list to grant individual users access to Sigma. */
  isCustomAnalyticsEnabled: Maybe<Scalars['Boolean']>;
  /** Returns if the organization is in trial mode. */
  isCustomAnalyticsTrial: Maybe<Scalars['Boolean']>;
  isFavoritesEnabled: Scalars['Boolean'];
  /** Is a feature available for a device's organization (guestUI, Arrival Display)? */
  isFeatureFlagEnabledForDeviceOrg: Scalars['Boolean'];
  isFeatureFlagEnabledForOrgOrMe: Scalars['Boolean'];
  /** Is guest self-registration enabled for a device? */
  isGuestSelfRegistrationEnabled: Scalars['Boolean'];
  /** @deprecated Feature flag should be checked on the client side */
  isGuidelinesFeatureFlagEnabled: Scalars['Boolean'];
  isPassiveCheckInFeatureEnabledForBuilding: Scalars['Boolean'];
  isRipplingAccountConnectedForOrganization: Scalars['Boolean'];
  /** Gets the show weekend setting for current (user) actor */
  isShowWeekendsEnabledForMe: MyShowWeekendsResponse;
  isUpgradeNeededToEnableGuidelinesFeature: Scalars['Boolean'];
  /** Returns latest change date for all reservations indicating the latest reservation state change. If the returned timestamp changes in subsequent requests, it indicates that either a desk or space reservation has been created since last fetch. NULL response when no reservations exist for the specified level */
  levelChangeDetectionToken: Maybe<Scalars['ISODateTime']>;
  listBuildingCidrs: ListBuildingCidrsResponse;
  /** Requires permission to manage services in at least one place */
  listMeetingServices: ListMeetingServicesResponse;
  /** Requires permission to manage services in at least one place */
  listMeetingServicesCategories: ListMeetingServicesCategoriesResponse;
  /** Checks seats for multidate reservation availability. */
  reservationsMultiDayGroupedByDate: Array<DeskAvailabilityLocalizedDate>;
  /** If org has not migrated yet, we cannot show new UI. New orgs have no need to migrate. Orgs that never used old meeting services have no need to migrate. Admin UI should *also* be gated behind CurrentUserAndOrgs.hasWorkplaceServicesManagePermissionForCurrentTenant. */
  shouldShowNewTicketUI: ShouldShowNewTicketUiResponse;
  /** Tells you if the current user is allowed to report issues on a space */
  spaceAllowsIssueReports: ResourceAllowsIssueReportsResponse;
  ticketReadFromTestTable: TicketReadFromTestTableResponse;
  ticketTestQuery: Maybe<TicketTestQueryResponse>;
  /** Fetch visit document with agreement from user based on visit invite ID */
  visitDocumentAgreement: VisitDocumentAgreementResponse;
  /** Query for visit and document information associated with a decision ID (assumed to be document accept) */
  visitDocumentByDecisionId: Maybe<VisitDocumentByDecisionResponse>;
  /** @deprecated Use visitDocumentByIdWithCustomVisitType instead */
  visitDocumentById: Maybe<VisitDocumentResponse>;
  visitDocumentByIdWithCustomVisitType: Maybe<VisitDocumentResponse>;
  /**
   * Query paged organizations documents available to workflows which can be applied to visits
   * @deprecated Use visitDocumentNameStatusAndTypesPageWithCustomVisitType instead
   */
  visitDocumentNameStatusAndTypesPage: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /** Query paged organizations documents available to workflows which can be applied to visits */
  visitDocumentNameStatusAndTypesPageWithCustomVisitType: Array<VisitDocumentNameStatusAndTypesQueryResponse>;
  /**
   * Returns document agreement details for `documentID` or first document agreement if no `documentId` is specified. If response is NULL, no documents to agree with to for a visit.
   * @deprecated Use visitDocumentWithNextDocumentIdWithCustomVisitType instead
   */
  visitDocumentWithNextDocumentId: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /** Returns document agreement details for `documentID` or first document agreement if no `documentId` is specified. If response is NULL, no documents to agree with to for a visit. */
  visitDocumentWithNextDocumentIdWithCustomVisitType: Maybe<VisitDocumentAgreementWithNextDocumentResponse>;
  /**
   * Query for menu overview of documents required for a key pair of visit type and location ID.
   * @deprecated Use visitOverviewOfDocumentsWithCustomVisitType instead
   */
  visitOverviewOfDocuments: Maybe<VisitWorkflowOverview>;
  /** Query for menu overview of documents required for a key pair of visit type and location ID. */
  visitOverviewOfDocumentsWithCustomVisitType: Maybe<VisitWorkflowOverview>;
};


export type QueryCheckOrganizationNameAvailabilityArgs = {
  name: Scalars['String'];
};


export type QueryCheckUserEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryCountMeetingServicesArgs = {
  input: CountMeetingServicesInput;
};


export type QueryCountVisitFilteredDocumentsArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
};


export type QueryDeskAllowsIssueReportsArgs = {
  deskId: Scalars['ID'];
};


export type QueryGetAccountUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetActiveDistributionsByAuthorIdArgs = {
  authorId: Scalars['ID'];
  service?: InputMaybe<Scalars['String']>;
};


export type QueryGetActiveDistributionsByOrganizationIdArgs = {
  organizationId: Scalars['ID'];
  service?: InputMaybe<Scalars['String']>;
};


export type QueryGetActivitiesSummaryForTimeRangeByLocationIdsArgs = {
  input: ActivitiesForTimeRangeByLocationIdsInput;
  pagingInput?: InputMaybe<ActivitiesForTimeRangeByLocationIdsPagingInput>;
};


export type QueryGetActivityChatNewerMessagesArgs = {
  activityID: Scalars['ID'];
  messagesAfter: Scalars['ID'];
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivityChatRemovedMessagesSinceArgs = {
  activityID: Scalars['ID'];
  since: Scalars['ISODateTime'];
};


export type QueryGetActivityChatTailArgs = {
  activityID: Scalars['ID'];
  messagesPriorTo?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetActivityForCurrentUserByIdArgs = {
  id: Scalars['ID'];
  joinedLimit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAllActivitiesForDateByLocationIdsArgs = {
  input?: InputMaybe<ActivitiesForDateByLocationIdsInput>;
};


export type QueryGetAllJoinedUsersByActivityIdArgs = {
  activityId: Scalars['ID'];
};


export type QueryGetAllVisitsArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationIdFilter: Array<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetAmenitiesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetAmenityByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnalyticsAuthorizationTokenArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetAnnouncementByIdArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetAnnouncementForLocationByIdArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
};


export type QueryGetAnnouncementViewAcknowledgeCountArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetAnnouncementsArgs = {
  locationId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<AnnouncementStatus>;
};


export type QueryGetAnyDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetAnyDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetArrivalDisplayArgs = {
  id: Scalars['ID'];
};


export type QueryGetArrivalDisplaysArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  isLinkedWithBadgePrinter?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  seekKey?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryGetAuthedTeamUsersByNameArgs = {
  teamName: Scalars['String'];
};


export type QueryGetBadgePrinterArgs = {
  id: Scalars['ID'];
};


export type QueryGetBadgePrintersArgs = {
  includeDeleted?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  seekKey?: InputMaybe<Scalars['ID']>;
  sortKey?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};


export type QueryGetBadgePrintersByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetBulkAnnouncementViewAcknowledgeCountArgs = {
  announcementIds: Array<Scalars['ID']>;
};


export type QueryGetCampusByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetCampusesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetCheckedInVisitsByPartialEmailArgs = {
  email: Scalars['String'];
  includeCheckedOutVisits?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  minutesAfter?: InputMaybe<Scalars['Int']>;
  minutesBefore?: InputMaybe<Scalars['Int']>;
  resultLimiter?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDeskAvailabilityByLevelIdForMeArgs = {
  filters?: InputMaybe<GetDeskAvailabilityByLevelIdFiltersInput>;
  scope: GetDeskAvailabilityByLevelIdScopeInput;
  viewOptions: GetDeskAvailabilityByLevelIdViewOptionsInput;
};


export type QueryGetDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskExclusionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskExclusionsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskReservationInstancesByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeskReservationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationsByLevelIdArgs = {
  endTime: Scalars['Date'];
  levelId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByLocationIdArgs = {
  endTime: Scalars['Date'];
  locationId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsBySpaceIdsArgs = {
  endTime: Scalars['ISODateTime'];
  spaceIds: Array<Scalars['ID']>;
  startTime: Scalars['ISODateTime'];
};


export type QueryGetDeskReservationsByUserIdArgs = {
  endTime: Scalars['Date'];
  id: Scalars['ID'];
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByUserIdsArgs = {
  endTime: Scalars['Date'];
  ids: Array<Scalars['ID']>;
  startTime: Scalars['Date'];
};


export type QueryGetDeskReservationsByZoneIdArgs = {
  endTime: Scalars['Date'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
  zoneId: Scalars['ID'];
};


export type QueryGetDeskReservationsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetDeskReservationsForUsersGroupedByDateAscendingArgs = {
  endTime: Scalars['Date'];
  ids: Array<Scalars['ID']>;
  startTime: Scalars['Date'];
};


export type QueryGetDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDesksWithAssigneesByLevelIdArgs = {
  levelId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetDisabledDeskByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDisabledDesksByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetDistributionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetDistributionsByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
};


export type QueryGetEmployeeVisitsAtLocationForEmployeeArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type QueryGetEmployeeVisitsForUsersAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetEventByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetEventsWithCheckInStatusForProxiedEventIdArgs = {
  externalAccountType: Scalars['String'];
  proxiedEventId: Scalars['ID'];
  timeContext: Scalars['String'];
};


export type QueryGetExternalEventsArgs = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type QueryGetGoogleBuildingsArgs = {
  externalAccountId: Scalars['String'];
};


export type QueryGetGoogleWorkingLocationSyncSettingForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetGroupsByKeywordArgs = {
  keyword: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetGuestInviteByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetGuestInviteCountArgs = {
  dateFilter?: InputMaybe<DateFilterOptionalInput>;
  excludeCanceled?: InputMaybe<Scalars['Boolean']>;
  locationIdFilter?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<GuestInviteStatus>;
  visitId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetGuestInvitesArgs = {
  dateFilter?: InputMaybe<DateFilterOptionalInput>;
  excludeCanceled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationIdFilter?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GuestInviteStatus>;
  visitId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetGuestVisitByIdArgs = {
  guestVisitId: Scalars['ID'];
};


export type QueryGetInOfficeDataByLocationArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type QueryGetInOfficeDataByLocationByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  locationId: Scalars['ID'];
};


export type QueryGetInOfficeDataByUserIdsAndLocationArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
  userIds: Array<Scalars['ID']>;
};


export type QueryGetIntegrationByExternalIdArgs = {
  externalId: Scalars['ID'];
};


export type QueryGetIntegrationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetIntegrationByOrgIdArgs = {
  orgId: Scalars['ID'];
};


export type QueryGetLegacyIntegrationByUserIdArgs = {
  resourceType: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetLegacyIntegrationExistsByTypeArgs = {
  resourceType: LegacyIntegrationResourceType;
  type: LegacyIntegrationType;
};


export type QueryGetLegacyIntegrationsByOrgIdArgs = {
  orgId: Scalars['ID'];
  resourceType: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGetLevelByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLevelsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetLocationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationByRemoteGoogleIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetLocationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetMeetingServiceByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetMeetingServiceCategoriesAvailableInSpaceIdArgs = {
  spaceId: Scalars['ID'];
};


export type QueryGetMeetingServicesAvailableInCategoryAndSpaceIdArgs = {
  categoryId: Scalars['ID'];
  spaceId: Scalars['ID'];
};


export type QueryGetMyEventsInTheRangeArgs = {
  endTime: Scalars['Date'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['Date'];
};


export type QueryGetMyEventsInTheRangeWithCheckInStatusArgs = {
  endTime: Scalars['Date'];
  limit?: InputMaybe<Scalars['Int']>;
  startTime: Scalars['Date'];
  timeContext: Scalars['String'];
};


export type QueryGetMyGuidelineWithProgressArgs = {
  myTimezone: Scalars['String'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdArgs = {
  locationId: Scalars['ID'];
};


export type QueryGetMyUnacknowledgedActiveAnnouncementsByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetMyWorkPolicyWithProgressArgs = {
  myTimezone: Scalars['String'];
};


export type QueryGetOfficeAdminsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  locationId: Scalars['ID'];
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetOrganizationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationByIntegrationArgs = {
  externalId: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGetOrganizationExternalAccountArgs = {
  externalId: Scalars['ID'];
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetOrganizationIdHashArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetOrganizationsByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetOrganizationsByUserEmailArgs = {
  email: Scalars['String'];
};


export type QueryGetPagedUsersArgs = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
};


export type QueryGetPagedUsersV2Args = {
  filters?: InputMaybe<PagedUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
};


export type QueryGetPaginatedSurveyResponsesArgs = {
  filters: GetSurveyResponsesFilters;
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID'];
};


export type QueryGetPaginatedSurveyResponsesTotalPageCountArgs = {
  filters: GetSurveyResponsesFilters;
  perPage: Scalars['Int'];
  surveyId: Scalars['ID'];
};


export type QueryGetPassesByOrganizationIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetPendingSurveyResponseForUserArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetPinnedUserSpacesByLocationIdsArgs = {
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetRipplingAccountStateArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRipplingWorkLocationsAndExistingMappingsArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetRobinUserInfoBySlackIdArgs = {
  externalId: Scalars['String'];
};


export type QueryGetRoleArgs = {
  organizationId: Scalars['ID'];
  roleSlug: Scalars['String'];
};


export type QueryGetSeatIdByStickerShortCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetSharedDeskScheduleArgs = {
  id: Scalars['ID'];
};


export type QueryGetSigmaEmbedUrlArgs = {
  edit?: InputMaybe<Scalars['Boolean']>;
  hideFolderNavigation?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  productionShadow?: InputMaybe<Scalars['Boolean']>;
  trial?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
  workbookUrlId: Scalars['String'];
};


export type QueryGetSigmaWorkbookArgs = {
  workbookId: Scalars['String'];
};


export type QueryGetSingleDayEmployeeVisitsForEmployeeArgs = {
  employeeId: Scalars['ID'];
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
};


export type QueryGetSpaceByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetSpaceSuggestableEventsForUserArgs = {
  buildingIdThatMustContainScheduledSpaces?: InputMaybe<Scalars['ID']>;
  endTime: Scalars['ISODateTime'];
  startTime: Scalars['ISODateTime'];
  userId: Scalars['ID'];
};


export type QueryGetSpacesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGetStickerBatchByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetStickerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetStickerByShortCodeArgs = {
  code: Scalars['String'];
};


export type QueryGetStickersByOrgIdArgs = {
  orgId: Scalars['ID'];
};


export type QueryGetSuggestedSpacesForEventsArgs = {
  input: GetSuggestedSpacesForEventsInput;
};


export type QueryGetSuggestedSpacesForTimeFrameArgs = {
  input: GetSuggestedSpacesForTimeFrameInput;
};


export type QueryGetSurveyResponseRatingSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID'];
};


export type QueryGetSurveyResponseStatusSummaryArgs = {
  filters: GetSurveyResponsesFilters;
  surveyId: Scalars['ID'];
};


export type QueryGetSurveyResponsesArgs = {
  buildingIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  inOfficeAtEnd: Scalars['ISODateTime'];
  inOfficeAtStart: Scalars['ISODateTime'];
  includeIncomplete?: InputMaybe<Scalars['Boolean']>;
  ratings?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  sortBy?: InputMaybe<GetSurveyResponsesSort>;
  surveyId: Scalars['ID'];
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryGetTeamUsersByNameArgs = {
  teamName: Scalars['String'];
};


export type QueryGetTenantIdByMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type QueryGetTimeZonesForRipplingAddressesArgs = {
  input: GetTimeZonesForRipplingAddressesInput;
};


export type QueryGetUnauthorizedArrivalDisplayDetailsArgs = {
  orgId: Scalars['ID'];
  registrationCode: Scalars['ArrivalDisplayRegistrationKey'];
};


export type QueryGetUpdatePermissionsForEventsArgs = {
  endTime: Scalars['ISODateTime'];
  eventIds: Array<Scalars['ID']>;
  startTime: Scalars['ISODateTime'];
};


export type QueryGetUserAcknowledgementByAnnouncementIdArgs = {
  announcementId: Scalars['ID'];
  locationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetUserAssignmentsArgs = {
  userIds: Array<InputMaybe<Scalars['ID']>>;
};


export type QueryGetUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserInOfficeDataByDayArgs = {
  dateRanges: Array<DateRangeInput>;
  id: Scalars['ID'];
};


export type QueryGetUserNotificationPreferencesArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserNotificationSettingsArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserSettingArgs = {
  settingSlug: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetUserSyncStatusArgs = {
  syncId: Scalars['ID'];
};


export type QueryGetUsersByEmailsArgs = {
  emails: Array<Scalars['String']>;
};


export type QueryGetUsersByIdsArgs = {
  filterBy?: InputMaybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<Array<InputMaybe<UserSortBy>>>;
  sortOrder?: InputMaybe<UserSortOrder>;
};


export type QueryGetUsersByKeywordArgs = {
  keyword: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetUsersByRoleArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId: Scalars['ID'];
  roleQuery?: InputMaybe<Scalars['String']>;
  roleSlug?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersForTeamsArgs = {
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};


export type QueryGetVisitAsyncRequestStatusArgs = {
  visitId: Scalars['ID'];
};


export type QueryGetVisitByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetVisitByMagicLinkArgs = {
  magicLink: Scalars['String'];
};


export type QueryGetVisitCountArgs = {
  dateFilter: DateFilterMandatoryInput;
  excludeCanceledVisits?: InputMaybe<Scalars['Boolean']>;
  locationIdFilter: Array<Scalars['ID']>;
};


export type QueryGetVisitsByEmailArgs = {
  email: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
  minutesAfter?: InputMaybe<Scalars['Int']>;
  minutesBefore?: InputMaybe<Scalars['Int']>;
};


export type QueryGetWhosInDataByLocationArgs = {
  date?: InputMaybe<Scalars['LocalDate']>;
  locationId: Scalars['ID'];
};


export type QueryGetWhosInDataByLocationByDayArgs = {
  dates?: InputMaybe<Array<Scalars['LocalDate']>>;
  locationId: Scalars['ID'];
};


export type QueryGetWhosInDataByLocationsByDayArgs = {
  dates: Array<Scalars['LocalDate']>;
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetWhosInDataForLocationsArgs = {
  date?: InputMaybe<Scalars['LocalDate']>;
  locationIds: Array<Scalars['ID']>;
};


export type QueryGetWorkAreaByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetWorkAreasByLevelArgs = {
  levelId: Scalars['ID'];
};


export type QueryGetWorkPolicyByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetZoneByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetZonesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryIsFeatureFlagEnabledForDeviceOrgArgs = {
  flagType?: InputMaybe<DeviceFeatureFlagType>;
};


export type QueryIsFeatureFlagEnabledForOrgOrMeArgs = {
  flagType?: InputMaybe<FeatureFlagType>;
};


export type QueryIsPassiveCheckInFeatureEnabledForBuildingArgs = {
  input: IsPassiveCheckInFeatureEnabledForBuildingInput;
};


export type QueryIsRipplingAccountConnectedForOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type QueryLevelChangeDetectionTokenArgs = {
  levelId: Scalars['ID'];
};


export type QueryListBuildingCidrsArgs = {
  input: ListBuildingCidrsInput;
};


export type QueryListMeetingServicesArgs = {
  input: ListMeetingServicesInput;
};


export type QueryListMeetingServicesCategoriesArgs = {
  input: ListMeetingServicesCategoriesInput;
};


export type QueryReservationsMultiDayGroupedByDateArgs = {
  dates: Array<Scalars['LocalDate']>;
  deskIds: Array<Scalars['ID']>;
  durationInMinutes: Scalars['Int'];
  startTime: Scalars['LocalTime'];
  timezone: Scalars['IANATimezone'];
};


export type QueryShouldShowNewTicketUiArgs = {
  input: ShouldShowNewTicketUiInput;
};


export type QuerySpaceAllowsIssueReportsArgs = {
  spaceId: Scalars['ID'];
};


export type QueryTicketReadFromTestTableArgs = {
  input: TicketReadFromTestTableInput;
};


export type QueryTicketTestQueryArgs = {
  input?: InputMaybe<TicketTestQueryInput>;
};


export type QueryVisitDocumentAgreementArgs = {
  documentId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
};


export type QueryVisitDocumentByDecisionIdArgs = {
  decisionId: Scalars['ID'];
};


export type QueryVisitDocumentByIdArgs = {
  id: Scalars['ID'];
};


export type QueryVisitDocumentByIdWithCustomVisitTypeArgs = {
  id: Scalars['ID'];
};


export type QueryVisitDocumentNameStatusAndTypesPageArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<QueryVisitDocumentSortBy>;
  sortOrder?: InputMaybe<QueryWorkflowSortOrder>;
};


export type QueryVisitDocumentNameStatusAndTypesPageWithCustomVisitTypeArgs = {
  filters?: InputMaybe<Array<VisitDocumentNameStatusAndTypesFilter>>;
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortByField?: InputMaybe<QueryVisitDocumentSortBy>;
  sortOrder?: InputMaybe<QueryWorkflowSortOrder>;
};


export type QueryVisitDocumentWithNextDocumentIdArgs = {
  documentId?: InputMaybe<Scalars['ID']>;
  visitInviteId: Scalars['ID'];
  visitLocationId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitDocumentWithNextDocumentIdWithCustomVisitTypeArgs = {
  documentId?: InputMaybe<Scalars['ID']>;
  visitInviteId: Scalars['ID'];
  visitLocationId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitOverviewOfDocumentsArgs = {
  locationId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};


export type QueryVisitOverviewOfDocumentsWithCustomVisitTypeArgs = {
  locationId: Scalars['ID'];
  visitInviteId: Scalars['ID'];
  visitType: Scalars['String'];
};

export type RemoveSpaceFromEventError = {
  __typename: 'RemoveSpaceFromEventError';
  message: Maybe<Scalars['String']>;
  reason: RemoveSpaceFromEventErrorReasons;
};

export enum RemoveSpaceFromEventErrorReasons {
  LACKING_EVENT_UPDATE_PERMISSIONS = 'LACKING_EVENT_UPDATE_PERMISSIONS',
  UNKNOWN = 'UNKNOWN'
}

export type RemoveSpaceFromEventInput = {
  eventId: Scalars['String'];
  spaceId: Scalars['String'];
};

export type RemoveSpaceFromEventResponse = {
  __typename: 'RemoveSpaceFromEventResponse';
  /** If the event has not been updated, an explanation will be provided */
  error: Maybe<RemoveSpaceFromEventError>;
  /** True if event has been updated */
  eventUpdated: Scalars['Boolean'];
  /** Updated event model */
  updatedEvent: Maybe<Event>;
};

export type ReportIssueInput = {
  description: Scalars['String'];
  isUnusable: Scalars['Boolean'];
};

export type ReportIssueResponse = {
  __typename: 'ReportIssueResponse';
  id: Scalars['ID'];
};

export type ReportIssueWithDeskInput = {
  deskId: Scalars['ID'];
  issue: ReportIssueInput;
};

export type ReportIssueWithSpaceInput = {
  issue: ReportIssueInput;
  spaceId: Scalars['ID'];
};

export type ReportUserPresenceAtIpAddressInput = {
  /** Version of the app in use, e.g. 'v3.28.3 (v1)' */
  appVersion?: InputMaybe<Scalars['String']>;
  /** Unique identifier associated with user's personal device */
  deviceUuid: Scalars['ID'];
  /** Purely for logging/debugging, X-Forwarded-For or source IP is the source of truth */
  ipAddressGuess?: InputMaybe<Scalars['IPAddress']>;
  operatingSystem: OperatingSystem;
  /** e.g. the '10' in Android 10, but intentionally loose like a 'User-Agent' string. For logging/debugging. */
  operatingSystemVersion: Scalars['String'];
  /** Unique identifier for this request, for client/server log correlation */
  requestUuid: Scalars['ID'];
  /** Arbitrary string to describe what triggered this report, e.g. 'background fetch', 'background push', etc. */
  trigger?: InputMaybe<Scalars['String']>;
};

export type ReportUserPresenceAtIpAddressResponse = {
  __typename: 'ReportUserPresenceAtIPAddressResponse';
  /** Zero or more building IDs associated with this IP address. IP addresses can be assigned to multiple buildings. */
  buildingIds: Array<Scalars['ID']>;
  /** What we think the IP address is, ignoring the provided guess. Could be null if we get malformed headers (rare). */
  ipAddress: Maybe<Scalars['IPAddress']>;
  /** True if reported IP address matches a known IP address associated with a building */
  matchesBuilding: Scalars['Boolean'];
};

export type Reservation = {
  __typename: 'Reservation';
  accountReservee: Maybe<AccountReservee>;
  confirmation: Maybe<DeskConfirmation>;
  endTime: Maybe<Scalars['String']>;
  exclusions: Array<Exclusion>;
  healthCheckpoint: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence: Maybe<Scalars['String']>;
  /** @deprecated use the account reservee, it will be scoped to the reservations org */
  reservee: Maybe<Reservee>;
  seat: Desk;
  seriesId: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  timeZone: Maybe<Scalars['String']>;
  type: Scalars['String'];
  visibility: DeskReservationVisibility;
};

export type ReservationEdge = {
  __typename: 'ReservationEdge';
  confirmation: Maybe<DeskConfirmation>;
  endTime: Maybe<Scalars['String']>;
  healthCheckpoint: Maybe<HealthCheckpoint>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence: Maybe<Scalars['String']>;
  reservee: Maybe<Reservee>;
  seat: Desk;
  seriesId: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  timeZone: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

/** Reservation invitee. One of the fields must be specified. */
export type ReservationInvitee = {
  /** Invitee email */
  email?: InputMaybe<Scalars['String']>;
  /** Invitee slug */
  slug?: InputMaybe<Scalars['String']>;
  /** Robin user ID */
  userId?: InputMaybe<Scalars['ID']>;
};

/** Visibility of reservation */
export enum ReservationVisibility {
  CONFIDENTIAL = 'CONFIDENTIAL',
  DEFAULT = 'DEFAULT',
  PERSONAL = 'PERSONAL',
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

/** The ID of the reservation and if it is visible to the current user. Intended use is by the Status Board to use as a reference for which reservations to hide */
export type ReservationsBySpacesForStatusBoard = {
  __typename: 'ReservationsBySpacesForStatusBoard';
  id: Scalars['ID'];
  visibility: DeskReservationVisibility;
};

export type ReserveSpaceRequest = {
  /** Event end time (ISO format) */
  endTime?: InputMaybe<Scalars['Date']>;
  /** True if the event creator is invited to the event */
  eventCreatorIsInvited: Scalars['Boolean'];
  /** The description of the event. Defaults to empty string */
  eventDescription?: InputMaybe<Scalars['String']>;
  /** The title of the event. Defaults to empty string */
  eventTitle?: InputMaybe<Scalars['String']>;
  /** Set of included integration IDs */
  includedIntegrations: Array<Scalars['ID']>;
  /** Event invitees */
  invitees: Array<ReservationInvitee>;
  /** Is the event an all-day event? Default false */
  isAllDay?: InputMaybe<Scalars['Boolean']>;
  /** The name of the location where the event will take place. Defaults to empty string */
  locationName?: InputMaybe<Scalars['String']>;
  /** Is event pre-confirmed */
  preConfirm?: InputMaybe<Scalars['Boolean']>;
  /** ID of the reserved space */
  spaceId: Scalars['ID'];
  /** Event start time (ISO format) */
  startTime?: InputMaybe<Scalars['Date']>;
  /** Event visibility */
  visibility: ReservationVisibility;
};

/** Reservation response */
export type ReserveSpaceResponse = {
  __typename: 'ReserveSpaceResponse';
  /** If the space has not been reserved, an explanation will be provided */
  reason: Maybe<Scalars['String']>;
  /** True if space has been reserved */
  spaceReserved: Scalars['Boolean'];
};

export type Reservee = {
  __typename: 'Reservee';
  email: Scalars['String'];
  participationStatus: Maybe<ParticipationStatus>;
  user: Maybe<User>;
  visitorId: Maybe<Scalars['ID']>;
};

export type ResourceAllowsIssueReportsResponse = {
  __typename: 'ResourceAllowsIssueReportsResponse';
  allowed: Scalars['Boolean'];
};

export type RipplingAccount = {
  __typename: 'RipplingAccount';
  id: Scalars['ID'];
};

export type RipplingWorkLocationWithMappingToRobinBuilding = {
  __typename: 'RipplingWorkLocationWithMappingToRobinBuilding';
  ripplingWorkLocationAddress: Maybe<Scalars['String']>;
  ripplingWorkLocationId: Scalars['ID'];
  ripplingWorkLocationName: Scalars['String'];
  robinBuildingId: Maybe<Scalars['ID']>;
};

export type RipplingWorkLocationsWithMappings = {
  __typename: 'RipplingWorkLocationsWithMappings';
  locationsWithMappings: Array<RipplingWorkLocationWithMappingToRobinBuilding>;
};

export type RobinUserInfoForSlack = {
  __typename: 'RobinUserInfoForSlack';
  token: Scalars['String'];
  userId: Scalars['ID'];
};

export type Role = {
  __typename: 'Role';
  created_at: Scalars['String'];
  description: Maybe<Scalars['String']>;
  is_default: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  organization_id: Scalars['ID'];
  policies: Array<Policy>;
  /** `schedule` is used to distinguish access roles from other roles, and has value of null (which may have distinguished meaning from empty array) by default. */
  schedule: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  updated_at: Maybe<Scalars['String']>;
};

export type RoleCreationInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Array<RolePolicyInput>>;
  slug: Scalars['String'];
};

export type RolePolicyInput = {
  is_allowed: Scalars['Boolean'];
  object_id: Scalars['ID'];
  object_type?: InputMaybe<Scalars['String']>;
  permission_operation: Scalars['String'];
};

export enum ScheduleSourceType {
  GOOGLE = 'google',
  USER = 'user'
}

export type SchedulingAggregateOfDesks = {
  __typename: 'SchedulingAggregateOfDesks';
  /** Total number of desks of type Assigned that are available within the parent */
  deskAssignedAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Assigned that are booked within the parent */
  deskAssignedBookedTotal: Scalars['Int'];
  /** Total number of desks of type Assigned within the parent */
  deskAssignedTotal: Scalars['Int'];
  /** Total number of desks of type Assigned that are unavailable within the parent */
  deskAssignedUnavailableTotal: Scalars['Int'];
  /** Total number of available desks within the parent */
  deskAvailableTotal: Scalars['Int'];
  /** Total number of booked desks within the parent */
  deskBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are available within the parent */
  deskHotAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are booked within the parent */
  deskHotBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hot within the parent */
  deskHotTotal: Scalars['Int'];
  /** Total number of desks of type Hot that are unavailable within the parent */
  deskHotUnavailableTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are available within the parent */
  deskHotelAvailableTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are booked within the parent */
  deskHotelBookedTotal: Scalars['Int'];
  /** Total number of desks of type Hotel within the parent */
  deskHotelTotal: Scalars['Int'];
  /** Total number of desks of type Hotel that are unavailable within the parent */
  deskHotelUnavailableTotal: Scalars['Int'];
  /** Total number of unavailable desks within the parent */
  deskUnavailableTotal: Scalars['Int'];
};

export type SetAutoDeskBookingInput = {
  autoDeskBooking: Scalars['Boolean'];
};

export type SetBuildingCidrsInput = {
  buildingId: Scalars['ID'];
  cidrs: Array<Scalars['IPAddressCIDR']>;
};

export type SetBuildingCidrsResponse = {
  __typename: 'SetBuildingCidrsResponse';
  buildingCidrsUpdated: Scalars['Boolean'];
  invalidCidrErrors: Array<InvalidCidrError>;
};

export type SetMyHomepageInput = {
  homepage: HomepageType;
};

export type SetMyLanguageTagInput = {
  languageTag: Scalars['String'];
};

export type SetPassiveCheckInFeatureForBuildingInput = {
  buildingId: Scalars['ID'];
};

export type SetShowWeekendsForMeInput = {
  showWeekends: Scalars['Boolean'];
};

export type SetUserNotificationSettingInput = {
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  values: Array<InputMaybe<NotificationSettingType>>;
};

export type SetWeeklyScheduleInput = {
  fri: Scalars['Boolean'];
  id?: InputMaybe<Scalars['ID']>;
  instanceEndTime?: InputMaybe<Scalars['LocalEndTime']>;
  instanceStartTime?: InputMaybe<Scalars['LocalEndTime']>;
  locationId: Scalars['String'];
  mon: Scalars['Boolean'];
  recurrenceEndDate?: InputMaybe<Scalars['LocalDate']>;
  recurrenceStartDate?: InputMaybe<Scalars['LocalDate']>;
  sat: Scalars['Boolean'];
  source: ScheduleSourceType;
  sun: Scalars['Boolean'];
  thu: Scalars['Boolean'];
  tue: Scalars['Boolean'];
  userId: Scalars['String'];
  wed: Scalars['Boolean'];
};

export type Setting = {
  __typename: 'Setting';
  slug: Scalars['String'];
  value: Scalars['String'];
};

export enum SettingDataType {
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  JSON = 'JSON',
  STRING = 'STRING'
}

export enum SettingOwnerType {
  USER = 'USER'
}

export type SharedDeskSchedule = {
  __typename: 'SharedDeskSchedule';
  id: Scalars['ID'];
  schedule: Array<DeskSchedule>;
};

export type SharedDeskSchedulesInput = {
  deskId: Scalars['ID'];
  notifyUnassigned: Scalars['Boolean'];
  sharedSchedules: Array<SharedScheduleInput>;
};

export type SharedScheduleInput = {
  recurrence: Scalars['String'];
  userId: Scalars['ID'];
};

export type ShouldShowNewTicketUiInput = {
  organizationId: Scalars['ID'];
};

export type ShouldShowNewTicketUiResponse = {
  __typename: 'ShouldShowNewTicketUIResponse';
  migrationHasHappened: Scalars['Boolean'];
  migrationRequiredBeforeShowingNewUI: Scalars['Boolean'];
  showNewUI: Scalars['Boolean'];
};

export enum SigmaEmbedType {
  CREATOR = 'CREATOR',
  VIEWER = 'VIEWER'
}

export type SigmaEmbedUrl = {
  __typename: 'SigmaEmbedUrl';
  url: Maybe<Scalars['String']>;
};

export type SigmaFile = {
  __typename: 'SigmaFile';
  badge: Scalars['String'];
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  parentId: Scalars['String'];
  parentUrlId: Scalars['String'];
  path: Scalars['String'];
  permission: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  urlId: Scalars['String'];
};

export enum SigmaLocationType {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED'
}

export type SigmaTag = {
  __typename: 'SigmaTag';
  name: Scalars['String'];
  versionTagId: Scalars['String'];
};

export type SigmaWorkbook = {
  __typename: 'SigmaWorkbook';
  createdAt: Scalars['String'];
  createdBy: Scalars['String'];
  isArchived: Scalars['Boolean'];
  latestVersion: Scalars['Int'];
  name: Scalars['String'];
  path: Scalars['String'];
  tags: Maybe<Array<Maybe<SigmaTag>>>;
  updatedAt: Scalars['String'];
  updatedBy: Scalars['String'];
  url: Scalars['String'];
  workbookId: Scalars['String'];
  workbookUrlId: Scalars['String'];
};

export type SimpleLocation = {
  __typename: 'SimpleLocation';
  accountId: Maybe<Scalars['String']>;
  id: Maybe<Scalars['String']>;
  remoteGoogleId: Maybe<Scalars['String']>;
  remoteGoogleLabel: Maybe<Scalars['String']>;
  timeZone: Maybe<Scalars['String']>;
};

export enum SnapTo {
  FIFTEEN = 'FIFTEEN',
  SIXTY = 'SIXTY',
  THIRTY = 'THIRTY'
}

/** The order with which to sort */
export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export type Space = {
  __typename: 'Space';
  amenities: Array<SpaceAmenity>;
  /** Availability of space for the next half hour. GREEN: available for the next half hour. YELLOW: event scheduled within the next thirty minutes. RED: Event currently being held */
  availableAt: Maybe<Availability>;
  behaviors: Array<Scalars['String']>;
  calendar: Maybe<Calendar>;
  /** Capacity is unsigned 32bit integer. */
  capacity: Maybe<Scalars['BigInt']>;
  confirmedAvailability: Maybe<SpaceAvailability>;
  createdAt: Maybe<Scalars['Date']>;
  description: Maybe<Scalars['String']>;
  /** @deprecated Deprecated because this field is no longer retrievable from API */
  disabledAt: Maybe<Scalars['Date']>;
  discoveryRadius: Maybe<Scalars['Float']>;
  eventWithCheckInStatus: Maybe<EventWithCheckInStatus>;
  filteredDesksByAmenityIds: Array<Desk>;
  id: Scalars['ID'];
  image: Maybe<Scalars['String']>;
  isAccessible: Maybe<Scalars['Boolean']>;
  isDisabled: Maybe<Scalars['Boolean']>;
  isManaged: Scalars['Boolean'];
  levelId: Maybe<Scalars['ID']>;
  locationId: Scalars['Int'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  reservationPolicies: SpaceReservationPolicies;
  scheduleConfiguration: Maybe<SpaceScheduleConfiguration>;
  type: Maybe<SpaceType>;
  updatedAt: Maybe<Scalars['Date']>;
  windowedAvailability: Array<SpaceWindowAvailability>;
  zones: Array<Zone>;
};


export type SpaceAvailableAtArgs = {
  time?: InputMaybe<Scalars['Date']>;
};


export type SpaceConfirmedAvailabilityArgs = {
  durationInMinutes?: InputMaybe<Scalars['Int']>;
  time?: InputMaybe<Scalars['ISODateTime']>;
};


export type SpaceFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type SpaceWindowedAvailabilityArgs = {
  firstWindowSplit?: InputMaybe<SnapTo>;
  intervalInMinutes?: InputMaybe<Scalars['Int']>;
  meetingDurationInMinutes?: InputMaybe<Scalars['Int']>;
  numberOfWindows?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['Date']>;
};

export type SpaceAmenity = {
  __typename: 'SpaceAmenity';
  amenityId: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum SpaceAvailability {
  /** Currently available */
  AVAILABLE = 'AVAILABLE',
  /** Resource has been booked and confirmed */
  BOOKED_CONFIRMED = 'BOOKED_CONFIRMED',
  /** Resource has been booked and not confirmed */
  BOOKED_UNCONFIRMED = 'BOOKED_UNCONFIRMED',
  /** Resource marked as unavailable for booking */
  UNAVAILABLE = 'UNAVAILABLE',
  /** Resource schedule not found */
  UNKNOWN = 'UNKNOWN'
}

export enum SpaceFilters {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  SCHEDULING = 'SCHEDULING'
}

export type SpaceReservationPolicies = {
  __typename: 'SpaceReservationPolicies';
  /** Automatically remove ghost meetings from the room's calendar if nobody checks in. */
  abandonedMeetingProtection: Scalars['Boolean'];
  /** Time to wait before an unconfirmed meeting is canceled. */
  abandonedThreshold: Scalars['ISO8601Duration'];
  /** Restrict how far in advance this space can be booked. */
  advanceBookingThreshold: Maybe<Scalars['ISO8601Duration']>;
  allowRecurringEvents: Scalars['Boolean'];
  id: Scalars['ID'];
  maxReservationLength: Maybe<Scalars['ISO8601Duration']>;
  /** Show a check-in button for each meeting */
  meetingCheckins: Scalars['Boolean'];
  restrictBookingToWorkingHours: Scalars['Boolean'];
  /** The number of consecutive abandoned meetings before the space is permanently removed from the series. */
  strikesForRecurringEvents: Maybe<Scalars['Int']>;
};

export enum SpaceScheduleConfiguration {
  ON_DEMAND = 'ON_DEMAND',
  REQUEST_ONLY = 'REQUEST_ONLY',
  SCHEDULED = 'SCHEDULED'
}

export enum SpaceType {
  BREAKOUT = 'BREAKOUT',
  BREAK_ROOM = 'BREAK_ROOM',
  CAFE = 'CAFE',
  CAFETERIA = 'CAFETERIA',
  CALL = 'CALL',
  CLASSROOM = 'CLASSROOM',
  CONFERENCE = 'CONFERENCE',
  EVENT = 'EVENT',
  FITNESS = 'FITNESS',
  HUDDLE = 'HUDDLE',
  INTERVIEW = 'INTERVIEW',
  KITCHEN = 'KITCHEN',
  LAB = 'LAB',
  LACTATION = 'LACTATION',
  LISTENING = 'LISTENING',
  LOBBY = 'LOBBY',
  LOUNGE = 'LOUNGE',
  MEETING = 'MEETING',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER',
  PARKING = 'PARKING',
  PROJECT = 'PROJECT',
  RESTROOM = 'RESTROOM',
  RESTROOM_FEMALE = 'RESTROOM_FEMALE',
  RESTROOM_MALE = 'RESTROOM_MALE',
  STUDIO = 'STUDIO',
  STUDY_ROOM = 'STUDY_ROOM',
  THEATER = 'THEATER',
  UTILITY = 'UTILITY',
  WORK = 'WORK'
}

export type SpaceWindowAvailability = {
  __typename: 'SpaceWindowAvailability';
  end: Maybe<Scalars['Date']>;
  isConfirmed: Maybe<Scalars['Boolean']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd: Maybe<Scalars['Date']>;
  start: Maybe<Scalars['Date']>;
  status: Maybe<Availability>;
  unbookableReasons: Array<Maybe<UnbookableReasonType>>;
};

export type StandardWorkingHours = DateSpecificWorkingHours & {
  __typename: 'StandardWorkingHours';
  date: Scalars['Date'];
  timeFrames: Array<TimeFrame>;
};

export type Sticker = {
  __typename: 'Sticker';
  associatedDeskId: Maybe<Scalars['ID']>;
  batchId: Scalars['ID'];
  /** @deprecated Deprecated due to the potential circular queries that can be made via the fields on this type. Please use the `associatedDeskId` field and make a second query for desk information. */
  desk: Maybe<Desk>;
  displayCode: Scalars['Int'];
  id: Scalars['ID'];
  shortCode: Scalars['String'];
  shortUrl: Scalars['String'];
};

export type StickerAssignment = {
  __typename: 'StickerAssignment';
  seatId: Scalars['Int'];
  stickerId: Scalars['ID'];
};

export type StickerAssignmentInput = {
  seatId: Scalars['Int'];
  stickerId: Scalars['ID'];
};

export type StickerBatch = {
  __typename: 'StickerBatch';
  id: Scalars['ID'];
  stickers: Array<Sticker>;
};

export type SuggestedSpaceForEvent = {
  __typename: 'SuggestedSpaceForEvent';
  eventId: Scalars['ID'];
  space: Maybe<Space>;
  suggestedSpaceRecordId: Maybe<Scalars['String']>;
};

/** Survey configuration */
export type Survey = {
  __typename: 'Survey';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean'];
  /** List of buildings associated with the survey */
  buildings: Maybe<Array<Maybe<Location>>>;
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean'];
  /** Survey name */
  name: Scalars['String'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Survey configuration without building links */
export type SurveyEdge = {
  __typename: 'SurveyEdge';
  /** Boolean value indicating if the survey allows anonymous responses */
  allowAnonymous: Scalars['Boolean'];
  /** Date the survey was created */
  createdAt: Scalars['ISODateTime'];
  /** User that created the survey */
  createdBy: User;
  /** Unique identifier */
  id: Scalars['ID'];
  /** Boolean value indicating if the survey is active */
  isActive: Scalars['Boolean'];
  /** Survey name */
  name: Scalars['String'];
  /** Organization associated with the survey */
  organization: Organization;
  /** Date the survey was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that last updated the survey */
  updatedBy: User;
};

/** Response to a sentiment survey */
export type SurveyResponse = {
  __typename: 'SurveyResponse';
  /** Building of the reservation */
  building: Maybe<Location>;
  /** Response comment */
  comment: Maybe<Scalars['String']>;
  /** Date the response was created */
  createdAt: Scalars['ISODateTime'];
  /** Unique identifier */
  id: Scalars['ID'];
  /** Date the user was in the office */
  inOfficeAt: Scalars['ISODateTime'];
  /** Boolean that indicates if the response was anonymous */
  isAnonymous: Scalars['Boolean'];
  /** Organization associated with the survey response */
  organization: Organization;
  /** Sentiment rating */
  rating: Maybe<Scalars['Int']>;
  /** Reservation associated with the survey response */
  reservation: Maybe<Reservation>;
  /** Status of the survey response */
  status: SurveyResponseStatus;
  /** Date the response was submitted */
  submittedAt: Maybe<Scalars['ISODateTime']>;
  /** Survey that was responded to */
  survey: SurveyEdge;
  /** Date the resopnse was last updated */
  updatedAt: Scalars['ISODateTime'];
  /** User that responded */
  user: Maybe<User>;
};

/** Survey response rating summary */
export type SurveyResponseRatingSummary = {
  __typename: 'SurveyResponseRatingSummary';
  /** Total number of negative responses */
  negativeCount: Scalars['Int'];
  /** Total number of neutral responses */
  neutralCount: Scalars['Int'];
  /** Total number of positive responses */
  positiveCount: Scalars['Int'];
  /** Total number of completed responses */
  totalCount: Scalars['Int'];
};

/** The key to sort survey responses by */
export enum SurveyResponseSortKey {
  IN_OFFICE_AT = 'IN_OFFICE_AT',
  RATING = 'RATING'
}

/** Status of the survey response */
export enum SurveyResponseStatus {
  COMPLETED = 'COMPLETED',
  DISMISSED = 'DISMISSED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING'
}

/** Survey response status summary */
export type SurveyResponseStatusSummary = {
  __typename: 'SurveyResponseStatusSummary';
  /** Total number of COMPLETED responses */
  completedCount: Scalars['Int'];
  /** Total number of DISMISSED responses */
  dismissedCount: Scalars['Int'];
  /** Total number of EXPIRED responses */
  expiredCount: Scalars['Int'];
  /** Total number of PENDING responses */
  pendingCount: Scalars['Int'];
  /** Total number of responses */
  totalCount: Scalars['Int'];
};

export enum SyncState {
  DONE = 'DONE',
  IN_PROGRESS = 'IN_PROGRESS',
  NEVER_HAPPENED = 'NEVER_HAPPENED'
}

export type TeamsUserSearchResult = {
  __typename: 'TeamsUserSearchResult';
  avatar: Maybe<Scalars['String']>;
  familyName: Maybe<Scalars['String']>;
  givenName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type Theme = {
  __typename: 'Theme';
  color: Maybe<Scalars['String']>;
  logo: Maybe<Scalars['String']>;
};

export type Ticket = {
  /** Can be null if nobody is assigned */
  assignees: Maybe<UsersAndGroups>;
  completedForm: CompletedTicketForm;
  id: Scalars['ID'];
  /** human-friendly identifier like 'CAT-123' */
  key: Scalars['String'];
  requester: Maybe<User>;
  title: Scalars['String'];
};

export type TicketAddToTestTableInput = {
  name: Scalars['String'];
};

export type TicketAddToTestTableResponse = {
  __typename: 'TicketAddToTestTableResponse';
  row: TicketTestTableRow;
};

export type TicketAnswerMenuChoice = {
  __typename: 'TicketAnswerMenuChoice';
  option: TicketQuestionMenuOption;
  /** Required if option.quanitifiable is true */
  quantity: Maybe<Scalars['Int']>;
};

export type TicketQuestion = {
  id: Scalars['ID'];
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingChoices = AbstractTicketQuestionExpectingChoices & TicketQuestion & {
  __typename: 'TicketQuestionExpectingChoices';
  chooseOnlyOneOption: Scalars['Boolean'];
  id: Scalars['ID'];
  options: Array<TicketQuestionOption>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingChoicesInput = {
  chooseOnlyOneOption: Scalars['Boolean'];
  options: Array<TicketQuestionOptionInput>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingMenuChoices = AbstractTicketQuestionExpectingChoices & TicketQuestion & {
  __typename: 'TicketQuestionExpectingMenuChoices';
  chooseOnlyOneOption: Scalars['Boolean'];
  id: Scalars['ID'];
  options: Array<TicketQuestionMenuOption>;
  /** e.g. 'Which way do you want the room to be set up?' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingMenuChoicesInput = {
  chooseOnlyOneOption: Scalars['Boolean'];
  options: Array<TicketQuestionMenuOptionInput>;
  /** e.g. 'Appetizers' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingText = TicketQuestion & {
  __typename: 'TicketQuestionExpectingText';
  id: Scalars['ID'];
  multilineAnswer: Scalars['Boolean'];
  /** 'What is your expense code?' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionExpectingTextInput = {
  multilineAnswer: Scalars['Boolean'];
  /** 'What is your expense code?' */
  prompt: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TicketQuestionInput = {
  expectingChoices?: InputMaybe<TicketQuestionExpectingChoicesInput>;
  expectingMenuChoices?: InputMaybe<TicketQuestionExpectingMenuChoicesInput>;
  expectingText?: InputMaybe<TicketQuestionExpectingTextInput>;
};

export type TicketQuestionMenuOption = AbstractTicketQuestionOption & {
  __typename: 'TicketQuestionMenuOption';
  /** 'A perfectly adequate sandwich with cheese' */
  description: Maybe<Scalars['Markdown']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  /** 'Sandwich' */
  name: Scalars['String'];
  quantifiable: Scalars['Boolean'];
};

export type TicketQuestionMenuOptionInput = {
  /** 'Two pieces of bread with meat in between - yum!' */
  description?: InputMaybe<Scalars['Markdown']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  /** 'Sandwich' */
  name: Scalars['String'];
  quantifiable: Scalars['Boolean'];
};

export type TicketQuestionOption = AbstractTicketQuestionOption & {
  __typename: 'TicketQuestionOption';
  /** 'We'll set up tables facing in *one* direction, evenly spaced' */
  description: Maybe<Scalars['Markdown']>;
  id: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
  /** 'Classroom' */
  name: Scalars['String'];
};

export type TicketQuestionOptionInput = {
  /** 'We'll set up tables facing in *one* direction, evenly spaced' */
  description?: InputMaybe<Scalars['Markdown']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  /** 'Classroom' */
  name: Scalars['String'];
};

export type TicketReadFromTestTableInput = {
  filterByName?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<TicketReadFromTestTableSortBy>;
};

export type TicketReadFromTestTableResponse = {
  __typename: 'TicketReadFromTestTableResponse';
  rows: Array<TicketTestTableRow>;
};

export enum TicketReadFromTestTableSortBy {
  CREATED_AT = 'CREATED_AT',
  NAME = 'NAME'
}

export type TicketRegardsEventAtSpace = {
  __typename: 'TicketRegardsEventAtSpace';
  event: Maybe<Event>;
  eventEnd: Scalars['ISODateTime'];
  eventStart: Scalars['ISODateTime'];
  space: Maybe<Space>;
};

export type TicketTestQueryInput = {
  blah?: InputMaybe<Scalars['ISODateTime']>;
  id: Scalars['ID'];
  whatever: Scalars['Boolean'];
};

export type TicketTestQueryResponse = {
  __typename: 'TicketTestQueryResponse';
  blah: Maybe<Scalars['ISODateTime']>;
  id: Scalars['ID'];
  whatever: Scalars['Boolean'];
};

export type TicketTestTableRow = {
  __typename: 'TicketTestTableRow';
  createdAt: Scalars['ISODateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TimeFrame = {
  __typename: 'TimeFrame';
  end: Scalars['String'];
  start: Scalars['String'];
};

export type TimeFrameInput = {
  end: Scalars['LocalEndTime'];
  start: Scalars['LocalEndTime'];
};

export enum TimeInclusivityType {
  EXCLUSIVE = 'EXCLUSIVE',
  INCLUSIVE = 'INCLUSIVE'
}

export type UnassignStickerRequest = {
  stickerId: Scalars['ID'];
};

export type UnauthenticatedGuest = {
  __typename: 'UnauthenticatedGuest';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** UnauthenticatedVisitResult represents the visit for a single GuestInvite. The result is "unauthenticated" because it is available to unauthenticated queries. A UnauthenticatedVisitResult is the guest (GuestInvite) along with information about their associated Visit. */
export type UnauthenticatedVisitResult = {
  __typename: 'UnauthenticatedVisitResult';
  arrivalInstructions: Maybe<Scalars['String']>;
  arrivalLocation: PublicLocationOrDefault;
  arrivalLocationId: Scalars['String'];
  checkinOn: Maybe<Scalars['String']>;
  checkoutOn: Maybe<Scalars['String']>;
  customVisitType: Scalars['String'];
  guest: UnauthenticatedGuest;
  healthCheckpointSurveyResponse: Maybe<GenericHealthCheckpointResponse>;
  host: PublicUserWithEmail;
  /** The ID of the GuestInvite */
  id: Scalars['ID'];
  organization: PublicOrganization;
  startTime: Scalars['ISODateTime'];
  status: GuestInviteStatus;
  /** The ID of the associated visit */
  visitId: Scalars['ID'];
  /** @deprecated replaced by the more flexible customVisitType */
  visitType: VisitType;
};

export enum UnbookableReasonType {
  ADMIN_ONLY_BOOKING = 'ADMIN_ONLY_BOOKING',
  CONFLICT_WITH_ANOTHER_MEETING = 'CONFLICT_WITH_ANOTHER_MEETING',
  LACKING_SPACE_BOOKING_PERMISSIONS = 'LACKING_SPACE_BOOKING_PERMISSIONS',
  OUTSIDE_WORKING_HOURS = 'OUTSIDE_WORKING_HOURS',
  OVER_MAX_LENGTH = 'OVER_MAX_LENGTH',
  RECURRENCE_NOT_ALLOWED = 'RECURRENCE_NOT_ALLOWED',
  TOO_FAR_IN_FUTURE = 'TOO_FAR_IN_FUTURE'
}

export type UnconfirmableReason = {
  __typename: 'UnconfirmableReason';
  reason: UnconfirmableReasonType;
  value: Maybe<Scalars['String']>;
};

export enum UnconfirmableReasonType {
  CANNOT_CONFIRM_SERIES_MASTER = 'CANNOT_CONFIRM_SERIES_MASTER',
  CANNOT_CONFIRM_SHARED_DESK = 'CANNOT_CONFIRM_SHARED_DESK',
  CHECKIN_DISABLED = 'CHECKIN_DISABLED',
  MISSING_RESERVATION_END_TIME = 'MISSING_RESERVATION_END_TIME',
  OUTSIDE_OF_CHECK_IN_WINDOW = 'OUTSIDE_OF_CHECK_IN_WINDOW'
}

export type UpdateActivityInput = {
  activityLocation?: InputMaybe<Scalars['String']>;
  /** Should be a URL */
  coverImageUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['ISODateTime'];
  start: Scalars['ISODateTime'];
  timezone: Scalars['String'];
  title: Scalars['String'];
  virtualLocation?: InputMaybe<Scalars['String']>;
};

export type UpdateDefaultPassInput = {
  isDefaultAccessPassEnabled: Scalars['Boolean'];
};

export type UpdateDeskReservationResponse = {
  __typename: 'UpdateDeskReservationResponse';
  updatedReservations: Array<UpdatedReservation>;
};

export type UpdateEventError = {
  __typename: 'UpdateEventError';
  message: Maybe<Scalars['String']>;
  reason: Maybe<EventUpdateFailReasons>;
};

/** Reservation response */
export type UpdateEventResponse = {
  __typename: 'UpdateEventResponse';
  /** If the event has not been updated, an explanation will be provided */
  error: Maybe<UpdateEventError>;
  /** True if event has been updated */
  eventUpdated: Scalars['Boolean'];
  /** Updated event model */
  updatedEvent: Maybe<Event>;
};

/** Set a field to null to avoid changing it on the reservation. */
export type UpdateMyReservationDeskAndTimeFrameInput = {
  end?: InputMaybe<DateWithTimezone>;
  reservationId: Scalars['ID'];
  seatId?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<DateWithTimezone>;
};

export type UpdateWorkAreaInput = {
  deskIds: Array<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateWorkAreasInput = {
  workAreasToCreate: Array<CreateWorkAreaInput>;
  workAreasToDelete: Array<Scalars['ID']>;
  workAreasToUpdate: Array<UpdateWorkAreaInput>;
};

export type UpdatedReservation = {
  __typename: 'UpdatedReservation';
  id: Scalars['ID'];
};

export type User = {
  __typename: 'User';
  avatar: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  defaultLocation: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in favor of primary_email */
  email: Maybe<Scalars['String']>;
  familyName: Maybe<Scalars['String']>;
  givenName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationState: LocationState;
  name: Maybe<Scalars['String']>;
  notificationPreferences: NotificationPreferences;
  officeAccess: Array<OfficeAccess>;
  primaryEmail: Maybe<Email>;
  reservations: Array<Reservation>;
  reservationsAtLocationGroupedByDateAscending: Array<UserDeskReservationByDate>;
  reservationsGroupedByDate: Array<UserDeskReservationByDate>;
  slug: Maybe<Scalars['String']>;
  timeZone: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  workingHours: Maybe<Array<Maybe<UserWorkingHour>>>;
};


export type UserLocationStateArgs = {
  campusId?: InputMaybe<Scalars['ID']>;
  endTime: Scalars['Date'];
  locationId: Scalars['ID'];
  recurrence?: InputMaybe<Scalars['String']>;
  startTime: Scalars['Date'];
};


export type UserReservationsArgs = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};


export type UserReservationsAtLocationGroupedByDateAscendingArgs = {
  endTime: Scalars['ISODateTime'];
  locationId: Scalars['ID'];
  startTime: Scalars['ISODateTime'];
};


export type UserReservationsGroupedByDateArgs = {
  endTime: Scalars['Date'];
  startTime: Scalars['Date'];
};

export type UserAssignment = {
  __typename: 'UserAssignment';
  assignments: Array<Maybe<DeskAssignmentSchedule>>;
  avatarUrl: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName: Maybe<Scalars['String']>;
};

export type UserDeskReservation = {
  __typename: 'UserDeskReservation';
  reservations: Array<Reservation>;
  userId: Scalars['ID'];
};

export type UserDeskReservationByDate = {
  __typename: 'UserDeskReservationByDate';
  date: Scalars['Date'];
  reservations: Array<UserTruncatedReservation>;
};

export type UserInOffice = {
  __typename: 'UserInOffice';
  deskReservations: Array<Reservation>;
  employeeVisits: Array<EmployeeVisit>;
  userId: Scalars['ID'];
};

export type UserInOfficeByDay = {
  __typename: 'UserInOfficeByDay';
  date: Scalars['String'];
  userInOffice: Maybe<UserInOffice>;
};

export type UserNotificationsSetting = {
  __typename: 'UserNotificationsSetting';
  name: Scalars['String'];
  ownerId: Scalars['ID'];
  values: Array<Maybe<NotificationSettingType>>;
};

export type UserOrGroup = {
  __typename: 'UserOrGroup';
  userOrGroup: UserOrGroupUnion;
};

/** Either a group ID or a user ID is required */
export type UserOrGroupInput = {
  groupId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UserOrGroupUnion = Group | User;

export type UserSetting = {
  __typename: 'UserSetting';
  dataType: SettingDataType;
  ownerId: Scalars['ID'];
  ownerType: SettingOwnerType;
  slug: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export enum UserSortBy {
  FAVORITE = 'FAVORITE',
  NAME = 'NAME'
}

export enum UserSortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export type UserSyncStatus = {
  __typename: 'UserSyncStatus';
  state: SyncState;
  userCount: Scalars['Int'];
};

export type UserTimeFrame = {
  __typename: 'UserTimeFrame';
  end: Maybe<Scalars['Date']>;
  start: Maybe<Scalars['Date']>;
};

export type UserTruncatedDeskConfirmation = {
  __typename: 'UserTruncatedDeskConfirmation';
  confirmedAt: Maybe<Scalars['String']>;
  deviceId: Maybe<Scalars['ID']>;
  seatReservationId: Scalars['ID'];
};

export type UserTruncatedReservation = {
  __typename: 'UserTruncatedReservation';
  /** @deprecated Broken, use `truncatedConfirmation` */
  confirmation: Maybe<DeskConfirmation>;
  endTime: Maybe<Scalars['String']>;
  healthCheckpointStatus: Maybe<HealthCheckpointStatus>;
  /** Reservation Id */
  id: Scalars['ID'];
  recurrence: Maybe<Scalars['String']>;
  reservee: UserTruncatedReservee;
  seat: Desk;
  seriesId: Maybe<Scalars['ID']>;
  startTime: Scalars['String'];
  truncatedConfirmation: Maybe<UserTruncatedDeskConfirmation>;
  type: Scalars['String'];
  visibility: DeskReservationVisibility;
};

export type UserTruncatedReservee = {
  __typename: 'UserTruncatedReservee';
  email: Scalars['String'];
  participationStatus: Maybe<ParticipationStatus>;
  userId: Maybe<Scalars['ID']>;
  visitorId: Maybe<Scalars['ID']>;
};

export type UserWithDefault = {
  __typename: 'UserWithDefault';
  avatar: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['String']>;
  defaultLocation: Maybe<Scalars['String']>;
  familyName: Maybe<Scalars['String']>;
  givenName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** If true, no user by this ID can be found in the user system */
  isDefault: Scalars['Boolean'];
  name: Maybe<Scalars['String']>;
  primaryEmail: Maybe<Email>;
  slug: Maybe<Scalars['String']>;
  timeZone: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['String']>;
  workingHours: Maybe<Array<Maybe<UserWorkingHour>>>;
};

export type UserWorkingHour = {
  __typename: 'UserWorkingHour';
  day: Maybe<Scalars['Int']>;
  timeFrames: Array<Maybe<UserTimeFrame>>;
};

export type UsersAndGroups = {
  __typename: 'UsersAndGroups';
  groups: Array<Group>;
  /** Users and Groups in one list - use fragments to select fields */
  usersAndGroups: Array<UserOrGroupUnion>;
  /** groups also contain users, but these are in addition to the users from the groups */
  usersOutsideOfGroups: Array<User>;
};

/** At least one group or user ID is required */
export type UsersAndGroupsInput = {
  groupIds: Array<Scalars['ID']>;
  userIds: Array<Scalars['ID']>;
};

export enum VisitDocumentStatus {
  ARCHIVED = 'ARCHIVED',
  INACTIVE = 'INACTIVE',
  LIVE = 'LIVE'
}

export type Visit = {
  __typename: 'Visit';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions: Maybe<Scalars['String']>;
  /** Arrival location */
  arrivalLocation: Location;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** Custom reason for this visit */
  customVisitType: Maybe<Scalars['String']>;
  /** Number of guests in the visit */
  guestCount: Maybe<Scalars['Int']>;
  /** Guest invitations */
  guestInvites: Array<GuestInvite>;
  /** Guests for this visit */
  guests: Array<Guest>;
  /** Host robin user */
  host: UserWithDefault;
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** Visit unique identifier */
  id: Scalars['ID'];
  /** Organization */
  organization: Organization;
  /** How was the visit created? */
  organizer: VisitOrganizer;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /**
   * Reason for this visit
   * @deprecated replaced by the more flexible customVisitType
   */
  visitType: VisitType;
};


export type VisitGuestInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type VisitGuestsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export enum VisitAsyncRequestReport {
  DONE = 'DONE',
  ERROR = 'ERROR',
  HCP_CREATED = 'HCP_CREATED',
  VISIT_CREATED = 'VISIT_CREATED'
}

export enum VisitAsyncRequestStatus {
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  REQUEST_RECEIVED = 'REQUEST_RECEIVED'
}

export type VisitAsyncRequestStatusResponse = {
  __typename: 'VisitAsyncRequestStatusResponse';
  errorText: Array<Scalars['String']>;
  status: Maybe<VisitAsyncRequestStatus>;
};

export type VisitDocumentAgreementOutcome = {
  __typename: 'VisitDocumentAgreementOutcome';
  id: Scalars['ID'];
  outcome: VisitDocumentAgreementStatus;
};

export type VisitDocumentAgreementResponse = {
  __typename: 'VisitDocumentAgreementResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome: Maybe<VisitDocumentAgreementOutcome>;
};

export enum VisitDocumentAgreementStatus {
  ACCEPT = 'ACCEPT'
}

export type VisitDocumentAgreementWithNextDocumentResponse = {
  __typename: 'VisitDocumentAgreementWithNextDocumentResponse';
  completionStatus: VisitDocumentCompletionStatus;
  documentBody: Scalars['DocumentBody'];
  documentName: Scalars['String'];
  id: Scalars['ID'];
  isRequired: Scalars['Boolean'];
  /** If NULL, there is no next document */
  nextDocumentId: Maybe<Scalars['ID']>;
  possibleOutcomes: Array<VisitDocumentAgreementOutcome>;
  selectedOutcome: Maybe<VisitDocumentAgreementOutcome>;
  workflowId: Scalars['ID'];
};

export type VisitDocumentByDecisionResponse = {
  __typename: 'VisitDocumentByDecisionResponse';
  /** Date and time the document was accepted for specified decision */
  agreedOn: Scalars['ISODateTime'];
  /** Document body */
  body: Scalars['DocumentBody'];
  /** ID of document associated with descition */
  id: Scalars['ID'];
  /** Document name */
  name: Scalars['String'];
  /** Email addresses of individuals notified via email */
  notificationEmails: Array<Scalars['String']>;
  /** Associated visit model */
  visit: VisitOrDefault;
};

export enum VisitDocumentCompletionStatus {
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE'
}

export enum VisitDocumentNameStatusAndTypesField {
  LOCATION_ID = 'LOCATION_ID',
  STATUS = 'STATUS',
  VISIT_TYPE = 'VISIT_TYPE'
}

export type VisitDocumentNameStatusAndTypesFilter = {
  filterOn: VisitDocumentNameStatusAndTypesField;
  filterValues: Array<Scalars['String']>;
};

export type VisitDocumentNameStatusAndTypesQueryResponse = {
  __typename: 'VisitDocumentNameStatusAndTypesQueryResponse';
  /** ISO encoded timestamp representing date/time when this document was added */
  addedOn: Scalars['ISODateTime'];
  id: Scalars['ID'];
  /** Name of the document */
  name: Scalars['String'];
  /** Association status of this document to active or archived workflows */
  status: VisitDocumentStatus;
  /** The types of visits, if any, which are actively associated with this document */
  visitTypes: Array<Scalars['String']>;
};

export type VisitDocumentResponse = {
  __typename: 'VisitDocumentResponse';
  /** The full body text of the document. */
  documentBody: Scalars['String'];
  /** The unique name of the document */
  documentName: Scalars['String'];
  id: Scalars['ID'];
  /** The locations which are associated with this document */
  locationIds: Array<Scalars['String']>;
  /** Notification emails associated with document */
  notificationEmails: Array<Scalars['String']>;
  /** Association status of this document to active or archived workflows */
  status: VisitDocumentStatus;
  /** The types of visits which are associated with this document */
  visitTypes: Array<Scalars['String']>;
};

export enum VisitEventAcknowledgement {
  VISIT_STARTING_SOON = 'VISIT_STARTING_SOON'
}

export type VisitInputType = {
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions?: InputMaybe<Scalars['String']>;
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitMagicLinkAuthResponse = {
  __typename: 'VisitMagicLinkAuthResponse';
  id: Scalars['ID'];
  tenantId: Scalars['String'];
  token: Scalars['String'];
  type: Scalars['String'];
};

export type VisitOrDefault = {
  __typename: 'VisitOrDefault';
  /** What does the guest need to do when they arrive?. Default to null */
  arrivalInstructions: Maybe<Scalars['String']>;
  /** Arrival location */
  arrivalLocation: Maybe<Location>;
  /** Visit unique identifier */
  arrivalLocationId: Maybe<Scalars['ID']>;
  /** Number of guests in the visit */
  guestCount: Maybe<Scalars['Int']>;
  /** Guest invitations */
  guestInvites: Maybe<Array<GuestInvite>>;
  /** Guests for this visit */
  guests: Maybe<Array<Guest>>;
  /** Host robin user */
  host: Maybe<UserWithDefault>;
  /** ID of user acting as host for this visit */
  hostUserId: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  /** Organization */
  organization: Maybe<Organization>;
  /** How was the visit created? */
  organizer: Maybe<VisitOrganizer>;
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Maybe<Scalars['ISODateTime']>;
  /** Reason for this visit */
  visitType: Maybe<VisitType>;
};


export type VisitOrDefaultGuestInvitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type VisitOrDefaultGuestsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type VisitOrganizer = {
  __typename: 'VisitOrganizer';
  /** The arrival display that the visit was created on, if exists */
  arrivalDisplay: Maybe<PublicArrivalDisplay>;
  /** The ID of the entity from where the visit was created */
  id: Scalars['ID'];
  /** How the visit was created */
  type: VisitOrganizerType;
};

export enum VisitOrganizerType {
  ARRIVAL_DISPLAY = 'ARRIVAL_DISPLAY',
  USER = 'USER'
}

export enum VisitType {
  CUSTOMER_VISIT = 'CUSTOMER_VISIT',
  EVENT = 'EVENT',
  JOB_INTERVIEW = 'JOB_INTERVIEW',
  MAINTENANCE = 'MAINTENANCE',
  OTHER = 'OTHER',
  VIP_VISIT = 'VIP_VISIT'
}

export type VisitWithIdInputType = {
  /** ID of the location where the guests should arrive and check-in */
  arrivalLocationId: Scalars['ID'];
  /** ID of user acting as host for this visit */
  hostUserId: Scalars['ID'];
  /** ID of the visit */
  id: Scalars['ID'];
  /** Start time of visit represented by ISO 8601 date-time */
  startTime: Scalars['ISODateTime'];
  /** Reason for this visit */
  visitType: VisitType;
};

export type VisitWorkflowOverview = {
  __typename: 'VisitWorkflowOverview';
  /** Workflow documents */
  documents: Array<WorkflowDocumentOverviewForVisitor>;
  /** Workflow ID */
  id: Scalars['ID'];
};

export type WeeklyScheduleResult = {
  __typename: 'WeeklyScheduleResult';
  fri: Scalars['Boolean'];
  id: Scalars['String'];
  instanceEndTime: Scalars['UtcTime'];
  instanceStartTime: Scalars['UtcTime'];
  location: Maybe<Location>;
  mon: Scalars['Boolean'];
  sat: Scalars['Boolean'];
  sun: Scalars['Boolean'];
  thu: Scalars['Boolean'];
  tue: Scalars['Boolean'];
  wed: Scalars['Boolean'];
};

export type WhosInData = {
  __typename: 'WhosInData';
  byLocation: Maybe<Array<WhosInDataByLocation>>;
  totalNumberOfWhosIn: Scalars['Int'];
  whosIn: Maybe<Array<WhosInUser>>;
};


export type WhosInDataTotalNumberOfWhosInArgs = {
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type WhosInDataWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WhosInDataByDay = {
  __typename: 'WhosInDataByDay';
  date: Scalars['ISODateTime'];
  whosInData: Maybe<WhosInData>;
  /** @deprecated Deprecated in favor of whosInData.byLocation */
  whosInDataByLocation: Maybe<Array<WhosInDataByLocation>>;
};

export type WhosInDataByLocation = {
  __typename: 'WhosInDataByLocation';
  locationId: Scalars['ID'];
  totalNumberOfWhosIn: Scalars['Int'];
  whosIn: Maybe<Array<WhosInUser>>;
  /** @deprecated Deprecated in favor of totalNumberOfWhosIn and whosIn */
  whosInData: WhosInData;
};


export type WhosInDataByLocationWhosInArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  preferredUserIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type WhosInUser = {
  __typename: 'WhosInUser';
  deskReservationIds: Array<Scalars['String']>;
  employeeVisitId: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  locationId: Scalars['ID'];
  userEmail: Maybe<Scalars['String']>;
  userId: Maybe<Scalars['String']>;
};

export type WindowAvailability = {
  __typename: 'WindowAvailability';
  end: Maybe<Scalars['Date']>;
  /** @deprecated Deprecated because the end becomes representing the pillEnd */
  pillEnd: Maybe<Scalars['Date']>;
  start: Maybe<Scalars['Date']>;
  status: Maybe<Availability>;
};

export type WorkArea = {
  __typename: 'WorkArea';
  /** Total desks within the level that fit within various scheduling type and status buckets */
  deskSchedulingAggregates: SchedulingAggregateOfDesks;
  desks: Array<Desk>;
  id: Scalars['ID'];
  level: Level;
  name: Scalars['String'];
};

export type WorkPolicy = {
  __typename: 'WorkPolicy';
  createdAt: Maybe<Scalars['ISODateTime']>;
  id: Scalars['ID'];
  interval: WorkPolicyInterval;
  isDefault: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rank: Maybe<Scalars['Int']>;
  status: WorkPolicyStatus;
  tooltipMessage: Maybe<Scalars['String']>;
};

export type WorkPolicyInput = {
  interval: WorkPolicyIntervalInput;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status: WorkPolicyStatus;
  tooltipMessage?: InputMaybe<Scalars['String']>;
};

export type WorkPolicyInterval = {
  __typename: 'WorkPolicyInterval';
  frequency: Maybe<Scalars['Int']>;
  intervalType: WorkPolicyIntervalType;
  specificDays: Maybe<Array<Scalars['Int']>>;
};

export type WorkPolicyIntervalInput = {
  frequency?: InputMaybe<Scalars['Int']>;
  intervalType: WorkPolicyIntervalType;
  specificDays?: InputMaybe<Array<Scalars['Int']>>;
};

export enum WorkPolicyIntervalType {
  MONTHLY = 'MONTHLY',
  SPECIFIC_DAYS = 'SPECIFIC_DAYS',
  WEEKLY = 'WEEKLY'
}

export enum WorkPolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SCHEDULED = 'SCHEDULED'
}

export type WorkPolicyWithProgress = {
  __typename: 'WorkPolicyWithProgress';
  id: Scalars['ID'];
  interval: WorkPolicyInterval;
  message: Maybe<Scalars['String']>;
  minimumDaysProgress: Maybe<Scalars['Int']>;
  /** @deprecated Use minimumDaysProgress instead. */
  progress: Scalars['Int'];
  specificDaysProgress: Maybe<Array<Scalars['Int']>>;
  tooltipMessage: Maybe<Scalars['String']>;
};

export type WorkflowDocumentOverviewForVisitor = {
  __typename: 'WorkflowDocumentOverviewForVisitor';
  /** Is document complete for this visit */
  completionStatus: VisitDocumentCompletionStatus;
  id: Scalars['ID'];
  /** Is document required in workflow */
  isRequired: Scalars['Boolean'];
  /** Name of the document */
  name: Scalars['String'];
};

export type WorkingHours = {
  __typename: 'WorkingHours';
  day: Maybe<Scalars['Int']>;
  timeFrames: Array<TimeFrame>;
};

export type WorkingHoursInput = {
  day?: InputMaybe<DayOfWeek>;
  timeFrames: Array<TimeFrameInput>;
};

/** A zone is an area within a space that represents a collection of desks or a table */
export type Zone = {
  __typename: 'Zone';
  desks: Array<Desk>;
  filteredDesksByAmenityIds: Array<Desk>;
  /** Zone ID is a unique identifier for the zone */
  id: Scalars['ID'];
  /** Name of the zone */
  name: Scalars['String'];
  /** Identifies the type of this zone. Zones can be Pods or Table */
  type: ZoneType;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneDesksArgs = {
  filters?: InputMaybe<Array<DeskFilters>>;
};


/** A zone is an area within a space that represents a collection of desks or a table */
export type ZoneFilteredDesksByAmenityIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type ZoneDeskReservation = {
  __typename: 'ZoneDeskReservation';
  reservations: Array<Reservation>;
  zoneId: Scalars['ID'];
};

/** Describes the type of zone */
export enum ZoneType {
  /** A pod is a collection of desks */
  POD = 'POD',
  /** A zone with the table type is typically a large table with many seats */
  TABLE = 'TABLE'
}

import React from 'react';
import {getDisplayName} from '@robinpowered/atlas-common';

const Context = React.createContext({});

export const {Consumer: MapKeyConsumer, Provider: MapKeyProvider} = Context;

/**
 * A HoC to provide map key context to given component.
 *
 * @param {Object} WrappedComponent The component to wrap & provide the map key for.
 * @return {Object} The wrapped component.
 */
export const withMapKey = (WrappedComponent) => {
  const WithMapKey = (props) => (
    <MapKeyConsumer>
      {(mapKey) => <WrappedComponent mapKey={mapKey} {...props} />}
    </MapKeyConsumer>
  );
  WithMapKey.displayName = `WithMapKey(${getDisplayName(WrappedComponent)})`;
  return WithMapKey;
};

import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from '../../../../contexts';
import { UserSortBy, UserSortOrder } from '../../../../__generated__/types';
import {
  UseAvatarListContainerDataGetAccountUsersByIds,
  UseAvatarListContainerDataQuery,
  UseAvatarListContainerDataQueryVariables,
} from './__generated__/useAvatarListContainerData.generated';

type AvatarListContainerAccountUser =
  UseAvatarListContainerDataGetAccountUsersByIds;

const PAGE_SIZE = 50;

const usersQueryParams = {
  limit: PAGE_SIZE,
  offset: 0,
  sortByField: [UserSortBy.FAVORITE, UserSortBy.NAME],
  sortOrder: UserSortOrder.ASCENDING,
};

const GET_ACCOUNT_USERS = gql`
  query UseAvatarListContainerData(
    $ids: [ID!]!
    $limit: Int
    $offset: Int
    $sortByField: [UserSortBy]
    $sortOrder: UserSortOrder
  ) {
    getAccountUsersByIds(
      ids: $ids
      limit: $limit
      offset: $offset
      sortByField: $sortByField
      sortOrder: $sortOrder
    ) {
      id
      name
      avatar
      slug
      isMyFavorite
    }
  }
`;

export const useAvatarListContainerData = (userIds: string[]) => {
  const { tenantId } = useApolloContext();
  const { data, loading } = useQuery<
    UseAvatarListContainerDataQuery,
    UseAvatarListContainerDataQueryVariables
  >(GET_ACCOUNT_USERS, {
    variables: {
      ids: userIds,
      ...usersQueryParams,
    },
    skip: !userIds || userIds.length === 0 || !tenantId,
  });
  return {
    users: mapQueryResultToUsers(data),
    loading,
  };
};

const mapQueryResultToUsers = (
  userDetails: UseAvatarListContainerDataQuery | undefined
): AvatarListContainerAccountUser[] => {
  if (userDetails?.getAccountUsersByIds) {
    return userDetails.getAccountUsersByIds.map((user) => {
      return {
        id: user?.id,
        name: user?.name,
        avatar: user?.avatar,
        slug: user?.slug,
        isMyFavorite: user?.isMyFavorite,
      };
    }) as AvatarListContainerAccountUser[];
  }
  return [];
};

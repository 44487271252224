import {
  ApolloError,
  ApolloQueryResult,
  OperationVariables,
  gql,
  useQuery,
} from '@apollo/client';
import { createContext, useContext } from 'react';
import {
  UseDayListDataQuery,
  UseDayListDataQueryVariables,
} from './graphql/__generated__/useDayListData.generated';
import { useDateContext } from '../Date/DateContext';
import { useUserContext } from '../User/UserContext';
import { useApolloContext } from '../ApolloContext';
import { useLocationsContext } from '../Location';

const DEFAULT_DATE_RANGE_LENGTH = 7;

// TODO: Investigate why these types are needed. We may
// be over fetching data if we need these narrower types
export type WhosInByDate = {
  date: string;
  whosIn: WhosInUser[];
};

export type WhosInUser = {
  userId: string | null;
  userEmail: string | null;
  isAnonymous: boolean;
};

type WhosInProviderProps = {
  children: React.ReactNode;
};

type WhosInContextType = {
  data: WhosInByDate[] | undefined;
  error: ApolloError | undefined;
  loading: boolean;
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<UseDayListDataQuery>>;
};

const WhosInContext = createContext<WhosInContextType>({
  data: undefined,
  error: undefined,
  loading: false,
  refetch: undefined,
});

const GET_WHOS_IN_BY_LOCATION_BY_DAY = gql`
  query UseDayListData($locationIds: [ID!]!, $dates: [LocalDate!]!) {
    getWhosInDataByLocationsByDay(locationIds: $locationIds, dates: $dates) {
      date
      whosInData {
        totalNumberOfWhosIn
        whosIn {
          isAnonymous
          userEmail
          userId
        }
      }
    }
  }
`;

export const WhosInProvider = ({ children }: WhosInProviderProps) => {
  const { weekScheduleDates } = useDateContext();
  const { currentUser } = useUserContext();
  const { tenantId } = useApolloContext();
  const { selectedLocation } = useLocationsContext();

  const dates = weekScheduleDates.map((date) => date.format('YYYY-MM-DD'));

  const {
    data: whosInData,
    error,
    refetch,
    loading,
  } = useQuery<UseDayListDataQuery, UseDayListDataQueryVariables>(
    GET_WHOS_IN_BY_LOCATION_BY_DAY,
    {
      skip: !currentUser?.id || !tenantId,
      variables: {
        locationIds: selectedLocation ? [selectedLocation.id] : [],
        dates,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  return (
    <WhosInContext.Provider
      value={{
        data: mapQueryResultToWhosIn(whosInData),
        error,
        loading,
        refetch,
      }}
    >
      {children}
    </WhosInContext.Provider>
  );
};

const mapQueryResultToWhosIn = (
  whosInData: UseDayListDataQuery | undefined
): WhosInByDate[] => {
  return (
    whosInData?.getWhosInDataByLocationsByDay.map((byDate) => {
      const users = byDate?.whosInData?.whosIn?.map((user) => {
        return {
          userId: user.userId,
          userEmail: user.userEmail,
          isAnonymous: user.isAnonymous,
        };
      });

      return {
        date: byDate.date,
        whosIn: users || [],
      };
    }) || []
  );
};

export const useWhosInContext = (): WhosInContextType => {
  return useContext(WhosInContext);
};

import React, { FC, useMemo } from 'react';
import {
  Flex,
  Colors,
  Heading,
  UtilityButton,
} from '@robinpowered/design-system';
import { Close } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { uniqBy } from 'lodash';
import { AmenitiesFilter } from './AmenitiesFilter';
import { useSpaceBookingContext } from '../../contexts/SpaceBooking/SpaceBookingContext';
import { Accordion, AccordionContent } from './Accordion';
import { useTranslation } from 'react-i18next';
import { CapacityFilter } from './CapacityFilter';
import { SpaceTypesFilter } from './SpaceTypesFilter';
import { FilterSelectionPills } from './FilterSelectionPills';
import { MapSpaceStateForLevelSpaces } from '../../contexts/SpaceBooking/hooks/__generated__/useSpaceStateQuery.generated';

type Props = {
  spaces?: MapSpaceStateForLevelSpaces[];
};

export const FiltersView: FC<Props> = ({ spaces }) => {
  const { t } = useTranslation('spaceBooking', { keyPrefix: 'filters' });
  const { t: tNoPrefix } = useTranslation('spaceBooking');
  const {
    updateAmenityFilter,
    updateSpaceTypeFilter,
    updateCapacityFilter,
    goBack,
  } = useSpaceBookingContext();
  const amenities = useMemo(
    () =>
      uniqBy(
        spaces?.flatMap((space) => space.amenities),
        'amenityId'
      ),
    [spaces]
  );

  const spaceTypesWithId = useMemo(
    () =>
      uniqBy(spaces, 'type').flatMap((space, index) =>
        space.type !== null ? { spaceType: space.type, id: index } : []
      ),
    [spaces]
  );

  const maxCapacity = useMemo(
    () => Math.max(...(spaces?.map((space) => space.capacity ?? 0) ?? [])),
    [spaces]
  );

  return (
    <Flex mt="8px">
      <Flex
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p="4px 16px"
        mb="8px"
      >
        <Heading.Regular>{t('title')}</Heading.Regular>
        <UtilityButton
          icon={Close}
          aria-label={tNoPrefix('aria.go_back')}
          onClick={goBack}
          autoFocus
        />
      </Flex>
      <FilterSelectionPills />
      <FilterWrapper>
        <Accordion startOpen label={t('amenities')}>
          <AccordionContent>
            <AmenitiesFilter
              amenities={amenities}
              onChange={updateAmenityFilter}
            />
          </AccordionContent>
        </Accordion>
      </FilterWrapper>
      <FilterWrapper>
        <Accordion startOpen label={t('capacity')}>
          <AccordionContent>
            <CapacityFilter
              maxCapacity={maxCapacity}
              onChange={updateCapacityFilter}
            />
          </AccordionContent>
        </Accordion>
      </FilterWrapper>
      <FilterWrapper>
        <Accordion startOpen label={t('space_type')}>
          <AccordionContent>
            <SpaceTypesFilter
              spaceTypesWithId={spaceTypesWithId}
              onChange={updateSpaceTypeFilter}
            />
          </AccordionContent>
        </Accordion>
      </FilterWrapper>
    </Flex>
  );
};

const FilterWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${Colors.Gray10};
  margin: 0 24px;
  margin-bottom: 12px;
`;

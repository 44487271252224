import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {
  featureShape,
  isSameFeature,
  shallowEqualObjects,
  createPropToEventMap,
  extractEventCallbackProps
} from '@robinpowered/atlas-common';
import FeatureEvents from '../FeatureEvents';
import CursorStyle from '../CursorStyle';
import FeatureState from '../FeatureState';
import {LayerEventTypeList} from '../../constants/layerEventType';

const propToEventMap = createPropToEventMap(LayerEventTypeList);
const eventToPropMap = Object.entries(propToEventMap).reduce(
  (map, [prop, event]) => ({...map, [event]: prop}),
  {}
);

class AtlasMarker extends Component {
  static propTypes = {
    /**
     * The feature to set state and user event handlers for.
     * @see {@link https://docs.mapbox.com/mapbox-gl-js/api/#map#setfeaturestate}
     */
    feature: featureShape.isRequired,
    /**
     * The layer specifier on which the events should be listened on.
     */
    specifier: PropTypes.string.isRequired,
    /**
     * Callback called when the marker is clicked.
     */
    onClick: PropTypes.func,
    /**
     * Callback called when the marker is moused over (i.e. hovered).
     */
    onMouseover: PropTypes.func
  };

  state = {
    featureState: {},
    hovered: false,
    showLabel: false
  };

  shouldComponentUpdate(nextProps, nextState) {
    const {feature, ...props} = nextProps;
    const {feature: featureCurrent, ...propsCurrent} = this.props;

    return (
      !shallowEqualObjects(props, propsCurrent) ||
      !isSameFeature(feature, featureCurrent) ||
      nextState.hovered !== this.state.hovered ||
      nextState.showLabel !== this.state.showLabel
    );
  }

  handleMouseMove = (event) => {
    if (!this.state.hovered) {
      this.setState((state) => {
        if (state.hovered) {
          return state;
        }

        return {
          ...state,
          hovered: true
        };
      });
    }

    if (!this.state.showLabel) {
      this.setState((state) => {
        if (state.showLabel) {
          return state;
        }

        return {
          ...state,
          showLabel: true
        };
      });
    }

    this.callback(event);
  };

  handleMouseOut = (event) => {
    if (this.state.hovered) {
      this.setState({
        hovered: false
      });
    }

    if (this.state.showLabel) {
      this.setState({
        showLabel: false
      });
    }

    this.callback(event);
  };

  callback = (event) => {
    const prop = this.props[eventToPropMap[event.type]];

    if (!prop) {
      return;
    }

    prop(this.props.feature, event);
  };

  attachFeatureToCallbacks(feature, callbacks) {
    return Object.entries(callbacks).reduce(
      (featureCallbacks, [key, callback]) => {
        featureCallbacks[key] = this.callback;
        return featureCallbacks;
      },
      {}
    );
  }

  render() {
    const {feature, specifier, ...callbacks} = this.props;

    const extractedCallbacks = extractEventCallbackProps(
      callbacks,
      LayerEventTypeList
    );
    const featureCallbacks = this.attachFeatureToCallbacks(
      feature,
      extractedCallbacks
    );

    return (
      <Fragment>
        {this.state.showLabel && (
          <FeatureState
            feature={feature}
            state={{
              'show-label': this.state.showLabel
            }}
          />
        )}
        <FeatureEvents
          feature={feature}
          specifier={specifier}
          {...featureCallbacks}
          onTouchstart={this.handleMouseMove}
          onMousemove={this.handleMouseMove}
          onMouseout={this.handleMouseOut}
        />
        {this.state.hovered &&
          // We only want to indicate that the marker can be clicked when
          // it can be interacted with.
          this.props.onClick && <CursorStyle cursor="pointer" />}
      </Fragment>
    );
  }
}

export default AtlasMarker;

import { gql, QueryResult, useQuery } from '@apollo/client';
import { useApolloContext } from '../..';
import {
  GetLevelsByIdsQuery,
  GetLevelsByIdsQueryVariables,
} from './../hooks/__generated__/useGetLevels.generated';

const GET_LEVELS_QUERY = gql`
  query GetLevelsByIds($ids: [ID!]!) {
    getLevelsByIds(ids: $ids) {
      id
      name
      mapIsAvailable
    }
  }
`;

export const useGetLevels: (
  levelIds: string[]
) => QueryResult<GetLevelsByIdsQuery, GetLevelsByIdsQueryVariables> = (
  levelIds: string[]
) => {
  const { tenantId } = useApolloContext();
  return useQuery<GetLevelsByIdsQuery, GetLevelsByIdsQueryVariables>(
    GET_LEVELS_QUERY,
    {
      variables: {
        ids: levelIds,
      },
      skip: !levelIds || levelIds.length === 0 || !tenantId,
    }
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Returns display name of given react component.
 *
 * @param {Object} Component The component instance for which to get the name.
 * @return {string} Name of the component, `Component` if not found.
 */
exports.default = (function (_a) {
    var displayName = _a.displayName, name = _a.name;
    return displayName || name || 'Component';
});

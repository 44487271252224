import { Dropdown, DropdownProps, Flex } from '@fluentui/react-northstar';
import { useCallback, useMemo } from 'react';
import Floors from '@robinpowered/icons/v2/Floors';
import styled from '@emotion/styled';
import { GetLevelsByIdsQuery } from '../../../contexts/DeskBooking/hooks/__generated__/useGetLevels.generated';

type LevelPickerProps = {
  levelData: GetLevelsByIdsQuery | undefined;
  selectedLevelId: string | undefined;
  onLevelChange: (levelId: string) => void;
};

type LevelDropdownItemValue = {
  key: string;
  header: string;
};

export const LevelPicker = ({
  levelData,
  selectedLevelId,
  onLevelChange,
}: LevelPickerProps) => {
  const sortedLevels = useMemo((): LevelDropdownItemValue[] => {
    const levels = levelData?.getLevelsByIds?.length
      ? [...levelData.getLevelsByIds]
      : [];
    return levels
      .sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true })
      )
      .map((f) => ({
        key: f.id,
        header: f.name,
      }));
  }, [levelData?.getLevelsByIds]);

  const handleLevelChange = useCallback(
    (
      event: React.MouseEvent | React.KeyboardEvent | null,
      data: DropdownProps
    ) => {
      const value = data.value as LevelDropdownItemValue;

      const selectedLevel = levelData?.getLevelsByIds.find(
        (f) => f.id === value.key
      );

      if (selectedLevel) {
        onLevelChange(selectedLevel.id);
      }
    },
    [levelData?.getLevelsByIds, onLevelChange]
  );

  return (
    <Flex
      vAlign="center"
      style={{
        boxShadow: '0px 2px 4px 0px #00000024',
        paddingLeft: '8px',
        borderRadius: '4px',
      }}
    >
      <FloorsIcons size={20} />
      <Dropdown
        highlightedIndex={sortedLevels.findIndex(
          (f) => f.key === selectedLevelId
        )}
        items={sortedLevels}
        value={sortedLevels.find((f) => f.key === selectedLevelId)?.header}
        onChange={handleLevelChange}
        fluid
        inverted
      />
    </Flex>
  );
};

const FloorsIcons = styled(Floors)`
  path {
    stroke-width: 1.5px;
  }
`;

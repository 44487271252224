import {
  useQuery,
  gql,
  OperationVariables,
  QueryResult,
  useMutation,
} from '@apollo/client';
import { IsShowWeekendsEnabledForMeIsShowWeekendsEnabledForMe as IsShowWeekendsEnabledForMe } from './__generated__/useShowWeekendsEnabledForMe.generated';

const GET_SHOW_WEEKENDS = gql`
  query IsShowWeekendsEnabledForMe {
    isShowWeekendsEnabledForMe {
      showWeekends
    }
  }
`;

const SET_SHOW_WEEKENDS = gql`
  mutation SetShowWeekendsForMe($input: SetShowWeekendsForMeInput!) {
    setShowWeekendsForMe(input: $input)
  }
`;

type UseIsShowWeekendsEnabledForMeAPI = {
  getShowWeekends: QueryResult<IsShowWeekendsEnabledForMe, OperationVariables>;
  setShowWeekends: (input: boolean) => Promise<void>;
};

export function useIsShowWeekendsEnabledForMe(
  tenantId: string | undefined
): UseIsShowWeekendsEnabledForMeAPI {
  const [setShowWeekendsData] = useMutation(SET_SHOW_WEEKENDS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_SHOW_WEEKENDS,
      },
    ],
  });

  const setShowWeekends = async (input: boolean) => {
    await setShowWeekendsData({
      variables: {
        input: {
          showWeekends: input,
        },
      },
    });
  };

  const getShowWeekends = useQuery<
    IsShowWeekendsEnabledForMe,
    OperationVariables
  >(GET_SHOW_WEEKENDS, {
    skip: !tenantId,
  });

  return {
    getShowWeekends,
    setShowWeekends,
  };
}

import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { ApolloError } from '@apollo/client';
import {
  PEOPLE_SELECTED_LOCATION,
  WEEK_VIEW_SELECTED_PLACES,
} from '../../constants/location';
import { useApolloContext, useUserContext } from '..';
import { Location } from './hooks/useOrgLocations';
import { useBestLocation } from './hooks';
import { useOrgLocations } from './hooks/useOrgLocations';
import { useTenantLocalStorage } from '../../utilities';

export type Campus = {
  id: string;
  name: string;
};
export type WorkingHours = {
  day: number | null;
  timeFrames: {
    start: string;
    end: string;
  }[];
};
export type CustomWorkingHours = {
  id: string;
  date: string;
  timeFrames: {
    start: string;
    end: string;
  }[];
};
export type OfficeAccess = {
  id: string;
  schedule: string[];
};
export type Levels = {
  id: string;
  zones: Zone[];
  mapIsAvailable: boolean | null;
  name: string;
};
export type Zone = {
  id: string;
  name: string;
};

export type OrgLocation = Location;

export type SelectedPlaces = {
  campusIds: string[];
  locationIds: string[];
};

type LocationsContextType = {
  locations: OrgLocation[];
  preferredLocation?: OrgLocation;
  loading: boolean;
  error?: ApolloError;
  selectedPlaces: SelectedPlaces;
  setSelectedPlaces: (selectedPlaces: SelectedPlaces) => void;
  refetch: () => void;
  selectedLocation?: OrgLocation;
  setSelectedLocationId: (val: string | undefined) => void;
  isLocationModalOpen: boolean;
  setIsLocationModalOpen: (val: boolean) => void;
};

export const locationsContext = createContext<LocationsContextType>({
  locations: [],
  loading: true,
  selectedPlaces: { campusIds: [], locationIds: [] },
  setSelectedPlaces: () => null,
  refetch: () => null,
  selectedLocation: undefined,
  setSelectedLocationId: (val: string | undefined) => null,
  isLocationModalOpen: false,
  setIsLocationModalOpen: (val: boolean) => null,
});

const isSelectionEmpty = (selection?: SelectedPlaces): boolean => {
  if (!selection) {
    return true;
  }
  return selection.campusIds.length === 0 && selection.locationIds.length === 0;
};

export const LocationsProvider: FC = ({ children }) => {
  const {
    currentUser,
    loading: orgLoading,
    error: orgError,
    refetch: orgRefetch,
  } = useUserContext();
  const { tenantId } = useApolloContext();

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);

  const {
    locations,
    sortedLocations,
    loading: locationsLoading,
    error: locationsError,
    called: locationsCalled,
    refetch: locationRefetch,
  } = useOrgLocations(currentUser?.id, tenantId, orgLoading);

  const preferredLocation = useBestLocation(sortedLocations);

  const [persistedSelectedPlaces, setSelectedPlaces] =
    useTenantLocalStorage<SelectedPlaces>(WEEK_VIEW_SELECTED_PLACES, {
      campusIds: [],
      locationIds: [],
    });

  const [persistedSelectedLocationId, setSelectedLocationId] =
    useTenantLocalStorage<string | undefined>(
      PEOPLE_SELECTED_LOCATION,
      undefined
    );

  const selectedPlaces = useMemo(() => {
    return persistedSelectedPlaces || { campusIds: [], locationIds: [] };
  }, [persistedSelectedPlaces]);

  useEffect(() => {
    if (preferredLocation && !persistedSelectedLocationId) {
      setSelectedLocationId(preferredLocation.id);
    }
  }, [preferredLocation, persistedSelectedLocationId, setSelectedLocationId]);

  const selectedLocation = useMemo(() => {
    return locations.find(
      (location) => location.id === persistedSelectedLocationId
    );
  }, [locations, persistedSelectedLocationId]);

  useEffect(() => {
    if (preferredLocation && isSelectionEmpty(selectedPlaces)) {
      setSelectedPlaces({ campusIds: [], locationIds: [preferredLocation.id] });
    }
  }, [preferredLocation, selectedPlaces, setSelectedPlaces]);

  // Consider loading to be true if the getLocations query hasn't been executed
  // yet. This prevents thrashing of the loading state on the initial load.
  const loading = orgLoading || !locationsCalled || locationsLoading;

  const refetchAll = useCallback(() => {
    if (orgRefetch) {
      orgRefetch();
    }
    if (locationRefetch) {
      locationRefetch();
    }
  }, [orgRefetch, locationRefetch]);

  return (
    <locationsContext.Provider
      value={{
        locations: sortedLocations,
        preferredLocation: preferredLocation,
        loading,
        refetch: refetchAll,
        error: orgError ?? locationsError,
        selectedPlaces,
        setSelectedPlaces,
        selectedLocation,
        setSelectedLocationId,
        isLocationModalOpen,
        setIsLocationModalOpen,
      }}
    >
      {children}
    </locationsContext.Provider>
  );
};

export const useLocationsContext = (): LocationsContextType => {
  return useContext(locationsContext);
};

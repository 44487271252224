import styled from '@emotion/styled';
import {
  Avatar,
  Body07,
  Colors,
  Column,
  Heading06,
} from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';
import { AnonymousAvatar } from '../Avatar/AnonymousAvatar';

type props = {
  userName?: string | null;
  avatar?: string | null;
  isDisabled?: boolean;
};

export const JoinOfficeCTA = ({
  userName,
  avatar,
  isDisabled,
}: props): JSX.Element => {
  const { t } = useTranslation('weekSchedule');

  return (
    <Container
      minHeight="132px"
      maxWidth="185px"
      alignItems="center"
      isDisabled={isDisabled}
    >
      <AvatarContainer>
        <Avatar name={userName || ''} src={avatar || ''} size={34} />
        <AnonAvatar />
        <AnonAvatar />
      </AvatarContainer>
      <TopByline>{'Office days are better with your team'}</TopByline>
      <BottomByline>
        {"Nobody has shared they're coming in yet. Be the first!"}
      </BottomByline>
    </Container>
  );
};

const Container = styled(Column)<{ isDisabled?: boolean }>`
  ${(props) =>
    props.isDisabled &&
    `
      justify-items: center;

    display: flex;
    > div {
      opacity: 0.7;
    }
    > h6 {
      opacity: 0.7;
    }
  `}
`;

const AnonAvatar = styled(AnonymousAvatar)`
  width: 34px;
  height: 34px;
`;

const AvatarContainer = styled.div`
  height: 34px;
  display: flex;

  > div {
    margin-left: -8px;
  }
`;

const TopByline = styled(Heading06)`
  text-align: center;
`;

const BottomByline = styled(Body07)`
  text-align: center;
`;

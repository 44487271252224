import { QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { toISOStringWithoutMilliseconds } from '../../../utilities';
import { useDateContext, useLocationsContext } from '../../../contexts';
import {
  GetMyEventsInTheRangeGetMyEventsInTheRange,
  GetMyEventsInTheRangeQuery,
} from './__generated__/useMyEvents.generated';

export type Event = GetMyEventsInTheRangeGetMyEventsInTheRange;

export const GET_MY_EVENTS = gql`
  query GetMyEventsInTheRange(
    $locationIds: [ID!]!
    $startTime: Date!
    $endTime: Date!
    $limit: Int
  ) {
    getMyEventsInTheRange(
      startTime: $startTime
      endTime: $endTime
      limit: $limit
    ) {
      id
      title
      startTime
      endTime
      isAllDay
      invitees {
        id
        responseStatus
        user {
          id
        }
      }
      spaces {
        id
      }
      whosInData(locationIds: $locationIds) {
        totalNumberOfWhosIn
        whosIn {
          userId
          deskReservationIds
          employeeVisitId
          locationId
        }
      }
    }
  }
`;

export const useMyEvents = (
  limit?: number
): QueryResult<GetMyEventsInTheRangeQuery> => {
  const { weekStartDate, weekEndDate } = useDateContext();
  const { selectedLocation } = useLocationsContext();

  return useQuery<GetMyEventsInTheRangeQuery>(GET_MY_EVENTS, {
    variables: {
      locationIds: selectedLocation ? [selectedLocation.id] : [],
      startTime: toISOStringWithoutMilliseconds(weekStartDate),
      endTime: toISOStringWithoutMilliseconds(weekEndDate),
      limit: limit,
    },
    skip: !selectedLocation,
  });
};
